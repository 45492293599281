export const setIsLoggedIn = ( state, isLoggedIn ) => {
  
    state.isLoggedIn = isLoggedIn;
}
export const setIsLoading = ( state, isLoading ) => {

    state.isLoading = isLoading;
}
export const setUserData = ( state, userData ) => {
  localStorage.setItem("user", JSON.stringify(userData));
  state.userData = userData;
}
export const setSubscription = ( state, userSubscription ) => {
  state.userData.subscription = userSubscription;
  localStorage.setItem("user", JSON.stringify(state.userData) )
}
export const setWallet = ( state, wallet ) => {
  state.wallet = wallet;
}
export const setWalletTotal = ( state, walletTotal ) => {
  state.walletTotal = walletTotal;
}
export const setOpenSuscriptionModal = ( state, isOpen ) => {
  state.isOpenSubscriptionModal = isOpen;
}
export const setSubscriptionRequired = ( state, subscriptionName ) => {
  state.subscriptionRequired = subscriptionName;
}
export const cleanUserData = ( state ) => {
  const setToNull = (objectData)=>{
    if(!state[objectData])return
    Object.keys(state[objectData]).forEach( field =>{
      state[objectData][field] = null;
    })
  }
  setToNull("userData");
}