<template>
    <div>
       <dropzone-file-upload
        ref="image"
        v-model="selectedFile"
        :multiple="false"
        :options="{ acceptedFiles: acceptedFiles ? acceptedFiles: 'image/jpeg, image/jpg, image/png' }"
        @change="onFileSelected"
        :placeholder="placeholderInputFile"
        :validationsImage="validationsDrop"
        :isRemovingFile="isRemovingFile"
        :disabled="disabled"
        >
        </dropzone-file-upload>
    </div>
    <modal
        v-model:show="isModalVueCropper"
        modal-classes="border-radius-stevie"
        body-classes="p-0 border-radius-stevie bg-athens-gray"
        modal-content-classes="border-radius-stevie modal-animation"
    >
        <div class="crop-image">
        <Cropper
            ref="cropper"
            class="w-100 bg-white"
            :src="selectedFile"
            :min-height="validationsImage.minHeight"
            :min-width="validationsImage.minWidth"
            :max-height="validationsImage.maxHeight"
            :max-width="validationsImage.maxWidth"
            :stencil-props="{
                aspectRatio: aspectRatio,
            }"
            :image-restriction="imageRestriction ? imageRestriction : 'fit-area'"
        />
          <div class="crop-container d-flex justify-content-between position-absolute">
            <div
              class="crop-button border-radius-stevie font-weight-500 cursor-pointer base-button btn-default"
              @click="crop"
            >{{ $t("cropImage") }}</div>
            <div
              class="crop-button border-radius-stevie font-weight-500 cursor-pointer base-button btn-default"
              @click="isModalVueCropper = false"
            >{{ $t("close") }}</div>
          </div>
        </div>
    </modal>
</template>
<script>

import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
import modal from "@/components/Modal";
import 'vue-advanced-cropper/dist/style.css';
import { Cropper } from 'vue-advanced-cropper';
import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { watch } from '@vue/runtime-core';
import { computed } from 'vue';

export default {
    name:'ImageCropper',
    components:{
        DropzoneFileUpload,
        modal,
        Cropper,
    },
    props:[ "imageRestriction" , "isAllowedCloseModal", "disabled", "validationsImage", "aspectRatio", "fileName", "reset", "acceptedFiles", "fileValue"],
    emits:["changeImage"],
    setup(props, { emit }) {
        const isModalVueCropper = ref(false)
        const mimeType = ref();
        const selectedFile = ref();
        const cropper = ref(null);
        const resultingImage = ref(null);
        const image = ref(null);
        const { t } = useI18n();
        const toast = useToast();
        const validationsDrop = {maxSize: props.validationsImage.maxSize};
        const isRemovingFile = ref(false);
        const placeholderInputFile = computed(()=>{
            let { minHeight, minWidth, maxHeight, maxWidth }= props.validationsImage;
            if(minWidth && minHeight ){
                return t('campaignForm.placeholder.image', [minWidth, minHeight])
            }else if(maxHeight && maxWidth){
                return t('landingForm.placeholder.imageMax', [maxWidth, maxHeight])
            }else{
                return t("dropImageHere")
            }
        })
        const onFileSelected = (e) => {
            if(e.length === 0){
                isRemovingFile.value = false
                return
            }
            isModalVueCropper.value = true
            const file = e[0];
            mimeType.value = file.type
            if (typeof FileReader === 'function') {
                isModalVueCropper.value = true
                const reader = new FileReader()
                reader.onload = (event) => {
                selectedFile.value = event.target.result
                }
                reader.readAsDataURL(file)
            } else {
                toast.success(t("notification.error.fileReaderNotSupported"));
                isModalVueCropper.value = false;
            }
        }

        const crop = () => {
            const { canvas } = cropper.value.getResult();
            resultingImage.value = dataURLtoFile(canvas.toDataURL(mimeType.value, 1.0), props.fileName);
            if(!isValidImage(canvas, resultingImage.value.size)){
                resultingImage.value = null;
                isRemovingFile.value = true;
            }
            changeImage(resultingImage.value)
            isModalVueCropper.value = false;
        }

        const dataURLtoFile = (dataUrl, fileName) => {
            let arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], fileName, {type:mime});
        }
        const changeImage = (image)=>{
            emit('changeImage',image);
        }
        const isValidImage = (file, size) => {
            if(!file || !props.validationsImage) return true;

            let isValid = true;
            let ratioMin = null;
            let ratioMax = null;
            let ratioValidation = null;
            const { height, width } = file;

            const { minHeight, minWidth, aspectRatio, maxWidth, maxHeight, maxSize } = props.validationsImage;
            if(aspectRatio) {
                ratioValidation = (width/height).toFixed(2);
                ratioMax = aspectRatio + (aspectRatio * 0.05);
                ratioMin = aspectRatio - (aspectRatio * 0.05);
            }

            //maxSize and size in bytes
            if(maxSize && size > maxSize){
                isValid = false;
                toast.warning(t("formValidations.invalidCroppedSize", [`${Math.round(maxSize/1024)} KB max.` ]));
            }

            if(minHeight && height < minHeight){
                isValid = false
                toast.warning(t("formValidations.invalidHeight", [`${minHeight}px min` ]));
            }
            if(minWidth && width < minWidth){
                isValid = false
                toast.warning(t("formValidations.invalidWidth", [`${minWidth}px min` ]));
            }
            if(aspectRatio && (ratioValidation < ratioMin) || (ratioValidation > ratioMax)){
                isValid = false;
                toast.warning(t("formValidations.invalidRatio"));
            }
            if(maxWidth && width > maxWidth){
                isValid = false
                toast.warning(t("formValidations.invalidWidth", [`${maxWidth}px max` ]));
            }
            if(maxHeight && width > maxHeight){
                isValid = false
                toast.warning(t("formValidations.invalidWidth", [`${maxHeight}px max` ]));
            }
            return isValid
        }
        watch(() => props.resetCropped, (value) => {
            if(value === true){
                isRemovingFile.value = true;
                selectedFile.value = false;
            }
        })

        watch(() => props.fileValue, (value) => {
            if(value === null) {
                image.value.removeAllFiles();
            }
        })

        return{
            isModalVueCropper,
            mimeType,
            selectedFile,
            cropper,
            resultingImage,
            image,
            validationsDrop,
            isRemovingFile,
            placeholderInputFile,
            onFileSelected,
            changeImage,
            crop
        }


    },
}
</script>
