<template>
  <div class="">
    <Form
      id="updatePersonalInfo"
      class="mt-6"
      @submit="update"
      :validation-schema="schema"
      :initialValues="targets"
      v-slot="{ errors }"
    >
      <div class="row">
        <div class="col-12 col-lg-6 mb-4">
          <div class="row m-0">
            <div class="col-12 col-sm-8 px-0 d-flex flex-column">
              <label for="website" class="label d-block">{{$t('website')}}</label>
              <Field
                id="website"
                name="website"
                type="url"
                :label="$t('website')"
                :placeholder="$t('urlEnter')"
                class="input-stevie"
              ></Field>
              <ErrorMessage name="website" class="text-danger text-required-error" />
            </div>

            <div class="col-12 col-sm-4 px-0 pl-sm-1 d-flex flex-column">
              <label for="companySize">{{$t('companySize')}}</label>
              <el-select
                v-model="companyType"
              >
                <el-option
                  v-for="companySize in companyTypes"
                  :key="companySize.value"
                  :value="companySize.value"
                  :label="`${$t(`enterprises.${companySize.size}`)} : ${$t(`employeesNumber.${companySize.employees}`)}`"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6  mb-4">
          <div class="w-100 mt--2">
            <label for="google">{{$t('google')}}</label>
            <Field name="google" v-slot="{ field }">
              <el-autocomplete
                v-model="googlePlacesInput"
                :fetch-suggestions="querySearchAsync"
                :trigger-on-focus="false"
                :placeholder="$t('google')"
                @select="selectGooglePlaces"
                @blur="blurHandleGooglePlaces"
                class="autocomplete-stevie w-100"
              />
            </Field>
            <ErrorMessage name="google" class="text-danger text-required-error" />
          </div>
        </div>
        <div class="col-12 col-lg-6  mb-4">
          <div class="w-100">
            <label for="facebook" class="label d-block">{{$t('facebook')}}</label>
            <Field
              id="facebook"
              name="facebook"
              type="url"
              :label="$t('facebook')"
              :placeholder="$t('targetsPlaceholder.facebook')"
              class="input-stevie w-100"
            ></Field>
            <ErrorMessage name="facebook" class="text-danger text-required-error" />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div class="w-100">
            <label for="instagram" class="label d-block">{{$t('instagram')}}</label>
            <Field
              id="instagram"
              name="instagram"
              type="url"
              :label="$t('instagram')"
              :placeholder="$t('targetsPlaceholder.instagram')"
              class="input-stevie w-100"
            ></Field>
            <ErrorMessage name="instagram" class="text-danger text-required-error" />
          </div>
        </div>
        <div class="col-12 col-lg-6  mb-4">
          <div class="w-100">
            <label for="werzuwem" class="label d-block">{{$t('werzuwem')}}</label>
            <Field
              id="werzuwem"
              name="werzuwem"
              type="url"
              :label="$t('werzuwem')"
              :placeholder="$t('targetsPlaceholder.werzuwem')"
              class="input-stevie w-100"
            ></Field>
            <ErrorMessage name="werzuwem" class="text-danger text-required-error" />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div class="w-100">
            <label for="wlw" class="label d-block">{{$t('wlw')}}</label>
            <Field
              id="wlw"
              name="wlw"
              type="url"
              :label="$t('wlw')"
              :placeholder="$t('targetsPlaceholder.wlw')"
              class="input-stevie w-100"
            ></Field>
            <ErrorMessage name="wlw" class="text-danger text-required-error" />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div class="w-100">
            <label for="kompass" class="label d-block">{{$t('kompass')}}</label>
            <Field
              id="kompass"
              name="kompass"
              type="url"
              :label="$t('kompass')"
              :placeholder="$t('targetsPlaceholder.kompass')"
              class="input-stevie w-100"
            ></Field>
            <ErrorMessage name="kompass" class="text-danger text-required-error" />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div class="w-100">
            <label for="linkedin" class="label d-block">{{$t('linkedin')}}</label>
            <Field
              id="linkedin"
              name="linkedin"
              type="url"
              :label="$t('linkedin')"
              :placeholder="$t('targetsPlaceholder.linkedin')"
              class="input-stevie w-100"
            ></Field>
            <ErrorMessage name="linkedin" class="text-danger text-required-error" />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div class="w-100">
            <label for="xing" class="label d-block">{{$t('xing')}}</label>
            <Field
              id="xing"
              name="xing"
              type="url"
              :label="$t('xing')"
              :placeholder="$t('targetsPlaceholder.xing')"
              class="input-stevie w-100"
            ></Field>
            <ErrorMessage name="xing" class="text-danger text-required-error" />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div class="w-100">
            <label for="twitter" class="label d-block">{{$t('twitter')}}</label>
            <Field
              id="twitter"
              name="twitter"
              type="url"
              :label="$t('twitter')"
              :placeholder="$t('targetsPlaceholder.twitter')"
              class="input-stevie w-100"
            ></Field>
            <ErrorMessage name="twitter" class="text-danger text-required-error" />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div class="w-100">
            <label for="youtube" class="label d-block">{{$t('youtube')}}</label>
            <Field
              id="youtube"
              name="youtube"
              type="url"
              :label="$t('youtube')"
              :placeholder="$t('targetsPlaceholder.youtube')"
              class="input-stevie w-100"
            ></Field>
            <ErrorMessage name="youtube" class="text-danger text-required-error" />
          </div>
        </div>
      </div>
      <div class="row justify-content-end m-0">
        <base-button
          borderRadiusLeft
          borderRadiusRight
          native-type="submit"
          class="p-0 save-button mt-4"
          :disabled="isButtonDisabled || !targets"
        >
          <span v-if="!isButtonDisabled">{{ $t("save") }}</span>
          <clip-loader v-else :color="colorLoader" :size="sizeLoader" class="pt-2"></clip-loader>
        </base-button>
      </div>
    </Form>
    <visibility-modal :isOpenModal="isOpenModal" :website="consultedUrl" @closeModal="manageModal('', false)"></visibility-modal>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n/index";
import { useToast } from "vue-toastification";
import { ref } from "vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import useVisibility from '../../../composables/useVisibility';
import VisibilityModal from "../../Components/VisibilityModal";
import { formatWebsite, isInvalidUrl } from '../../../composables/useFormatUrl';
import { ElAutocomplete, ElSelect, ElOption } from 'element-plus';

export default {
  name: "Targets",
  components: {
    Form,
    Field,
    ErrorMessage,
    ClipLoader,
    VisibilityModal,
    ElSelect,
    ElOption
  },
  watch:{
    userTargets(newValue, oldValue){
      this.setData(newValue)
    }
  },
  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const targets = ref(null);

    const isButtonDisabled = ref(false);
    const colorLoader = ref("#ffffff");
    const sizeLoader = ref("20px");
    const { userTargets , getTargets, editTargets, getOptionsToGooglePlaces } = useVisibility();
    const isOpenModal = ref(false)
    const consultedUrl = ref('')
    const companyType = ref('');
    const originalUrl = ref('')
    const urlInvalid = ref({
      website: false,
      google: false,
      facebook: false,
      instagram: false,
      werzuwem: false,
      wlw: false,
      kompass: false,
      linkedin: false,
      xing: false,
      twitter: false,
      youtube: false
    })
    const companyTypes = ref([
    { value: 1 , size:"s", employees:"less10employees"},
    { value: 2 , size:"m", employees:"less49employees"},
    { value: 3 , size:"l", employees:"less249employees"},
    { value: 4 , size:"xl", employees:"more249employees"},
    ])
    const googlePlaces = ref([]);
    const googlePlacesInput = ref([])

    const initValues = async () => {
      let response = await getTargets();
      if (response && response.status === 200) {
        setData(response.data)
      }
    }

    const querySearchAsync = async (queryString, cb) => {
       const results = await initAutocompleteGooglePlaces(queryString);
      googlePlaces.value = results.data.map(({name, url}) => ({ value: name, url }));
      cb(googlePlaces.value)
    }

    const initAutocompleteGooglePlaces = async (inputUrl) => {
      return await getOptionsToGooglePlaces(inputUrl);
    }


    const manageModal = ( url , isOpen = true) => {
      if(isOpen){
        consultedUrl.value = url? url.url :userTargets.value.website;
      }
      isOpenModal.value = isOpen;
    }

    const update = async (values, {resetForm}) => {

      isButtonDisabled.value = true;
      const valuesEntries = Object.entries(values);
      let openModal = originalUrl.value !== values.website;
      values['company_type'] = companyType.value;
      valuesEntries.forEach(([key, value]) => {
        values[key] = values[key]? formatWebsite(value) : null;
        urlInvalid.value[key] = values[key] !== null && isInvalidUrl(values[key]);
      })
      if(Object.entries(urlInvalid.value).some( ([url, value]) => value )){
        isButtonDisabled.value = false;
        return;
      }

      let response = await editTargets(values);
      if (response && response.status === 200) {
        targets.value = response.data;
        companyType.value = targets.value.company_type;
        resetForm()
        if(openModal){
          setTimeout(()=>{manageModal(null, true)}, 2000)
        }
        toast.success(t("notification.success.general"));
      }
      if( response && response.errors) {
        valuesEntries.forEach( value => value = Object.keys(response.errors).includes(value))
        toast.error(t(response.message), { timeout: 3000 });
      }
      isButtonDisabled.value = false;
    }

    const schema = {
      website: "required|invalidUrl",
      google: "invalidUrl",
      facebook: "invalidUrl",
      instagram: "invalidUrl",
      werzuwem: "invalidUrl",
      wlw: "invalidUrl",
      kompass: "invalidUrl",
      linkedin: "invalidUrl",
      xing: "invalidUrl",
      twitter: "invalidUrl",
      youtube: "invalidUrl",
    }

    const setData = (data) => {
      targets.value = data;
      googlePlacesInput.value = data.google;
      companyType.value = data.company_type;
      originalUrl.value = data.website;
    }

    const blurHandleGooglePlaces = ({ target }) => {
      targets.value.google = target.value;
    }

    const selectGooglePlaces = ( {url} ) => {
      targets.value.google = url;
      googlePlacesInput.value = url;
    }

    initValues();
    return {
      targets,
      companyType,
      isButtonDisabled,
      colorLoader,
      sizeLoader,
      update,
      schema,
      isOpenModal,
      consultedUrl,
      userTargets,
      setData,
      manageModal,
      companyTypes,
      initAutocompleteGooglePlaces,
      googlePlacesInput,
      ElAutocomplete,
      querySearchAsync,
      selectGooglePlaces,
      urlInvalid,
      blurHandleGooglePlaces
    }
  }
}
</script>
<style scoped lang="scss">
.url-invalid{
  position: absolute;
  top: 4.25rem;
}

:deep().btn.btn-remove:disabled{
  background-color: transparent!important;
}

.btn-remove{
  border: none!important;
  margin-left: 5px!important;
  border-radius: 5px!important;
}
</style>
