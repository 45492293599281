import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import store from "../store";

const useTemplates = () => {
  const toast = useToast();
  const { t } = useI18n()

  const getTemplates = async(page = 1) => {
    const response= await store.dispatch('campaigns/getTemplates', page)

    return response
  }
  const deleteTemplate = async(id) => {
    const response= await store.dispatch('campaigns/deleteTemplate', id)
    if (response && response.status === 200) {
      toast.success(t("notification.success.general"));
    }
    return response
  }
  const getTemplate = async(id) => {
    const response= await store.dispatch('campaigns/getTemplate', id)
    if(response && response.status === 200){
      response.data = fillTemplate(response.data)
    }
    return response
  }
  const editTemplate = async(template) => {
    let id = template.get('id')
    const response= await store.dispatch('campaigns/editTemplate', { template: template, id: id})
    if (response && response.status === 200) {
      response.data = fillTemplate(response.data)
      toast.success(t("notification.success.general"));
    }
    return response
  }
  const createTemplate = async(template) => {
    const response= await store.dispatch('campaigns/createTemplate', template)
    if (response && response.status === 200) {
      response.data = fillTemplate(response.data)
      toast.success(t("notification.success.general"));
    }
    return response
  }

  const cloneTemplate = async(id) =>{
    const response = await store.dispatch('campaigns/cloneTemplate', id)
    if(response && response.status === 200){
      toast.success(t("notification.success.general"));
    }
    return response
  }
  const fillTemplate = (template)=>{

    const parseJSON=  (field) =>{
      if(template[field]){
        template[field]= typeof template[field] === 'string' ? JSON.parse(template[field]) : template[field];
      }else{
        []
      }
    }
    let JSONfields = ["google_headline","google_description","google_keywords"];
    JSONfields.forEach(field => parseJSON(field))
  
    template.invested = parseFloat(template.invested);
    template.target_location_region = template.target_location_region === null ? null : +template.target_location_region;
    return template
}
  

  return {
    templates: computed(()=> store.state.campaigns.templates),
    getTemplates,
    deleteTemplate,
    getTemplate,
    editTemplate,
    createTemplate,
    cloneTemplate,
    fillTemplate

  };
};

export default useTemplates;
