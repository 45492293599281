<template>
  <div class="min-height-security">

    <div class="row justify-content-between">
      <h6 class="col-6 heading-small text-muted mb-4">{{ $t('password') }}</h6>
    </div>

    <Form
      id="updatePassword"
      @submit="updatePassword"
      :validation-schema="schema"
    >
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-md-5">
            <base-input
              name="current_password"
              type="password"
              :label="$t('currentPassword')"
              :placeholder="$t('currentPassword')"
              input-classes="input-stevie"
              label-classes=""
            >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <base-input
              name="password"
              type="password"
              :label="$t('newPassword')"
              :placeholder="$t('newPassword')"
              input-classes="input-stevie"
              label-classes=""
            >
            </base-input>
          </div>
          <div class="col-md-5">
            <base-input
              name="password_confirmation"
              type="password"
              :label="$t('newPasswordConfirmation')"
              :placeholder="$t('newPasswordConfirmation')"
              input-classes="input-stevie"
              label-classes=""
            >
            </base-input>
          </div>
          <div class="col-12 text-right">
            <base-button
              borderRadiusLeft
              borderRadiusRight
              native-type="submit"
              class="p-0 save-button"
              form="updatePassword"
              :disabled="isLoading"
            >
            <span v-if="!isLoading"> {{ $t("save") }}</span>
            <clip-loader v-else color="#ffffff" size="20px" class="pt-2"></clip-loader>
            </base-button>
          </div>
        </div>
      </div>
    </Form>

    <hr class="my-4" />
  </div>
</template>

<script>
import UserData from "./UserData";
import { Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import { useI18n } from "vue-i18n/index";
import { useToast } from "vue-toastification";
import { ref, inject } from "vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import useUserData from "@/composables/useUserData"



export default {
  name: "UserSecurity",
  components: {
    Form,
    UserData,
    ClipLoader
  },
  setup() {
    const twoFactor = ref(null);
    const twoFactorQR = ref(null);
    const { t } = useI18n();
    const toast = useToast();
    const { changeUserData, userData }= useUserData();
    const loginFacebook = inject("loginFacebook");
    const changeLoginFacebook = inject("changeLoginFacebook");
    const isLoading = ref(false);

    changeLoginFacebook(JSON.parse(localStorage.getItem("loginFacebook")));

    const updatePassword = async (value) => {
      isLoading.value= true;
      const route = "/user/update/password";
      let response = await axios.put(route, value);

      if (response && response.status === 200) {
        toast.success(t("notification.success.general"));
      }
      isLoading.value= false;
    }
    const updateTwoFactor = async () => {
      const route = "/user/two-factor-authentication";
      let response;
      if (twoFactor.value === "1") {
        response = await axios.post(route);
      }
      if (twoFactor.value === "0") {
        response = await axios.delete(route);
      }
      if (response && response.status === 200) {
        if (response.data === "success") {
          twoFactorQR.value = null;
        } else {
          twoFactorQR.value = response.data
        }
        toast.success(t("notification.success.general"));
      }
    }

    const linkUnlinkFacebook = async () => {
      if (!userData.value.is_linked_facebook) {
        if (loginFacebook.value.status !== "connected") {
          await new Promise(async (resolve) => {
            FB.login(function (response) {
              changeLoginFacebook(response);
              resolve();
            }, {scope: 'public_profile,email'});
          });
        }

        if (loginFacebook.value.status === "connected") {
          FB.api(`/me?fields=email`, async function(response) {
            const data = loginFacebook.value.authResponse;
            data.email = response.email;
            const route = "/fb/link";
            let responseLink = await axios.post(route, data);
            if (responseLink && responseLink.status === 200) {
              changeLoginFacebook(responseLink.data);
              userData.value.is_linked_facebook = true;
              changeUserData(userData.value)
              toast.success(t("notification.success.general"));
            }
          });
        }
      }

      if (userData.value.is_linked_facebook) {
        const route = "/fb/unlink";
        let response = await axios.post(route);
        if (response && response.status === 200) {
          userData.value.is_linked_facebook = false;
          changeUserData(userData.value)
          toast.success(t("notification.success.general"));
        }
      }
    }

    const showTwoFactorQR = async () => {
      const route = "/user/two-factor-qr-code";
      let response = await axios.get(route);
      if (response && response.status === 200) {
        twoFactorQR.value = response.data
        twoFactor.value = "1"
      } else {
        twoFactor.value = "0"
      }
    }
    //When it is finished in the backend, it will be implemented in the frontend.
    // showTwoFactorQR();

    const schema = Yup.object().shape({
      name: Yup.string().required().label(t("name")),
      last_name: Yup.string().required().label(t("lastName")),
      email: Yup.string().required().label(t("email")),

      current_password: Yup.string().min(6).required().label(t("currentPassword")),
      password: Yup.string().min(6).required().label(t("password")),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], t("passwordsMustMatch"))
        .label(t("passwordConfirmation")),

      twoFactor: Yup.string().required().label(t("twoFactor")),
    });

    return {
      schema,
      twoFactor,
      twoFactorQR,
      userData,
      updatePassword,
      updateTwoFactor,
      linkUnlinkFacebook,
      isLoading
    }
  }
}
</script>

<style scoped>
.min-height-security {
  min-height: 487px;
}
</style>
