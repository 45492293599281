<template>
    <div>
        <input 
            name="autocomplete-input"
            :disabled="isDisabled"
            v-model="address"
            :placeholder="placeholder"
            type="text"
            class="input-stevie form-control"
            />
    </div>
</template>
<script>
import { onMounted, ref } from "@vue/runtime-core"
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n/index';
export default {
    name: "AutocompletInput",
    props:['address', 'placeholder', 'isDisabled'],
    emits:['setAddress'],
    setup( _ , { emit }) {
        const toast = useToast();
        const { t } = useI18n()
        const autocompleteInput = ref(null);
        
        onMounted(()=>{
            const options = {
                fields: ["formatted_address", "geometry", "name"],
                strictBounds: false,
                componentRestrictions: { country: ["AT", "DE", "CH"] },
            };
            autocompleteInput.value = new google.maps.places.Autocomplete(document.getElementsByName('autocomplete-input')[0], options);
            autocompleteInput.value.addListener("place_changed", () => {
                    const place = autocompleteInput.value.getPlace();
                    if (!place.geometry || !place.geometry.location) {
                        toast.warning( t("formValidations.addressSelect"), { timeout: 3000 });
                        return
                    }
                    const address = {
                        address: place.formatted_address,
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    }
                    emit('setAddress', address);
                
            })
        
        })
        return {
            autocompleteInput,
        }
    }
}
</script>
