<template>
  <div class="modal-height" v-if="isLoadingSocialMedia || !website">
      <spinner-loading-modal v-model:show="isLoadingSocialMedia"></spinner-loading-modal>
  </div>
  <form v-else @submit.prevent="sendForm()" class="modal-height row justify-content-between p-3">
    <template v-for="(value, key) in socialMediaUrls" :key="key">
      <div v-if="steps[currentStep] === key" class="row">
          <h3 class="col-12 col-md-9 d-flex flex-column justify-content-start pt-2">
            {{ $t(`socialMediaForm.title.${key}`) }}
          </h3>
          <img
            class="col-6 col-md-3 img-contain logo-img"
            :src="`/img/theme/${key}-logo.png`"
            :alt="`${key} logo`"
          />
          <div
            class="col-12 col-md-9 m-2 mt-3 row justify-content-center justify-content-md-start"
          >
            <template v-for="(url, index) in socialMediaUrls[key]" :key="index" >
              <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center">

              <div class="size-bnt-rrss mb-1" >
                <el-tooltip placement="top" :content="url.url">
                  <base-button
                    @click="rrssForm[key] = url.url"
                    type="checkbox"
                    class="btn-image p-0 mb-2 d-flex flex-column justify-content-end"
                    :class="{ 'active-btn': url.url === rrssForm[key] }"
                  >

                    <img
                      v-if="url.img64"
                      :src=" `data:image/png;base64, ${url.img64}`
                      "
                      @error="setAltImg($event, key)"
                      class="img-cover"
                    />
                    <img
                      v-else
                      :src="
                        url.imageURL ? url.imageURL : `/img/theme/default_${key}.jpg`
                      "
                      @error="setAltImg($event, key)"
                      class="img-cover"
                    />
                    <div class="text-uppercase pt-2 pb-2">
                      {{ nameTrimmer(url.name) }}
                    </div>
                  </base-button>
                </el-tooltip>
                <small v-if="url.info" class="d-flex justify-content-center text-center" :title="url.info">{{ url.info.substr(0, 15)+(url.info.length>15?'...':'') }}</small>
              </div>
              </div>
            </template>
            <div class="col-12 col-md-6 col-lg-3 d-flex justify-content-center">
              <div class="size-bnt-rrss" >
                <base-button
                  @click="rrssForm[key] = null"
                  type="checkbox"
                  :class="{ 'active-btn': rrssForm[key] === null}"
                  class="btn-image p-0 mb-2 d-flex flex-column justify-content-end"
                >
                  <div class="none-image d-flex flex-column justify-content-center">
                    <span class="">
                      {{ $t("socialMediaForm.none") }}
                    </span>
                  </div>
                  <div class="space-none-btn"></div>
                </base-button>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-3 row d-flex align-items-center align-items-md-end btn-margin"
          >
            <base-button
              @click="nextStep()"
              v-if="currentStep + 1 !== steps.length"
              class="w-100 mr-md-3"
              type="primary"
              native-type="submit"
              >{{ $t("socialMediaForm.nextStep") }}</base-button
            >
            <base-button
              v-else
              @click="sendForm()"
              class="w-100 mr-md-3"
              type="primary"
              native-type="submit"
              >{{ $t("socialMediaForm.nextStep") }}</base-button
            >
          </div>


      </div>
    </template>
  </form>
</template>
<script>
import { ref } from "@vue/reactivity";
import { useRoute } from 'vue-router';
import useSocialMedia from "@/composables/useSocialMedia";
import useVisibility from '@/composables/useVisibility';
import useLoggedIn from '@/composables/useLoggedIn';

import BaseInput from "@/components/Inputs/BaseInput.vue";
import SpinnerLoadingModal from "@/views/Components/SpinnerLoadingModal";

export default {
  components: { BaseInput, SpinnerLoadingModal },
  name: "rrss",
  props: {
    website: String,
  },
  watch:{
    website(newValue, oldValue){
        if(newValue && newValue.length > 0 && oldValue !== newValue){
          this.initSocialMediaValues(newValue)
        }
      }
  },
  emits: ['isEndForm'],
  setup(props , { emit }) {
    const route = useRoute()
    const { isLoggedIn }= useLoggedIn()
    const { userTargets } = useVisibility()
    const { socialMediaUrls, isLoadingSocialMedia, getSocialMediaUrls, setRrssForm } = useSocialMedia();
    const rrssForm = ref({
      facebook: null,
      instagram: null,
      google: null,
    });
    const socialMedia = ['google', 'instagram', 'facebook'];

    const currentStep = ref(0);
    const showInput = ref(false);
    const steps = ref([])

    const sendForm = async () => {

      setRrssForm(rrssForm.value)
      emit('isEndForm')

    };

    const nameTrimmer = name => {
      return name.slice(0, 10);
    };
    const setAltImg =  (event, step) => {
      event.target.src = `/img/theme/default_${step}.jpg`;
    };
    const nextStep = () => {
      currentStep.value = currentStep.value + 1;
    };
    const setSteps = () =>{
      if(isLoggedIn.value && route.name !== "Landing" && userTargets.value.website !== null){
        steps.value = socialMedia.filter((field) => userTargets.value[field] === null)
      }else{
        steps.value = Object.keys(socialMediaUrls.value);
      }
    }
    const initSocialMediaValues = async(url) =>{
      const response = await getSocialMediaUrls(url);
      if(response && response.status === 200){
        setSteps();

        steps.value.forEach((step)=>{
          if(socialMediaUrls.value[step].length > 0){
            rrssForm.value[step] = socialMediaUrls.value[step][0].url
          }
        })

      }else{
        emit('isEndForm', true)
      }

    }
    const capitalize = (word)=>{
       if(!word)return '';
        word = word.toString()
        return word.charAt(0).toUpperCase() + word.slice(1)
    }


    initSocialMediaValues(props.website)

    return {
      isLoadingSocialMedia,
      socialMediaUrls,
      rrssForm,
      showInput,
      currentStep,
      steps,

      sendForm,
      nameTrimmer,
      setAltImg,
      nextStep,
      initSocialMediaValues,
      capitalize
    };
  },
};
</script>
<style scoped lang="scss">
.modal-height {
  min-height: 430px;
}
.size-bnt-rrss{
  max-width: 170px;
  .btn-image {
    height: 170px;
    width: 170px;
    max-height: 170px;
    max-width: 170px;
    .none-image {
      color: #7386a1;
    }
    img,
    .none-image {
      height: 120px;
      width: 120px;
      border: 2px solid #91abbf;
      border-radius: 3px;
    }
    .space-none-btn{
      height: 37px;
    }
  }
  .active-btn {
    box-shadow: none;
    background-color: rgba(90,111,231,0.1);
  }
}
.logo-img{
  max-height: 120px;
}
@media (max-width: 576px) {

  .btn-margin{
    margin-left: 0px;
  }
}
</style>
