<template>
  <modal v-model:show="isOpenModal" @close="closeModal()" modal-classes="modal-company-size" body-classes="p-0" modal-content-classes="border-radius-stevie shadow-company-size modal-animation" :is-allowed-close-modal="isAllowedCloseModal">
    <card
      v-if="steps[step] === 'companySize'"
      header-classes="bg-transparent"
      body-classes="p-0 row mx-1"
      card-classes="bg-company-size mb-0 px-2 px-sm-5 pb-2 pb-sm-4"
    >
      <div class="col-12 text-center">
        <h1 class="mt-4 mb-0 display-1">
          {{ $t("almostDone") }}
        </h1>
        <p class="font-weight-500 mb-0">{{$t('visibilityModal.moreInfo')}}:</p>
        <p class="font-weight-600">{{$t('visibilityModal.companySize')}}</p>
      </div>
      <div class="col-12 row justify-content-between m-0 px-0">
        <div class="col-12 col-sm-6 col-xl-3 px-1 px-sm-2">
          <card
            header-classes="bg-transparent"
            body-classes="px-0 py-2 py-sm-4 cursor-pointer"
            @click="setCompanySize(1)"
          >
            <div class="col-12 text-center">
              <img src="/img/icons/stevie/company-s-shape.svg" alt="Company S icon">
              <h1 class="mt-3 mb-2">
                {{ $t("enterprises.s") }}
              </h1>
            </div>
            <div class="col-12 row justify-content-center m-0 px-0 px-sm-4 px-lg-0">
              <div class="col-12 mb-3 px-1 px-sm-3 text-center text-sm-left">
                <img src="/img/icons/stevie/company-employees-shape.svg" class="mr-2" alt="Company employees icon">
                <span class="text-company-size font-weight-500">{{ $t("employeesNumber.less10employees") }}</span>
              </div>
              <div class="col-12 px-1 px-sm-3 text-center text-sm-left">
                <img src="/img/icons/stevie/company-billing-shape.svg" class="mr-2" alt="Company billing icon">
                <span class="text-company-size font-weight-500">{{ $t("turnover.less2mill") }}</span>
              </div>
            </div>
          </card>
        </div>

        <div class="col-12 col-sm-6 col-xl-3 px-1 px-sm-2">
          <card
            header-classes="bg-transparent"
            body-classes="px-0 py-2 py-sm-4 cursor-pointer"
            @click="setCompanySize(2)"
          >
            <div class="col-12 text-center">
              <img src="/img/icons/stevie/company-m-shape.svg" alt="Company M icon">
              <h1 class="mt-3 mb-2">
                {{ $t("enterprises.m") }}
              </h1>
            </div>
            <div class="col-12 row justify-content-center m-0 px-0 px-sm-4 px-lg-0">
              <div class="col-12 mb-3 px-1 px-sm-3 text-center text-sm-left">
                <img src="/img/icons/stevie/company-employees-shape.svg" class="mr-2" alt="Company employees icon">
                <span class="text-company-size font-weight-500">{{ $t("employeesNumber.less49employees") }}</span>
              </div>
              <div class="col-12 px-1 px-sm-3 text-center text-sm-left">
                <img src="/img/icons/stevie/company-billing-shape.svg" class="mr-2" alt="Company billing icon">
                <span class="text-company-size font-weight-500">{{ $t("turnover.less10mill") }}</span>
              </div>
            </div>
          </card>
        </div>

        <div class="col-12 col-sm-6 col-xl-3 px-1 px-sm-2">
          <card
            header-classes="bg-transparent"
            body-classes="px-0 py-2 py-sm-4 cursor-pointer"
            @click="setCompanySize(3)"
          >
            <div class="col-12 text-center">
              <img src="/img/icons/stevie/company-l-shape.svg" alt="Company L icon">
              <h1 class="mt-3 mb-2">
                {{ $t("enterprises.l") }}
              </h1>
            </div>
            <div class="col-12 row justify-content-center m-0 px-0 px-sm-4 px-lg-0">
              <div class="col-12 mb-3 px-1 px-sm-3 text-center text-sm-left">
                <img src="/img/icons/stevie/company-employees-shape.svg" class="mr-2" alt="Company employees icon">
                <span class="text-company-size font-weight-500">{{ $t("employeesNumber.less249employees") }}</span>
              </div>
              <div class="col-12 px-1 px-sm-3 text-center text-sm-left">
                <img src="/img/icons/stevie/company-billing-shape.svg" class="mr-2" alt="Company billing icon">
                <span class="text-company-size font-weight-500">{{ $t("turnover.less50mill") }}</span>
              </div>
            </div>
          </card>
        </div>

        <div class="col-12 col-sm-6 col-xl-3 px-1 px-sm-2">
          <card
            header-classes="bg-transparent"
            body-classes="px-0 py-2 py-sm-4 cursor-pointer"
            @click="setCompanySize(4)"
          >
            <div class="col-12 text-center">
              <img src="/img/icons/stevie/company-xl-shape.svg" alt="Company XL icon">
              <h1 class="mt-3 mb-2">
                {{ $t("enterprises.xl") }}
              </h1>
            </div>
            <div class="col-12 row justify-content-center m-0 px-0 px-sm-4 px-lg-0">
              <div class="col-12 mb-3 px-1 px-sm-3 text-center text-sm-left">
                <img src="/img/icons/stevie/company-employees-shape.svg" class="mr-2" alt="Company employees icon">
                <span class="text-company-size font-weight-500">{{ $t("employeesNumber.more249employees") }}</span>
              </div>
              <div class="col-12 px-1 px-sm-3 text-center text-sm-left">
                <img src="/img/icons/stevie/company-billing-shape.svg" class="mr-2" alt="Company billing icon">
                <span class="text-company-size font-weight-500">{{ $t("turnover.more50mill") }}</span>
              </div>
            </div>
          </card>
        </div>
      </div>
    </card>
    <card
       v-if="steps[step]=== 'rrssForm'"
       header-classes="bg-transparent"
      body-classes="p-0 mx-0 row justify-content-center"
      card-classes="mb-0 bg-company-size"
    >
      <rrss-form :website="website" @isEndForm="isEndFormEvent" class="col-12 px-1" />
    </card>
    <card
      v-if="steps[step]=== 'loading'"
      header-classes="bg-transparent"
      body-classes="p-0 mx-0 row"
      card-classes="mb-0"
    >
      <div class="col-12 px-0">
        <img class="mw-100 border-radius-stevie" :src="displayedImage" alt="Loader Images">
        <base-progress type="blue-stevie" show-percent animated striped :height="16" :value="percentProgress" class="w-100 px-4" progressClasses=" custom-color" ></base-progress>
      </div>
    </card>

  </modal>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useVisibility from '../../composables/useVisibility'
import useLoggedIn from '../../composables/useLoggedIn'

import RrssForm from './RrssForm.vue';
import Modal from "../../components/Modal";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
    name: "VisibilityModal",
    props: {
        isOpenModal: Boolean,
        website: String,
        onlyLoader: Boolean,
    },
    components: {
        Modal,
        ScaleLoader,
        RrssForm,
    },
    watch:{
      isOpenModal(newValue, oldValue){
        if(newValue && oldValue !== newValue){
          this.checkSteps()
        }
      }
    },
    emits: ['closeModal'],
    setup(props, { emit }) {
        const route = useRoute();
        const router = useRouter();

        const {
          rrssForm,
          userTargets,
          postVisibility,
          setUrlInvalid,
          saveNewTargets,
          editWebsite
          } = useVisibility();
        const { isLoggedIn, setIsLoggedIn }= useLoggedIn();

        const colorLoader = ref("#012454");
        const marginLoader = ref("8px");
        const heightLoader = ref("20px");
        const displayedImage = ref("/img/loader/slider_01.jpg");
        const isAllowedCloseModal = ref(false);

        const steps = ref([]);
        const step = ref(0);
        const viForm = ref({
            website: null,
            company_size: null,
        })
        const isEndForm = ref(true)
        const haveError = ref(false)
        const percentProgress= ref(0)

        const checkSteps = ()=> {
            if(!localStorage.getItem("api_token")){
              setIsLoggedIn(false)
            }
            step.value = 0;
            steps.value = ["companySize", "rrssForm", "loading"]

            if(isLoggedIn.value && route.name !== "Landing" && userTargets.value.website !== null){

              viForm.company_size = userTargets.company_type;
              steps.value = ["rrssForm", "loading"]
              const socialMedia = ['google', 'instagram', 'facebook']
              const hasSocialMedia = socialMedia.every((field) => userTargets.value[field] !== null)
              if(hasSocialMedia){
                steps.value = ["loading"]
                nextStep()
              }


            }
            if(steps.value.includes('rrssForm')){
              isEndForm.value = false;
            }
        };
        const setCompanySize = (size)=>{
            viForm.value.company_size = size;
            nextStep()
        }
        const postVisibilityData = async() => {

            viForm.value.website = props.website;
            if(isLoggedIn.value && userTargets.value.company_type){
              viForm.value.company_size = userTargets.value.company_type
            }
            const response = await postVisibility(viForm.value)
            percentProgress.value = 100;
            const responseMicroUrlWebsite = response.data.website;

            if(responseMicroUrlWebsite !== props.website && isLoggedIn.value) editWebsite(responseMicroUrlWebsite);

            setTimeout(()=>{
              if((response && response.status !== 200) || !response){
                haveError.value = true;
              }
              endProcess();

            },300)
        }
        const nextStep= ()=>{
            if(steps.value.length > 1){

              step.value = step.value + 1;
            }
            if(steps.value[step.value]=== 'loading'){
              increaseProgress();
              loop();
              postVisibilityData();
            }
        }
        const closeModal = () => {
            resetValues();
            emit('closeModal')
        };
        const loop = () => {
            const messages = [
                "/img/loader/slider_01.jpg",
                "/img/loader/slider_02.jpg",
                "/img/loader/slider_03.jpg",
            ];
            isAllowedCloseModal.value = false;
            messages.forEach((message, index) => {
                setTimeout(function () {
                displayedImage.value = message;
                }, index * 6000);
            })
        };
        const isEndFormEvent = (error) =>{
          if(error){
            haveError.value = true;
            closeModal()
            return
          }
          viForm.value = {...rrssForm.value, ...viForm.value};
          isEndForm.value = true;
          nextStep();
        }
        const endProcess= () => {
          if(haveError.value){
            closeModal();
            return
          }
          saveNewTargets(isLoggedIn.value)
          setUrlInvalid('');
          closeModal();
          if (route.name === "Landing") {
            router.push({ name: "Dashboard" });
          }
        }
        const resetValues= ()=> {
          percentProgress.value = 0;
          viForm.value = {
            website: '',
            company_size: null
          }
          haveError.value = false;
        }
        const increaseProgress= ()=>{
          const interval = setInterval(increase, 300);
          let timePass = 0;
          function increase() {
            timePass += 1
            let stepTime = viForm.value.website && !viForm.value.website.includes(userTargets.value.website) ? 5: 10;
            if(timePass >= 10){
              stepTime = 1;
            }
            if(percentProgress.value <= 90 && stepTime !== 1 ||  percentProgress.value < 99 && stepTime === 1 ){
              percentProgress.value += stepTime
            }
            if(percentProgress.value >= 99 || percentProgress.value === 0 ){
              clearInterval(interval);
            }
          }
        }
        //Get VI info when data it's from Wordpress plugin
        const getVIfromWP = async()=>{

          steps.value = ["loading"];
          loop()
          increaseProgress();
          let response = await postVisibility(JSON.parse(localStorage.getItem("visibilityForm")))
          percentProgress.value = 100;
          setTimeout(()=>{
              if((response && response.status !== 200) || !response){
                haveError.value = true;
              }
              endProcess();

          }, 300)
          localStorage.removeItem("visibilityForm")
        }

        onMounted(()=>{
          if(props.onlyLoader){
            getVIfromWP();
          }
        })

        return{
            colorLoader,
            marginLoader,
            heightLoader,
            displayedImage,
            isAllowedCloseModal,
            steps,
            step,
            haveError,
            isEndForm,
            percentProgress,
            setCompanySize,
            closeModal,
            isEndFormEvent,
            checkSteps,
        }
    },
}
</script>
<style lang="scss" scoped>
  .progress-default {
    .progress-label{
      display: none;
    }
  }
</style>
