import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { computed } from "vue";
import { campaignStatus } from "../util/Settings";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";


const useCampaigns = () =>{

    const store = useStore();
    const route = useRoute()
    const campaignsStatus = campaignStatus;
    const toast  = useToast();
    const { t } = useI18n()

    const getCampaigns = async(page, filter)=>{

        const response = await store.dispatch('campaigns/getCampaigns', {page:page, filter:filter})

        return response
    }
    const changeStatusCampaign = async(campaignId, status)=>{
        const response = await store.dispatch('campaigns/changeStatus', {id: campaignId, statusId:status, statusName: campaignStatus[status].name})
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }

    const cloneCampaign = async(campaignId) =>{
        const response = await store.dispatch('campaigns/cloneCampaign', campaignId)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }

    const getCampaign = async(id)=>{
        const response = await store.dispatch('campaigns/getCampaign', id)
        if(response && response.status === 200){
            response.data = fillCampaign(response.data)
        }
        return response
    }
    const updateCampaign = async(id, campaign, saveWithoutResponse = false)=>{
        let response = await store.dispatch('campaigns/updateCampaign', {id, campaign})
        response = manageResponseCRUD(response, saveWithoutResponse )
        return response
    }
    const changeCampaignToReady = async(id, campaign, saveWithoutResponse = false)=>{
        let response = await store.dispatch('campaigns/changeCampaignToReady', {id, campaign})
        response = manageResponseCRUD(response, saveWithoutResponse )
        return response
    }
    const storeCampaign = async(campaign, saveWithoutResponse)=>{
        let response = await store.dispatch('campaigns/storeCampaign', campaign)
        response = manageResponseCRUD(response, saveWithoutResponse )
        return response
    }
    
    const manageResponseCRUD = (response, saveWithoutResponse)=>{
        if(response && response.status === 200){
            response.data = fillCampaign(response.data)
            if(!saveWithoutResponse){
                toast.success(t("notification.success.general"));
            }
        }
        if( response.url && response.url[0]){
            toast.error(response.url[0], { timeout: 3000 });
        }
        return response
    }
    const fillCampaign = (campaign)=>{
        campaign.invested = parseFloat(campaign.invested);
        campaign.target_location_region = campaign.target_location_region === null ? null : +campaign.target_location_region;
        campaign.google_headline = JSON.parse(campaign.google_headline);
        campaign.google_long_headline = JSON.parse(campaign.google_long_headline);
        campaign.google_description = JSON.parse(campaign.google_description);
        campaign.google_keywords = campaign.google_keywords ? JSON.parse(campaign.google_keywords): [];
        return campaign
    }
    const getTotalActivesCampaigns = async()=>{
        let response = await store.dispatch('campaigns/getTotalActivesCampaigns')
        return response
    }

    const pausedCampaign = async(campaignId, status) => {
        const response = await store.dispatch('campaigns/pausedCampaign', { campaignId, status })
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response;
    }

    const startedCampaign = async(campaignId, status) => {
        const response = await store.dispatch('campaigns/startedCampaign', { campaignId, status })
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response;
    }

    return {
        campaignsData: computed(()=> store.state.campaigns.campaignsData),
        totalActivesCampaigns: computed(()=> store.state.campaigns.totalActivesCampaigns),
        getCampaigns,
        changeStatusCampaign,
        cloneCampaign,
        getCampaign,
        updateCampaign,
        changeCampaignToReady,
        storeCampaign,
        getTotalActivesCampaigns,
        pausedCampaign,
        startedCampaign
    }
}
export default useCampaigns;
