import { provide, ref } from "vue";

const loginFacebook = () => {
  const loginFacebook = ref(null);
  const changeLoginFacebook = (value) => {
    loginFacebook.value = value;
    localStorage.setItem("loginFacebook", JSON.stringify(loginFacebook.value));
  };
  provide("loginFacebook", loginFacebook);
  provide("changeLoginFacebook", changeLoginFacebook);

  return {
    loginFacebook,
    changeLoginFacebook,
  };
};

export default loginFacebook;
