import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Pages/AuthLayout";

// Dashboard pages
import Dashboard from "../views/Dashboard/Dashboard.vue";

// Pages
import Login from "../views/Pages/Login.vue";
import Register from "../views/Pages/Register";
import Landing from "../views/Pages/Landing/Landing.vue"
import NotFound from "../views/Pages/NotFound/NotFound";
import EditProfileForm from "../views/Pages/UserProfile/EditProfileForm";
import UserData from "../views/Pages/UserProfile/Tabs/UserData.vue";
import UserSecurity from "../views/Pages/UserProfile/Tabs/UserSecurity.vue";
import Subscription from "../views/Pages/UserProfile/Tabs/Subscription.vue";
import Wallet from "../views/Pages/UserProfile/Tabs/Wallet.vue";
import LegalNotice from "../views/Pages/LegalNotice/LegalNotice";
import PrivacyPolicy from "../views/Pages/PrivacyPolicy/PrivacyPolicy";
import CampaignsList from "../views/Pages/Campaigns/CampaignsList";
import CampaignsCalendar from "../views/Pages/Campaigns/Calendar";
import CampaignsLayout from "../views/Pages/Campaigns/CampaignsLayout";
import TemplatesList from "../views/Pages/Campaigns/Templates/TemplatesList";
import TemplateForm from "../views/Pages/Campaigns/Templates/TemplateForm";
import CampaignForm from "../views/Pages/Campaigns/CampaignForm";
import CampaignReport from "../views/Pages/Campaigns/CampaignReport";
import Settings from "../views/Settings/Settings";
import Targets from "../views/Settings/Tabs/Targets.vue"

const routes = [
  {
    path: "/dashboards",
    // redirect: "/dashboard",
    component: DashboardLayout,
    name: "Dashboards",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/profile",
        components: { default: EditProfileForm},
        children:[
          {
            path:'',
            name: 'profile',
            component:  UserData,

        },
        {
            path:'security',
            name: 'security',
            component: UserSecurity

        },
        {
            path:'subscription',
            name: 'subscription',
            component: Subscription
        },
        {
            path:'wallet',
            name: 'Wallet',
            component: Wallet,
            beforeEnter: (to, from, next) => {
              subscriptionOnly(from,next)
            }
        },
        ]
      },
      {
        path: "/campaigns",
        name: "Campagins",
        components: { default: CampaignsLayout },
        /*beforeEnter: (to, from, next) => {
          subscriptionOnly(from,next)
        },*/
        children:[
          {
              path: "",
              name: "CampaignsList",
              components: { default: CampaignsList },
          },
          {
              path: "templates",
              name: "TemplatesList",
              components: { default: TemplatesList },
          },
          {
              path: "calendar",
              name: "CampaignsCalendar",
              components: { default: CampaignsCalendar },
          },
          {
            path: "new-template",
            name: "NewTemplate",
            components: { default: TemplateForm },
          },
          {
            path: "edit-template/:id",
            name: "EditTemplate",
            components: { default: TemplateForm },
          },
          {
            path: "new-campaign",
            name: "NewAd",
            components: { default: CampaignForm },
            /*beforeEnter: (to, from, next) => {
              subscriptionOnly(from,next)
            }*/
          },
          {
            path: "edit-campaign/:id",
            name: "EditAd",
            components: { default: CampaignForm },
            props: true,
            /*beforeEnter: (to, from, next) => {
              subscriptionOnly(from,next)
            }*/
          },
          {
            path: "report-campaign/:id",
            name: "ReportAd",
            components: { default: CampaignReport },
            props: true,
          },
        ]
      },
      {
        path: "/mails-marketing",
        name: "MailsMarketing",
        component: ()=> import('@/views/Pages/MailsMarketing/MailsLayout.vue'),
        children:[
          {
              path: "",
              name: "ContactsList",
              component: ()=> import('@/views/Pages/MailsMarketing/Contacts/ContactsList.vue'),
          },
          {
              path: "mails",
              name: "MailsList",
              component: ()=> import('@/views/Pages/MailsMarketing/Mails/MailsList.vue'),
          },
          {
              path: "templates",
              name: "MailsTemplates",
              component: ()=> import('@/views/Pages/MailsMarketing/Mails/MailsTemplates.vue'),
          },
          {
            path: "template/new",
            name: "NewMail",
            component: ()=> import('@/views/Pages/MailsMarketing/Mails/MailForm.vue'),
          },
          {
            path: "template/:id",
            name: "EditMail",
            component: ()=> import('@/views/Pages/MailsMarketing/Mails/MailForm.vue'),

          },
          {
            path: "template/:id/contacts",
            name: "EmailsSent",
            component: ()=> import('@/views/Pages/MailsMarketing/Mails/MailsSent.vue'),

          },
        ]
      },
      {
        path: "/landings",
        name: "landings",
        component: ()=> import('@/views/Pages/Landings/LandingsLayout.vue'),
        children:[
          {
              path: "",
              name: "LandingsList",
              component: ()=> import('@/views/Pages/Landings/LandingsList.vue'),
          },
          {
            path: "templates",
            name: "LandingsTemplates",
            component: ()=> import('@/views/Pages/Landings/LandingsTemplates.vue'),
          },
          {
            path: "new",
            name: "NewLanding",
            component: ()=> import('@/views/Pages/Landings/LandingForm.vue'),
          },
          {
            path: ":id",
            name: "EditLanding",
            component: ()=> import('@/views/Pages/Landings/LandingForm.vue'),

          },
        ]
      },
      {
        path: "/settings",
        name: "Settings",
        components: { default: Settings},
        children:[
          {
            path:'',
            name: 'targets',
            component:  Targets,

          },
        ]
      },
    ],
  },
  {
    path: "/home",
    redirect: "/",
    component: AuthLayout,
    children: [
      {
        path: "/",
        redirect: "/login",
        name: "Landing",
        components: { default: Landing },
      },
      {
        path: "/legal-notice",
        name: "LegalNotice",
        components: { default: LegalNotice },
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        components: { default: PrivacyPolicy },
      },
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "Register",
        components: { default: Register },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound,
      },
    ],
  },
  {
    path: "/landing/:url",
    name: "CustomLanding",
    component: ()=> import('@/views/Pages/Landings/CustomLanding.vue'),
  },
  {
    path: "/landings/preview",
    name: "LandingPreview",
    component: ()=> import('@/views/Pages/Landings/CustomLanding.vue'),
  },
];
const subscriptionOnly = (from, next)=>{
  let user = localStorage.getItem('user')
    if(user){
      user = JSON.parse(user);
      if(!user.subscription){
        router.push({name:'subscription'})
      }else{
        next()
      }
    }
}
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  }
  if (to.hash) {
    return { el: to.hash };
  }
  return { top: 0, left: 0 }
}

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
  scrollBehavior,
});

// router.resolve({
//   name: "NotFound",
//   params: { pathMatch: ["not_found"] },
// }).href;

router.beforeEach((to, from, next) => {

  const isAuth = !!localStorage.getItem("api_token");
  const isVerifiedEmail = parseInt(to.query.verified) === 1;
  if( to.name === "CustomLanding" || to.name === "NotFound"){
    next()
    return
  }
  if (
    to.name !== "Login" &&
    to.name !== "Register" &&
    to.name !== "Landing" &&
    to.name !== "Dashboard" &&
    to.name !== "LegalNotice" &&
    to.name !== "PrivacyPolicy"
  ) {
    if (isAuth) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else if (to.name === "Login" || to.name === "Register") {
    if (isAuth) {
      next({ name: "Dashboard" });
    } else {
      if (isVerifiedEmail) {
        next({ name: "Login", query: { verified: isVerifiedEmail}});
        return;
      }
      next();
    }
  } else {
    if (isVerifiedEmail) {
      next({ name: "Login", query: { verified: isVerifiedEmail}});
      return;
    }
    if(isAuth && to.name === 'Landing'){
      next({name:'Dashboard'})
      return;
    }
    next();
  }
});

export default router;
