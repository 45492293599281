import axios from "axios";

export const getLandings = async ({ commit }, {page, filters}) => {

    commit('setIsLoading', true)

    let routeList = `/landing/templates?page=${page}`;

    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        routeList = `${routeList}&${key}=${value}`
      })
    }

    const response = await axios.get(routeList);
    if(response && response.status === 200){
        commit('setLandings', response.data);
    }
    commit('setIsLoading', false)

    return response

}
export const getCategories = async ({commit}) => {

  commit('setIsLoading', true)

  let routeList = `/template-categories`;
  const response = await axios.get(routeList);
  if(response && response.status === 200){
    commit('setCategories', response.data);
  }
  commit('setIsLoading', false)

}
export const getLanding = async ({ commit }, id) => {

    commit('setIsLoading', true)

    let routeList = `/landing/templates/${id}`;

    const response = await axios.get(routeList);
    if(response && response.status === 200){
        commit('setLanding', response.data);
    }
    commit('setIsLoading', false)

    return response

}
export const getPreviewLanding = async ({ commit }, landing) =>{
    const route = `/landing/templates/preview`;
    const response = await axios.post(route, landing);

    return response
}
export const storeLanding = async ({ commit }, landing) =>{
    const route = `/landing/templates`;
    const response = await axios.post(route, landing);

    return response
}
export const updateLanding = async ({ commit }, {formData, id}) =>{
    const route = `/landing/templates/${id}`;
    const response = await axios.post(route, formData);

    return response
}
export const deleteLanding = async ({ commit }, id) =>{
    const route = `/landing/templates/${id}`;
    const response = await axios.delete(route);

    return response
}
export const cloneLanding = async ({ commit }, id) =>{
    const route = `/landing/templates/${id}/clone`;
    const response = await axios.get(route);

    return response
}
export const checkUniqueUrl = async ({ commit }, url) =>{
    const route = `/landing/check?url=${url}`;
    const response = await axios.get(route);

    return response
}
export const getPublicLanding = async ({ commit }, url) =>{
    const route = `/landing/${url}`;
    const response = await axios.get(route);

    return response
}
export const getLandingsTemplates = async ({ commit }, {page, filters}) => {

  commit('setIsLoading', true)

  let routeList = `/landing/templates/statics?page=${page}`;

  const response = await axios.get(routeList);
  if(response && response.status === 200){
    commit('setLandingsTemplates', response.data);
  }
  commit('setIsLoading', false)

  return response

}
export const cloneLandingTemplate = async ({ commit }, id) =>{
  const route = `/landing/templates/statics/${id}/clone`;
  const response = await axios.get(route);

  return response
}
