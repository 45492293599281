<template>
  <footer class="footer px-4 px-md-7 mx-2">
    <div class="row align-items-center justify-content-start">
      <div class="col-lg-6 d-flex justify-content-start">
        <div class="footer-text">
          &copy;
          {{ year }}
          <a 
            href="https://www.stevie.cloud/"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Stevie.Cloud
          </a>
        </div>
        <span class="mx-2">|</span>
        <span class="footer-text">
          {{ $t('supportEmail') }}: 
          <a 
            href="mailto:support@stevie.cloud"
            target="_blank" 
            rel="noopener noreferrer"
          >
            {{ supportEmail }}
          </a>
        </span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      supportEmail: 'support@stevie.cloud'
    };
  },
};
</script>
<style></style>
