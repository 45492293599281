
export default () => ({
    isLoggedIn: false,
    isLoading: false,
    isLoadingSocialMedia: false,
    isLoadingVisibility: false,
    isLoadingTargets: false,
    socialMediaUrls: [],
    viForm:{
        url:'',
        company_type: null,
    },
    rrssForm:{
        instagram: null,
        facebook: null,
        google: null,
    },
    newTargets: {},
    visibilityData: {
        id: null,
        company_type:null,
        company_size:null,
        facebook_score: null,
        google_score: null,
        instagram_score: null,
        other01_score: null,
        other02_score: null,
        technik_score: null,
        website_score: null,
        facebook: null,
        google: null,
        instagram: null,
        kompass: null,
        linkedin: null,
        twitter: null,
        url: null,
        website:null,
        werzuwem: null,
        wlw: null,
        xing: null,
        youtube: null,
        google_improve: 0,
        facebook_improve: 0,
        instagram_improve: 0,
        technik_improve: 0,
        other01_improve: 0,
        other02_improve: 0,
        timestamp: null

    },
    urlInvalid:'',
    userTargets: {
        company_type: null,
        website: null, 
        facebook: null,
        google: null, 
        instagram: null, 
        kompass: null, 
        linkedin: null, 
        twitter: null, 
        wlw: null,
        xing: null,
        youtube: null,
    },
    isViFails: false,
})