<template>
  <div class="dashboard-margin">
    <div class="row justify-content-around m-0">
      <div class="custom-card col-12 col-xl-8 mb-4 mb-xl-0">
        <div>
          <div class="row align-items-end">
            <div class="col-12 col-lg-6">
              <h3>{{ $t($route.fullPath.includes('edit') ? 'campaignForm.editCampaign' : 'campaignForm.createCampaign')
                }}</h3>
              <base-button class="p-0 back-button d-flex justify-content-between px-2" @click="backToList">
                <div>
                  <img src="/img/icons/stevie/back-shape.svg" class="rotateArrow mr--2" alt="Back icon">
                  <img src="/img/icons/stevie/back-shape.svg" class="rotateArrow" alt="Back icon">
                </div>
                {{ $t("allCampaigns") }}
                <div></div>
              </base-button>
            </div>
          </div>
        </div>

        <card v-if="ad.errors_exist" class="w-100 d-flex flex-column info-errors mt-4"
          header-classes="border-radius-top-stevie color-warning">
          <div class="d-flex justify-content-between title">
            <h3>
              <em class="fas fa-exclamation-triangle icon-info-error"></em>
              {{ $t('campaignErrors.title') }}
            </h3>
            <button @click="hideErrors = !hideErrors" class="btn p-1 px-2">
              <em :class="`fas icon-title fa-angle-${hideErrors ? 'down' : 'up'}`"></em>
            </button>
          </div>

          <div v-show="!hideErrors" class="content">
            <p>{{ $t('campaignErrors.subtitle') }}</p>
            <ul v-for="(errorType, errorTypeKey) in ad.errors" :key="errorType">
              <li v-if="errorType && Object.keys(errorType).length !== 0" class="bold">
                {{ $t(errorTypeKey) }}
              </li>

              <div v-for="(error, errorField) in errorType" :key="error">

                <p v-if="errorField === 'POLICY_ERROR' || errorField === 'POLICY_FINDING'">
                  {{ error }}
                </p>

                <p v-else-if="error === 'UNEXPECTED_ERROR'">
                  {{ errorField === 'ad' ?
                $t(`errorCode.${errorTypeKey}.${error}`) :
                $t(`errorCode.${errorTypeKey}.${error}_FIELD`, [errorField])
                  }}
                </p>
                <p v-else>
                  <span class="capitalize fw-500">{{ $t(errorField) }}:</span>
                  <br>
                  {{ $te(`errorCode.${errorTypeKey}.${error}`) ? $t(`errorCode.${errorTypeKey}.${error}`) : error }}
                </p>
              </div>

              <!-- <p v-if="errorType?.description" class="mb-2">
                {{ errorType.description }}
              </p>

              <p v-else-if="errorType?.message" class="mb-2">
                {{ errorType.message }}
              </p> -->
            </ul>
          </div>
        </card>

        <div class="pt-3 px-0">
          <Form @submit="null" :initialValues="ad" @change="uploadForm($event); hasUnsavedChanges = true">
            <section class="segment highlight-segment">
              <h3 class="subtitle-form">{{ $t("formSegment.general") }}</h3>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <label class="label">{{ $t("nameYourCampaign") }}</label>
                  <Field :disabled="isDisabledEdit" name="name" v-model="ad.name"
                    :placeholder="$t('campaignForm.placeholder.name')" type="text" maxLength="255"
                    class="input-stevie d-block w-100"
                    :class="{ 'has-danger': markAsInvalid(ad.name === null || ad.name === '') }" />
                  <small v-if="markAsInvalid(ad.name === null || ad.name === '')" class="text-danger position-absolute">
                    {{ $t('formValidations.required') }}
                  </small>
                </div>

                <div class="col-12 col-lg-6">
                  <label class="label">{{ $t("url") }}</label>
                  <el-tooltip placement="top" :content="$t('walletInfo')">
                    <img src="/img/icons/stevie/info-shape.svg" class="icon-card ml-1" alt="Info icon" />
                  </el-tooltip>
                  <Field alternative :disabled="isDisabledEdit" name="url" v-model="ad.url"
                    :placeholder="$t('campaignForm.placeholder.website')" type="text" class="input-stevie d-block w-100"
                    :class="{ 'has-danger': markAsInvalid(ad.url === null || ad.url === '' || !isValidLink(ad.url)) }"
                    @change="({ target: { value } }) => ad.magic_wand.url = value" />
                  <small v-if="markAsInvalid(ad.url === null || ad.url === '' || !isValidLink(ad.url))"
                    class="text-danger position-absolute">
                    {{ $t('formValidations.urlValid') }}
                  </small>
                </div>

                <div class="my-4 col-12">
                  <base-button borderRadiusLeft borderRadiusRight :disabled="isDisabledEdit" class="d-flex w-100"
                    @click="isShowMagicWandModal = true; magicWandFieldSelected = null">
                    <img class="high-brightness magic-wand-styles not-hover-active mr-2"
                      src="/img/icons/common/magic-wand.png" alt="Magic wand icon">
                    <span class="text-ellipsis">{{ $t('magicWandButtonSetup') }}</span>
                  </base-button>
                </div>
              </div>
            </section>

            <section class="segment">
              <div class="row">
                <div class="col-12 d-flex flex-wrap px-0">
                  <h3 class="subtitle-form col-12">{{ $t("campaignForm.invest") }}</h3>
                  <div class="col-12 col-lg-6 col-2xl-3">
                    <label class="label">{{ $t("campaignForm.durationCampaign") }}</label>
                    <base-input :disabled="isDisabledEdit" name="start_on" class="mb-0" v-model="ad.start_on"
                      type="date" :min="today" input-classes="input-stevie"
                      :class="{ 'border border-danger rounded': ad.start_on && invalidDateStart }" />
                    <small v-if="markAsInvalid(ad.start_on === null || ad.start_on === '') || invalidDateStart"
                      class="text-danger my-0">
                      {{ $t('formValidations.startDate') }}
                    </small>
                  </div>

                  <div class="col-12 col-lg-6 col-2xl-3 pl-lg-3 mt-3 container-end-on">
                    <base-input :disabled="isDisabledEdit" name="end_on" v-model="ad.end_on" type="date" :min="minDate"
                      :max="maxDate" class="mb-0" input-classes="input-stevie"
                      :class="{ 'border border-danger rounded': ad.end_on && invalidDateEnd }">
                    </base-input>
                    <small v-if="markAsInvalid(ad.end_on === null || ad.end_on === '' || invalidDateEnd)"
                      class="text-danger">
                      {{ $t('formValidations.endDate') }}
                    </small>
                  </div>

                  <div class="col-12 col-md-4 col-lg-4 mt-3">
                    <label class="label">{{ $t("campaignForm.invested") }}</label>
                    <el-tooltip placement="top" :content="$t('walletInfo')">
                      <img src="/img/icons/stevie/info-shape.svg" class="icon-card ml-1" alt="Info icon" />
                    </el-tooltip>
                    <Field :disabled="isDisabledEdit" v-model="ad.invested" name="invested" type="number"
                      class="input-stevie w-100" :max="maxInvest" min="0"
                      :class="{ 'has-danger': maxInvest !== '' && ad.invested > maxInvest || ad.status === 1 && ad.invested <= 0 }" />
                    <small v-if="maxInvest !== '' && ad.invested > maxInvest && originalStatus !== ad.status"
                      class="text-danger">
                      {{ $t('formValidations.exceedCredit') }}
                      <a @click="goToWallet" class="text-red cursor-pointer" style="text-decoration: underline;">
                        {{ $t('formValidations.exceedCreditLink') }}
                      </a>
                    </small>
                    <small v-else-if="maxInvest !== '' && (ad.status == 1 && ad.invested <= 0)"
                      class="text-danger mt--3">{{ $t('formValidations.wrongInvested')
                      }}</small>
                    <small v-else-if="wrongMinInvested" class="text-danger mt--3">
                      <el-tooltip placement="top"
                        :content="$t('formValidations.minInvested', [minInvested.toFixed(2)])">
                        <em class="fas fa-exclamation-triangle icon-info-error"></em>
                      </el-tooltip>
                    </small>
                  </div>

                  <div class="col-12 col-md-8 col-lg-8 mt-3">
                    <label>
                      {{ $t('distribution') }}
                    </label>
                    <div>
                      <div class="d-flex align-items-center">
                        <img src="/img/icons/stevie/Facebook-icon-campaign.svg" class="icons-distribution"
                          alt="Facebook logo">
                        <section class="flex-1 sliderInvested px-2">
                          <div class="mw-100" :class="{ 'disabled-click': isDisabledEdit }">
                            <base-slider v-model="ad.invested_google" @update:modelValue="changeInvested"
                              :options="{ step: 5 }"></base-slider>
                          </div>
                        </section>
                        <img src="/img/icons/stevie/Google-icon-campaign.svg" class="icons-distribution ml-auto"
                          alt="Google logo">
                      </div>

                      <div>
                        <div class="d-flex justify-content-between">
                          <span class="sliderText">{{ $n((ad.invested * ad.invested_facebook / 100), 'currency')
                            }}</span>
                          <p class="sliderText">{{ Number(ad.invested_facebook).toFixed() }} / {{
                Number(ad.invested_google).toFixed() }}</p>
                          <span class="sliderText">{{ $n((ad.invested * ad.invested_google / 100), 'currency') }}</span>
                        </div>
                      </div>
                    </div>
                    <small v-if="markAsInvalid((Number(ad.invested_facebook) + Number(ad.invested_google)) != 100)"
                      class="text-danger position-absolute">{{ $t('formValidations.required')
                      }}</small>
                  </div>
                </div>
              </div>
            </section>

            <section class="segment highlight-segment">
              <h3 class="subtitle-form">{{ $t("formSegment.targetGroup") }}</h3>
              <div class="row">
                <div class="col-12 col-lg-6 mt-4">
                  <label>{{ $t("age") }} ({{ $t('optional') }})</label>
                  <base-input name="target_age">
                    <el-select :disabled="isDisabledEdit" clearable :placeholder="$t('campaignForm.placeholder.old')"
                      v-model="ad.target_age" @clear="ad.targe_age = null" @change="hasUnsavedChanges = true">
                      <el-option v-for="age in optionsForm.agesOptions" :key="age" :value="age"
                        :label="$t(`agesRange.${age}`)">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-12 col-lg-6 mt-4">
                  <label>{{ $t("gender") }} ({{ $t('optional') }})</label>
                  <base-input name="target_gender">
                    <el-select :disabled="isDisabledEdit" clearable :placeholder="$t('campaignForm.placeholder.gender')"
                      v-model="ad.target_gender" @clear="ad.target_gender = null" @change="hasUnsavedChanges = true">
                      <el-option v-for="gender in optionsForm.genderOptions" :key="gender" :value="gender"
                        :label="$t(`genders.${gender}`)">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-12 mt-4">
                  <div class="d-flex">
                    <base-checkbox :disabled="isDisabledEdit" v-model="isRegionTarget" @change="changeLocationType()"
                      inline>
                      <p class="regionalCheckboxText">{{ $t('campaignForm.regionalLimit') }}</p>
                    </base-checkbox>
                  </div>
                </div>
                <div class="col-12 col-lg-6 mt-4" v-if="!isRegionTarget">
                  <label class="mr-4">{{ $t("region") }}</label>
                  <base-input name="target_location_region">
                    <el-select :disabled="isDisabledEdit" :placeholder="$t('select.region')"
                      v-model="ad.target_location_region" @change="hasUnsavedChanges = true">
                      <el-option v-for="region in optionsForm.regions" :key="region" :value="region.id"
                        :label="$t(`regions.${region.name}`)">
                      </el-option>
                    </el-select>
                  </base-input>
                  <small v-if="markAsInvalid(ad.target_location_region === null)" class="text-danger position-absolute">
                    {{ $t('formValidations.required') }}
                  </small>
                </div>

                <div class="col-12 col-lg-6 mt-4" v-if="isRegionTarget">
                  <label class="mr-4">{{ $t("address") }}</label>
                  <AutocompletAddress :isDisabled="isDisabledEdit" @setAddress="setAddress"
                    :address="ad.target_location_address" :placeholder="$t('addAddress')" class="mb-4">
                  </AutocompletAddress>
                  <small
                    v-if="markAsInvalid(ad.target_location_latitude === null || ad.target_location_longitude === null)"
                    class="text-danger position-absolute mt--3">
                    {{ $t('formValidations.addressSelect') }}
                  </small>
                </div>
                <div class="col-12 col-lg-6 mt-4" v-if="isRegionTarget">
                  <label class="label">{{ $t("radio") }}</label>
                  <el-tooltip placement="top" :content="$t('radioInfo')">
                    <img src="/img/icons/stevie/info-shape.svg" class="icon-card ml-1" alt="Info icon" />
                  </el-tooltip>
                  <div class="input-group">
                    <input :disabled="isDisabledEdit" v-model="ad.target_location_radio" type="number" min="1" max="80"
                      class="input-stevie form-control">
                    <div v-if="!isDisabledEdit" class="input-group-append no-btn p-1 d-flex justify-content-center">
                      Km
                    </div>
                  </div>
                  <small v-if="!isValidRadioLocation" class="text-danger d-block my-2">
                    {{ $t('formValidations.radio_greater_than_allowed', [MAXIMUM_ALLOWED_RADIO]) }}
                  </small>
                  <small v-if="markAsInvalid(ad.target_location_radio === null)" class="text-danger d-block my-2">
                    {{ $t('formValidations.required') }}
                  </small>
                </div>
                <div class="col-12 col-lg-6 mt-4">
                  <label>
                    {{ $t("category") }}
                    <el-tooltip placement="top" :content="$t('categoryInfo')">
                      <img src="/img/icons/stevie/info-shape.svg" class="icon-card ml-1" alt="Info icon" />
                    </el-tooltip>
                  </label>
                  <base-input name="target_gender">
                    <el-select :disabled="isDisabledEdit" :placeholder="$t('campaignForm.placeholder.category')"
                      v-model="ad.meta_category" @change="hasUnsavedChanges = true">
                      <el-option v-for="category in optionsForm.categoryOptions" :key="category" :value="category"
                        :label="$t(`categoryOptions.${category}`)">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </section>

            <section class="row segment">
              <div class="row">
                <div class="col-12 d-flex">
                  <h3 class="pr-2">{{ $t('facebook') }}</h3>
                  <h3 class="subtitle-form">{{ $t("formSegment.campaignDetails") }}</h3>
                </div>

                <div class="col-12 row m-0 mb-4 px-0 ad-form">
                  <div class="col-12 col-sm-6">
                    <label>
                      <span class="label-text">
                        {{ $t("image") }}
                      </span>
                      <span class="sublabel">(Min: {{ validationsForms.meta_image.minWidth }} x
                        {{ validationsForms.meta_image.minHeight }} px, Max:
                        {{ KBtoMB(validationsForms.meta_image.maxSize) }} MB)</span>
                    </label>
                    <div v-show="!ad.meta_image" class="uploader-campaigns">
                      <ImageCropped :isAllowedCloseModal="false" :validationsImage="validationsForms.meta_image"
                        :aspect-ratio="validationsForms.meta_image.aspectRatio" file-name="metaImage"
                        :disabled="isDisabledEdit || isFacebookBlock"
                        @changeImage="ad.meta_image = $event; hasUnsavedChanges = true" :fileValue="ad.meta_image" />
                    </div>
                    <div v-show="ad.meta_image" class="uploader-campaigns dz-message p-0 size-media-ads">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit || isFacebookBlock"
                        @click="ad.meta_image = null; hasUnsavedChanges = true">
                        <img class="h-100 w-100 media-ad" alt="Image placeholder" :src="getImageUrl(ad.meta_image)">
                        <span class="text-hover">{{ $t('campaignForm.placeholder.image',
                [validationsForms.meta_image.minWidth, validationsForms.meta_image.minHeight]) }}</span>
                      </base-button>
                    </div>
                    <small v-if="markAsInvalid(ad.meta_image === null) && !isFacebookBlock"
                      class="text-danger position-absolute">{{ $t('formValidations.required')
                      }}</small>
                  </div>

                  <div class="col-12 col-sm-6">
                    <label>
                      <span class="label-text">
                        {{ $t("optionalVideo") }}
                      </span>
                      <span class="sublabel">(Max: {{ KBtoMB(validationsForms.meta_video.maxSize) }} MB)</span>
                      <span v-show="ad.meta_video && typeof ad.meta_video === 'string'"
                        class="sublabel btn-link cursor-pointer ml-2" @click="downloadVideo(ad.meta_video)">{{
                $t("downloadVideo") }}</span>
                    </label>
                    <div v-show="!ad.meta_video" class="uploader-campaigns">
                      <el-upload :accept="allowedVideoFormatsCampaigns" class="w-100 uploader-campaigns" action="" drag
                        :auto-upload="false" :show-file-list="false"
                        :on-change="uploadFile => getImage(uploadFile, 'meta_video', ad)"
                        :disabled="isDisabledEdit || isFacebookBlock">
                        <div v-if="ad.meta_video === null" class="el-upload__text">
                          {{ $t('campaignForm.placeholder.video') }}
                        </div>
                        <div class="el-upload__image" v-else>
                          <video :src="getImageUrl(ad.meta_video)" class="previewImg url" />
                          <div class="text-hover">
                            {{ $t('campaignForm.placeholder.video') }}
                          </div>
                        </div>
                      </el-upload>
                    </div>
                    <div v-show="ad.meta_video" class="uploader-campaigns dz-message p-0 size-media-ads">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit || isFacebookBlock"
                        @click="ad.meta_video = null; hasUnsavedChanges = true">
                        <video class="h-100 w-100 media-ad" alt="Video placeholder" :src="getImageUrl(ad.meta_video)" />
                        <span class="text-hover">
                          <em class="fas fa-trash icon-delete-video"></em>
                          {{ $t('campaignForm.placeholder.video') }}
                        </span>
                      </base-button>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-6">
                  <label class="d-flex align-items-center">
                    <span class="mr-auto">{{ $t("metaHeadline") }}</span>
                  </label>

                  <div class="input-group">
                    <Field name="meta_headline">
                      <input v-model="ad.meta_headline" type="text" class="input-stevie w-100 pr-4"
                        :class="{ 'has-danger': markAsInvalid(ad.meta_headline === null || ad.meta_headline === '') && !isFacebookBlock }"
                        :disabled="isDisabledEdit || isFacebookBlock || isLoadingMagicWand"
                        :placeholder="$t('campaignForm.placeholder.metaHeadline')"
                        :maxLength="validationsForms.maxCharsMetaHeadline">
                      <magic-wand class="mr-1 position-absolute magicWand"
                        :class="{ 'disabled': isDisabledEdit || isFacebookBlock || isLoadingMagicWand }"
                        :isDisabled="isDisabledEdit || isFacebookBlock || isLoadingMagicWand"
                        :isLoading="magicWandSpinner.meta_headline" :isError="isShowTooltipAIError.meta_headline"
                        @click="getTextsToAI('meta_headline', null, [ad.meta_headline])" />
                    </Field>
                  </div>
                  <small v-if="markAsInvalid(ad.meta_headline === null || ad.meta_headline === '') && !isFacebookBlock"
                    class="text-danger mt--3">{{ $t('formValidations.required')
                    }}</small>
                </div>

                <div class="col-12 col-lg-6 mt-2 mt-lg-0">
                  <label class="d-flex align-items-center">
                    <span class="mr-auto">{{ $t("metaUrlDescription") }}</span>
                  </label>

                  <div class="input-group">
                    <Field name="meta_url_description">
                      <input :disabled="isDisabledEdit || isFacebookBlock || isLoadingMagicWand"
                        v-model="ad.meta_url_description" type="text"
                        :placeholder="$t('campaignForm.placeholder.metaDescription')"
                        class="input-stevie d-block w-100 pr-4" :maxLength="validationsForms.maxCharsMetaDescription"
                        :class="{ 'has-danger': markAsInvalid(ad.meta_url_description === null || ad.meta_url_description === '') && !isFacebookBlock }">
                    </Field>
                    <magic-wand class="mr-1 position-absolute magicWand"
                      :class="{ 'disabled': isDisabledEdit || isFacebookBlock || isLoadingMagicWand }"
                      :isDisabled="isDisabledEdit || isFacebookBlock || isLoadingMagicWand"
                      :isLoading="magicWandSpinner.meta_url_description"
                      :isError="isShowTooltipAIError.meta_url_description"
                      @click="getTextsToAI('meta_url_description', null, [ad.meta_url_description])" />
                  </div>
                  <small
                    v-if="markAsInvalid(ad.meta_url_description === null || ad.meta_url_description === '') && !isFacebookBlock"
                    class="text-danger mt--3">{{ $t('formValidations.required')
                    }}</small>
                </div>

                <div class="col-12 col-lg-6 mt-2">
                  <label class="d-flex align-items-center">
                    <span class="mr-auto">{{ $t("metaText") }}</span>
                  </label>

                  <div class="input-group">
                    <Field name="meta_text">
                      <textarea class="textarea-stevie d-block w-100 hide-scrollbar" v-model="ad.meta_text"
                        :disabled="isDisabledEdit || isFacebookBlock || isLoadingMagicWand"
                        :placeholder="$t('campaignForm.placeholder.metaText')"
                        :maxLength="validationsForms.maxCharsMetaText" :rows="7" />
                      <magic-wand class="mr-1 position-absolute magicWand"
                        :class="{ 'disabled': isDisabledEdit || isFacebookBlock || isLoadingMagicWand }"
                        :isDisabled="isDisabledEdit || isFacebookBlock || isLoadingMagicWand"
                        :isLoading="magicWandSpinner.meta_text" :isError="isShowTooltipAIError.meta_text"
                        @click="getTextsToAI('meta_text', null, [ad.meta_text])" />
                    </Field>
                  </div>
                  <small v-if="markAsInvalid(ad.meta_text === null || ad.meta_text === '') && !isFacebookBlock"
                    class="text-danger mt--3">{{ $t('formValidations.required')
                    }}</small>
                </div>
              </div>
            </section>

            <section class="row segment highlight-segment">
              <div class="row">
                <div class="col-12 d-flex">
                  <h3 class="pr-2">{{ $t('googleAd') }}</h3>
                  <h3 class="subtitle-form">{{ $t("formSegment.campaignDetails") }}</h3>
                </div>

                <div class="col-12 row m-0 px-0 ad-form gap-1">
                  <div class="col-12 col-sm-6 mb-2">
                    <label>
                      <span class="label-text">
                        {{ $t("squareImage") }}
                      </span>
                      <span class="sublabel">(Min: {{ validationsForms.google_square_image.minWidth }} x
                        {{ validationsForms.google_square_image.minHeight }} px, Max:
                        {{ KBtoMB(validationsForms.google_square_image.maxSize) }} MB)</span></label>
                    <div v-show="!ad.google_square_image" class="uploader-campaigns square-image">
                      <ImageCropped :isAllowedCloseModal="false"
                        :validationsImage="validationsForms.google_square_image" :aspect-ratio="1"
                        file-name="googleSquareImage" :disabled="isDisabledEdit || isGoogleBlock"
                        @changeImage="ad.google_square_image = $event; hasUnsavedChanges = true"
                        :fileValue="ad.google_square_image" />
                    </div>
                    <div v-show="ad.google_square_image"
                      class="dz-message p-0 size-media-ads uploader-campaigns square-image">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit || isGoogleBlock"
                        @click="ad.google_square_image = null; hasUnsavedChanges = true">
                        <img class="h-100 w-100 media-ad" alt="Image placeholder"
                          :src="getImageUrl(ad.google_square_image)">
                        <span class="text-hover">{{ $t('campaignForm.placeholder.image',
                [validationsForms.google_square_image.minWidth,
                validationsForms.google_square_image.minHeight]) }}</span>
                      </base-button>
                    </div>
                    <small v-if="markAsInvalid(ad.google_square_image === null) && !isGoogleBlock"
                      class="text-danger">{{ $t('formValidations.required')
                      }}</small>
                  </div>

                  <div class="col-12 col-sm-6 mb-2">
                    <label>
                      <span class="label-text">
                        {{ $t("squareLogo") }}
                      </span>
                      <span class="sublabel">(Min: {{ validationsForms.google_square_logo.minWidth }} x
                        {{ validationsForms.google_square_logo.minHeight }} px, Max:
                        {{ KBtoMB(validationsForms.google_square_logo.maxSize) }} MB)</span>
                    </label>
                    <div v-show="!ad.google_square_logo" class="uploader-campaigns square-image">
                      <ImageCropped :isAllowedCloseModal="false" :validationsImage="validationsForms.google_square_logo"
                        :aspect-ratio="1" file-name="googleSquareImage" :disabled="isDisabledEdit || isGoogleBlock"
                        @changeImage="ad.google_square_logo = $event; hasUnsavedChanges = true"
                        :fileValue="ad.google_square_logo" />
                    </div>
                    <div v-show="ad.google_square_logo"
                      class="dz-message p-0 size-media-ads uploader-campaigns square-image">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit || isGoogleBlock"
                        @click="ad.google_square_logo = null; hasUnsavedChanges = true">
                        <img class="h-100 w-100 media-ad" alt="Image placeholder"
                          :src="getImageUrl(ad.google_square_logo)">
                        <span class="text-hover">{{ $t('campaignForm.placeholder.image',
                [validationsForms.google_square_logo.minWidth,
                validationsForms.google_square_logo.minHeight]) }}</span>
                      </base-button>
                    </div>
                    <small v-if="markAsInvalid(ad.google_square_logo === null) && !isGoogleBlock"
                      class="text-danger">{{ $t('formValidations.required')
                      }}</small>
                  </div>

                  <div class="col-12 col-sm-6 mb-2">
                    <label>
                      <span class="label-text">
                        {{ $t("image") }}
                      </span>
                      <span class="sublabel">(Min: {{ validationsForms.google_image.minWidth }} x
                        {{ validationsForms.google_image.minHeight }} px, Max:
                        {{ KBtoMB(validationsForms.google_image.maxSize) }} MB)</span> </label>
                    <div v-show="!ad.google_image" class="uploader-campaigns">
                      <ImageCropped :isAllowedCloseModal="false" :validationsImage="validationsForms.google_image"
                        :aspect-ratio="validationsForms.google_image.aspectRatio" file-name="googleImage"
                        :disabled="isDisabledEdit || isGoogleBlock"
                        @changeImage="ad.google_image = $event; hasUnsavedChanges = true" :fileValue="ad.google_image" />
                    </div>
                    <div v-show="ad.google_image" class="uploader-campaigns dz-message p-0 size-media-ads">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit || isGoogleBlock"
                        @click="ad.google_image = null; hasUnsavedChanges = true">
                        <img class="h-100 w-100 media-ad" alt="Image placeholder" :src="getImageUrl(ad.google_image)">
                        <span class="text-hover">{{ $t('campaignForm.placeholder.image',
                [validationsForms.google_image.minWidth, validationsForms.google_image.minHeight]) }}</span>
                      </base-button>
                    </div>
                    <small v-if="markAsInvalid(ad.google_image === null) && !isGoogleBlock"
                      class="text-danger position-absolute">{{ $t('formValidations.required')
                      }}</small>
                  </div>

                  <div class="col-12 col-sm-6 mb-2">
                    <label>
                      <span class="label-text">
                        {{ $t("logo") }}
                      </span>
                      <span class="sublabel">(Min: {{ validationsForms.google_logo.minWidth }} x
                        {{ validationsForms.google_logo.minHeight }} px, Max:
                        {{ KBtoMB(validationsForms.google_logo.maxSize) }} MB)</span>
                    </label>
                    <div v-show="!ad.google_logo" class="uploader-campaigns">
                      <ImageCropped :isAllowedCloseModal="false" :validationsImage="validationsForms.google_logo"
                        :aspect-ratio="validationsForms.google_logo.aspectRatio" file-name="googleImage"
                        :disabled="isDisabledEdit || isGoogleBlock"
                        @changeImage="ad.google_logo = $event; hasUnsavedChanges = true" :fileValue="ad.google_logo" />
                    </div>
                    <div v-show="ad.google_logo" class="uploader-campaigns dz-message p-0 size-media-ads">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit || isGoogleBlock"
                        @click="ad.google_logo = null; hasUnsavedChanges = true">
                        <img class="h-100 w-100 media-ad" alt="Image placeholder" :src="getImageUrl(ad.google_logo)">
                        <span class="text-hover">
                          <em class="fas fa-trash icon-delete-video"></em>
                          {{ $t('campaignForm.placeholder.image', [validationsForms.google_logo.minWidth,
              validationsForms.google_logo.minHeight]) }}
                        </span>
                      </base-button>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-6 mt-4 d-flex flex-column">
                  <label class="d-flex align-items-center">
                    <div class="mr-auto">
                      <span>{{ $t("googleHeadline") }}</span>
                      <el-tooltip placement="top" :content="$t('infoGoogle.header')">
                        <img src="/img/icons/stevie/info-shape.svg" class="icon-card ml-1" alt="Info icon" />
                      </el-tooltip>
                    </div>
                  </label>

                  <div v-for="(value, index) in ad.google_headline" :key="index">
                    <base-input name="google_headline" group class="mb-4">
                      <div class="input-group">
                        <Field name="google_headline">
                          <input :disabled="isDisabledEdit || isGoogleBlock || isLoadingMagicWand"
                            :placeholder="$t('campaignForm.placeholder.googleHeadline')"
                            v-model="ad.google_headline[index]" type="text"
                            :maxlength="validationsForms.maxCharsGoogleHeadline" class="input-stevie form-control pr-4"
                            :class="{ 'no-rounded-borders': ad.google_headline.filter(item => item !== '').length < validationsForms.minField_google_headline && !isGoogleBlock }">
                          <magic-wand class="mr-1 position-absolute magicWand"
                            :class="{ 'disabled': isDisabledEdit || isGoogleBlock || isLoadingMagicWand }"
                            :isDisabled="isDisabledEdit || isGoogleBlock || isLoadingMagicWand"
                            :isLoading="magicWandSpinner.google_headline[index]"
                            :isError="isShowTooltipAIError.google_headline[index]"
                            @click="getTextsToAI('google_headline', index, ad.google_headline)" />
                        </Field>
                      </div>
                    </base-input>
                  </div>
                  <small
                    v-if="markAsInvalid(ad.google_headline.filter(item => item !== '').length < validationsForms.minField_google_headline) && !isGoogleBlock"
                    class="text-danger">
                    {{ $t("formValidations.requiredMinFields", [validationsForms.minField_google_headline]) }}
                  </small>
                  <small v-if="markAsInvalid(haveDuplicates(ad.google_headline))" class="text-danger">
                    {{ $t("formValidations.duplicateFields") }}
                  </small>
                </div>

                <div class="col-12 col-lg-6 mt-4 d-flex flex-column">
                  <label class="d-flex align-items-center">
                    <div class="mr-auto">
                      <span>{{ $t("google_long_headline") }}</span>
                      <el-tooltip placement="top" :content="$t('infoGoogle.long_header')">
                        <img src="/img/icons/stevie/info-shape.svg" class="icon-card ml-1" alt="Info icon" />
                      </el-tooltip>
                    </div>
                  </label>

                  <div v-for="(value, index) in ad.google_long_headline" :key="index">
                    <base-input name="google_long_headline" group class="mb-4">
                      <div class="input-group">
                        <Field name="google_long_headline">
                          <input :disabled="isDisabledEdit || isGoogleBlock || isLoadingMagicWand"
                            :placeholder="$t('campaignForm.placeholder.googleHeadline')"
                            v-model="ad.google_long_headline[index]" type="text"
                            :maxlength="validationsForms.maxCharsGoogleLongHeadline"
                            class="input-stevie form-control pr-4"
                            :class="{ 'no-rounded-borders': ad.google_long_headline.filter(item => item !== '').length < validationsForms.minField_google_long_headline && !isGoogleBlock }">
                          <magic-wand class="mr-1 position-absolute magicWand"
                            :class="{ 'disabled': isDisabledEdit || isGoogleBlock || isLoadingMagicWand }"
                            :isDisabled="isDisabledEdit || isGoogleBlock || isLoadingMagicWand"
                            :isLoading="magicWandSpinner.google_long_headline[index]"
                            :isError="isShowTooltipAIError.google_long_headline[index]"
                            @click="getTextsToAI('google_long_headline', index, ad.google_long_headline)" />
                        </Field>
                      </div>
                    </base-input>
                  </div>
                  <small
                    v-if="markAsInvalid(ad.google_long_headline.filter(item => item !== '').length < validationsForms.minField_google_long_headline) && !isGoogleBlock"
                    class="text-danger">
                    {{ $t("formValidations.requiredMinFields", [validationsForms.minField_google_long_headline]) }}
                  </small>
                  <small v-if="markAsInvalid(haveDuplicates(ad.google_long_headline))" class="text-danger">
                    {{ $t("formValidations.duplicateFields") }}
                  </small>
                </div>

                <div class="col-12 mt-4">
                  <label class="d-flex align-items-center">
                    <div class="mr-auto">
                      <span>{{ $t("googleDescription") }}</span>
                      <el-tooltip placement="top" :content="$t('infoGoogle.adsDescription')">
                        <img src="/img/icons/stevie/info-shape.svg" class="icon-card ml-1" alt="Info icon" />
                      </el-tooltip>
                    </div>
                  </label>
                  <div v-for="(value, index) in ad.google_description" :key="index" class="mb-4">
                    <base-input name="google_description" group>
                      <div class="input-group">
                        <Field name="google_description">
                          <textarea :disabled="isDisabledEdit || isGoogleBlock || isLoadingMagicWand"
                            :placeholder="$t('campaignForm.placeholder.googleDescription')"
                            v-model="ad.google_description[index]"
                            :maxlength="index === 0 ? validationsForms.maxCharsFirstGoogleDescription : validationsForms.maxCharsRestGoogleDescription"
                            class="input-stevie form-control google-description hide-scrollbar" />
                          <magic-wand class="mr-1 position-absolute magicWand"
                            :class="{ 'disabled': isDisabledEdit || isGoogleBlock || isLoadingMagicWand }"
                            :isDisabled="isDisabledEdit || isGoogleBlock || isLoadingMagicWand"
                            :isLoading="magicWandSpinner.google_description[index]"
                            :isError="isShowTooltipAIError.google_description[index]"
                            @click="getTextsToAI('google_description', index, ad.google_description)" />
                        </Field>
                      </div>
                    </base-input>
                  </div>
                  <base-button v-if="ad.google_description.length < validationsForms.maxField_google_description"
                    class="btn-add-fields"
                    @click="manageField('google_description', index, 'add'); hasUnsavedChanges = true"
                    :disabled="isDisabledEdit || isGoogleBlock">
                    <img class="mr-1 icon" src="/img/icons/stevie/add-shape-color-blue-light.svg" alt="Add icon" />
                    {{ $t("addGoogleDescription") }}
                  </base-button>
                  <small
                    v-if="markAsInvalid(ad.google_description && ad.google_description.filter(item => item !== '').length < 2) && !isGoogleBlock"
                    class="text-danger">
                    {{ $t("formValidations.requiredMinFields", [2]) }}
                  </small>
                  <small v-if="markAsInvalid(haveDuplicates(ad.google_description))"
                    class="text-danger position-absolute">{{
                $t("formValidations.duplicateFields")
              }}</small>
                </div>
              </div>
            </section>

            <section class="containerSaveButton row justify-content-around align-items-center segment footer">
              <base-button borderRadiusLeft borderRadiusRight @click="manageAd()"
                class="col-12 col-lg-5 m-0 text-center p-0 start-campaign-button"
                :disabled="isLoading || isDisabledEdit || originalStatus == ad.status && ad.status === 1">
                <span v-if="!isLoadingSaving"> {{ $t("saveCampaign") }}</span>
                <clip-loader v-else color="#ffffff" size="20px" class="pt-2"></clip-loader>
              </base-button>

              <base-button borderRadiusLeft borderRadiusRight @click="manageConfirmModal()"
                class="col-12 col-lg-5 m-0 text-center p-0 start-campaign-button"
                :disabled="isLoading || (isDisabledEdit && cantEditStatus)">
                <span v-if="!isLoadingChangingStatus">
                  {{
                ad.status === originalStatus && ad.status === 0 || ad.status === 6 ? $t("publishCampaign") :
                  ad.status === originalStatus && ad.status === 1 || ad.status === 2 ? $t("pauseCampaign") :
                    $t("startCampaign") }}
                </span>
                <clip-loader v-else color="#ffffff" size="20px" class="pt-2"></clip-loader>
              </base-button>
            </section>
          </Form>
        </div>
      </div>

      <div class="col-12 col-xl-4 px-0 m-0 justify-content-center">
        <div class="px-0 pl-lg-0 pl-xl-5">
          <meta-ad-preview v-show="!isFacebookBlock" class="ad-preview-meta px-0" :url="ad.url"
            :meta-headline="ad.meta_headline" :meta-description="ad.meta_url_description" :meta-text="ad.meta_text"
            :meta-image="getImageUrl(ad.meta_image)" />

          <google-ad-preview v-show="!isGoogleBlock" class="ad-preview-google px-0"
            :title="$t('previewGoogleAdDisplay')" :url="ad.url" :google-headline="ad.google_headline[0]"
            :google-description="ad.google_description[0]" :google-image="getImageUrl(ad.google_square_image)"
            :is-with-image="true" />
        </div>
      </div>
    </div>
  </div>

  <ConfirmModal v-if="showConfirmModal" :showModal="showConfirmModal" :modalInfo="modalConfirm" :isLoading="isLoading"
    @confirm="modalConfirm.confirmAction(true)" @cancel="modalConfirm.cancelAction(false)"
    @closeModal="showConfirmModal = false" />
  <ModalUnsavedChanges :hasUnsavedChanges="hasUnsavedChanges && isLeaveView" @close="closeUnsavedChanges()"
    @saveChanges="saveChanges()" :isLoading="isLoading"></ModalUnsavedChanges>

  <RechargeWallet :showModal="showWalletModal" originRoute="campaign" :id="ad.id" @closeModal="showWalletModal=false">
  </RechargeWallet>

  <magic-wand-config v-if="isShowMagicWandModal" :magicWandData="ad.magic_wand" :fieldSelected="magicWandFieldSelected"
    :isLoading="isLoadingMagicWand" @close-modal="isShowMagicWandModal = false" @data-edited="editMagicWandData"
    @get-texts-by-ia="getTextsToAI($event)" />
</template>

<script>
import moment from "moment";
import axios from "axios";
import { Form, Field } from 'vee-validate';
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n/index";
import { computed, onMounted, onUnmounted, ref, toRaw } from "vue";
import useWallet from '@/composables/useWallet.js';

import RechargeWallet from "../../Components/RechargeWallet";
import ConfirmModal from "../../Components/ConfirmModal";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import GoogleAdPreview from "./Components/GoogleAdPreview";
import MetaAdPreview from "./Components/MetaAdPreview";
import BaseSlider from "../../../components/BaseSlider.vue";
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
import ImageCropped from '../../Components/ImageCropped.vue';
import AutocompletAddress from '@/views/Components/AutocompleteAddress.vue';
import ModalUnsavedChanges from "@/views/Components/ModalUnsavedChanges.vue";
import { formatWebsite } from '../../../composables/useFormatUrl';
import { regionsGermany, campaignStatus, allowedVideoFormatsCampaigns } from "../../../util/Settings";
import useCampaigns from "@/composables/useCampaings";
import useForms from '@/composables/useForms';
import useStevieAI from '@/composables/useStevieAI';
import { getImageUrl, removeNullAndEmptyStringFromObject } from '@/util/helperFunctions.js'
import { ElUpload, ElSelect, ElOption } from "element-plus";
import router from "../../../router";
import useSubscription from '@/composables/useSubscription';
import MagicWandConfig from "./Components/MagicWandConfig.vue";
import MagicWand from "../../../views/Components/MagicWand.vue";

export default {
  name: "CampaignForm",
  props: ["id", "isClone"],
  components: {
    DropzoneFileUpload,
    ClipLoader,
    GoogleAdPreview,
    MetaAdPreview,
    BaseSlider,
    Form,
    ImageCropped,
    AutocompletAddress,
    ConfirmModal,
    RechargeWallet,
    ElSelect,
    ElOption,
    ElUpload,
    ModalUnsavedChanges,
    MagicWand,
    MagicWandConfig
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { t } = useI18n();
    const { getTotalAvailable, walletTotal } = useWallet()
    const { totalActivesCampaigns, getCampaign, updateCampaign, changeCampaignToReady, storeCampaign, getTotalActivesCampaigns, pausedCampaign, startedCampaign } = useCampaigns();
    const { markAsInvalid, isValidForm, isValidLink } = useForms();
    const { hasSubscription, inGracePeriod, subscriptionPermissions, changeSuscriptionModal } = useSubscription();
    const { requestCampaignDataFromAi } = useStevieAI();
    const metaImage = ref(null);
    const googleImage = ref(null);
    const googleSquareImage = ref(null);
    const video = ref(null);
    const id = ref(null)
    const ad = ref({
      name: null,
      url: null,
      status: 0,
      meta_headline: '',
      meta_text: '',
      meta_image: null,
      meta_video: null,
      meta_category: 'NONE',
      meta_url_description: '',
      invested_facebook: 50,
      invested_google: 50,
      invested: 0,
      errors_exist: false,
      errors: null,
      start_on: null,
      end_on: null,
      google_headline: ['', '', '', '', ''],
      google_description: ['', '', '', ''],
      google_long_headline: ['', '', '', '', ''],
      google_image: null,
      google_logo: null,
      google_square_image: null,
      google_square_logo: null,
      target_: null,
      target_gender: null,
      target_age: null,
      target_location_region: 0,
      target_location_address: null,
      target_location_latitude: null,
      target_location_longitude: null,
      target_location_radio: 0,
      magic_wand: {
        language: 'german',
        language_style: 'standard language',
        expected_goal: null,
        advertised_content: null,
        keywords: [],
        more_about: null,
        key_features: null,
        url: null
      }
    });
    const routeAd = ref("/campaigns")
    const isLoading = ref(false);
    const isLoadingSaving = ref(false);
    const isLoadingChangingStatus = ref(false);
    const validationsForms = ref({
      minField_google_headline: 1,
      maxField_google_headline: 5,
      minField_google_long_headline: 1,
      maxField_google_long_headline: 5,
      minField_google_description: 2,
      maxField_google_description: 4,
      maxCharsFirstGoogleDescription: '60',
      maxCharsRestGoogleDescription: '90',
      maxCharsMetaDescription: '30',
      maxCharsGoogleHeadline: '30',
      maxCharsGoogleLongHeadline: '90',
      maxCharsMetaHeadline: '25',
      maxCharsMetaText: '125',
      minImageResolution: "Min: 600x314px",
      minSquareResolution: "Min 600x600px",
      google_square_image: {
        minHeight: 600,
        minWidth: 600,
        maxSize: 5242880
      },
      google_image: {
        minHeight: 314,
        minWidth: 600,
        maxSize: 5242880,
        aspectRatio: 600 / 314,
      },
      google_square_logo: {
        minHeight: 128,
        minWidth: 128,
        maxSize: 5242880,
        relation: '1:1'
      },
      google_logo: {
        minHeight: 128,
        minWidth: 512,
        maxSize: 5242880,
        aspectRatio: 512 / 128,
        relation: '4:1'
      },
      meta_image: {
        minHeight: 314,
        minWidth: 600,
        maxSize: 5242880,
        aspectRatio: 600 / 314,
      },
      meta_video: {
        formats: allowedVideoFormatsCampaigns,
        // maxSize: 943718400
        maxSize: 52428800
      }
    })
    const keyInput = ref(null)
    const modalConfirm = ref({
      title: null,
      subtitle: null,
      textCancel: null,
      textConfirm: null,
      confirmAction: null,
      cancelAction: null,
      allowClose: false,
    })
    const hasChangedStatus = ref(false)
    const showConfirmModal = ref(false)
    const showWalletModal = ref(false)
    const originalStatus = ref(0)
    const isRegionTarget = ref(false)
    const hasUnsavedChanges = ref(false);
    const isLeaveView = ref(false)
    const requiredGoogleFields = ["google_image", "google_square_image", "google_square_logo", "google_description", "google_headline", "google_long_headline"];
    const requiredFacebookFields = ["meta_text", "meta_headline", "meta_url_description", "meta_image"];
    const isGoToWallet = ref(false);
    const allFieldsFillableWithAi = ["meta_text", "meta_headline", "meta_url_description", "google_description", "google_headline", "google_long_headline"];
    const isLoadingMagicWand = ref(false);
    const magicWandSpinner = ref({
      meta_text: false,
      meta_headline: false,
      meta_url_description: false,
      google_description: [false, false, false, false],
      google_headline: [false, false, false, false, false],
      google_long_headline: [false, false, false, false, false],
    })
    const isShowTooltipAIError = ref({
      meta_text: false,
      meta_headline: false,
      meta_url_description: false,
      google_description: [false, false, false, false],
      google_headline: [false, false, false, false, false],
      google_long_headline: [false, false, false, false, false],
    })
    const MINIMUM_ALLOWED_RADIO = 0;
    const MAXIMUM_ALLOWED_RADIO = 80;
    const today = ref(moment(new Date()).format('YYYY-MM-DD'))
    const optionsForm = ref({
      agesOptions: ['18_24', '25_34', '35_44', '45_54', '55_64', '65_up'],
      genderOptions: ['male', 'female', 'other'],
      locations: ['region', 'local'],
      regions: regionsGermany,
      status: [],
      categoryOptions: ['CREDIT', 'EMPLOYMENT', 'HOUSING', 'ISSUES_ELECTIONS_POLITICS', 'NONE']
    });
    const minInvested = ref(20)
    const wrongMinInvested = ref(false)
    const screenWidth = ref(0);
    const isShowMagicWandModal = ref(false);
    const magicWandFieldSelected = ref(null);

    // Computeds

    const maxInvest = computed(() => {
      if (ad.value.status == 1) {
        return walletTotal.value
      } else {
        return ""
      }
    })
    const invalidDateEnd = computed(() => {
      if (ad.value.start_on === null || ad.value.end_on === null) return false
      return moment(ad.value.end_on).diff(moment(ad.value.start_on), 'days') < 1
    })
    const invalidDateStart = computed(() => {
      return ad.value.start_on ? moment(ad.value.start_on).diff(today.value, 'days') < 0 : false
    })
    const minDate = computed(() => {
      if (ad.value.start_on === null) {
        return moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
      } else {
        return moment(ad.value.start_on).add(1, 'days').format('YYYY-MM-DD')
      }
    })
    const maxDate = computed(() => {
      if (ad.value.start_on === null) {
        return moment(new Date()).add(150, 'days').format('YYYY-MM-DD')
      } else {
        return moment(ad.value.start_on).add(150, 'days').format('YYYY-MM-DD')
      }
    })
    const isMagicWandConfigurationCompleted = computed(() => {
      const requiredFields = ["language", "language_style", "expected_goal", "advertised_content", "keywords"];

      return requiredFields.every(requiredField => ad.value.magic_wand[requiredField]);
    })
    const isDisabledEdit = computed(() => {
      const pendingStatus = 0;
      return id.value !== null && ad.value.status !== pendingStatus && ad.value.status === originalStatus.value;
    })
    const isFacebookBlock = computed(() => ad.value.invested_facebook <= 0)
    const isGoogleBlock = computed(() => ad.value.invested_google <= 0)

    const cantEditStatus = computed(() => {
      const readyStatus = 1;
      const canceledStatus = 4;
      const finalisedStatus = 5;
      const inReviewStatus = 6;

      return ad.value.status === readyStatus || ad.value.status === canceledStatus || ad.value.status === finalisedStatus || originalStatus.value === inReviewStatus;
    })

    const hasSubscriptionActive = computed(() => {
      return Boolean(hasSubscription.value || inGracePeriod.value);
    })

    const canPublishCampaign = computed(() => {
      return validateForm() && hasSubscriptionActive.value && totalActivesCampaigns.value <= subscriptionPermissions.value.numberCampaigns;
    })

    const isValidRadioLocation = computed(() => {
      const ZERO = 0;
      return ad.value.target_location_radio >= ZERO && ad.value.target_location_radio <= MAXIMUM_ALLOWED_RADIO;
    })


    // Methods

    const manageAd = async (saveWithoutResponse = false) => {
      if (invalidDateStart.value || invalidDateEnd.value) {
        const typeDate = invalidDateStart.value ? 'startDate' : 'endDate';

        toast.warning(t(`formValidations.${typeDate}`), { timeout: 5000 });
        return;
      };

      if (!isValidRadioLocation.value) {
        toast.warning(t('formValidations.location_radio_out_of_radius', [MINIMUM_ALLOWED_RADIO, MAXIMUM_ALLOWED_RADIO]), { timeout: 5000 });
        return;
      }

      ad.value.invested_facebook = Math.trunc(ad.value.invested_facebook);
      ad.value.url = formatWebsite(ad.value.url);
      ad.value.status = isGoToWallet.value ? originalStatus.value : ad.value.status;
      ad.value.invested ||= 0;

      if (ad.value.status === 1 && !canPublishCampaign.value) {
        ad.value.status = 0;
        return;
      }

      const formData = new FormData();
      const exceptFields = [];

      if (isRegionTarget.value) {
        exceptFields.push("target_location_region");
      } else {
        const addFields = ["target_location_address", "target_location_longitude", "target_location_latitude"];
        addFields.forEach((field) => exceptFields.push(field));
      }

      Object.keys(ad.value).forEach(field => {
        const fieldsToJson = ["google_long_headline", "google_headline", "google_description"];
        if (exceptFields.includes(field)) return;

        let value = ad.value[field];
        const isArrayField = Array.isArray(value);

        if (fieldsToJson.includes(field)) {
          value = ad.value[field].filter(value => value);

          if (Array.isArray(value) && value.length === 0) {
            formData.append(field, '');
          } else {
            formData.append(field, JSON.stringify(value));
          }
          return;
        }

        if (isArrayField) {
          value = JSON.stringify(value)
        }

        if (field === 'magic_wand') {
          const magicData = toRaw(value);

          for (const key in magicData) {
            if (magicData[key] === null) {
              formData.append(key, '');
              continue;
            }

            if (Array.isArray(magicData[key])) {
              formData.append(key, JSON.stringify(magicData[key]));
              continue;
            }

            formData.append(key, magicData[key]);
          }
          return;
        }

        if (ad.value[field] === null) {
          formData.append(field, '');
          return;
        }
        formData.append(field, value);
      })
      let response;
      if (ad.value.status === 0) {
        isLoadingSaving.value = true
      }
      if (ad.value.status === 1 || ad.value.status === 2 || ad.value.status === 3) {
        isLoadingChangingStatus.value = true
      }
      isLoading.value = true;
      if (id.value) {
        response = await updateAd(formData, saveWithoutResponse);
      } else {
        response = await storeAd(formData, saveWithoutResponse);
      }
      await getTotalActivesCampaigns()
      if (ad.value.status === 0) {
        isLoadingSaving.value = false
      }
      if (ad.value.status === 1 || ad.value.status === 2 || ad.value.status === 3) {
        isLoadingChangingStatus.value = false
      }
      isLoading.value = false;
      hasUnsavedChanges.value = false;
      return response
    }

    const storeAd = async (data, saveWithoutResponse) => {
      let response = await storeCampaign(data, saveWithoutResponse);
      if (response && response.status === 200) {
        if (!saveWithoutResponse) {
          router.push({ name: "CampaignsList" });
        }
        fillAd(response.data)
      }
      return response
    }

    const updateAd = async (data, saveWithoutResponse) => {
      data.append("_method", "PUT");

      let imageFields = ["meta_image", "google_image", "google_square_image", "google_square_logo", "google_logo", "meta_video"];
      imageFields.forEach(field => {
        let value = data.get(field)
        if (value && typeof value === 'string') {
          data.delete(field)
        }
      })

      let response;
      if (ad.value.status === 1) {
        response = await changeCampaignToReady(id.value, data, saveWithoutResponse)
      } else {
        response = await updateCampaign(id.value, data, saveWithoutResponse)
      }
      if (response && response.status === 200) {
        getTotalAvailable()
        originalStatus.value = response.data.status;
        if (!saveWithoutResponse) {

          fillAd(response.data)
        }
      } else if (response) {
        if (response.url && response.url[0]) {
          toast.error(response.url[0], { timeout: 3000 });
        }
      }
      return response
    }

    const showWandConfigurationModal = () => isShowMagicWandModal.value = true;

    const getTextsToAI = async (field = null, indexField = null, currentFieldValue = null) => {
      if (isLoadingMagicWand.value) return;

      magicWandFieldSelected.value = field;
      if (isMagicWandConfigurationCompleted.value) {
        isLoadingMagicWand.value = true;
        const fieldsWithIndex = ['google_headline', 'google_description', 'google_long_headline'];

        try {
          if (field && fieldsWithIndex.includes(field)) {
            magicWandSpinner.value[field][indexField] = true;
          } else {
            magicWandSpinner.value[field] = true;
          }

          const fields = field ? Array(field) : allFieldsFillableWithAi;
          const magicWandFields = removeNullAndEmptyStringFromObject(ad.value.magic_wand);
          const isFirstGoogleDescription = indexField === 0 && fields[0] === 'google_description';

          const payload = { isFirstGoogleDescription, currentFieldValue, data: { fields, ...magicWandFields } };
          const { response, isUniqueField } = await requestCampaignDataFromAi(payload);

          if (response && response.status === 200) {
            fillCampaignDataFromAI({ data: response.data, isUniqueField, indexField });
          }

          if (field) {
            isShowTooltipAIError.value[field] = Boolean(response && response['error_openAI']);

            setTimeout(() => { isShowTooltipAIError.value[field] = false; }, 5000);
          }

        } catch (error) {
          toast.error(t("notification.error.tryAgainLater"));
          console.log(error);
        } finally {
          if (field && fieldsWithIndex.includes(field)) {
            magicWandSpinner.value[field][indexField] = false;
          } else {
            magicWandSpinner.value[field] = false;
          }
          isShowMagicWandModal.value = false;
          isLoadingMagicWand.value = false;
        }
        return;
      }
      isShowMagicWandModal.value = true;
      toast.error(t("notification.error.incompleteMagicWandConfiguration"));
    }

    const fillCampaignDataFromAI = ({ data: dataFromAI, isUniqueField, indexField = null }) => {
      hasUnsavedChanges.value = true;
      const fieldsToParse = ['google_description', 'google_headline', 'google_long_headline'];

      for (const key in dataFromAI) {
        if (!isUniqueField && fieldsToParse.includes(key)) dataFromAI[key] = JSON.parse(dataFromAI[key]);

        if (isUniqueField && indexField !== null) {
          ad.value[key][indexField] = dataFromAI[key];
        } else {
          ad.value[key] = dataFromAI[key];
        }
      }
    }

    const showCampaign = async (id) => {
      let response = await getCampaign(id);
      if (response && response.status === 200) {
        fillAd(response.data);
      }
    }

    const fillAd = (data) => {
      data = fillJsonFields(data);
      data.magic_wand ||= { language: 'german', language_style: 'standard language', advertised_content: null, keywords: [], more_about: null, key_features: null };

      data.magic_wand.language ||= 'german';
      data.magic_wand.language_style ||= 'standard language';
      data.magic_wand.url = data.url;
      data.magic_wand.keywords = data.magic_wand.keywords.length ? JSON.parse(data.magic_wand.keywords) : [];

      ad.value = { ...ad.value, ...data };
      isRegionTarget.value = ad.value.target_location_region === null

      originalStatus.value = data.status;
    }

    const fillJsonFields = (data) => {
      const fieldsToJson = ['google_description', 'google_headline', 'google_long_headline'];

      fieldsToJson.forEach(item => {
        if (!data[item] && item === 'google_description') {
          data[item] = item === 'google_description' ? ['', '', '', ''] : ['', '', '', '', ''];
        }
        if (!data[item] && item === 'google_headline') {
          data[item] = item === 'google_headline' ? ['', '', '', ''] : ['', '', '', '', ''];
        }
        if (!data[item] && item === 'google_long_headline') {
          data[item] = item === 'google_long_headline' ? ['', '', '', ''] : ['', '', '', '', ''];
        }
        if (data[item] && data[item].length < validationsForms.value[`maxField_${item}`]) {
          data[item].length = validationsForms.value[`maxField_${item}`];
        }
      })

      return data;
    }

    const manageField = (name, index, action) => {
      const array = name === 'google_headline' ? ad.value.google_headline : ad.value.google_description;
      const isAddingField = action === 'add';
      if (isAddingField) {
        if (validationsForms.value[`maxField_${name}`] && array.length >= validationsForms.value[`maxField_${name}`]) return;

        array.push('')
      } else {
        array.splice(index, 1);
      }
    }

    const uploadForm = (event) => {
      const fieldName = event.srcElement.name;
      const value = event.target.value;
      if (Object.keys(ad.value).includes(fieldName) && value) {
        ad.value[fieldName] = value;
      }
      // checkInvested();
    }

    const changeInvested = (event) => {
      hasUnsavedChanges.value = true;
      ad.value.invested_facebook = 100 - Math.trunc(event);
      ad.value.invested_google = Math.trunc(event);
      // checkInvested();
    }

    const checkInvested = () => {

      const days = (new Date(ad.value.end_on).getTime() - new Date(ad.value.start_on).getTime()) / (1000 * 3600 * 24);

      // Less than 3 days = 20€ per day
      // Between 3 and 30 days = 10€ per day
      // More than 31 days = 6.5€ per day
      if (days <= 3) {
        minInvested.value = (days * 20);
      } else if (days > 3 && days <= 31) {
        minInvested.value = (days * 10);
      } else {
        minInvested.value = (days * 6.45);
      }
      // Add the 5% of error margin
      minInvested.value = Math.trunc(minInvested.value * 1.05);

      // Check if the invested is valid
      wrongMinInvested.value = ad.value.invested < minInvested.value;

    }

    const setAddress = (location) => {
      const { address, lat, lng } = location
      ad.value.target_location_address = address;
      ad.value.target_location_latitude = lat;
      ad.value.target_location_longitude = lng;
    }

    const changeLocationType = () => {
      ad.value.target_location_address = null;
      ad.value.target_location_latitude = null;
      ad.value.target_location_longitude = null;
      ad.value.target_location_radio = 0;
      ad.value.target_location_region = 0;
    };

    const hideErrors = ref(false);

    //VALIDATE FORM
    const validateForm = () => {
      let isValid = true;
      let requiredFields = ["name", "url", "start_on", "end_on"];

      let addFields = [];
      if (!isRegionTarget.value) {
        addFields = ["target_location_region"]
      } else {
        addFields = ["target_location_address", "target_location_longitude", "target_location_latitude", "target_location_radio"]
      }
      if (!isGoogleBlock.value) addFields = [...addFields, ...requiredGoogleFields];
      if (!isFacebookBlock.value) addFields = [...addFields, ...requiredFacebookFields];

      requiredFields = [...requiredFields, ...addFields];

      requiredFields.forEach(field => {
        let isArrayField = Array.isArray(ad.value[field]);
        if (isArrayField) {
          let minFields = validationsForms.value[`minField_${field}`];
          let elements = ad.value[field].filter(item => item !== '');
          if (elements.length < minFields) isValid = false
        }
      })

      if (!isValidLink(ad.value.url)) {
        isValid = false
      }

      if (isRegionTarget.value && !isValidRadioLocation.value) {
        isValid = false;
      }

      if (Number(ad.value.invested_facebook) + Number(ad.value.invested_google) != 100) {
        isValid = false
      }

      if (ad.value.status == 1 && (ad.value.invested <= 0 || (maxInvest.value >= ad.value.invested && wrongMinInvested.value))) {
        isValid = false
      }

      if (moment(ad.value.start_on).diff(today.value, 'days') < 0 || moment(ad.value.end_on).diff(moment(ad.value.start_on), 'days') < 1) {
        isValid = false
      }

      if (haveDuplicates(ad.value.google_description) || haveDuplicates(ad.value.google_headline) || haveDuplicates(ad.value.google_long_headline)) {
        isValid = false
      }

      isValid = isValidForm(ad.value, requiredFields, isValid);

      if (maxInvest.value !== '' && ad.value.invested > maxInvest.value) {
        if (isValid && ad.value.status === 1) {
          modalConfirm.value = {
            title: 'insufficientFunds',
            subtitle: 'insufficientFundsInfo',
            textCancel: 'saveAsDraft',
            textConfirm: 'rechargeWalletAndPublish',
            confirmAction: responseWallet,
            cancelAction: responseWallet,
            allowClose: true,
          }
          showConfirmModal.value = true;
          return false
        }
      }
      return isValid
    }

    const haveDuplicates = (value) => {
      value = value.filter(text => text !== '')
      const originalSize = value.length;
      const unique = Array.from(new Set(value))
      return originalSize !== unique.length
    }

    const cleanFields = (fields) => {
      const cleanFields = {
        google_image: null,
        google_logo: null,
        google_square_image: null,
        google_square_logo: null,
        google_description: ['', '', '', ''],
        google_headline: ['', '', '', '', ''],
        google_long_headline: ['', '', '', '', ''],
        meta_text: '',
        meta_headline: '',
        meta_url_description: '',
        meta_image: null,
        meta_video: null,
      };

      fields.forEach(field => { ad.value[field] = cleanFields[field] });
    }

    const backToList = () => {
      if (hasUnsavedChanges.value) {
        isLeaveView.value = true;
        return
      }

      const savedFilters = JSON.parse(localStorage.getItem('adsFilters'))

      router.push({ name: 'CampaignsList', query: savedFilters })

    }

    const manageConfirmModal = () => {
      let text;
      let action;
      if (hasSubscriptionActive.value) {
        if (ad.value.status === 0) {
          ad.value.status = 1;
        }

        if (ad.value.status === 2 || ad.value.status === 3) {
          ad.value.status = ad.value.status === 2 ? ad.value.status = 3 : ad.value.status = 2;
        }
        // 2: Started (live) 3: paused
        const statusWithModal = [2, 3];
        // checkInvested();
        if (!statusWithModal.includes(ad.value.status)) {
          manageAd();
          return;
        }

        const modalByStatus = {
          2: {
            text: 'changeStatusStarted',
            action: confirmChangeStatus
          },
          3: {
            text: 'changeStatusPaused',
            action: confirmChangeStatus
          }
        };

        text = modalByStatus[ad.value.status].text;
        action = modalByStatus[ad.value.status].action;
      } else {
        action = goToSubscriptionView;
        text = 'publishCampaingWithoutSubscription';
      }

      modalConfirm.value = {
        title: `${text}.title`,
        subtitle: `${text}.subtitle`,
        textCancel: `${text}.textCancel`,
        textConfirm: `${text}.textConfirm`,
        allowClose: false,
        confirmAction: action,
        cancelAction: cancelChangeStatus
      }
      setTimeout(() => {
        showConfirmModal.value = true;
      }, 10)
    }

    const cancelChangeStatus = async () => {
      ad.value.status = originalStatus.value;
      showConfirmModal.value = false;
      if (ad.value.status === 0) {
        isLoadingSaving.value = false
      }
      if (ad.value.status === 1 || ad.value.status === 2 || ad.value.status === 3) {
        isLoadingChangingStatus.value = false
      }
      isLoading.value = false;
    }

    const filterStatusOptionsByCurrentStatus = () => {
      const { label: labelCurrentStatus } = campaignStatus.find(({ id }) => id === ad.value.status);
      const optionsByStatus = {
        pending: ['pending', 'ready'],
        ready: ['ready', 'paused'],
        started: ['started', 'paused'],
        paused: ['paused', 'started'],
        canceled: ['canceled', 'started'],
        finalised: ['finalised'],
        inReview: ['inReview']
      };

      optionsForm.value.status = [...campaignStatus].filter(({ label }) => optionsByStatus[labelCurrentStatus].includes(label));
    }

    const editMagicWandData = ([field, value]) => {
      hasUnsavedChanges.value = true;
      if (field === 'url') {
        ad.value[field] = value;
      }
      ad.value.magic_wand[field] = value;
    }

    const confirmChangeStatus = async () => {
      hasUnsavedChanges.value = true;
      if (ad.value.status === 0) {
        isLoadingSaving.value = true
      }
      if (ad.value.status === 1 || ad.value.status === 2 || ad.value.status === 3) {
        isLoadingChangingStatus.value = true
      }
      isLoading.value = true;
      let response;
      try {
        if (ad.value.status === 3) {
          response = await pausedCampaign(id.value, ad.value.status);
        }
        if (ad.value.status === 2) {
          response = await startedCampaign(id.value, ad.value.status);
        }
      } catch (error) {
        console.log(error);
        ad.value.status = originalStatus.value;
      } finally {
        ad.value.status = response.status === 200 ? response.data.status : originalStatus.value;
        originalStatus.value = ad.value.status;
        hasChangedStatus.value = true;
        if (ad.value.status === 0) {
          isLoadingSaving.value = false
        }
        if (ad.value.status === 1 || ad.value.status === 2 || ad.value.status === 3) {
          isLoadingChangingStatus.value = false
        }
        isLoading.value = false;
        showConfirmModal.value = false;
      }
    }

    const goToSubscriptionView = async () => {
      await manageAd();
      router.push({ name: 'subscription' })
    }

    const saveAndCloseModal = async () => {
      await manageAd();
      if (ad.value.status === 0) {
        isLoadingSaving.value = false
      }
      if (ad.value.status === 1 || ad.value.status === 2 || ad.value.status === 3) {
        isLoadingChangingStatus.value = false
      }
      isLoading.value = false;
      showConfirmModal.value = false;
    }

    const responseWallet = async (confirmRecharge) => {
      ad.value.status = 0;
      let response = await manageAd(true);
      if (response && response.status === 200) {
        if (confirmRecharge) {
          showWalletModal.value = true;
        }
      }
      showConfirmModal.value = false;
    }

    const getImage = async (file, field, object) => {
      const isValid = field.includes('video') ? await isValidVideo(file, field) : await isValidImage(file, field);
      if (isValid) {
        hasUnsavedChanges.value = true;
        object[field] = file.raw;
      }
    };

    const isValidImage = async (file, field) => {

      let validations = validationsForms.value[field]
      if (!file || !validations) return true

      let size = file.size;
      let isValid = true;
      const { minHeight, minWidth, maxSize } = validationsForms.value[field];

      let imgUrl = URL.createObjectURL(file.raw);
      let infoImg = await loadImage(imgUrl)

      if (!infoImg.height || !infoImg.width) return true

      let { height, width } = infoImg;
      //maxSize and size in bytes
      if (maxSize && size > maxSize) {
        isValid = false;
        toast.warning(t("formValidations.invalidSize", [`${Math.round(maxSize / 1024)} KB max.`]));
      }
      return isValid
    };

    const isValidVideo = async (file, field) => {
      const validations = validationsForms.value[field];
      if (!file || !validations) return true;

      let isValid = true;
      const size = file.size;
      const { maxSize, formats } = validationsForms.value[field];

      //maxSize and size in bytes
      if (maxSize && size > maxSize) {
        isValid = false;
        const maxAllowedSize = Math.round(maxSize / 1024 / 1024);
        toast.warning(t("formValidations.invalidSizeVideo", [`${maxAllowedSize} MB max`]));
      }

      // Formats
      if (!formats.includes(file.raw.type)) {
        const formatlist = formats.map(format => format.split('/')[1]).join(' ');
        isValid = false;
        toast.warning(t("formValidations.invalidFormat", [formatlist]));
      }
      return isValid;
    };

    const loadImage = (path) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = path
        img.onload = () => {
          resolve(img)
        }
        img.onerror = e => {
          reject(e)
        }
      })
    }

    const KBtoMB = (kb) => {
      const mb = 1048576
      return (kb / mb).toFixed(1)
    }

    const downloadVideo = async (url) => {
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = url;
      anchor.target = "_blank"
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }

    const goToWallet = () => {
      isGoToWallet.value = true;
      if (hasUnsavedChanges.value) {
        isLeaveView.value = true;
        return
      }
      router.push({ name: 'Wallet' });
    }

    const saveChanges = async () => {
      if (ad.value.status === 0) {
        isLoadingSaving.value = true
      }
      if (ad.value.status === 1 || ad.value.status === 2 || ad.value.status === 3) {
        isLoadingChangingStatus.value = true
      }
      isLoading.value = true;
      let response = await manageAd();
      if (ad.value.status === 0) {
        isLoadingSaving.value = false
      }
      if (ad.value.status === 1 || ad.value.status === 2 || ad.value.status === 3) {
        isLoadingChangingStatus.value = false
      }
      isLoading.value = false;
      hasUnsavedChanges.value = false;
      isLeaveView.value = false;
      if (response && response.status === 200) {
        isGoToWallet.value ? goToWallet() : backToList()
      } else {
        hasUnsavedChanges.value = false;
      }
    };

    const closeUnsavedChanges = () => {
      hasUnsavedChanges.value = false;
      isLeaveView.value = false;
      isGoToWallet.value ? goToWallet() : backToList()
    }

    const handleResize = () => {
      screenWidth.value = window.innerWidth;
    }

    onMounted(async () => {
      if (route.params.id) {
        id.value = route.params.id;
        await showCampaign(route.params.id);
      } else {
        isShowMagicWandModal.value = true;
      }
      filterStatusOptionsByCurrentStatus();
      getTotalAvailable();

      if (route.query.wallet_id) {
        showWalletModal.value = true;
      }
      await getTotalActivesCampaigns();
      localStorage.removeItem('adsFilters')

      handleResize()
      window.addEventListener('resize', function (event) {
        handleResize()
      });
    })

    onUnmounted(() => {
      window.removeEventListener("resize", function (event) {
        handleResize()
      });
    })

    return {
      allowedVideoFormatsCampaigns,
      video,
      googleImage,
      googleSquareImage,
      ad,
      isLoading,
      isLoadingSaving,
      isLoadingChangingStatus,
      validationsForms,
      walletTotal,
      maxInvest,
      isDisabledEdit,
      cantEditStatus,
      id,
      keyInput,
      optionsForm,
      showConfirmModal,
      showWalletModal,
      modalConfirm,
      originalStatus,
      today,
      invalidDateEnd,
      invalidDateStart,
      minDate,
      maxDate,
      haveDuplicates,
      validateForm,
      manageAd,
      manageField,
      markAsInvalid,
      uploadForm,
      changeInvested,
      setAddress,
      changeLocationType,
      backToList,
      manageConfirmModal,
      getImage,
      isValidImage,
      isValidLink,
      getImageUrl,
      isRegionTarget,
      KBtoMB,
      downloadVideo,
      hasUnsavedChanges,
      isLeaveView,
      saveChanges,
      closeUnsavedChanges,
      hideErrors,
      minInvested,
      wrongMinInvested,
      screenWidth,
      isFacebookBlock,
      isGoogleBlock,
      goToWallet,
      getTextsToAI,
      isShowMagicWandModal,
      showWandConfigurationModal,
      magicWandFieldSelected,
      editMagicWandData,
      magicWandSpinner,
      isLoadingMagicWand,
      Field,
      isShowTooltipAIError,
      MAXIMUM_ALLOWED_RADIO,
      isValidRadioLocation
    };
  },
}
</script>

<style scoped lang="scss">
.icons-ads-height {
  max-height: 60px;

  &.facebook-icon-ads {
    max-height: 80px !important;
  }
}

.ad-preview-meta {
  aspect-ratio: 210px/500px;
}

.ad-preview-google {
  aspect-ratio: 210px/500px;
}

.label-light {
  color: #adb5bd;
}

.input-group .input-stevie {
  border-radius: 5px !important;

  &.no-rounded-borders {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &:disabled {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}

.input-group {
  .input-group-append.no-btn {
    background-color: #c6dcf5;
    min-width: 43px;
    border: 1px solid #c6dcf5 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    color: #adb5bd;
  }
}

.containerSaveButton {
  height: 180px;
}

.keyword-input {
  height: auto !important;

  .keyword-input__keyword {
    height: 25px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 6px;
    line-height: 25px;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  .keyword-input__keyword>span {
    cursor: pointer;
    opacity: 0.75;
  }

  .keyword-input__text {
    border: none;
    outline: none;
    line-height: 34px;
    background: none;
    width: 200px;
    max-width: 100%;
  }

  &.disabled {
    background-color: #EDF0F2 !important;

    .keyword-input__keyword {
      background-color: #FFFFFF;
    }
  }
}

.regionalCheckboxText {
  text-transform: none;
}

.sliderText {
  font-size: 13px;
}

.sliderInvested {
  width: -webkit-fill-available;

  .noUi-connect {
    background: #cccccc !important;

    .noUi-horizontal .noUi-handle,
    .noUi-vertical .noUi-handle {
      background: #012454 !important;

    }
  }
}

.icons-distribution {
  height: 20px;
  width: 20px;
}

:deep().el-switch__label.is-active {
  color: #5E72E4 !important;
}

:deep().min-height-textarea {
  height: 91px;
}

:deep() .textarea-block {
  height: 91px !important;
}

.small-text {
  font-size: 11px;
  opacity: 0.8;
  display: flex;
  align-items: center;
}

.word-break {
  word-break: break-word;
}

.info-errors {
  background-color: #FFF3CD;
  color: #997404 !important;

  .card-body {
    padding: 0.75rem !important;
  }

  h3,
  .icon-title {
    color: #A16207 !important;
    margin-bottom: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.custom-card {
  background: #ffffff;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  padding: 20px 21px;
  border-radius: 20px;
}

.segment {
  margin: 0 -21px;
  padding: 22px 31px 21px;

  &.footer {
    padding-top: 29px;
    margin-bottom: -20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &.highlight-segment {
    background-color: #E7F6FD;
  }
}

:deep()input[type="date"] {
  appearance: none !important;
  padding-left: 33px !important;
  background: url('/img/icons/stevie/calendar-alt.svg') no-repeat left;
  background-size: 13px;
  background-position: 9px 8px;
}

:deep()input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.google-description {
  height: 77px !important;
  padding: 10px !important;
}

.btn-add-fields {
  background-color: transparent !important;
  color: #0373a8;
  font-size: 13px;
  border: none;
  box-shadow: none;
  padding: 0px;
  display: flex;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    color: #10305D !important;
    box-shadow: none;
  }

  .icon {
    height: 13px;
    width: 13px;
  }
}

.btn-description {
  border: none !important;
  margin-left: 5px !important;
  border-radius: 5px !important;
}

.uploader-campaigns {
  height: 150px;

  &.square-image {
    margin: 0 auto;
    aspect-ratio: 1 / 1;
  }
}

label:has(.sublabel) {
  display: flex;
  flex-wrap: wrap;

  .label-text {
    padding-right: 5px;
  }

  .sublabel {
    text-transform: none;
    font-weight: 500;
  }
}

.magic-wand-styles {
  width: 1.2rem;
  height: 1.2rem;
  margin-bottom: 3px;
}

.icon-delete-video {
  position: absolute;
  top: 10px;
  right: 10px;
}

img {
  &.high-brightness {
    filter: brightness(5);
  }
}

.magicWand {
  bottom: 0.3rem;
  right: 0rem;
  z-index: 9
}

.hide-scrollbar {
  overflow: hidden;
}

@media (min-width: 768px) {
  .container-end-on {
    margin-top: 1.85rem !important;
  }
}

@media (min-width: 1440px) {
  .containerSaveButton {
    height: 95px;
  }
}
</style>
