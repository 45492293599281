<template>
 <div class="dashboard-margin">
    <div class="row justify-content-around m-0">
      <div class="custom-card col-12">
        <div>
          <div class="row align-items-end">
          <div class="col-12 col-lg-6">
              <h3>{{ $t("configureTemplate") }}</h3>
              <base-button
                class="p-0 back-button d-flex justify-content-between px-2"
                @click="backToList"
              >
                <div>
                  <img src="/img/icons/stevie/back-shape.svg" class="rotateArrow mr--2" alt="Back icon">
                  <img src="/img/icons/stevie/back-shape.svg" class="rotateArrow" alt="Back icon">
                </div>
                {{ $t("allTemplates") }}
                <div></div>
              </base-button>
            </div>
          </div>
          <div class="pt-3 px-0">
            <Form @submit="null" :initialValues="campaign" @change="changeForm">
              <section class="segment highlight-segment">
                <h3 class="subtitle-form">{{ $t("formSegment.general") }}</h3>
                <div class="row">
                  <div class="col-12 col-lg-9">
                    <label class="">{{ $t("templateName") }}:</label>
                    <base-input
                      alternative
                      name="template_name"
                      v-model="campaign.template_name"
                      type="text"
                      input-classes="input-stevie"
                      label-classes="input-placeholder-stevie"
                      :class="{
                        'has-danger': markAsInvalid(
                          campaign.template_name === null || campaign.template_name === ''
                        ),
                      }"
                    >
                    </base-input>
                    <small
                      v-if="
                        markAsInvalid(
                          campaign.template_name === null || campaign.template_name === ''
                        )
                      "
                      class="text-danger position-absolute mt--3"
                      >{{ $t("formValidations.required") }}</small
                    >
                  </div>
                  <div class="col-12 col-lg-9">
                    <label class="">{{ $t("campaignName") }}:</label>
                    <base-input
                      :placeholder="$t('campaignForm.placeholder.name')"
                      alternative
                      name="name"
                      v-model="campaign.name"
                      type="text"
                      input-classes="input-stevie"
                      label-classes="input-placeholder-stevie"
                    >
                    </base-input>
                    <small v-if="markAsInvalid( campaign.name === null || campaign.name === '')"
                      class="text-danger position-absolute mt--3"
                      >{{ $t("formValidations.required") }}</small>
                  </div>
                  <div class="col-12 col-lg-3">
                    <label>{{ $t("status") }}</label>
                    <div class="flex">
                      <el-select
                        disabled
                        :placeholder="$t('select.status')"
                        v-model="campaign.status"
                      >
                      <el-option v-for="status in optionsForm.status" :key="status.id" :value="status.value" :label="$t(`statusList.${status.label}`)">
                      </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="col-12 col-lg-9">
                    <label class="">{{ $t("url") }}:</label>
                    <base-input
                      alternative
                      name="url"
                      v-model="campaign.url"
                      :placeholder="$t('campaignForm.placeholder.website')"
                      type="text"
                      input-classes="input-stevie"
                      label-classes="input-placeholder-stevie"
                    >
                    </base-input>
                    <small v-if="markAsInvalid( campaign.url === null || campaign.url === ''|| !isValidLink(campaign.url))"
                      class="text-danger position-absolute mt--3"
                      >{{ $t("formValidations.required") }}</small>
                  </div>
                  <div class="col-12 col-lg-9 row pr-lg-0 mr-lg-4">
                    <div class="col-6 col-lg-6 pr-0">
                      <label class="label">{{ $t("campaignForm.durationCampaign") }}</label>
                      <base-input
                        alternative
                        disabled
                        name="start_on"
                        type="date"
                        input-classes="input-stevie"
                        label-classes="input-placeholder-stevie"
                      >
                      </base-input>
                    </div>
                    <div class="col-6 col-lg-6 pr-0">
                      <label class="label">{{ $t("campaignForm.durationCampaign") }}</label>
                      <base-input
                        alternative
                        disabled
                        name="start_on"
                        type="date"
                        input-classes="input-stevie"
                        label-classes="input-placeholder-stevie"
                      >
                      </base-input>
                    </div>

                  </div>

                  <div class="col-12 col-lg-3 ">
                    <label class="">
                      <div>
                        {{ $t("invested") }}
                        <el-tooltip placement="top" :content="$t('walletInfo')">
                          <img
                            src="/img/icons/stevie/info-shape.svg"
                            class="icon-card ml-1"
                            alt="Info icon"
                          />
                        </el-tooltip>
                      </div>
                    </label>
                    <base-input
                      alternative
                      disabled
                      v-model="campaign.invested"
                      name="invested"
                      type="number"
                      input-classes="input-stevie"
                      label-classes="input-placeholder-stevie"
                      min="0"
                    >
                    </base-input>
                  </div>
                  <div class="col-12 col-lg-9">
                    <label>
                      {{ $t('distribution') }}
                    </label>
                    <div>
                      <div class="d-flex align-items-center">
                        <img src="/img/icons/stevie/Facebook-icon-campaign.svg" class="icons-distribution" alt="Facebook logo">
                        <section class="flex-1 sliderInvested px-2">
                          <div class="mw-100" :class="{'disabled-click': true}">
                            <base-slider v-model="campaign.invested_facebook" @update:modelValue="changeInvested" :options="{step:5}"></base-slider>
                          </div>
                        </section>
                        <img src="/img/icons/stevie/Google-icon-campaign.svg" class="icons-distribution ml-auto" alt="Google logo">
                      </div>

                      <div>
                        <div class="d-flex justify-content-center">
                          <p class="sliderText">{{ Number(campaign.invested_facebook).toFixed() }} / {{ Number(campaign.invested_google).toFixed() }}</p>
                        </div>
                      </div>
                    </div>
                    <small v-if="markAsInvalid((Number(campaign.invested_facebook) + Number(campaign.invested_google)) != 100 )" class="text-danger position-absolute">{{$t('formValidations.required')
                    }}</small>
                  </div>
                </div>
              </section>
              <section class="segment">
                <h3 class="subtitle-form">{{ $t("formSegment.targetGroup") }}</h3>
                <div class="row">
                  <div class="col-12 col-lg-6 mt-4">
                    <label class="">{{ $t("age") }} ({{$t('optional')}})</label>
                    <base-input class="" name="target_age">
                      <el-select
                        :placeholder="$t('select.age')"
                        v-model="campaign.target_age"
                        clearable
                        @clear="campaign.target_age = null"
                      >
                        <el-option
                          v-for="age in optionsForm.agesOptions"
                          :key="age"
                          :value="age"
                          :label="$t(`agesRange.${age}`)"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-12 col-lg-6 mt-4">
                    <label class="">{{ $t("gender") }} ({{$t('optional')}})</label>
                    <base-input class="" name="target_gender">
                      <el-select
                          :placeholder="$t('select.gender')"
                          v-model="campaign.target_gender"
                          clearable
                          @clear="campaign.target_gender = null"
                        >
                          <el-option
                            v-for="gender in optionsForm.genderOptions"
                            :key="gender"
                            :value="gender"
                            :label="$t(`genders.${gender}`)"
                          >
                          </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-12 mt-4">
                    <div class="d-flex">
                      <div class="d-flex">
                        <base-checkbox :disabled="isDisabledEdit"  v-model="isRegionTarget" @change="changeLocationType()" inline>
                          <p class="regionalCheckboxText">{{ $t('campaignForm.regionalLimit') }}</p>
                        </base-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 mt-4" v-if="!isRegionTarget">
                    <label class="mr-4">{{ $t("region") }}:</label>
                    <base-input class="" name="target_location_country">
                      <el-select
                        :placeholder="$t('select.region')"
                        v-model="campaign.target_location_region"
                        clearable
                        @clear="campaign.target_location_region = null"
                      >
                          <el-option
                            v-for="region in optionsForm.regions"
                            :key="region"
                            :value="region.id"
                            :label="$t(`regions.${region.name}`)"
                          >
                          </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-12 col-lg-6 mt-4" v-if="isRegionTarget">
                    <label class="mr-4">{{ $t("address") }}:</label>
                    <AutocompletAddress
                      @setAddress="setAddress"
                      :address="campaign.target_location_address"
                      :placeholder="$t('addAddress')"
                      class="mb-4"
                    ></AutocompletAddress>
                  </div>
                  <div class="col-12 col-lg-6 mt-4" v-if="isRegionTarget">
                    <label class="mr-4">{{ $t("radio") }}:</label>
                    <div class="input-group">
                      <input
                        v-model="campaign.target_location_radio"
                        type="number"
                        min="1"
                        max="80"
                        class="input-stevie form-control"
                      />
                      <div
                        class="input-group-append no-btn p-1 d-flex justify-content-center"
                      >
                        Km
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="segment highlight-segment">
                <div class="row ad-form">
                  <div class="col-12 d-flex">
                    <h3 class="pr-2">{{ $t('facebook') }}</h3>
                    <h3 class="subtitle-form">{{ $t("formSegment.campaignDetails") }}</h3>
                  </div>
                  <div class="col-12 col-sm-6">
                    <label>
                      <span class="label-text">
                        {{ $t("image") }}
                      </span>
                      <span class="sublabel"> (Min: {{validationsForms.meta_image.minWidth}} x {{validationsForms.meta_image.minHeight}} px, Max: {{KBtoMB(validationsForms.meta_image.maxSize)}} MB)</span>
                    </label>
                    <div v-show="!campaign.meta_image" class="uploader-campaigns">
                      <ImageCropped
                        :isAllowedCloseModal="false"
                        :validationsImage="validationsForms.meta_image"
                        :aspect-ratio="600/314"
                        file-name="metaImage"
                        :disabled="isDisabledEdit"
                        @changeImage="changeImage($event, 'meta_image')"
                        :fileValue="campaign.meta_image"
                      />
                    </div>
                    <div v-show="campaign.meta_image" class="uploader-campaigns dz-message p-0 size-media-ads">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit"
                        @click="campaign.meta_image = null"
                      >
                        <img class="h-100 w-100 media-ad" alt="Image placeholder" :src="getImageUrl(campaign.meta_image)">
                        <span class="text-hover">{{$t('campaignForm.placeholder.image', [validationsForms.meta_image.minWidth, validationsForms.meta_image.minHeight])}}</span>
                      </base-button>
                    </div>
                      <small v-if="markAsInvalid(campaign.meta_image === null)" class="text-danger position-absolute">{{$t('formValidations.required')
                    }}</small>
                  </div>
                  <div class="col-12 col-sm-6">
                    <label>
                      <span class="label-text">
                        {{ $t("optionalVideo") }}
                      </span>
                      <span class="sublabel"> (Max: {{KBtoMB(validationsForms.meta_video.maxSize)}} MB)</span>
                    </label>
                    <div v-show="!campaign.meta_video" class="uploader-campaigns">
                      <el-upload
                      :accept="allowedVideoFormatsCampaigns"
                      class="w-100 uploader-campaigns"
                      action=""
                      drag
                      :auto-upload="false"
                      :show-file-list="false"
                      :on-change="uploadFile => getImage(uploadFile, 'meta_video', campaign)"
                      :disabled="isDisabledEdit"
                    >
                      <div
                        v-if="campaign.meta_video === null"
                        class="el-upload__text"
                      >
                        {{ $t('campaignForm.placeholder.video') }}
                      </div>
                      <div class="el-upload__image" v-else>
                        <video
                          :src="getImageUrl(campaign.meta_video)"
                          class="previewImg url"
                        />
                        <div class="text-hover">
                          {{ $t('campaignForm.placeholder.video') }}
                        </div>
                      </div>
                    </el-upload>
                    </div>
                    <div v-show="campaign.meta_video" class="uploader-campaigns dz-message p-0 size-media-ads">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit"
                        @click="campaign.meta_video = null"
                      >
                        <video class="h-100 w-100 media-ad" alt="Video placeholder" :src="getImageUrl(campaign.meta_video)" />

                        <span class="text-hover">{{$t('campaignForm.placeholder.video')}}</span>
                      </base-button>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <label class="">{{ $t("metaHeadline") }}</label>
                    <base-input
                      alternative
                      :disabled="isDisabledEdit"
                      name="meta_headline"
                      v-model="campaign.meta_headline"
                      type="text"
                      input-classes="input-stevie"
                      :placeholder="$t('campaignForm.placeholder.metaHeadline')"
                      label-classes="input-placeholder-stevie"
                      :maxLength="validationsForms.maxCharsMetaHeadline"
                      :class="{'has-danger': markAsInvalid(campaign.meta_headline=== null || campaign.meta_headline=== '')}"
                    >
                    </base-input>
                    <small v-if="markAsInvalid(campaign.meta_headline === null || campaign.meta_headline === '')" class="text-danger position-absolute mt--3">{{$t('formValidations.required')
                    }}</small>
                  </div>
                  <div class="col-12 col-lg-6">
                    <label class="">{{ $t("metaUrlDescription") }}</label>
                    <base-input
                      alternative
                      :disabled="isDisabledEdit"
                      name="meta_url_description"
                      v-model="campaign.meta_url_description"
                      type="text"
                      :placeholder="$t('campaignForm.placeholder.metaDescription')"
                      input-classes="input-stevie"
                      label-classes="input-placeholder-stevie"
                      :maxLength="validationsForms.maxCharsMetaDescription"
                    >
                    </base-input>
                  </div>
                  <div class="col-12">
                    <label class="">{{ $t("metaText") }}</label>
                    <base-input
                      alternative
                      :disabled="isDisabledEdit"
                      name="meta_text"
                      :value="campaign.meta_text"
                      type="textarea"
                      :placeholder="$t('campaignForm.placeholder.metaText')"
                      input-classes="form-control col-12 input-textarea-shadow textarea-block"
                      label-classes="input-placeholder-stevie"
                      :maxLength="validationsForms.maxCharsMetaText"
                      :class="{'has-danger': markAsInvalid(campaign.meta_text=== null || campaign.meta_text=== '')}"
                      :rows="7"
                    >
                    </base-input>
                    <small v-if="markAsInvalid(campaign.meta_text === null || campaign.meta_text === '')" class="text-danger position-absolute mt--3">{{$t('formValidations.required')
                      }}</small>
                  </div>
                </div>
              </section>
              <section class="segment">
                <div class="row">
                  <div class="col-12 d-flex">
                    <h3 class="pr-2">{{ $t('googleAd') }}</h3>
                    <h3 class="subtitle-form">{{ $t("formSegment.campaignDetails") }}</h3>
                  </div>

                  <div class="col-12 row m-0 px-0 ad-form">
                  <div class="col-12 col-sm-6 ">
                    <label>
                      <span class="label-text">
                        {{ $t("squareImage") }}
                      </span>
                      <span class="sublabel"> (Min: {{validationsForms.google_square_image.minWidth}} x {{validationsForms.google_square_image.minHeight}} px, Max: {{KBtoMB(validationsForms.google_square_image.maxSize)}} MB)</span></label>
                    <div v-show="!campaign.google_square_image" class="uploader-campaigns square-image">
                      <ImageCropped
                        :isAllowedCloseModal="false"
                        :validationsImage="validationsForms.google_square_image"
                        :aspect-ratio="1"
                        file-name="googleSquareImage"
                        :disabled="isDisabledEdit"
                        @changeImage="changeImage($event, 'google_square_image')"
                        :reset="resetCropped"
                        :fileValue="campaign.google_square_image"
                      />
                    </div>
                    <div v-show="campaign.google_square_image" class="dz-message p-0 size-media-ads uploader-campaigns square-image">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit"
                        @click="campaign.google_square_image = null"
                      >
                        <img class="h-100 w-100 media-ad" alt="Image placeholder" :src="getImageUrl(campaign.google_square_image)">
                        <span class="text-hover">{{$t('campaignForm.placeholder.image', [validationsForms.google_square_image.minWidth, validationsForms.google_square_image.minHeight])}}</span>
                      </base-button>
                    </div>
                      <small v-if="markAsInvalid(!campaign.google_square_image)" class="text-danger">{{$t('formValidations.required')
                  }}</small>
                  </div>
                  <div class="col-12 col-sm-6">
                    <label>
                      <span class="label-text">
                        {{ $t("image") }}
                      </span>
                      <span class="sublabel"> (Min: {{validationsForms.google_image.minWidth}} x {{validationsForms.google_image.minHeight}} px, Max: {{KBtoMB(validationsForms.google_image.maxSize)}} MB)</span> </label>
                    <div v-show="!campaign.google_image" class="uploader-campaigns">
                      <ImageCropped
                        :isAllowedCloseModal="false"
                        :validationsImage="validationsForms.google_image"
                        :aspect-ratio="1.91"
                        file-name="googleImage"
                        :disabled="isDisabledEdit"
                        @changeImage="changeImage($event, 'google_image')"
                        :reset="resetCropped"
                        :fileValue="campaign.google_image"
                      />
                    </div>
                    <div v-show="campaign.google_image" class="uploader-campaigns dz-message p-0 size-media-ads">
                      <base-button
                        class="display-image bg-transparent border-0 shadow-none m-0 delete-ad-media p-0 h-100 w-100"
                        :disabled="isDisabledEdit"
                        @click="campaign.google_image = null"
                      >
                        <img class="h-100 w-100 media-ad" alt="Image placeholder" :src="getImageUrl(campaign.google_image)">
                        <span class="text-hover">{{$t('campaignForm.placeholder.image', [validationsForms.google_image.minWidth, validationsForms.google_image.minHeight])}}</span>
                      </base-button>
                    </div>
                    <small v-if="markAsInvalid(!campaign.google_image)" class="text-danger">{{$t('formValidations.required')
                  }}</small>
                  </div>
                </div>


                  <div class="col-12 col-lg-6 mt-4 d-flex flex-column">
                    <label class="d-flex align-items-center">
                      {{ $t("googleHeadline") }} {{`${campaign.google_headline.length}/${validationsForms.maxFieldGoogleHeadline}`}}

                      <el-tooltip placement="top" :content="$t('infoGoogle.header')">
                        <img
                          src="/img/icons/stevie/info-shape.svg"
                          class="icon-card ml-1"
                          alt="Info icon"
                        />
                      </el-tooltip>
                    </label>
                    <div v-for="(value, index) in campaign.google_headline" :key="index">
                      <base-input name="google_headline" group class="mb-4">
                        <div class="input-group"
                        >
                          <input :disabled="isDisabledEdit" :placeholder="$t('campaignForm.placeholder.googleHeadline')" v-model="campaign.google_headline[index]" type="text" :maxlength="validationsForms.maxCharsGoogleHeadline" class="input-stevie form-control" aria-describedby="button-addon2"
                          :class="{'no-rounded-borders': campaign.google_headline.length > 3}"
                          >
                          <div v-if="!isDisabledEdit" class="input-group-append">
                            <button v-if="campaign.google_headline.length > 3" @click="manageField('google_headline', index, 'remove')" class="btn btn-outline-primary btn-input-stevie " type="button" id="button-addon2">
                              <em class="fas fa-trash"></em>
                            </button>
                          </div>
                        </div>
                      </base-input>
                    </div>
                    <base-button
                      class="btn-add-fields mr-auto"
                      @click="manageField('google_headline', index, 'add')"
                    >
                          <img
                            class="mr-1 icon"
                            src="/img/icons/stevie/add-shape-color-blue-light.svg"
                            alt="Add icon"
                          />
                      {{ $t("addGoogleHeader") }}
                      <div></div>
                  </base-button>
                    <small v-if="markAsInvalid(campaign.google_headline.filter(item => item !== '').length < 3)" class="text-danger">{{
                        $t("formValidations.requiredMinFields",[3])
                      }}</small>
                    <small v-if="markAsInvalid(haveDuplicates(campaign.google_headline))" class="text-danger">{{
                        $t("formValidations.duplicateFields")
                      }}</small>
                  </div>
                  <div class="col-12 col-lg-6 mt-4">
                    <label class="d-flex align-items-center">
                      {{ $t("googleDescription") }} {{`${campaign.google_description.length}/${validationsForms.maxFieldGoogleDescription}`}}
                      <el-tooltip placement="top" :content="$t('infoGoogle.description')">
                        <img
                          src="/img/icons/stevie/info-shape.svg"
                          class="icon-card ml-1"
                          alt="Info icon"
                        />
                      </el-tooltip>
                    </label>
                    <div v-for="(value, index) in campaign.google_description" :key="index">
                      <base-input name="google_description" group >
                        <div class="d-flex">
                          <textarea :disabled="isDisabledEdit" :placeholder="$t('campaignForm.placeholder.googleDescription')" v-model="campaign.google_description[index]" :maxlength="validationsForms.maxCharsGoogleDescription" class="input-stevie form-control google-description" aria-describedby="button-addon2"
                          >
                          </textarea>
                          <div v-if="!isDisabledEdit" >
                            <button v-if="campaign.google_description.length > 2" @click="manageField('google_description', index, 'remove')" class="btn btn-outline-primary btn-input-stevie btn-description" type="button" id="button-addon2">
                              <em class="fas fa-trash"></em>
                            </button>
                          </div>
                        </div>
                      </base-input>
                    </div>
                    <base-button
                      class="btn-add-fields"
                      @click="manageField('google_description', index, 'add')"
                    >
                          <img
                            class="mr-1 icon"
                            src="/img/icons/stevie/add-shape-color-blue-light.svg"
                            alt="Add icon"
                          />
                      {{ $t("addGoogleDescription") }}
                      <div></div>
                  </base-button>
                    <small v-if="markAsInvalid(campaign.google_description.filter(item => item !== '').length < 2)" class="text-danger">
                      {{ $t("formValidations.requiredMinFields",[2]) }}
                    </small>
                    <small v-if="markAsInvalid(haveDuplicates(campaign.google_description))" class="text-danger ">{{
                        $t("formValidations.duplicateFields")
                      }}</small>
                  </div>
                  <div class="col-12 mb-4 mt-3">
                    <label class=""
                      >{{ $t("keywords") }}
                      <small>({{ $t("formValidations.maxKeywords") }})</small>:</label
                    >
                    <div
                      class="keyword-input input-stevie"
                    >
                      <div
                        v-for="(keyword, index) in campaign.google_keywords"
                        :key="index"
                        @click="deleteKeyword(index)"
                        class="keyword-input__keyword"
                      >
                        <span>x</span>
                        {{ keyword }}
                      </div>
                      <input
                        v-model.trim="keyInput"
                        @keypress.space="addKeyword"
                        @keypress.enter="addKeyword"
                        type="text"
                        :disabled="campaign.google_keywords.length === 8"
                        :placeholder="campaign.google_keywords.length >= 8 ? '' :$t('enterKeyword')"
                        class="keyword-input__text"
                        pattern="/^[0-9a-zA-Z]+$/"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section class="containerSaveButton d-flex justify-content-center align-items-center segment highlight-segment footer">
                <base-button
                  borderRadiusLeft
                  borderRadiusRight
                  @click="manageTemplate()"
                  class="p-0 start-campaign-button"
                  :disabled="isLoading"
                >
                  <span v-if="!isLoading"> {{ $t("saveTemplate") }}</span>
                  <clip-loader
                    v-else
                    color="#ffffff"
                    size="20px"
                    class="pt-2"
                  ></clip-loader>
                </base-button>
              </section>
            </Form>
          </div>
        </div>
      </div>
    </div>
 </div>
</template>

<script>
import { Form } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n/index";
import { onMounted, ref } from "vue";

import { formatWebsite } from "@/composables/useFormatUrl";
import { regionsGermany, allowedVideoFormatsCampaigns } from "@/util/Settings";
import {getImageUrl} from '@/util/helperFunctions.js'

import useForms from "@/composables/useForms.js";
import useTemplates from "@/composables/useTemplates.js";

import AutocompletAddress from "@/views/Components/AutocompleteAddress.vue";
import BaseSlider from "@/components/BaseSlider.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { ElSelect, ElOption, ElUpload } from "element-plus";
import ImageCropped from '@/views/Components/ImageCropped.vue';
import { campaignStatus } from "../../../../util/Settings";

export default {
  name: "templateForm",
  props: ["id"],
  components: {
    Form,
    AutocompletAddress,
    BaseSlider,
    ClipLoader,
    ElSelect,
    ElOption,
    ElUpload,
    ImageCropped
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { t } = useI18n();
    const { markAsInvalid, uploadForm, isValidForm, isSubmit, isValidLink } = useForms();
    const { getTemplate, createTemplate, editTemplate  } = useTemplates()
    const id = ref(null);
    const isDisabledEdit = ref(false);
    const resetCropped = ref(false)
    const campaign = ref({
      template_name: null,
      name: null,
      url: null,
      meta_headline: null,
      meta_text: null,
      meta_image:null,
      meta_video:null,
      meta_url_description: null,
      invested_facebook: 50,
      invested_google: 50,
      invested: 0,
      google_headline: ["", "", ""],
      google_description: ["", ""],
      google_keywords: [],
      google_image: null,
      google_square_image: null,
      target_gender: null,
      target_age: null,
      target_location_region: null,
      target_location_address: null,
      target_location_latitude: null,
      target_location_longitude: null,
      target_location_radio: null,
    });
    const isLoading = ref(false);
    const validationsForms = ref({
      maxFieldGoogleHeadline: 15,
      maxFieldGoogleDescription: 4,
      maxCharsGoogleDescription: "90",
      maxCharsMetaDescription: "30",
      maxCharsGoogleHeadline: "30",
      maxCharsMetaHeadline: "25",
      maxCharsMetaText: "125",
      minImageResolution: "Min: 600x314px",
      minSquareResolution: "Min 600x600px",
      google_square_image:{
        minHeight: 600,
        minWidth: 600,
        maxSize: 5242880
      },
      google_image:{
        minHeight: 314,
        minWidth: 600,
        maxSize: 5242880
      },
      meta_image:{
        minHeight: 314,
        minWidth: 600,
        maxSize: 5242880
      },
      meta_video: {
        formats: allowedVideoFormatsCampaigns,
        // maxSize: 943718400
        maxSize: 52428800
      }
    });
    const keyInput = ref(null);
    const optionsForm = ref({
      agesOptions: ["18_24", "25_34", "35_44", "45_54", "55_64", "65_up"],
      genderOptions: ["male", "female", "other"],
      locations: ["region", "local"],
      regions: regionsGermany,
      status: campaignStatus
    });
    const isRegionTarget = ref(false)

    const manageTemplate = async () => {
      let form = {...campaign.value};
      form.invested_facebook = Math.trunc(
        campaign.value.invested_facebook
      );
      form.url = formatWebsite(campaign.value.url);

      let otherValidations;

      if(haveDuplicates(campaign.value.google_description) || haveDuplicates(campaign.value.google_headline)){
          otherValidations = false
      }
      if(!isValidLink(campaign.value.url)){
        otherValidations = false
      }

      const requiredFields = ["google_description", "google_headline", "google_image", "google_square_image", "meta_headline",  "meta_image", "meta_text", "name", "template_name", "url"];

      if(!isValidForm(campaign.value, requiredFields, otherValidations))return

      form.google_headline = campaign.value.google_headline.filter(
        text => text !== ""
      );
      form.google_description = campaign.value.google_description.filter(
        text => text !== ""
      );

      if (isRegionTarget.value) {
        delete form.target_location_region;
      }else{
        delete form.target_location_address;
        delete form.target_location_latitude;
        delete form.target_location_longitude;
        delete form.target_location_radio;
      }
      Object.keys(form).forEach(field =>{
        let value = form[field]
        if(Array.isArray(value) && value.length === 0){
          form[field] = null
        }
      })
      const formData = new FormData();
      Object.keys(form).forEach(field=>{
        let value = form[field]
        if(value || typeof value === 'number'){
          let isArrayField = Array.isArray(value);
          if(isArrayField){
            value = JSON.stringify(value)
          }
          formData.append(field, value)
        }
      })


      let response;
      if (id.value) {
        response = await updateTemplate(formData);
      } else {
        response = await storeTemplate(formData);
      }
      return response;
    };

    const storeTemplate = async (data) => {
      isLoading.value = true;

      let response = await createTemplate(data)
      if (response && response.status === 200) {

        router.push({ name: "TemplatesList" });
        fillTemplate(response.data);

      } else if (response) {
        if (response.url && response.url[0]) {
          toast.error(response.url[0], { timeout: 3000 });
        }
      }
      isLoading.value = false;
      return response;
    };
    const updateTemplate = async (data) => {
      isLoading.value = true;

      let imageFields = ["meta_image", "google_image", "google_square_image"];
      imageFields.forEach(field =>{
        let value = data.get(field)
        if(value &&  typeof value === 'string'){
          data.delete(field)
        }
      })

      let response = await editTemplate(data);
      if (response && response.status === 200) {
      } else if (response) {
        if (response.url && response.url[0]) {
          toast.error(response.url[0], { timeout: 3000 });
        }
      }
      isLoading.value = false;
      return response;
    };

    const fillTemplate = data => {
      data.invested = parseFloat(data.invested);

      data.google_headline = data.google_headline ? data.google_headline : campaign.value.google_headline;
      data.google_description = data.google_description ? data.google_description : campaign.value.google_description;
      data.google_keywords = data.google_keywords
        ? data.google_keywords
        : [];
      campaign.value = { ...campaign.value, ...data };
      isRegionTarget.value = campaign.value.target_location_region === null
    };
    const manageField = (name, index, action) => {
      const array =
        name === "google_headline"
          ? campaign.value.google_headline
          : campaign.value.google_description;
      const isAddingField = action === "add";
      if (isAddingField) {
        array.push("");
      } else {
        array.splice(index, 1);
      }
    };
    const changeForm = event => {
      campaign.value = uploadForm(event, campaign.value)

    };
    const changeInvested = event => {
      campaign.value.invested_google = 100 - Math.trunc(event);
    };
    const setAddress = location => {
      const { address, lat, lng } = location;
      campaign.value.target_location_address = address;
      campaign.value.target_location_latitude = lat;
      campaign.value.target_location_longitude = lng;
    };
    const changeLocationType = () => {
      campaign.value.target_location_address = null;
      campaign.value.target_location_latitude = null;
      campaign.value.target_location_longitude = null;
      campaign.value.target_location_radio = null;
      campaign.value.target_location_region = 0;
    };

    const addKeyword = () => {
      if (
        !keyInput.value ||
        keyInput.value === "" ||
        campaign.value.google_keywords.length > 7
      ) {
        keyInput.value = null;
        return;
      }
      let keyword = keyInput.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
      keyword = keyword.split(" ").join("");
      campaign.value.google_keywords.push(keyword);
      keyInput.value = null;
    };

    const deleteKeyword = index => {
      campaign.value.google_keywords.splice(index, 1);
    };

    const haveDuplicates = (value)=>{
      if(!value) return false
      value = value.filter( text => text !== '' )
      const originalSize = value.length;
      const unique = Array.from(new Set(value))
      return originalSize !== unique.length
    }
    const getImage = async(file, field, object) => {
      const isValid = field.includes('video') ? await isValidVideo(file, field) : await isValidImage(file, field);
      if(isValid){
        object[field] = file.raw;
      }else{
        toast.warning(t("formValidations.invalidImage"));
      }
    };
    const isValidImage = async(file, field) => {

      let validations = validationsForms.value[field]
      if(!file || !validations)return true

      let size = file.size;
      let isValid = true;
      const { minHeight, minWidth }= validationsForms.value[field];

      let imgUrl = URL.createObjectURL(file.raw);
      let infoImg = await loadImage(imgUrl)

      if(!infoImg.height || !infoImg.width)return true

      let {height, width } = infoImg;

      if(minHeight){
        isValid = height >= minHeight && isValid
      }
      if(minWidth){
        isValid = width >= minWidth && isValid
      }
      return isValid
    };

    const isValidVideo = async(file, field) => {
      const validations = validationsForms.value[field];
      if(!file || !validations) return true;

      let isValid = true;
      const size = file.size;
      const { maxSize, formats } = validationsForms.value[field];

      //maxSize and size in bytes
      if(maxSize && size > maxSize){
        isValid = false;
        const maxAllowedSize = Math.round(maxSize/1024/1024);
        toast.warning(t("formValidations.invalidSizeVideo", [`${maxAllowedSize} MB max` ]));
      }

      // Formats
      if( !formats.includes(file.raw.type) ) {
        const formatlist = formats.map( format => format.split('/')[1] ).join(' ');
        isValid = false;
        toast.warning(t("formValidations.invalidFormat", [formatlist] ));
      }
      return isValid;
    };

    const loadImage = (path)=>{
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = path
        img.onload = () => {
          resolve(img)
        }
        img.onerror = e => {
          reject(e)
        }
      })
    }

    const changeImage = (image, field)=>{
      resetCropped.value = false;
      if(image){
        campaign.value[field] = image;
      }else{
        resetCropped.value = true;
      }
    }
    const backToList = ()=> {

      const savedFilters = JSON.parse(localStorage.getItem('table_filters'))
      router.push({name:'TemplatesList', query: savedFilters})
    }

    const KBtoMB = (kb) => {
      const mb = 1048576
      return (kb/mb).toFixed(1)
    }

    onMounted(async () => {
      if (route.params.id) {
        id.value = route.params.id;
        const response = await getTemplate(route.params.id);
        if(response.status === 200){

          fillTemplate(response.data)
        }
      }

    });

    return {
      allowedVideoFormatsCampaigns,
      campaign,
      isLoading,
      validationsForms,
      isSubmit,
      id,
      keyInput,
      optionsForm,
      isDisabledEdit,
      resetCropped,
      isRegionTarget,
      manageTemplate,
      manageField,
      markAsInvalid,
      changeForm,
      changeInvested,
      addKeyword,
      deleteKeyword,
      setAddress,
      changeLocationType,
      haveDuplicates,
      getImage,
      getImageUrl,
      isValidImage,
      changeImage,
      isValidLink,
      backToList,
      KBtoMB
    };
  },
};
</script>

<style scoped lang="scss">
.icons-ads-height {
  max-height: 60px;
  &.facebook-icon-ads {
    max-height: 80px !important;
  }
}
.input-group .input-stevie {
  border-radius: 5px !important;
  &.no-rounded-borders {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &:disabled {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}
.input-group {
  .input-group-append.no-btn {
    background-color: #c6dcf5;
    min-width: 43px;
    border: 1px solid #c6dcf5 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    color: #adb5bd;
  }
}
.keyword-input {
  height: auto !important;

  .keyword-input__keyword {
    height: 25px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 6px;
    line-height: 25px;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .keyword-input__keyword > span {
    cursor: pointer;
    opacity: 0.75;
  }

  .keyword-input__text {
    border: none;
    outline: none;
    line-height: 34px;
    background: none;
    width: 200px;
    max-width: 100%;
  }
  &.disabled {
    background-color: #edf0f2 !important;
    .keyword-input__keyword {
      background-color: #ffffff;
    }
  }
}
.small-text {
  font-size: 11px;
  opacity: 0.8;
  display: flex;
  align-items: center;
}
.word-break {
  word-break: break-word;
}
:deep().custom-radio .custom-control-label{
  font-size: 13px;
  color: #8c8c93;
  text-transform: none!important;
  font-weight: 400;
}

.custom-card {
  background: #ffffff;
  box-shadow: 0 0 2rem 0 rgba(136,152,170,0.15);
  padding: 20px 21px;
  border-radius: 20px;
}
.segment {
  margin: 0 -21px;
  padding: 22px 31px 21px;

  &.footer {
    padding-top: 29px;
    margin-bottom: -20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.highlight-segment {
    background-color: #E7F6FD;
  }
}


.sliderText {
  font-size: 13px;
}
.sliderInvested {
  width: -webkit-fill-available;
  .noUi-connect {
    background: #cccccc!important;

    .noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
      background: #012454!important;

    }
  }
}
.icons-distribution {
  height: 20px;
  width: 20px;
}
.regionalCheckboxText {
  text-transform: none;
}
:deep().min-height-textarea{
  height: 91px;
}
:deep() .textarea-block{
  height: 91px!important;
}
//Google fields
.btn-description{
  border: none!important;
  margin-left: 5px!important;
  border-radius: 5px!important;
}
.google-description{
  height: 77px !important;
}
.btn-add-fields{
  background-color: transparent!important;
  color: #0373a8;
  font-size: 13px;
  border: none;
  box-shadow: none;
  padding: 0px;
  display: flex;
  align-items: center;
  &:hover, &:active, &:focus{
    color: #10305D!important;
    box-shadow: none;
  }
  .icon{
    height: 13px;
    width: 13px;
  }
}
//Styles of footer form
.containerSaveButton {
  height: 95px;
}

//Styles of input image
.uploader-campaigns{
  height: 107px;

  &.square-image {
    height: 300px!important;
    :deep().dz-default {
      height: 300px!important;
    }
  }
}
label:has(.sublabel){
  display: flex;
  flex-wrap: wrap;
  .label-text{
    padding-right: 5px;
  }
  .sublabel{
    text-transform: none;
    font-weight: 500;
  }
}

</style>
