<template>
  <div class="dashboard-margin">
    <div class="row justify-content-around px-0">
      <div class="col-12 order-1">
        <plugin-v-i
          v-if="visibilityData.website_score"
          v-on:login="loginModal"
          v-on:register="registerModal"
          :index-visibility="visibilityData"
          :logged-in="isLoggedIn"
          @reloadVI="manageModal(null, true)"
        ></plugin-v-i>
        <plugin-enter-url
          v-if="!visibilityData.website"
          @setUrlVi="manageModal"
        ></plugin-enter-url>
      </div>
      <div v-if="visibilityData.website" class="col-12 col-md-6 col-lg-4 order-2">
        <plugin-google v-if="isLoggedIn || showLittle" :google="visibilityData.google_score" :google-url="visibilityData.google" :improvePercentage="visibilityData.google_improve" ></plugin-google>
        <dummy-card v-else stats-card card-title="google" @register-modal="registerModal"></dummy-card>
      </div>
      <div v-if="visibilityData.website" class="col-12 col-md-6 col-lg-4 order-3">
        <plugin-facebook v-if="isLoggedIn || showLittle" :facebook="visibilityData.facebook_score" :facebook-url="facebookInfo" :improvePercentage="visibilityData.facebook_improve"></plugin-facebook>
        <dummy-card v-else stats-card card-title="facebook" @register-modal="registerModal"></dummy-card>
      </div>
      <div v-if="visibilityData.website" class="col-12 col-md-6 col-lg-4 order-4">
        <plugin-instagram v-if="isLoggedIn || showLittle" :instagram="visibilityData.instagram_score" :instagram-url="visibilityData.instagram" :improvePercentage="visibilityData.instagram_improve"></plugin-instagram>
        <dummy-card v-else stats-card card-title="instagram" @register-modal="registerModal"></dummy-card>
      </div>

      <div v-if="visibilityData.website" class="col-12 col-md-6 col-lg-4 order-5">
        <plugin-technik
          v-if="isLoggedIn || showLittle"
          :technik="visibilityData.website_technique_score"
          :improvePercentage="visibilityData.technik_improve"
        ></plugin-technik>
        <dummy-card
          v-else
          stats-card
          card-title="technik"
          @register-modal="registerModal"
        ></dummy-card>
      </div>
      <div v-if="!isLoggedIn" class="col-12 col-md-6 col-lg-4 order-6">
        <plugin-need-account
          @register-modal="registerModal"
        ></plugin-need-account>
      </div>

      <div v-if="visibilityData.website" class="col-12 col-md-6 col-lg-4 order-6">
        <plugin-relevant
          v-if="isLoggedIn || showLittle"
          :relevantMentions="visibilityData.other02_score"
          :relevantUrls="relevantUrls"
          :improvePercentage="visibilityData.other02_improve"
        ></plugin-relevant>
        <dummy-card
          v-else
          stats-card
          card-title="relevantMentions"
          @register-modal="registerModal"
        ></dummy-card>
      </div>
      <div v-if="visibilityData.website" class="col-12 col-md-6 col-lg-4 order-7">
        <plugin-others
          v-if="isLoggedIn || showLittle"
          :others="visibilityData.other01_score"
          :othersUrls="viOthersUrl"
          :improvePercentage="visibilityData.other01_improve"
        ></plugin-others>
        <dummy-card
          v-else
          stats-card
          card-title="others"
          @register-modal="registerModal"
        ></dummy-card>
      </div>
    </div>
    <!-- End charts-->
    <modal v-model:show="modals.register" body-classes="px-0 pt-0">
      <register
        v-if="modals.register"
        :is-modal="true"
        @login-modal="loginModal"
        v-on:modal="loadData"
      ></register>
    </modal>
    <modal v-model:show="modals.login" body-classes="px-0 pt-0">
      <login
        v-if="modals.login"
        :is-modal="true"
        v-on:modal="loadData"
        @register-modal="registerModal"
      ></login>
    </modal>
    <visibility-modal :isOpenModal="isOpenModal" :onlyLoader="onlyLoader" :website="consultedUrl" @closeModal="manageModal('', false)" ></visibility-modal>
    <vi-fails-modal :url="consultedUrl"></vi-fails-modal>
  </div>
</template>

<script>
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import useVisibility from '../../composables/useVisibility';
import useLoggedIn from '../../composables/useLoggedIn';


import PluginVI from "./Components/PluginVI";
import PluginEnterUrl from "./Components/PluginEnterUrl";
import PluginNeedAccount from "./Components/PluginNeedAccount";
import PluginGoogle from "./Components/PluginGoogle";
import PluginFacebook from "./Components/PluginFacebook";
import PluginInstagram from "./Components/PluginInstagram";
import PluginTechnik from "./Components/PluginTechnik";
import PluginRelevant from "./Components/PluginRelevant";
import PluginOthers from "./Components/PluginOthers";
import DummyCard from "./Components/DummyCard";
import Register from "../Pages/Register";
import Login from "../Pages/Login";
import Modal from "../../components/Modal";
import VisibilityModal from "../Components/VisibilityModal";
import ViFailsModal from "@/views/Pages/Landing/Components/ViFailsModal.vue";
import { useI18n } from 'vue-i18n/index';
import { useRoute, useRouter } from 'vue-router';

export default {
  components: {
    PluginVI,
    PluginEnterUrl,
    PluginNeedAccount,
    PluginGoogle,
    PluginFacebook,
    PluginInstagram,
    PluginTechnik,
    PluginRelevant,
    PluginOthers,
    DummyCard,
    Modal,
    Register,
    Login,
    VisibilityModal,
    ViFailsModal,
  },
  data() {
    return {
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
      modals: {
        register: false,
        login: false,
      },
      chart: null,
      isModalCompanySize: false,
      isProcessInProgress: false,
      onlyLoader: false,
    };
  },
  methods: {

    registerModal() {
      this.modals.register = true;
      this.modals.login = false;
    },
    loginModal() {
      this.modals.login = true;
      this.modals.register = false;
    },
    async loadData() {
      this.modals.login = false;
      await this.setIsLoggedIn(!!localStorage.getItem("api_token"));
    },
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute();
    const router = useRouter();
    const { visibilityData, isLoadingVisibility, viOthersUrl, relevantUrls , userTargets, getVisibility, postVisibility }= useVisibility();
    const { isLoggedIn, setIsLoggedIn }= useLoggedIn()
    const showLittle = ref(true);
    const userData = ref(null);
    const isLoading = ref(false);
    const isOpenModal = ref(false)
    const consultedUrl = ref('')
    const onlyLoader = ref(false)
    
    const facebookInfo = computed({
      get:()=>{
        const { facebook, facebook_likes, facebook_followers} = visibilityData.value;
        if(!facebook) return false
        let info = `${facebook}`;
        if(facebook_likes || facebook_likes === 0 ){
          info = `${info} \n ${t('facebookLikes')} : ${facebook_likes}`
        }
        if(facebook_followers || facebook_followers === 0){
          info = `${info} \n ${t('facebookFollowers')} : ${facebook_followers}`
        }
        return info;
      }
    });
    const manageModal= ( url , isOpen = true) => {
      if(isOpen){
        consultedUrl.value = url? url.url :userTargets.value.website;
      }
      isOpenModal.value = isOpen;
    }
    onMounted(()=>{
      if(!isLoadingVisibility.value && isLoggedIn.value && userTargets.value && userTargets.value.website){
        getVisibility();
      }
    })
    onBeforeMount(async()=>{
      consultedUrl.value = route.query.url;
      let visibilityForm = {...route.query, website: route.query.url};

      localStorage.setItem('visibilityForm', JSON.stringify(visibilityForm));
      router.replace({...router.currentRoute, query: {}})

      if(route.query.company_size && !isLoggedIn.value){
        onlyLoader.value = true;
        isOpenModal.value = true;
      }

    })

    setTimeout(function () {
      showLittle.value = false;
    }, 3000);


    watch(()=> isLoadingVisibility.value, (value, oldValue)=>{
      if(!value && oldValue ){
        showLittle.value = true;

        setTimeout(function () {
          showLittle.value = false;
        }, 4000);
      }
    })
    return {
      isLoggedIn,
      showLittle,
      userData,
      isLoading,
      visibilityData,
      isLoadingVisibility,
      viOthersUrl,
      relevantUrls,
      consultedUrl,
      isOpenModal,
      facebookInfo,
      onlyLoader,
      manageModal,
      setIsLoggedIn
    };
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.border-radius-stevie{
  background-color: white;
  min-height: 150px;
}

</style>
