import axios from "axios";

export const getCampaigns = async ({ commit }, params) => {
    const {page, filter} = params;

    commit('setIsLoading', true)

    let routeList = `/campaigns?page=${page}`;
    if( filter !== 'active'){
    routeList += `&filter_status=${filter}`
    }
    const response = await axios.get(routeList);
    if(response && response.status === 200){
        commit('setCampaigns', response.data);
    }
    commit('setIsLoading', false)

    return response

}
export const changeStatus = async ({ commit }, { id, statusId, statusName}) =>{
    const route = `campaigns/${id}/update-status-${statusName}`;
    const response = await axios.put(route, {status: statusId});
    
    return response
}
export const cloneCampaign = async ({ commit }, id) =>{
    const route = `campaigns/${id}/clone`;
    const response = await axios.post(route);
    
    return response
}
export const getCampaign = async ({ commit }, id) =>{
    const route = `campaigns/${id}`;
    const response = await axios.get(route);
    
    return response
}
export const updateCampaign = async ({ commit }, {id, campaign }) =>{
    const route = `campaigns/${id}`;
    const response = await axios.post(route, campaign);
    
    return response
}
export const changeCampaignToReady = async ({ commit }, {id, campaign }) =>{
    const route = `campaigns/${id}/update-status-ready`;
    const response = await axios.post(route, campaign);
    
    return response
}
export const storeCampaign = async ({ commit }, campaign) =>{
    const route = `campaigns`;
    const response = await axios.post(route, campaign);
    
    return response
}

export const getTemplates = async ({ commit }, page) => {

    const response = await axios.get(`/campaigns/templates?page=${page}`);

    if(response && response.status === 200){
        commit('setTemplates', response.data);
    }

    return response

}

export const deleteTemplate = async ({ commit }, id) => {

    const response = await axios.delete(`/campaigns/templates/${id}`);

    return response

}
export const getTemplate = async ({ commit }, id) => {

    const response = await axios.get(`/campaigns/templates/${id}`);

    return response

}
export const editTemplate = async ({ commit }, {template, id}) => {
    template.append('_method', 'PUT')
    const response = await axios.post(`/campaigns/templates/${id}`, template);

    return response

}
export const createTemplate = async ({ commit }, template) => {

    const response = await axios.post(`/campaigns/templates`, template);


    return response

}
export const cloneTemplate = async ({ commit }, id) =>{
    const route = `/campaigns/templates/${id}/clone`;
    const response = await axios.post(route);

    return response
}
export const getTotalActivesCampaigns = async ({ commit }) =>{
    const route = `/campaigns/actives`;
    const response = await axios.get(route);
    if(response && response.status === 200){
        commit('setTotalActivesCampaigns', response.data);
    }


    return response
}

export const pausedCampaign = async ({ commit }, { campaignId, status }) =>{
    const route = `campaigns/${campaignId}/update-status-paused`;
    const response = await axios.put(route, { status });
    
    return response;
}

export const startedCampaign = async ({ commit }, { campaignId, status }) =>{
    const route = `campaigns/${campaignId}/update-status-started`;
    const response = await axios.put(route, { status });
    
    return response;
}