<template>
  <modal v-model:show="isVerifiedEmailModal" :is-allowed-close-modal="isAllowedCloseModal" body-classes="p-0 border-radius-stevie bg-athens-gray" modal-content-classes="border-radius-stevie modal-animation">
    <div class="row m-0 p-4">
      <div class="col-12 px-0">
        <h3>{{ $t("verifiedEmail") }}</h3>
        <hr class="my-4" />
      </div>
      <div class="col-12 mt-2 px-0">
        <card header-classes="bg-transparent" body-classes="p-4 row">
          <div class="col-12 d-flex align-items-center">
            <p class="m-0 mr-auto">{{ $t("textVerifiedEmail") }}</p>
          </div>
        </card>
      </div>
      <div class="col-12 text-right px-0">
        <base-button
          borderRadiusLeft
          borderRadiusRight
          class="unlink-button py-2 px-0"
          @click="$emit('change-is-verified-email-modal')"
        >{{ $t("accept") }}</base-button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "../../components/Modal";
import { ref } from "vue";

export default {
  name: "VerifiedEmailModal",
  props: {
    isVerifiedEmailModal: Boolean,
  },
  components: {
    Modal,
  },
  setup() {
    const isAllowedCloseModal = ref(false);

    return {
      isAllowedCloseModal,
    }
  },
}
</script>

<style scoped>

</style>
