<template>
   <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `` }}</template>
  </metainfo>
  <router-view></router-view>
  <company-name-modal :isOpenModal="isOpenCompanyModal" @closeModal="isOpenCompanyModal = false"></company-name-modal>
  <limit-subscription-modal></limit-subscription-modal>
</template>

<script>
import useLoggedIn from "./composables/useLoggedIn";
import useUserData from "./composables/useUserData";
import useLoginFacebook from "./composables/useLoginFacebook";
import useVisibility from './composables/useVisibility';
import CompanyNameModal from '@/views/Components/CompanyNameModal';
import LimitSubscriptionModal from '@/views/Components/LimitSubscriptionModal';
import { ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';
import { formValidations } from "../src/util/globalValidationsForm";


export default {
  components:{
    CompanyNameModal,
    LimitSubscriptionModal
  },
  setup() {
    const { isLoggedIn, setIsLoggedIn } = useLoggedIn();
    const { getUserData, changeUserData, userData }= useUserData();
    const { setVisibilityData, getVisibility, userTargets } = useVisibility();
    const isOpenCompanyModal = ref(false)
    const { locale }= useI18n()
    useLoginFacebook();
    const description = "Mehr Sichtbarkeit mit der Stevie KI. Im Marketing ihres Unternehmens spielt die Online-Sichtbarkeit eine extrem wichtige Rolle. Aber die aktuellen Angebote für Ihr Marketing und die Online-Sichtbarkeit Ihres Unternehmens sind noch aus den 90-iger Jahren und damit fast völlig analog."
    const title = "Hey Stevie!"
    const favicon = "/img/brand/stevie_fb.jpg"

    formValidations();

    useMeta({
            title: title,
            url: process.env.VUE_APP_URL,
            htmlAttrs: { lang: locale.value, amp: true },
            meta: [
                { name: 'title', content: title},
                { name: 'description', content: description },
                { property: 'og:title', content: title },
                { property: 'og:description', content: description },
                { property: 'og:type', content: 'website' },
                { property: 'og:site_name', content: title },
                { property: 'og:url', content: document.location.href },
                { property: 'og:image', content: favicon },
            ]
    });
    
    setIsLoggedIn(!!localStorage.getItem('api_token'))
    const checkUserData = async() =>{

      if(isLoggedIn.value){
        const userData = JSON.parse(localStorage.getItem("user"))
        if(userData === null){
          await getUserData();
        }else{
          changeUserData(userData)
        }
      }
      let visibilityData = localStorage.getItem("visibilityData")
      if(visibilityData){
        await setVisibilityData(JSON.parse(visibilityData))
      }
      if(!visibilityData && userTargets.value && userTargets.value.website !== null){
        getVisibility()
      }
      setShowModal()
    }
    const setShowModal= ()=>{
      if(isLoggedIn.value && !userData.value.company_name){
        isOpenCompanyModal.value = true;
      }
    }
    checkUserData()
    watch(()=> isLoggedIn.value, (value)=>{
      if(value){
        setTimeout(()=>{

          setShowModal()
        },1000)
      }
    })
    return{
      isOpenCompanyModal
    }
  }
};
</script>
