<template>
   <card class="dashboard-margin mb-0" header-classes="border-radius-top-stevie" body-classes="pt-0">
    <div class="nav-wrapper pt-0">
      <div class="flex-column flex-md-row tabs-stevie">
        <ul
          class="flex-column flex-md-row nav nav-pills"
        >
          <li v-for="tab in tabs"  :key="tab.name" class="nav-item pr-0">
              <router-link :to="{name: tab.route}" exact-active-class="active" class="nav-link border-radius-bottom-stevie border-radius-top-none tabs-stevie shadow-none" >
                {{tab.name}}
              </router-link>
          </li>
        </ul>
      </div>
    </div>
    <card class="shadow-none" body-classes="px-4">
      <router-view ></router-view>
    </card>
  </card>
</template>

<script>
import { shallowRef } from '@vue/reactivity'
import { useI18n } from 'vue-i18n/index';

export default {
  name: "Settings",
  components: {
  },
  setup(){
    const { t }= useI18n() 
    const tabs = shallowRef([
      {
        name: t('targets'),
        route: 'targets',
      }
    ])
    return{
      tabs
    }
  }
}
</script>

<style scoped>

</style>
