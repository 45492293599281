
export default () => ({
    isLoading: false,
    landingsData:{
        data: [],
    },
    categories: {
    },
    landingPreview: null,
    landing: {},
    landingsTemplatesData:{
      data: [],
    }

})
