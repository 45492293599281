
export const viOthersUrl = ( state ) => {
    const others = [];
    const othersFields = ['linkedin', 'twitter', 'xing', 'youtube']
    othersFields.forEach((field)=>{
        let otherUrl= state.visibilityData[field]
        if(otherUrl){
            others.push(otherUrl)
        }
    })
 return others.join( "\n")
}
export const relevantUrls = ( state ) => {
    const relevantMentions = [];
    const relevantMentionsFields = ['wlw', 'werzuwem','kompass' ]
    relevantMentionsFields.forEach((field)=>{
        let otherUrl= state.visibilityData[field]
        if(otherUrl){
            relevantMentions.push(otherUrl)
        }
    })
 return relevantMentions.join( "\n")
}



