<template>
  <card class="dashboard-margin mb-0" header-classes="border-radius-top-stevie" body-classes="pt-0">
    <div class="nav-wrapper pt-0">
      <div class="flex-column flex-md-row tabs-stevie">
        <ul
          class="flex-column flex-md-row nav nav-pills"
        >
          <li v-for="tab in tabs"  :key="tab.name" class="nav-item pr-0">
              <router-link :to="{name: tab.route}" exact-active-class="active" class="nav-link border-radius-bottom-stevie border-radius-top-none tabs-stevie shadow-none" >
                {{tab.name}}
              </router-link>
          </li>
        </ul>
      </div>
    </div>
    <card class="shadow-none" body-classes="px-0">
      <spinner-loading-modal v-if="isLoading" v-model:show="isLoading"></spinner-loading-modal>
      <router-view ></router-view>
    </card>
  </card>
</template>
<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import { useI18n } from "vue-i18n/index";
import { useToast } from "vue-toastification";
import { onMounted, ref, shallowRef } from "vue";
import useUserData from "@/composables/useUserData"

import SpinnerLoadingModal from "@/views/Components/SpinnerLoadingModal";
import useSubscription from '@/composables/useSubscription';


export default {
  name: "EditProfileForm",
  components: {
    Form,
    SpinnerLoadingModal
  },
  setup() {
    const twoFactor = ref(null);
    const { t } = useI18n();
    const toast = useToast();
    const { changeUserData, userData, isLoading, getUserData }= useUserData();
    const { hasSubscription, inGracePeriod }= useSubscription();
    const tabs = shallowRef([
      {
        name: t('user'),
        route: 'profile',
      },
      {
        name: t('security'),
        route: 'security',
      },
      {
        name: t('invoice',2),
        route: 'subscription',
      },
      {
        name: t('wallet'),
        route: 'Wallet',
      }
    ])
    const updatePersonalInfo = async (value) => {
      const route = "/user/update";
      let response = await axios.put(route, value);

      if (response && response.status === 200) {
        toast.success(t("notification.success.general"));
        
        let newUserData = userData.value;
        newUserData.name = value.name;
        newUserData.last_name = value.last_name;
        newUserData.email = value.email;
        changeUserData(newUserData);

      }
    }
    const updatePassword = async (value) => {
      const route = "/user/update/password";
      let response = await axios.put(route, value);

      if (response && response.status === 200) {
        toast.success(t("notification.success.general"));
      }
    }
    const updateTwoFactor = async () => {
      const route = "/user/two-factor-authentication";
      let response;
      if (twoFactor.value === "1") {
        response = await axios.post(route);
      }
      if (twoFactor.value === "0") {
        response = await axios.delete(route);
      }
      if (response && response.status === 200) {
        toast.success(t("notification.success.general"));
      }
    }

    const schema = Yup.object().shape({
      name: Yup.string().required().label(t("name")),
      last_name: Yup.string().required().label(t("lastName")),
      email: Yup.string().required().label(t("email")),

      current_password: Yup.string().min(6).required().label(t("currentPassword")),
      password: Yup.string().min(6).required().label(t("password")),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], t("passwordsMustMatch"))
        .label(t("passwordConfirmation")),

      twoFactor: Yup.string().required().label(t("twoFactor")),
    });
    onMounted(()=>{
      if(!isLoading.value){
        getUserData();
      }
    })

    return {
      userData,
      isLoading,
      schema,
      twoFactor,
      tabs,
      inGracePeriod,
      hasSubscription,
      updatePersonalInfo,
      updatePassword,
      updateTwoFactor,
    }
  }
};
</script>
<style scoped lang="scss">
</style>
