<template>
 <modal v-model:show="showModal" :is-allowed-close-modal="modalInfo.allowClose ? modalInfo.allowClose : true" body-classes="p-0 border-radius-stevie bg-athens-gray" modal-content-classes="border-radius-stevie modal-animation">
    <div class="row m-0 p-4">
      <div class="col-12 px-0">
        <div class="d-flex justify-content-between">
          <h3>{{ $t(modalInfo.title) }}</h3>
          <base-button
            v-show="modalInfo.allowClose"
            outline
            class="p-1 border-0 h-fit"
            @click="$emit('closeModal')"
        ><i class="fas fa-times"></i></base-button>
        </div>
        <hr class="my-4" />
      </div>
      <div class="col-12 mt-2 px-0">
        <card header-classes="bg-transparent" body-classes="p-4 row">
          <div class="col-12 d-flex align-items-center">
            <p class="m-0 mr-auto">{{ $t(modalInfo.subtitle) }}</p>
          </div>
        </card>
      </div>
      
      <div class="col-12 d-flex justify-content-around px-0">
        <base-button
          borderRadiusLeft
          borderRadiusRight
          class="py-2 px-4"
          :disabled="isLoading"
          @click="$emit('confirm')"
        >
          <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
          {{ $t(modalInfo.textConfirm) }}
        </base-button>
        <base-button
          borderRadiusLeft
          borderRadiusRight
          class="py-2 px-4"
          :disabled="isLoading"
          @click="$emit('cancel')"
        >{{ $t(modalInfo.textCancel) }}</base-button>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from "../../components/Modal";
import { ref } from "vue";

export default {
  name: "ConfirmModal",
  props: {
    showModal: Boolean,
    isLoading: Boolean,
    modalInfo: Object
  },
  emits:['cancel', 'confirm','closeModal'],
  components: {
    Modal,
  },
  setup() {

    return {
    }
  },
}
</script>

<style scoped>

</style>
