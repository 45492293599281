import { defineRule } from "vee-validate";
import useForms from "@/composables/useForms";
import { useI18n } from "vue-i18n/index";

export const formValidations = () => {
  const { t } = useI18n();
  const { isValidEmail } = useForms()

  defineRule("required", (value) => {
    if (!value || !value.length) {
      return t("validationsForm.required");
    }
    return true;
  });

  defineRule("email", (value) => {
    if (!isValidEmail(value)) {
      return t("validationsForm.emailInvalid");
    }
    return true;
  });

  defineRule('minLength', (value, [limit]) => {
    if (!value || !value.length) {
      return true;
    }
    if (value.length < limit) {
      return t("validationsForm.minLength", [limit]);
    }
    return true;
  });

  defineRule('invalidUrl', (value) => {
    const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\/+.~#?&=]*)$/;
    if (value && !regex.test(value)) {
      return t("validationsForm.urlInvalid");
    }
    return true;
  });

  defineRule('confirmed', (value, [target], ctx) => {
    if (value === ctx.form[target]) {
      return true;
    }
    return t("passwordsMustMatch");
  });
}
