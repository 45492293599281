<template>
  <el-popover :width="200" trigger="hover" :content="$t('registerKnowMore')">
    <template #reference>
      <div @click="$emit('register-modal')" class="cursor-pointer card-margin" :class="customHeight">
        <em class="zindex centered-axis-xy ni ni-active-40 text-xl"></em>
        <stats-card
          v-if="statsCard"
          :title="cardTitle ? $t(cardTitle) : 'Dummy card'"
          type="gradient-red"
        >
          <template v-slot:footer>
            <div class="blur">
            <span class="text-success mr-2"
            ><i class="fa fa-arrow-up"></i> Dummy data</span
            >
              <h5 class="text-nowrap">{{ $t("sinceLastMonth") }}</h5>
            </div>
          </template>
        </stats-card>
        <card v-if="card" header-classes="bg-transparent" body-classes="p-0 row" class="h-100">
          <div class="col-12 align-items-center">
              <h6 class="margin-left-title mb-0 card-text">{{ cardTitle ? $t(cardTitle) : 'Dummy card' }}</h6>
          </div>
          <div class="chart-area w-100 h-100 d-flex justify-content-center align-items-center blur">
            <img
              :src="`/img/icons/stevie/${imgName}`"
              class="mt--1 ml-2"
              alt="Path icon"
            />
          </div>
        </card>
      </div>
    </template>
  </el-popover>
</template>

<script>
export default {
  name: "DummyCard",
  props: {
    statsCard: Boolean,
    card: Boolean,
    customHeight: "",
    cardTitle: null,
    imgName: ""
  },
  emits: ["register-modal"],
  data() {
    return {
      ordersChartID: "ordersChart",
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
    };
  },
};
</script>

<style scoped>
.zindex {
  z-index: 1000;
}
.blur {
  filter: blur(5px);
}
.centered-axis-xy {
  position: absolute;
  left: 50%;
  top: calc(50% - 15px);
  transform: translate(-50%, -50%);
}
.cursor-pointer {
  cursor: pointer;
}
.custom-height-campaigns {
  height: 280px;
}
.custom-height-social {
  height: 420px;
}
.card-margin {
  margin-bottom: 30px;
}
</style>
