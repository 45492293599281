<template>
    <div class="d-flex justify-content-end">
        <button
            type="button"
            class="btn btn-default btn-action mx-4 start-campaign-button d-flex justify-content-center align-items-center"
            @click="$emit('chooseTemplate', template)"
        >
            {{ $t('chooseTemplate') }}
        </button>
        <button
            type="button"
            class="btn btn-default btn-action start-campaign-button d-flex justify-content-center align-items-center"
            @click="$emit('close')"
        >
            {{ $t('closeTemplate') }}
        </button>
    </div>
    <hr>
    <div class="d-flex flex-wrap justify-content-center">
        <div class="px-0 px-lg-2 px-xl-3">
          <meta-ad-preview
            class="ad-preview px-0"
            :url="template.url"
            :meta-headline="template.meta_headline"
            :meta-description="template.meta_url_description"
            :meta-text="template.meta_text"
            :meta-image="getImageUrl(template.meta_image)"
          ></meta-ad-preview>
        </div>
        <div class="px-0 px-lg-2 px-xl-3">
          <google-ad-preview
            class="ad-preview px-0"
            :title="$t('previewGoogleAdDisplay')"
            :url="template.url"
            :google-headline="template.google_headline[0]"
            :google-description="template.google_description[0]"
            :google-image="getImageUrl(template.google_square_image)"
            :is-with-image="true"
          ></google-ad-preview>
        </div>
      </div>
</template>
<script>
    import {getImageUrl} from '@/util/helperFunctions.js'
    import { ref } from 'vue';
    import GoogleAdPreview from "../Pages/Campaigns/Components/GoogleAdPreview.vue"
    import MetaAdPreview from "../Pages/Campaigns/Components/MetaAdPreview.vue";

    export default {
      name: "CampaignTemplatePreview",
      emits: ["chooseTemplate", "close"],
      props: ["template"],
      components: {
        GoogleAdPreview,
        MetaAdPreview
      },
      setup() {
        const imgHeader = ref(null)
        const maxWidth = ref(null)

        const getSizeImg = (img) => {
            if(img && img.naturalWidth){
                let imgWidth = (img.naturalWidth*100)/600;
                imgWidth = imgWidth > 100 || maxWidth.value === null ? 100 : imgWidth;
                img.style = `width:${imgWidth}%`
            }
        }


        return{
            getImageUrl,
            getSizeImg,
            imgHeader,
        }
      }

    }
</script>

<style lang="scss" scoped>
.btn-action{
  border-radius: 100px;
}
.template{
  width: 100%;
  height: 100%;
  overflow-wrap: break-word;
}
.ad-preview {
  min-height: 210px;
  max-width: 500px;
}
</style>
