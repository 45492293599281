<template>
  <card
    header-classes="bg-transparent"
    body-classes="p-0 card-vi row align-items-center"
    card-classes="card-vi"
  >
    <div class="card-body">
      <h1 class="m-0 display-2 font-weight-900 text-woodsmoke text-center">
        {{ $t("yourOnlineVisibilityFull") }}
      </h1>
      <Form
        v-if="!visibilityMessage || visibilityMessage === 'loading'"
        @submit="setUrlVi"
        :validation-schema="schema"
      >
        <div class="row justify-content-center px-3">
          <div class="mt-sm-2 col-3 col-md-2 p-0 border-input-form-vi border-radius-left-30 border-right-none input-button-height-vi bg-white d-none d-sm-flex align-items-center">
            <img src="/img/icons/stevie/speedometer-icon.svg" class="pl-4" alt="Speedometer">
            <img src="/img/icons/stevie/arrow-shape.svg" class="position-absolute pr-3 pt-2 style-arrow-input" alt="Arrow">
          </div>
          <base-input
            name="url"
            :placeholder="$t('urlEnter')"
            input-classes="border-radius-none border-radius-sm-none border-radius-xs-left-30 border-left-none border-right-none input-button-height-vi input-form-vi"
            class="col-10 col-sm-6 col-md-8 px-0 mt-sm-2"
            :class="urlInvalid ? 'mb-0' : ''"
            @change="urlInvalid = null"
          >
          </base-input>
          <div class="mt-sm-2 col-2 col-sm-3 col-md-2 p-0 border-input-form-vi border-radius-right-30 border-left-none input-button-height-vi bg-white d-flex align-items-center">
            <base-button
              v-if="screenWidth > 576"
              :disabled="!buttonEnabled"
              block
              borderRadiusLeft
              borderRadiusRight
              class="button-height-33 button-width-form-vi button-margin-right-vi font-size-form-vi p-0 pt-1 input-icon"
              native-type="submit"
            >{{ $t("check") }}</base-button
            >
            <base-button
              v-else
              :disabled="!buttonEnabled"
              block
              borderRadiusLeft
              borderRadiusRight
              class="button-height-33 button-width-form-vi button-margin-right-vi font-size-form-vi p-0 pt-1 input-icon bg-transparent border-0 shadow-none"
              native-type="submit"
            ><img src="/img/icons/stevie/search-shape.svg" alt="Search icon"></base-button
            >
          </div>
        </div>
        <small v-if="urlInvalid" class="text-danger">{{
            urlInvalid
          }}</small>
      </Form>
    </div>
  </card>
</template>

<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n/index";
import { ref, useContext } from "vue";
import useVisibility from '../../../composables/useVisibility';
import useLoggedIn from '../../../composables/useLoggedIn';
import { formatWebsite } from '../../../composables/useFormatUrl';

export default {
  name: "PluginEnterUrl",
  props: {
    companySize: Number,
  },
  components: {
    Form,
  },
  emits:['setUrlVi'],
  setup(props) {

    const { t } = useI18n();
    const visibilityMessage = ref(null);
    const buttonEnabled = ref(true);
    const inputClasses = ref("");
    const progressType = ref("");
    const colorLabelText = ref("");
    const sizeLoader = ref("20px");
    const { emit } = useContext();
    const screenWidth = ref(window.screen.width);
    const { isLoggedIn } = useLoggedIn()
    const { urlInvalid }= useVisibility()

    const setUrlVi = (value) => {
      value.url = formatWebsite(value.url);
      emit("setUrlVi", value);
    }

    const schema = Yup.object().shape({
      url: Yup.string().required().label(t("url")),
    });

    window.addEventListener("resize", () => {
      screenWidth.value = window.screen.width;
    });

    return {
      schema,
      visibilityMessage,
      buttonEnabled,
      inputClasses,
      progressType,
      colorLabelText,
      sizeLoader,
      urlInvalid,
      isLoggedIn,
      screenWidth,
      setUrlVi,
    };
  },
}
</script>

<style scoped>

</style>
