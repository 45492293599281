<template>
  <div class="col-12 col-lg-6 row pl-lg-5 mb-5 mb-lg-8">
    <Form
      class="col-12"
      @submit="sendMessage"
      :validation-schema="schema"
    >
      <div class="col-12 flex-column justify-content-center d-inline">
        <Field
          id="email"
          name="email"
          type="text"
          class="form-control border-radius-stevie box-shadow-contact mb-4"
          :placeholder="$t('emailAddress')"
        ></Field>
        <ErrorMessage name="email" class="text-danger text-required-error error-message" />
      </div>
      <div class="col-12 justify-content-center d-inline">
        <Field
          id="subject"
          name="subject"
          type="text"
          class="form-control border-radius-stevie box-shadow-contact mb-4"
          :placeholder="$t('subject')"
        ></Field>
        <ErrorMessage name="subject" class="text-danger text-required-error error-message" />
      </div>
      <div class="col-12 justify-content-center d-inline">
        <Field
          id="message"
          name="message"
          type="text"
          class="form-control border-radius-stevie box-shadow-contact mb-4"
          :placeholder="$t('message')"
          v-slot="{ field }"
        >
          <textarea
            v-bind="field"
            id="message"
            class="form-control border-radius-stevie box-shadow-contact w-100 textarea-header mb-4"
            rows="8"
            :placeholder="$t('message')"
          />
        </Field>
        <ErrorMessage name="message" class="text-danger text-required-error error-message-textarea" />
      </div>
      <div class="col-12 d-flex justify-content-center d-lg-inline">
        <base-button
          block
          borderRadius
          native-type="submit"
          class="button-height-33 trial-button-vi box-shadow-contact"
          :disabled="isButtonDisabled"
        >
          <span v-if="!isButtonDisabled">Anfragen</span>
          <clip-loader v-else :color="colorLoader" :size="sizeLoader"></clip-loader>
        </base-button>
      </div>
    </Form>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import axios from "axios";
import { useI18n } from "vue-i18n/index";
import { useToast } from "vue-toastification";
import { ref } from "vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "ContactForm",
  components: {
    Form,
    Field,
    ErrorMessage,
    ClipLoader
  },
  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const isButtonDisabled = ref(false)
    const colorLoader = ref("#ffffff");
    const sizeLoader = ref("20px");

    const sendMessage = async (value, actions) => {
      isButtonDisabled.value = true;
      const route = "/contact-email";
      let response = await axios.post(route, value);
      if (response && response.status === 200) {
        toast.success(t("notification.success.general"));
        actions.resetForm();
        isButtonDisabled.value = false
      }
    }

    const schema = {
      email: "required|email",
      subject: "required",
      message: "required"
    }

    return {
      isButtonDisabled,
      colorLoader,
      sizeLoader,
      sendMessage,
      schema
    }
  }
}
</script>

<style scoped>
.error-message {
  min-width: 320px;
  top: 4.5rem;
}
.error-message-textarea {
  min-width: 320px;
  top: 13.5rem;
}
</style>
