export const setLandings = ( state, landingsData ) => {

    state.landingsData = landingsData;
}
export const setCategories = ( state, categories) => {
  state.categories = categories;
}
export const setLanding = ( state, landing ) => {

    state.landing = landing;
}
export const setIsLoading = ( state, isLoading ) => {

    state.isLoading = isLoading;
}
export const setLandingsTemplates = ( state, landingsTemplatesData ) => {

  state.landingsTemplatesData = landingsTemplatesData;
}

