import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

const NODE_ENV = process.env.NODE_ENV;

const useForms = () => {

  const toast = useToast();
  const { t } = useI18n();

  const isSubmit = ref(false);

  const isValidForm = (form, requiredFields, othersValidations = true)=>{
    let isValid = true;

    requiredFields.forEach(field=>{
      let isArrayField = Array.isArray(form[field]);
      if(isArrayField){
        let haveElement = form[field].some(item => item !== '' );
        if(!haveElement) isValid = false
      }
      if(!isArrayField && form[field] === null || form[field]=== ''){
        isValid = false
      }
    })
    if(!isValid || !othersValidations){
      isSubmit.value = true;
      toast.warning( t("formValidations.checkForm"), { timeout: 3000 });
    }

    return isValid && othersValidations

  }

  const markAsInvalid = (validation)=>{
    return validation && isSubmit.value
  }

  const uploadForm= (event, form)=>{
    const fieldName = event.srcElement.name;
    const value = event.target.value;
    if(Object.keys(form).includes(fieldName)){
      form[fieldName] = value;
    }
    if(fieldName.includes('[')){
      let arrayName = fieldName.split('[')[0]
      let subfieldName = fieldName.split(']').at(-1)
      let indexField = fieldName.split('[')[1].split(']')[0]
      if(Object.keys(form).includes(arrayName)){
        if(form[arrayName][indexField]){
          form[arrayName][indexField][subfieldName] = value;
        }
      }
    }
    return form;
  }
  const createFormData = (form) => {
    const formData = new FormData();

    Object.keys(form).forEach(field => {
      let value = form[field];
      if (value !== null) {
        let isArrayField = Array.isArray(value);
        if (isArrayField) {
          value = JSON.stringify(value);
        }
        formData.append(field, value);
      } else {
        formData.append(field, '');
      }
    });
    return formData
  }
  const isValidEmail = (email)=>{
    let regValidation = /^[\w-.+]+@([\w-]+\.)+([\w-]+)$/g ;
    return email ? email.match(regValidation) !== null : false
  }

  const isValidLink = link => {
    let regValidation = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\/+.~#?&=]*)$/;
    return link ? link.match(regValidation) !== null : false;
  };

  const removeHtmlTags = htmlText => htmlText.replace(/<[^>]+>/g, "");

  return {
    markAsInvalid,
    isValidForm,
    uploadForm,
    createFormData,
    isValidEmail,
    isValidLink,
    removeHtmlTags
  };
};

export default useForms;
