<template>
  <card header-classes="bg-transparent" body-classes="p-0 row m-0 py-3 py-2">
    <div class="col-12 d-flex justify-content-between">
      <div class="d-flex aligns-items-center">
        <h4 class="mb-0 card-text">{{ title }}</h4>
      </div>
      <div>
        <el-tooltip placement="top" :content="$t(`campaingGoogleInfoPreview.${isWithImage ? 'image': 'withoutImage'}`)">
          <img
            src="/img/icons/stevie/info-shape.svg"
            class="ml-1 mt-1 icon-card"
            alt="Info icon"
          />
        </el-tooltip>
      </div>
    </div>
    <div class="col-12">
      <img src="/img/icons/stevie/google.png" class="google-icon" alt="Google icon">
    </div>
    <div class="col-12">
      <div class="d-flex">
        <h3 class="ml-0 mr-2 my-0 title-ad">{{ $t("ad")}}</h3><p class="m-0 url">{{url ? url.replace('http://','').replace('https://','').split(/[/?#]/)[0] : $t("campaignPreview.url")}}</p>
      </div>
      <a :href="url" target="_blank" class="m-0 headline">{{ googleHeadline ? googleHeadline : $t("campaignPreview.subtitle") }}</a>
      <div class="row m-0">
        <p class="description p-0 m-0" :class="isWithImage ?'col-8':'col-12'">{{ googleDescription ? googleDescription : $t("campaignPreview.description") }}</p>
        <img v-if="googleImage" :src="googleImage" class="ml-2 google-image p-0" alt="Google Ad image">
        <img v-if="!googleImage && isWithImage" src="/img/theme/default-placeholder.png" class="ml-2 google-image p-0" alt="Google Ad image">
      </div>
    </div>
  </card>
</template>

<script>
export default {
  name: "GoogleAdPreview",
  props: ["url", "googleHeadline", "googleDescription", "googleImage", "isWithImage", "title"],
}
</script>

<style scoped>
.google-icon {
  width: 20px;
}
.google-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}
.headline {
  font-size: 14px;
  color: #0404c7;
  text-decoration: none solid rgb(4, 4, 199);
}
.description {
  font-size: 12px;
  color: #666666;
  text-decoration: none solid rgb(102, 102, 102);
  line-height: normal;
}
.title-ad {
  font-size: 12px;
  color: #2b2b2d;
  text-decoration: none solid rgb(43, 43, 45);
}
.url {
  font-size: 12px;
  color: #2b2b2d;
  text-decoration: none solid rgb(43, 43, 45);
}
</style>
