<template>
  <modal v-model:show="isModalUnlinkFacebook" modal-classes="" body-classes="p-0 border-radius-stevie bg-athens-gray" modal-content-classes="border-radius-stevie modal-animation">
    <div class="row m-0 p-4">
      <div class="col-12 px-0">
        <h3>Unlink Facebook</h3>
        <hr class="my-4" />
      </div>
      <div class="col-12 mt-2 px-0">
        <p>{{ $t("textUnlink") }}</p>
        <card header-classes="bg-transparent" body-classes="p-4 row">
          <div class="col-12 d-flex align-items-center">
            <img class="size-facebook-icon mr-3" src="/img/icons/stevie/facebook.png" alt="Facebook icon">
            <h4 class="m-0 mr-auto">Facebook</h4>
            <div class="row text-right">
              <small class="col-12 m-0 font-weight-500">{{ nameFB }}</small>
              <small class="col-12 m-0 font-weight-500">{{ emailFB }}</small>
            </div>
          </div>
        </card>
      </div>
      <div class="col-12 text-right px-0">
        <base-button
          borderRadiusLeft
          borderRadiusRight
          class="cancel-button py-2 border-0"
          @click="$emit('show-facebook-modal')"
        >{{ $t("cancel") }}</base-button>
        <base-button
          borderRadiusLeft
          borderRadiusRight
          class="unlink-button py-2"
          @click="$emit('unlink-facebook-modal')"
        >{{ $t("unlink") }}</base-button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "../../components/Modal";
import { ref, inject } from "vue";

export default {
  name: "UnlinkFacebookModal",
  props: {
    isModalUnlinkFacebook: Boolean,
  },
  components: {
    Modal,
  },
  setup(_, { emit }) {
    const nameFB = ref();
    const emailFB = ref();
    const loginFacebook = inject("loginFacebook");
    const changeLoginFacebook = inject("changeLoginFacebook");
    changeLoginFacebook(JSON.parse(localStorage.getItem("loginFacebook")));

    const unlinkFacebookModal = () => {
      emit("unlinkFacebookModal")
    };

    if (loginFacebook.value.status === "connected") {
      FB.api(`/me?fields=name,email`, async function(response) {
        nameFB.value = response.name;
        emailFB.value = response.email;
      });
    }

    return {
      nameFB,
      emailFB,
      unlinkFacebookModal,
    };
  }
}
</script>

<style scoped>
.size-facebook-icon {
  width: 40px;
  height: 40px;
}
</style>
