<template>
 <modal v-model:show="isOpenModal" :is-allowed-close-modal="false" body-classes="p-0 border-radius-stevie bg-athens-gray" modal-content-classes="border-radius-stevie modal-animation">
    <div class="row m-0 p-4">
      <div class="col-12 px-0">
        <div class="d-flex justify-content-between">
          <h3>{{ $t('companyNameRequired.title') }}</h3>
        </div>
        <hr class="my-4" />
      </div>
      <div class="col-12 mt-2 px-0">
        <card header-classes="bg-transparent" body-classes="p-4 pb-0 row">
          <div class="col-12 d-flex align-items-center">
            <p class="m-0 mr-auto">{{ $t('companyNameRequired.subtitle') }}</p>
          </div>
           <div class="col-12 my-1">
              <label class="w-100 text-center">{{ $t("companyName") }}</label>
              <base-input
                alternative
                name="companyName"
                v-bind:value="companyName"
                v-on:input="companyName = $event.target.value"
                type="text"
                input-classes="input-stevie"
                label-classes="input-placeholder-stevie"
              >
              </base-input>
            </div>
        </card>
      </div>
      <div class="col-12 mt-2 px-0">
      </div>
      <div class="col-12 d-flex justify-content-around px-0">
        <base-button
          borderRadiusLeft
          borderRadiusRight
          class="py-2 px-4"
          :disabled="isLoading || !companyName"
          native-type="submit"
          @click="sendCompanyName"
        >{{ $t('save') }}</base-button>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from "../../components/Modal";
import useUserData from '@/composables/useUserData';
import { ref } from '@vue/reactivity';

export default {
  name: "CompanyNameModal",
  props: {
    isOpenModal: Boolean,
  },
  emits:['closeModal'],
  components: {
    Modal,
  },
  setup(props, { emit }) {
      const { saveCompanyName, userData }= useUserData()
      const isLoading = ref(false);
      const companyName = ref(null)
      const sendCompanyName = async()=>{
        isLoading.value= true;
        await saveCompanyName(companyName.value)
        isLoading.value= false;
        companyName.value = null;
        emit('closeModal')
      }
    return {
        isLoading,
        companyName,
        sendCompanyName
    }
  },
}
</script>

<style scoped>
</style>
