import { shortWebsite } from "../../../composables/useFormatUrl";

const changeLocalStorageObject = (name, data)=>{
  const allFieldsEmpty = Object.keys(data).every(field=> data[field]===null)
  if(Object.keys(data).length === 0 || allFieldsEmpty){
    if (name !== "visibilityData") localStorage.removeItem(name)
  }else{
    localStorage.setItem(name, JSON.stringify(data))
  }
}

export const setIsLoggedIn = ( state, isLoggedIn ) => {
    state.isLoggedIn = isLoggedIn;
}
export const setSocialMediaUrls = ( state, socialMediaUrls ) => {
    state.socialMediaUrls = socialMediaUrls;
}
export const setTargetsUrls = ( state, targets) => {
    const PageUrl = ["instagram" , "facebook", "google", "kompass" ,"werzuwem", "wlw"];
    let targetsUrls = {
      url: targets.url
    }
    const excludedFields = ['website', 'company_type'];

    const fields = Object.keys(targets).filter( field => !excludedFields.includes(field));
    fields.forEach( field => {
      if(PageUrl.includes(field)){
        targetsUrls[`${field}PageUrl`] = targets[field];
      }else{
        targetsUrls[`${field}Url`]= targets[field];
      }
    })
    targetsUrls.website = targets.website
    targetsUrls.company_type = targets.company_type
    localStorage.setItem("targets", JSON.stringify(targetsUrls))
    state.targets = targetsUrls;
}

export const setIsLoading = ( state, {isLoading, name} ) => {
  if(!name){
    state.isLoading = isLoading;
  }else{
    state[`isLoading${name}`]= isLoading;
  }
}
export const setVisibilityData = ( state, visibilityData ) => {
  changeLocalStorageObject("visibilityData", visibilityData)
  state.visibilityData = visibilityData;
}
export const setUrlInvalid = ( state, urlInvalid ) => {
  state.urlInvalid = urlInvalid;
}
export const setRrssForm = ( state, rrssForm ) => {
  state.rrssForm = rrssForm;
}
export const setViForm = ( state, viForm ) => {
  viForm.url= shortWebsite(viForm.url);
  state.viForm = viForm;
}
export const setUserTargets = ( state, userTargets ) => {
  if(localStorage.getItem("user")){
    let userData = JSON.parse(localStorage.getItem("user"));
    userData.targets = userTargets;
    localStorage.setItem("user", JSON.stringify(userData));
  }
  state.userTargets = userTargets;
}
export const setNewTargets = ( state, newTargets ) => {
  changeLocalStorageObject("targetsForm", newTargets)
  state.newTargets = newTargets;
}
export const cleanUserData = ( state ) => {
  const setToNull = (objectData)=>{
    if(!state[objectData])return
    Object.keys(state[objectData]).forEach( field =>{
      state[objectData][field] = null;
    })
  }
  setToNull("visibilityData")
  setToNull("userTargets");
}
export const setIsViFails = ( state, isViFails ) => {
  state.isViFails = isViFails;
}
