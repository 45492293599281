<template>
  <div class="row justify-content-center mx-4">
    <div class="col-12 px-0">
      <div class="row mx-0 px-0 false-box-shadow">
        <div class="col-12 row justify-content-between w-100 mx-0 px-0">
          <span class="col-6 px-0 mb-1 text-bar font-weight-500">{{ $t("worstValue") }}</span>
          <span class="col-6 px-0 mb-1 text-right text-bar font-weight-500">{{ $t("bestValue") }}</span>
        </div>
        <img :src="barMeterShape" class="col-12 px-0 bar-height z-index-bar" alt="Bar meter">
        <img :src="triangleShape" class="position-absolute margin-triangle z-index-triangle shadow-triangle" id="score" alt="Triangle">
        <img :src="shadowBarShape" class="position-absolute w-100 bar-height shadow-bar mt-5" alt="Bar meter">
        <img :src="ovalShape" class="position-absolute margin-70 mt--1 z-index-oval" alt="Oval">

        <img v-for="(data, index) in marginsLine" :key="index"
             :src="lineBarShape"
             class="position-absolute vertical-line"
             :class="`${data}`"
             alt="Line"
        >

        <span v-for="(data, index) in marginsNumber" :key="index"
              class="position-absolute pl-1 mt-6 number-scale"
              :class="`${data}`"
        >{{ index }}
        </span>

        <div id="index" class="position-absolute mt-7">
          <span class="card-index" id="percent">{{ (index/10).toPrecision(2) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "BarMeter",
  props: ["index"],
  data() {
    return {
      barMeterShape: "/img/icons/stevie/bar-meter-shape.svg",
      shadowBarShape: "/img/icons/stevie/shadow-bar-shape.svg",
      lineBarShape: "/img/icons/stevie/line-bar-shape-rotated.svg",
      triangleShape: "/img/icons/stevie/triangle-rectangle-shape.svg",
      ovalShape: "/img/icons/stevie/oval-bar-shape.svg",
      marginsNumber: [
        "",
        "margin-8",
        "margin-18",
        "margin-28",
        "margin-38",
        "margin-48",
        "margin-58",
        "margin-68",
        "margin-78",
        "margin-88",
        "margin-97"
      ],
      marginsLine: [
        "",
        "margin-10",
        "margin-20",
        "margin-30",
        "margin-40",
        "margin-50",
        "margin-60",
        "margin-70",
        "margin-80",
        "margin-90",
        "margin-100"
      ]
    };
  },
   mounted() {
     document.getElementById(
       "score"
     ).style.marginLeft = `calc(${this.percent}% - (23px/2))`;

     document.getElementById(
       "index"
     ).style.marginLeft = `calc(${this.percent}% - ${this.pixelsSubtract}px)`;
   },
  setup(props) {
    const vi = ref(props.index);
    const percent = ref(0);
    const isInt = ref(Number.isInteger(vi.value));
    const pixelsSubtract = ref(isInt.value ? 15 : 45);

    const calculatePercent = (index) => {
      percent.value = index
    };

    calculatePercent(vi.value);

    return {
      percent,
      vi,
      pixelsSubtract,
      calculatePercent,
    };
  },
  watch: {
    index() {
      this.calculatePercent(this.index);
      document.getElementById(
        "score"
      ).style.marginLeft = `calc(${this.percent}% - (23px/2))`;

      document.getElementById(
        "index"
      ).style.marginLeft = `calc(${this.percent}% - ${this.pixelsSubtract}px)`;
    }
  }
}
</script>

<style scoped>
.z-index-bar {
  z-index: 100;
}
.z-index-triangle {
  z-index: 1000;
}
.z-index-oval {
  z-index: 1200;
}
.shadow-triangle {
  filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.6))
}
.shadow-bar {
  opacity: 0.5;
  filter: blur(10px);
}
.vertical-line {
  width: 1px;
  height: 164px;
  stroke-width: 1;
  margin-top: -30px;
}
.margin-triangle {
  margin-top: -23px;
  height: 110px;
}
.bar-height {
  height: 43px;
}
.number-scale {
  font-size: 13px;
  color: #8c8c93;
}
.text-bar {
  font-size: 13px;
  color: #2b2b2d;
}
.margin-8 {
  margin-left: 8%;
}
.margin-18 {
  margin-left: 18%;
}
.margin-28 {
  margin-left: 28%;
}
.margin-38 {
  margin-left: 38%;
}
.margin-48 {
  margin-left: 48%;
}
.margin-58 {
  margin-left: 58%;
}
.margin-68 {
  margin-left: 68%;
}
.margin-78 {
  margin-left: 78%;
}
.margin-88 {
  margin-left: 88%;
}
.margin-97 {
  margin-left: 97%;
}
.margin-10 {
  margin-left: 10%;
}
.margin-20 {
  margin-left: 20%;
}
.margin-30 {
  margin-left: 30%;
}
.margin-40 {
  margin-left: 40%;
}
.margin-50 {
  margin-left: 50%;
}
.margin-60 {
  margin-left: 60%;
}
.margin-70 {
  margin-left: 70%;
}
.margin-80 {
  margin-left: 80%;
}
.margin-90 {
  margin-left: 90%;
}
.margin-100 {
  margin-left: 100%;
}

@media (max-width: 576px) {
  .number-scale {
    font-size: 10px;
  }
  .text-bar {
    font-size: 10px;
  }

  .margin-8 {
    margin-left: 6%;
  }
  .margin-18 {
    margin-left: 16%;
  }
  .margin-28 {
    margin-left: 26%;
  }
  .margin-38 {
    margin-left: 36%;
  }
  .margin-48 {
    margin-left: 46%;
  }
  .margin-58 {
    margin-left: 56%;
  }
  .margin-68 {
    margin-left: 66%;
  }
  .margin-78 {
    margin-left: 76%;
  }
  .margin-88 {
    margin-left: 86%;
  }
  .margin-97 {
    margin-left: 95%;
  }
}
</style>
