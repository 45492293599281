import { computed, ref } from "vue";
import store from "../store";

const useSubscription = () => {

  const cancelSubscription = async(subscription) => {
    const response= await store.dispatch('user/cancelSubscription', subscription)
    if(response.status === 200){
      await store.dispatch('user/getUserData')
    }
    return response
  };
  const newSubscription = async(subscription) => {
    const response= await store.dispatch('user/subscribe', subscription)
    return response
  }
  const confirmSubscription = async (subcription) => {
    const response = await store.dispatch('user/confirmSubscription', subcription)
    return response
  }
  const changeSuscriptionModal = (isOpen, subscriptionName = "")=>{
    store.commit('user/setOpenSuscriptionModal', isOpen )
    if(subscriptionName){
      store.commit('user/setSubscriptionRequired', subscriptionName )
    }
  }
  return {
    hasSubscription: computed(() =>store.getters['user/hasSubscription'] ),
    isTrialSubscription: computed(() =>store.getters['user/isTrialSubscription'] ),
    inGracePeriod: computed(() =>store.getters['user/inGracePeriod'] ),
    subscriptionPermissions: computed(() =>store.getters['user/subscriptionPermissions'] ),
    isOpenSubscriptionModal: computed(()=> store.state.user.isOpenSubscriptionModal),
    subscriptionRequired: computed(()=> store.state.user.subscriptionRequired),
    newSubscription,
    cancelSubscription,
    confirmSubscription,
    changeSuscriptionModal
  };
};

export default useSubscription;
