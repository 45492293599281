<template>
  <div>
    <base-nav
      v-model="showMenu"
      type="light"
      :transparent="true"
      :menu-classes="screenWidth >= 992 ? 'justify-content-end pr-0 col-6' : 'justify-content-end col-12'"
      container-classes="d-flex w-100 h-100 align-items-start justify-content-between padding-navbar pr-lg-0"
      class="navbar-horizontal navbar-height-vi pr-0 z-index trigger-menu-wrapper"
      :class="screenWidth >= 992 ? 'position-absolute' : 'position-fixed'"
      expand="lg"
      :show="showMenu"
      @change="showItems"
    >
      <template v-slot:brand>
        <div class="navbar-wrapper col-6 px-0">
          <a class="navbar-brand logo-size-vi p-0" href="https://stevie.cloud">
            <div>
              <img class="w-100 h-100 logo-shadow" src="/img/brand/stevie.png" alt="logo" />
            </div>
          </a>
        </div>
      </template>

      <div class="navbar-collapse-header">
        <div class="row mx-0">
          <div class="col-6 collapse-brand">
            <a href="https://stevie.cloud">
              <img src="/img/brand/stevie.png" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              @click="showMenu = false"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>

      <div class="bg-navbar-items border-radius-left-stevie navbar-items-height d-flex">
        <ul class="navbar-nav align-items-lg-center pl-lg-3 pr-lg-6">
          <!-- <li class="nav-item">
            <router-link :to="{ name: 'Landing', hash: '#yourVisibility' }" class="nav-link">
              <span class="nav-link-inner--text">Deine Sichtbarkeit</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Landing', hash: '#moreVisibility' }" class="nav-link">
              <span class="nav-link-inner--text">Mehr Sichtbarkeit</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Landing', hash: '#channels' }" class="nav-link">
              <span class="nav-link-inner--text">Kanäle</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Landing', hash: '#prices' }" class="nav-link">
              <span class="nav-link-inner--text">Preise</span>
            </router-link>
          </li> -->
          <li v-if="!isLoggedIn" class="nav-item">
            <router-link :to="{ name: 'Login' }" class="nav-link">
              <span class="nav-link-inner--text">{{ $t("signIn") }}</span>
            </router-link>
          </li>
          <li v-if="!isLoggedIn" class="nav-item">
            <router-link :to="{ name: 'Register' }" class="nav-link">
              <base-button
                block
                borderRadius
                class="button-height-33 trial-button-vi"
              >
                {{ $t("30DaysFreeTrial") }}
              </base-button>
            </router-link>
          </li>
          <li v-if="isLoggedIn" class="nav-item">
            <router-link :to="{ name: 'Dashboard' }" class="nav-link">
              <span class="nav-link-inner--text">{{ $t("dashboard") }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </base-nav>

    <div class="main-content">
      <router-view></router-view>
    </div>

    <footer class="py-5 bottom-0 w-100 bg-footer-landing" id="footer-main">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 d-flex justify-content-center ">
            <div class="d-flex justify-content-center">
              <a class="nav-link" href="https://www.stevie.cloud/impressum-datenschutz/" rel="_blank">
                <h5 class="nav-link-inner--text mr-4 text-white">IMPRESSUM & DATENSCHUTZ</h5>
              </a>
            </div>
            <div class="d-flex justify-content-center">
              <div class="nav-link" >
                <h5 class="nav-link-inner--text mr-4 text-white">COPYRIGHT   &copy; {{ year }}</h5>
              </div>
               
            </div>
            <div class="d-flex justify-content-center">
              <a class="nav-link" href="https://www.stevie.cloud/" target="_blank" rel="noopener noreferrer">
                <h5 class="nav-link-inner--text mr-4 text-white">WWW.STEVIE.CLOUD </h5>
                </a>
            </div>
          </div>
         
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { ref } from "vue";
import useIsLoggedIn from "../../composables/useLoggedIn"

export default {
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  components: {},
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
      image: "/img/brand/bg-landing-page.jpeg",
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
  },
  setup() {
    const screenWidth = ref(window.screen.width);
    const { isLoggedIn } = useIsLoggedIn()

    window.addEventListener("resize", () => {
      screenWidth.value = window.screen.width;
    });

    const body = document.body;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;

    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll === 0) {
        body.classList.remove(scrollUp);
        return;
      }

      if (currentScroll > lastScroll && !body.classList.contains(scrollDown) && (screenWidth.value < 992)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (currentScroll < lastScroll && body.classList.contains(scrollDown) && (screenWidth.value < 992)) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      if (currentScroll > 60) lastScroll = currentScroll;
    });

    return {
      screenWidth,
      isLoggedIn,
    }
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.remove("background-dashboard-gradient");
      document.body.classList.remove("min-vh-100");
      document.body.classList.add("bg-white");
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-white");
    },
    setScrollBehaviorSmooth() {
      const html = document.documentElement;
      html.classList.add("scroll-smooth");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
    showItems(value) {
     this.showMenu = value;
    },
    scrollFix: function(hashbang)
    {
      location.hash = hashbang;
    }
  },
  beforeUnmount() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
        this.setScrollBehaviorSmooth();
      },
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}

.trigger-menu-wrapper {
  transition: transform 0.4s;
}

.scroll-down .trigger-menu-wrapper {
  transform: translate3d(0, -100%, 0);
}

.scroll-up .trigger-menu-wrapper {
  transform: none;
}

.scroll-up:not(.menu-open) .trigger-menu-wrapper {
  background: var(--lightpurple);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}
</style>
