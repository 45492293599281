import { computed } from "vue";
import { useStore } from "vuex";
import { formatWebsite } from "./useFormatUrl";
import { useRoute } from 'vue-router';


const useVisibility = () =>{

    const store = useStore();
    const route = useRoute()


    const postVisibility = async(viForm) =>{
        const targets = ['url','company_type' ,'website', 'facebook', 'google', 'instagram', 'kompass', 'linkedin', 'twitter', 'werzuwem', 'wlw', 'xing', 'youtube'];
        viForm.url = formatWebsite(viForm.website);
        let form = {};
        form.company_size = viForm.company_size;
        targets.forEach((field)=>{
            if(viForm[field]){

                form[field] = viForm[field];
            }
        })
        const response = await store.dispatch('visibility/postVisibility', form);
        return response
    };
    const getTargets = async()=>{

        const response = await store.dispatch('visibility/getTargets')

        return response
    }
    const saveNewTargets = async(isLogged) => {
        const visibilityData = {...store.state.visibility.visibilityData};
        const rrssForm = store.state.visibility.rrssForm;
        const viForm = store.state.visibility.viForm;
        if(viForm.company_size){
            store.commit('visibility/setNewTargets', {});
        }

        const targets = ['company_type', 'website', 'facebook', 'google', 'instagram', 'kompass', 'linkedin', 'twitter', 'werzuwem', 'wlw', 'xing', 'youtube'];

        Object.keys(visibilityData).forEach((field)=>{
            if(!targets.includes(field)){
                delete visibilityData[field]
            }
        })
        const newTargets = {...visibilityData, ...rrssForm}
        newTargets.company_type = viForm.company_size;
        if(isLogged){
            const userTargets = store.state.visibility.userTargets;
            let hasChanges = false;

            targets.forEach((field)=>{
                if((userTargets[field] || newTargets[field]) && (newTargets[field] !== userTargets[field])){
                    hasChanges = true;
                }
            })
            let editedTargets = {};
            if(userTargets.website !== null && !newTargets.website.includes(userTargets.website) && !hasChanges){
                return
            }
            if(!userTargets.website){
                const targetLocal = JSON.parse(localStorage.getItem("targetsForm"));
                if(targetLocal){
                    editedTargets = JSON.parse(localStorage.getItem("targetsForm"));
                }else{
                    editedTargets = newTargets;
                }
            }
            if(route.name === "Settings"){
                editedTargets = newTargets;
            }
            if(newTargets.website.includes(userTargets.website) || `${newTargets.website}/` === userTargets.website){
            
                const checkTargets = targets.slice(2);
                editedTargets = {...userTargets};
                delete editedTargets.website
                delete editedTargets.company_type
                checkTargets.forEach((field)=>{
                    if(userTargets[field] === null ){
                        editedTargets[field]= newTargets[field]
                    }
                })
            }

            await editTargets(editedTargets);
        }else{
            newTargets.company_type= viForm.company_size;
            store.commit('visibility/setNewTargets', newTargets);
        }

    }

    const editTargets = async(targets) =>{
        const response = await store.dispatch('visibility/editTargets', targets)

        return response
    }
    const setUrlInvalid = (url)=>{
        store.commit('visibility/setUrlInvalid', url)
    }
    const setVisibilityData = (viData)=>{
        store.commit('visibility/setVisibilityData', viData)
    }
    const setUserTargets = (targets)=>{
        store.commit('visibility/setUserTargets', targets)
    }
    const setIsViFails = (value)=>{
        store.commit('visibility/setIsViFails', value)
    }
    const getVisibility = async()=>{

        const response = await store.dispatch('visibility/getVisibility')

        return response
    }
    const sendVisibilityFail = async(form)=>{

        const response = await store.dispatch('visibility/sendVisibilityFail', form)

        return response
    }
    const removeTarget = async(field) => {
        const response = await store.dispatch('visibility/removeTarget', field)
        
        return response
    }
    const editWebsite = async(websiteUrl) => {
        const response = await store.dispatch('visibility/editWebsite', websiteUrl);

        return response;
    }

    const getOptionsToGooglePlaces = async(inputUrl) => await store.dispatch('visibility/getOptionsToGooglePlaces', inputUrl);

    return{
        visibilityData: computed(()=> store.state.visibility.visibilityData),
        viForm: computed(()=> store.state.visibility.viForm),
        urlInvalid: computed(()=> store.state.visibility.urlInvalid),
        isLoading: computed(()=> store.state.visibility.isLoading),
        isLoadingVisibility: computed(()=> store.state.visibility.isLoadingVisibility),
        userTargets: computed(()=> store.state.visibility.userTargets),
        rrssForm: computed(()=> store.state.visibility.rrssForm),
        newTargets: computed(()=> store.state.visibility.newTargets),
        isViFails: computed(()=> store.state.visibility.isViFails),
        viOthersUrl: computed(() =>store.getters['visibility/viOthersUrl'] ),
        relevantUrls: computed(() =>store.getters['visibility/relevantUrls'] ),

        getVisibility,
        postVisibility,
        saveNewTargets,
        getTargets,
        setUrlInvalid,
        setVisibilityData,
        setUserTargets,
        editTargets,
        setIsViFails,
        sendVisibilityFail,
        removeTarget,
        editWebsite,
        getOptionsToGooglePlaces
    }
}
export default useVisibility;
