<template>
  <div class="dashboard-margin">
    <div class="row justify-content-around px-0">
      <div class="col-12">
        <card header-classes="bg-transparent" body-classes="p-0 row">
          <div class="col-12 d-flex justify-content-between">
            <h4 class="margin-left-title title-plugin display-4">{{ $t('campaignReport') }}: {{ campaignName }}</h4>
            <div class="info-card margin-right-title d-flex flex-column justify-content-start align-items-end">
              <div>
                <el-tooltip placement="top" :content="$t('infoReport.headline')">
                  <img
                    src="/img/icons/stevie/info-shape.svg"
                    class="ml-1 icon-card"
                    alt="Info icon"
                  />
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="col-12">
            <base-button
              class="p-0 back-button d-flex justify-content-between px-2 margin-left-bottom-title"
              @click="backToList"
            >
              <div>
                <img src="/img/icons/stevie/back-shape.svg" class="rotateArrow mr--2" alt="Back icon">
                <img src="/img/icons/stevie/back-shape.svg" class="rotateArrow" alt="Back icon">
              </div>
              {{ $t("allCampaigns") }}
            </base-button>
          </div>
        </card>
      </div>
      <div class="col-12 col-md-8">
        <div class="row">
          <div class="col-12 col-md-6">
            <plugin-spend class="min-height-1" :invested-google="investedGoogle" :invested-facebook="investedFacebook" :total-spend="totalSpend"></plugin-spend>
          </div>
          <div class="col-12 col-md-6">
            <plugin-overall-clicks class="min-height-1" :total-clicks="totalClicks"></plugin-overall-clicks>
          </div>
          <div class="col-12 col-md-6">
            <plugin-overall-reach class="min-height-1" :total-reach="totalReach"></plugin-overall-reach>
          </div>
          <div class="col-12 col-md-6">
            <plugin-overall-impressions class="min-height-1" :total-impressions="totalImpressions"></plugin-overall-impressions>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-12">
            <plugin-google-ads class="min-height-2" :spend="googleMetrics.spend" :impressions="googleMetrics.impressions" :ctr="googleMetrics.ctr" :average_cpc="googleMetrics.average_cpc"></plugin-google-ads>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-4">
            <plugin-clicks class="min-height-3" :clicks-google="googleMetrics.clicks" :clicks-facebook="facebookMetrics.clicks" :clicks-instagram="instagramMetrics.clicks"></plugin-clicks>
          </div>
          <div class="col-12 col-md-4">
            <plugin-instagram-ads :spend="instagramMetrics.spend" :impressions="instagramMetrics.impressions" :reach="instagramMetrics.reach" :frequency="instagramMetrics.frequency" :outbound_clicks="instagramMetrics.outbound_clicks" :cpm="instagramMetrics.cpm" class="min-height-3"></plugin-instagram-ads>
          </div>
          <div class="col-12 col-md-4">
            <plugin-facebook-ads :spend="facebookMetrics.spend" :impressions="facebookMetrics.impressions" :reach="facebookMetrics.reach" :frequency="facebookMetrics.frequency" :outbound_clicks="facebookMetrics.outbound_clicks" :cpm="facebookMetrics.cpm" class="min-height-3"></plugin-facebook-ads>
          </div>
        </div>
      </div>
      <div class="col-12">
        <card header-classes="bg-transparent" body-classes="p-4 row">
          <div class="col-12">
            <div class="p-2 font-small">{{ $t('infoReport.disclaimer1') }}</div>
            <div class="p-2 font-small">{{ $t('infoReport.disclaimer2') }}</div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import useCampaigns from "@/composables/useCampaings";
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import PluginSpend from "./Components/PluginSpend.vue";
import PluginOverallClicks from "./Components/PluginOverallClicks.vue";
import PluginOverallReach from "./Components/PluginOverallReach.vue";
import PluginOverallImpressions from "./Components/PluginOverallImpressions.vue";
import PluginGoogleAds from "./Components/PluginGoogleAds.vue";
import PluginFacebookAds from "./Components/PluginFacebookAds.vue";
import PluginInstagramAds from "./Components/PluginInstagramAds.vue";
import PluginClicks from "./Components/PluginClicks.vue";
import Card from "../../../components/Cards/Card.vue";

export default {
  name: "CampaignForm",
  props: ["id"],
  components: {
    Card,
    PluginSpend,
    PluginOverallClicks,
    PluginOverallReach,
    PluginOverallImpressions,
    PluginGoogleAds,
    PluginFacebookAds,
    PluginInstagramAds,
    PluginClicks
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const { getCampaign } = useCampaigns();
    const id = ref(null)
    const campaignName = ref(null)
    const googleMetrics = ref({
      clicks: null,
      impressions: null,
      spend: null
    })
    const facebookMetrics = ref({
      clicks: null,
      cpm: null,
      frequency: null,
      impressions: null,
      outbound_clicks: null,
      reach: null,
      spend: null
    })
    const instagramMetrics = ref({
      clicks: null,
      cpm: null,
      frequency: null,
      impressions: null,
      outbound_clicks: null,
      reach: null,
      spend: null
    })
    const investedGoogle = ref(0)
    const investedFacebook = ref(0)
    const totalSpend = ref(0)
    const totalClicks = ref(0)
    const totalReach = ref(0)
    const totalImpressions = ref(0)

    const showCampaign = async (id) => {
      let response = await getCampaign(id);
      if (response && response.status === 200) {
        if (response.data.metrics.google_metrics) {
          googleMetrics.value = response.data.metrics.google_metrics
        }
        if (response.data.metrics.facebook_metrics) {
          facebookMetrics.value = response.data.metrics.facebook_metrics
        }
        if (response.data.metrics.instagram_metrics) {
          instagramMetrics.value = response.data.metrics.instagram_metrics
        }
        campaignName.value = response.data.name
        investedGoogle.value = response.data.invested_google
        investedFacebook.value = response.data.invested_facebook

        totalSpend.value = (response.data.metrics.google_metrics?.hasOwnProperty("spend")?response.data.metrics.google_metrics.spend/1000000:0) + (response.data.metrics.facebook_metrics?.hasOwnProperty("spend")?response.data.metrics.facebook_metrics.spend:0) + (response.data.metrics.instagram_metrics?.hasOwnProperty("spend")?response.data.metrics.instagram_metrics.spend:0)
        totalClicks.value = (response.data.metrics.google_metrics?.hasOwnProperty("clicks")?response.data.metrics.google_metrics.clicks:0) + (response.data.metrics.facebook_metrics?.hasOwnProperty("clicks")?response.data.metrics.facebook_metrics.clicks:0) + (response.data.metrics.instagram_metrics?.hasOwnProperty("clicks")?response.data.metrics.instagram_metrics.clicks:0)
        totalReach.value = (response.data.metrics.facebook_metrics?.hasOwnProperty("reach")?response.data.metrics.facebook_metrics.reach:0) + (response.data.metrics.instagram_metrics?.hasOwnProperty("reach")?response.data.metrics.instagram_metrics.reach:0)
        totalImpressions.value = (response.data.metrics.google_metrics?.hasOwnProperty("impressions")?response.data.metrics.google_metrics.impressions:0) + (response.data.metrics.facebook_metrics?.hasOwnProperty("impressions")?response.data.metrics.facebook_metrics.impressions:0) + (response.data.metrics.instagram_metrics?.hasOwnProperty("impressions")?response.data.metrics.instagram_metrics.impressions:0)
      }
    }

    const setTotalSpend = (googleMetrics, facebookMetrics, instagramMetrics) => {

    }

    const backToList = () => {
      router.push({ name: 'CampaignsList'})
    }

    onMounted(async() => {
      if (route.params.id) {
        id.value = route.params.id;
        await showCampaign(route.params.id);
      }
    })

    return {
      id,
      backToList,
      campaignName,
      googleMetrics,
      facebookMetrics,
      instagramMetrics,
      investedGoogle,
      investedFacebook,
      totalSpend,
      totalClicks,
      totalReach,
      totalImpressions
    };
  },
}
</script>

<style scoped lang="scss">
.icons-ads-height {
  max-height: 60px;
  &.facebook-icon-ads{
    max-height: 80px!important;
  }
}
.ad-preview-meta {
  aspect-ratio: 210px/500px;
}
.ad-preview-google {
  aspect-ratio: 210px/500px;
}
.label-light{
  color: #adb5bd;
}
.input-group .input-stevie{
  border-radius: 5px!important;
    &.no-rounded-borders{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &:disabled{
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}
.input-group {
  .input-group-append.no-btn{
    background-color: #c6dcf5;
    min-width: 43px;
    border: 1px solid #c6dcf5 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    color: #adb5bd;
  }
}
.containerSaveButton {
  height: 180px;
}
.keyword-input{
  height: auto!important;

  .keyword-input__keyword {
    height: 25px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 6px;
    line-height: 25px;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .keyword-input__keyword > span {
    cursor: pointer;
    opacity: 0.75;
  }

  .keyword-input__text {
    border: none;
    outline: none;
    line-height: 34px;
    background: none;
    width: 200px;
    max-width: 100%;
  }
  &.disabled{
    background-color: #EDF0F2!important;
    .keyword-input__keyword {
      background-color: #FFFFFF;
    }
  }
}
.regionalCheckboxText {
  text-transform: none;
}
.sliderText {
  font-size: 13px;
}
.sliderInvested {
  width: -webkit-fill-available;
  .noUi-connect {
    background: #cccccc!important;

    .noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
      background: #012454!important;

    }
  }
}
:deep().el-switch__label.is-active{
  color: #5E72E4!important;
}
:deep().min-height-textarea{
  height: 91px;
}
:deep() .textarea-block{
  height: 91px!important;
}
.small-text{
  font-size: 11px;
  opacity: 0.8;
  display: flex;
  align-items: center;
}
.word-break{
  word-break: break-word;
}

.info-errors {
    background-color: #FFF3CD;
    color: #997404 !important;

    .card-body{
      padding:0.75rem !important;
    }
    h3, .icon-title{
      color: #A16207 !important;
      margin-bottom: 0;
    }
}
.fade-enter-active,.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,.fade-leave-to {
  opacity: 0;
}
.custom-card {
  background: #ffffff;
  box-shadow: 0 0 2rem 0 rgba(136,152,170,0.15);
  padding: 20px 21px;
  border-radius: 20px;
}
.custom-report {
  background: #083B96;
  box-shadow: 0 0 2rem 0 rgba(136,152,170,0.15);
  padding: 20px 21px;
  border-radius: 20px;
}
.segment {
  margin: 0 -21px;
  padding: 22px 31px 21px;

  &.footer {
    padding-top: 29px;
    margin-bottom: -20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.highlight-segment {
    background-color: #E7F6FD;
  }
}
:deep()input[type="date"]{
  appearance: none!important;
  padding-left: 33px!important;
  background: url('/img/icons/stevie/calendar-alt.svg') no-repeat left;
  background-size: 13px;
  background-position: 9px 8px;
}
:deep()input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
.google-description{
  height: 77px !important;
  padding: 10px!important;
}
.btn-add-fields{
  background-color: transparent!important;
  color: #0373a8;
  font-size: 13px;
  border: none;
  box-shadow: none;
  padding: 0px;
  display: flex;
  align-items: center;
  &:hover, &:active, &:focus{
    color: #10305D!important;
    box-shadow: none;
  }
  .icon{
    height: 13px;
    width: 13px;
  }
}
.btn-description{
  border: none!important;
  margin-left: 5px!important;
  border-radius: 5px!important;
}
.uploader-campaigns {
  height: 150px;

  &.square-image {
    margin: 0 auto;
    aspect-ratio: 1 / 1;
  }
}
label:has(.sublabel){
  display: flex;
  flex-wrap: wrap;
  .label-text{
    padding-right: 5px;
  }
  .sublabel{
    text-transform: none;
    font-weight: 500;
  }
}
.magic-wand-styles {
  width: 1.2rem;
  height: 1.2rem;
  margin-bottom: 3px;
}
.icon-delete-video {
  position: absolute;
  top: 10px;
  right: 10px;
}

img{
  &.high-brightness {
      filter: brightness(5);
  }
}
.magicWand {
  bottom: 0.3rem;
  right: 0rem;
  z-index:9
}
.hide-scrollbar {
  overflow: hidden;
}
.min-height-1 {
  min-height: 150px;
}
.min-height-2 {
  min-height: 330px;
}
.min-height-3 {
  min-height: 370px;
}
.font-small {
  font-size: 10px;
}
@media (min-width: 768px) {
  .container-end-on {
    margin-top: 1.85rem!important;
  }
}
@media (min-width: 1440px) {
  .containerSaveButton {
    height: 95px;
  }
}
</style>
