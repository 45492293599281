<template>
  <div class="form-group">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div
      :class="[
        {
          'has-danger': !!errorMessage,
          'input-group': hasIcon,
          'input-group-alternative': alternative,
          focused: focused,
          'has-label': label || $slots.label,
          'has-append-text input-group': $slots.appendText
        },
        inputGroupClasses,
      ]"
    >
        <div
        v-if="$slots.appendText"
        class="input-group-append"
      >
        <span class="input-group-text">
          <slot name="appendText">
            
          </slot>
        </span>
      </div>
      <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
        <span class="input-group-text">
          <slot name="addonLeft">
            <i :class="addonLeftIcon"></i>
          </slot>
        </span>
      </div>
      <slot>
        <input
          v-if="type!=='textarea'"
          class="form-control"
          :name="name"
          :id="name"
          :type="type"
          onclick="type === 'date' ? this.showPicker(): null"
          :placeholder="placeholder"
          :required="required"
          :disabled="disabled"
          :value="inputValue"
          :readonly="readonly"
          @input="handleChange"
          @blur="focused = false"
          @focus="focused = true"
          :class="inputClasses"
          :min="min"
          :max="max"
          :maxLength="maxLength"
        />
        <textarea
          v-if="type==='textarea'"
          :name="name"
          :id="name"
          :placeholder="placeholder"
          :required="required"
          :disabled="disabled"
          :value="inputValue"
          :readonly="readonly"
          @input="handleChange"
          @blur="focused = false"
          @focus="focused = true"
          :class="inputClasses"
          :rows="rows"
          :maxLength="maxLength"
        ></textarea>
      </slot>
      <div
        v-if="addonRightIcon || $slots.addonRight"
        class="input-group-append"
      >
        <span class="input-group-text" :class="isClickableIcon ? 'py-0' : ''">
          <slot name="addonRight">
            <a v-if="isClickableIcon" :href="linkIcon" target="_blank">
              <i :class="addonRightIcon"></i>
            </a>
            <i v-else :class="addonRightIcon"></i>
          </slot>
        </span>
      </div>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-show="errorMessage"
      >
        {{ errorMessage }}
      </div>
    </slot>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "base-input",
  props: {
    addonRightIcon: String,
    addonLeftIcon: String,
    append: String,
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    alternative: {
      type: Boolean,
      description: "Whether input is of alternative layout",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label",
    },
    inputGroupClasses: {
      type: String,
      default: "",
    },
    group: {
      type: String,
      default: "",
    },
    inputClasses: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 10,
    },
    isClickableIcon: {
      type: Boolean,
      default: false
    },
    linkIcon: {
      type: String,
      default: ""
    },
    min: {
      type: String,
      default: ""
    },
    max: {
      type: String,
      default: ""
    },
    maxLength: {
      type: String,
      default: ""
    }
  },
  emits:["change"],
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined ||
        this.group
      );
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value,
      }
    );

    return {
      handleChange,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style scoped lang="scss">
.input-textarea-shadow{
  box-shadow: inset 0 1px 3px rgba(1, 36, 84 , 0.3) !important;
}
.min-height-textarea{
  min-height: 178px!important;
}
.has-append-text {
  .input-group-append {
    .input-group-text {
      height: 30px!important;
    }
  }
  .form-control {
    height: 32px!important;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.8;
  }
  input{
    height: 100%;
    color: #2b2b2d;
    border: 1px solid #c6dcf5;
    border-radius: 5px;
    background-color: #ffffff ;
    box-shadow: inset 0 1px 3px 0 rgba(1,36,84,0.3);

    &:disabled {
      background-color: #e9ecef!important;
      opacity: 0.8!important;
    }
  
  }

  .input-group-append, .input-group-text{
    height: 100%;
    background-color: transparent;
    background-color: rgba(234, 237, 239, 0.3);
    font-weight: 500;
    color: #012454;
    font-size: 14px!important; 
    max-width: 100%;
  }
  .input-group-append{
    border: 1px solid #c6dcf5 ;
    border-radius: 5px ;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
