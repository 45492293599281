<template>
  <modal v-model:show="isLoading" modal-classes="modal-company-size bg-transparent" body-classes="p-0" modal-content-classes="border-radius-stevie modal-animation bg-transparent shadow-none" :is-allowed-close-modal="isAllowedCloseModal">
    <card
      header-classes="bg-transparent"
      body-classes="p-0 mx-0 row"
      card-classes="bg-transparent shadow-none mb-0"
    >
      <div class="col-12 px-0">
        <div class="mw-100 mh-100 d-flex justify-content-center align-items-center mr-4">
          <fade-loader :color="colorLoader" :height="heightLoader" :width="widthLoader"></fade-loader>
        </div>
      </div>
    </card>

  </modal>
</template>

<script>
import Modal from "../../components/Modal";
import FadeLoader from "vue-spinner/src/FadeLoader.vue"
import { ref } from "vue";

export default {
  name: "SpinnerLoadingModal",
  props: {
    isLoading: Boolean,
  },
  components: {
    Modal,
    FadeLoader,
  },
  setup() {
    const colorLoader = ref("#86d3f7");
    const heightLoader = ref("20px");
    const widthLoader = ref("5px");
    const isAllowedCloseModal = ref(false);

    return {
      colorLoader,
      heightLoader,
      widthLoader,
      isAllowedCloseModal,
    };
  },
}
</script>

<style scoped>

</style>
