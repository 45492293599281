<template>
      <div class="row align-items-center">
        <div class="col-12">
          <h3 class="mb-2">{{ $t("adOverview") }}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-8 text-left pb-2 pb-sm-0 mb-2">
          <base-button
            borderRadiusLeft
            borderRadiusRight
            class="button-height-33 py-0 px-3"
            @click="saveFilters(); $router.push({ name: 'NewAd' });"
          >
            <img class="mr-1" src="/img/icons/stevie/add-shape.svg" alt="Add icon">
            {{ $t("newCampaign") }}
          </base-button>
        </div>
        <div class="col-12 col-sm-6 col-md-4 d-flex justify-content-end pb-2 pb-sm-0">
          <el-select popper-class="select-no-scroll" class="select-danger select-no-scroll" :placeholder="$t('filterBy')" v-model="statusSelected" @change="listCampaigns(1)">
            <el-option value="active" :label="$t('statusList.active')">
            </el-option>
            <el-option value="all" :label="$t('statusList.all')" >
            </el-option>
            <el-option  v-for="statusOption in statusSelect" :key="statusOption.id" :value="statusOption.name"
              :label="$t(`statusList.${statusOption.name}`)">
            </el-option>
          </el-select>
        </div>
      </div>

    <el-table
      class="table-responsive table-flush border-top-0 min-table"
      :data="campaignsData.data"
      @cell-click="cellClick"
      row-class-name="cursor-pointer"
    >
      <el-table-column prop="image" width="100px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }" >
          <div v-if="row.google_square_image || row.meta_image"  class="d-flex justify-content-end">
            <img alt="" :src="row.google_square_image || row.meta_image" class="avatar object-fit-cover"/>
          </div>
          <div v-else>
            <img src="/img/brand/default-placeholder.png" class="avatar"/>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('campaignName')" prop="description" min-width="200px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div  class="media align-items-center cursor-pointer" >
            <div class="media-body w-100 text-truncate">
              <span class="font-weight-500 name mb-0 text-sm text-dark">
                {{
                  row.name? row.name : `${$t('campaignOf')} ${$d(row.created_at, 'short')} ${$d(row.created_at, 'fullHour')}`
                }}
                </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('created')" prop="created" min-width="180px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div v-if="row.created_at"  class="align-items-center cursor-pointer">
            <div class="media-body w-100">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                  $d(row.created_at, 'longWithoutSeconds')
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('status')" prop="status" min-width="150px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div  class="flex cursor-pointer">
            <badge v-if="status[row.status]" class="badge-dot mr-4" size="lg">
              <i :class="`bg-${status[row.status].color}`"></i>
              <span  class="status font-weight-500 text-dark">{{ $t(`statusList.${status[row.status].name}`) }}</span>
            </badge>
            <i v-if="row.errors_exist" class="fas fa-exclamation-triangle icon-info-error"></i>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('actions')" min-width="230px" label-class-name="border-top-0 text-prussian-blue d-flex justify-content-end" align="right">
        <template v-slot="{ row }">
          <el-tooltip placement="top" :content="$t('report')">
            <base-button class="btn-default bg-transparent border-0 shadow-none px-2" @click="reportClick(row.id)" :disabled="!row.metrics">
              <em class="fas fa-chart-bar text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
          <el-tooltip placement="top" :content="$t('clone')">
            <base-button class="btn-default bg-transparent border-0 shadow-none px-2" @click="cloneItem(row.id)">
              <em class="fas fa-clone text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
          <el-tooltip placement="top" :content="$t('pause')">
            <base-button :disabled="row.status !== 2" class="btn-default bg-transparent border-0 shadow-none px-2" @click="manageConfirmModal(row.id, 3)">
              <em class="fas fa-pause text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
          <el-tooltip placement="top" :content="$t('start')">
            <base-button :disabled="row.status !== 3" class="btn-default bg-transparent border-0 shadow-none px-2" @click="manageConfirmModal(row.id, 2)">
              <em class="fas fa-play text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
          <el-tooltip placement="top" :content="$t('delete')">
            <base-button :disabled="row.status === 4" class="btn-default bg-transparent border-0 shadow-none px-2" @click="modalDeleteCampaign(row.id)">
              <em class="fas fa-trash text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>

    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination :modelValue="campaignsData.current_page" @update:modelValue="changePage" :total="campaignsData.total" :perPage="campaignsData.per_page"></base-pagination>
    </div>

  <ConfirmModal
    v-if="showConfirmModal"
    :showModal="showConfirmModal"
    :modalInfo="modalConfirm"
    :isLoading ="isLoading"
    @confirm="modalConfirm.confirmAction()"
    @cancel="modalConfirm.cancelAction(null)"
    @closeModal="showConfirmModal=false"
  />
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElSelect,
  ElOption
} from "element-plus";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { campaignStatus } from '@/util/Settings';
import useCampaigns from "../../../composables/useCampaings";
import ConfirmModal from "../../Components/ConfirmModal";

export default {
  name: "CampaignsList",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    ElSelect,
    ElOption,
    ConfirmModal
  },
  data() {
    return {
      infoShape: "/img/icons/stevie/info-shape.svg",
    };
  },
  setup() {
    const status = ref(campaignStatus);
    const statusSelect = ref(campaignStatus.filter( item => item.value !== 1));
    const router = useRouter();
    const route = useRoute();
    const statusSelected = ref('all');
    const showConfirmModal = ref(false)
    const changeStatusData = ref(null);

    const { campaignsData, getCampaigns, changeStatusCampaign, cloneCampaign, pausedCampaign, startedCampaign }= useCampaigns();
    const campaignIdToManage = ref(null)
    const modalConfirm = ref({});
    const isLoading = ref(false)

    const changePage = (event)=>{
      if(event !== campaignsData.value.current_page){
        listCampaigns(event)
      }
    }

    const listCampaigns = async (page = 1) => {
      await getCampaigns(page, statusSelected.value);
    }

    const saveFilters = async()=>{
      localStorage.setItem('adsFilters', JSON.stringify({page: campaignsData.value.current_page, filter_status: statusSelected.value}))
    }

    const editCampaign = (id, isClone = false) => {
      if(!isClone){
        saveFilters()
      }
      router.push({ name: 'EditAd', params: { id: id } });
    }
    const cloneItem = async (id) => {
      const response = await cloneCampaign(id)
      if (response && response.status === 200) {
        editCampaign(response.data.id, true)
      }
    }
    const modalDeleteCampaign = async (campaignId) => {
      if(campaignId){

        modalConfirm.value = {
          title:'delete',
          subtitle: 'textDelete',
          textCancel: 'no',
          textConfirm: 'yes',
          allowClose: false,
          confirmAction: deleteCampaign,
          cancelAction: modalDeleteCampaign,
        }
        campaignIdToManage.value = campaignId;
        showConfirmModal.value = true;

      }else{
        modalConfirm.value = {};
        campaignIdToManage.value = null;
        showConfirmModal.value = false;
      }
    }

    const deleteCampaign = async () => {
      isLoading.value= true;
      const response = await changeStatusCampaign(campaignIdToManage.value, 4)
      if(response && response.status === 200){
        listCampaigns(campaignsData.value.current_page)
      }
      isLoading.value= false;
      modalDeleteCampaign(null)

    }
    const manageConfirmModal = (campaignId, status) => {
      changeStatusData.value = { campaignId, status };
      let text = status === 3? 'changeStatusPaused' : 'changeStatusStarted';

      modalConfirm.value = {
        title: `${text}.title`,
        subtitle: `${text}.subtitle`,
        textCancel: `${text}.textCancel`,
        textConfirm: `${text}.textConfirm`,
        allowClose: false,
        confirmAction: confirmChangeStatus,
        cancelAction: cancelChangeStatus
      }

      showConfirmModal.value = true;
    }

    const confirmChangeStatus = async() => {
      const {status, campaignId} = changeStatusData.value;
      isLoading.value = true;

      status === 2 && await startedCampaign(campaignId, status);
      status === 3 && await pausedCampaign(campaignId, status);

      isLoading.value = false;
      showConfirmModal.value = false;
      changeStatusData.value = null;
      listCampaigns(campaignsData.value.current_page);
    }

    const cancelChangeStatus = async() => {
      showConfirmModal.value= false;
      isLoading.value = false;
      changeStatusData.value = null;
    }

    const cellClick = ({id: campaignId}, { property: columnName }) => {
      columnName && editCampaign(campaignId);
    }

    const reportClick = (campaignId) => {
      router.push({ name: 'ReportAd', params: { id: campaignId } });
    }

    onMounted(()=>{
      const { page, filter_status } = route.query;
      campaignsData.value.current_page = page ? page : 1;
      if(page || filter_status){
        statusSelected.value = filter_status ? filter_status : 'active';
        router.replace({...router.currentRoute, query: {}})
      }
        listCampaigns(campaignsData.value.current_page)
    })

    return {
      status,
      statusSelect,
      statusSelected,
      campaignsData,
      showConfirmModal,
      modalConfirm,
      isLoading,
      editCampaign,
      modalDeleteCampaign,
      cloneItem,
      deleteCampaign,
      listCampaigns,
      saveFilters,
      changePage,
      manageConfirmModal,
      pausedCampaign,
      startedCampaign,
      cellClick,
      reportClick
    };
  },
}
</script>

<style scoped lang="scss">
.el-table_1_column_4 th {
  text-align: right;
}
:deep() .el-table__body-wrapper{
  min-height: 350px!important;
}
.min-table{
  min-height: 450px;
  max-width: 99%!important;
}
.dropdown-item{
  height: 33px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover{
    color: #16181b!important;
    background-color: #f6f9fc!important;
  }
}
.icon-info-error{
  color: #FFC107;
}
</style>
