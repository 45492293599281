export const getImageUrl = (value) => {
    if(typeof value === 'string' || !value){
        return value
    } else {
        return URL.createObjectURL(value);
    }
}

export const removeNullAndEmptyStringFromObject  = (obj) => {
    Object.keys(obj).forEach(key => {
        if (obj[key] === null || obj[key] === '') {
            delete obj[key];
        }
    });

    return obj;
}

