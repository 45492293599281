/*!

=========================================================
* Vue Argon Dashboard PRO - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";
import { initFacebook } from "./composables/useInitFacebook";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'sweetalert2/dist/sweetalert2.css'
import VueAxios from "vue-axios";
import axios from "./plugins/axios.js";
import i18n from "./i18n";
import store from "./store";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { createMetaManager } from 'vue-meta'
import "./util/globalValidationsForm";


/*
We import the German language and check if it is the default language in the .env file
(more languages can be added if necessary)
*/
import { de } from "yup-locales";
import { setLocale } from "yup";
if (process.env.VUE_APP_I18N_LOCALE === "de") {
  setLocale(de);
}

initFacebook().then(startApp);

async function startApp() {
  const options = { containerClassName: "ct-notification",
  //Remove toast duplicates
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(t => t.content === toast.content).length !== 0) {
      return false;
    }
    return toast;
  } };

  const appInstance = createApp(App);

  if(process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "production"){
    Sentry.init({
      appInstance,
      environment: process.env.NODE_ENV,
      dsn: process.env.VUE_APP_SENTRY_URL,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      tracesSampleRate: 1.0,
    });
  }
  appInstance.use(router);
  appInstance.use(store);
  appInstance.use(createMetaManager())
  appInstance.use(Toast, options);
  appInstance.use(VueAxios, axios);
  appInstance.use(i18n);
  appInstance.provide("axios", appInstance.config.globalProperties.axios);
  appInstance.use(ArgonDashboard);

  await router.isReady()

  appInstance.mount("#app");
}
