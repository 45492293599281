<template>
  <modal v-model:show="hasUnsavedChanges" :is-allowed-close-modal="false" body-classes="p-0 border-radius-stevie bg-athens-gray" modal-content-classes="border-radius-stevie modal-animation">
    <div class="body">
      <div class="title">
        <h4 class="mb-3">{{$t('unsavedChanges.title')}}</h4>
        <p class="content">{{$t('unsavedChanges.subtitle')}}</p>
      </div>
      <base-button
        borderRadiusLeft
        borderRadiusRight
        @click="$emit('saveChanges')"
        class="btn-save"
        :disabled="isLoading"
      >
        <span v-if="!isLoading"> {{ $t("unsavedChanges.saveChanges") }}</span>
        <clip-loader
          v-else
          color="#ffffff"
          size="20px"
          class="pt-2"
        ></clip-loader>
      </base-button>
      <base-button
        borderRadiusLeft
        borderRadiusRight
        @click="$emit('close')"
        class="btn-link"
        :disabled="isLoading"
        link
      >
        <span> {{ $t("unsavedChanges.continueWithoutSave") }}</span>
      </base-button>

    </div>
  </modal>
</template>

<script>
import Modal from "../../components/Modal";
import { ref } from "vue";
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: "ModalUnsavedChanges",
  props: {
    hasUnsavedChanges: Boolean,
    isLoading: Boolean,
  },
  components: {
    Modal,
    ClipLoader
  },
  setup() {
    const isAllowedCloseModal = ref(false);

    return {
      isAllowedCloseModal,
    }
  },
}
</script>

<style scoped lang="scss">
  :deep().modal-dialog{
    max-width:600px!important ;
  }
  .body{
    width: 100%;
    padding-top: 63px;
    height: 353px;
    padding: 63px 24px 24px 24px;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 15px 35px rgba(0,25,117,0.1), 0px 5px 15px rgba(0,25,117,0.5);
    .title{
      width: 450px;
      color: #2b2b2d;
      font-size: 16px;
      h3{
        width: 90%;
        font-weight: 600;
      }
      .content{
        width: 80%;
      }
    }
  }
.btn-save{
  margin-top: auto;
  max-width: 242px;
  width: 100%;
}
:deep().btn-link{
  margin-top: 10px;
  color: #0373a8;
  background-color: #ffff;
  border: none;
}
</style>
