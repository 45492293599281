import store from "../store";

const useStevieAI = () => {

  const requestCampaignDataFromAi = async(payload) => {
    const response = await store.dispatch('stevieAI/getCampaignTexts', payload);

    return response;
  };
  
  return {
    requestCampaignDataFromAi
  };
};

export default useStevieAI;