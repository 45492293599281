
export default () => ({
    isLoading: false,
    mailsData:{
        data: [],
    },
    categories: {
    },
    allMails:[],
    mailPreview: null,
    mail: {},
    contactsData:{
        data:[]
    },
    allContacts: [],
    mailsTemplatesData:{
      data: [],
    },

})
