<template>
    <div id="fade-in" class="widget-side" :class="{'is-hide': isHide}">
        <div class="widget-container">
            <div class="widget-arrow" @click="isHide= !isHide">
                <img v-if="isHide" class="img-vector" src="/img/icons/stevie/widget-arrow-expand.svg" alt="">
                <img v-else class="img-vector" src="/img/icons/stevie/widget-arrow-hide.svg" alt="">
    
            </div>
            <div class="widget-text">
                <h1>{{$t('widget.title')}}</h1>
                <p>{{$t('widget.subtitle')}}</p>
                <base-button
                    block
                    borderRadius
                    class="btn-widget"
                    @click="$router.push({name:'CampaignsList'}); isHide = true"
                >
                
                    <img class="img-vector" src="/img/icons/stevie/rocket.svg" alt="">
                    {{ $t("widget.btn") }}
                </base-button>
            </div>
    
        </div>
        <div class="widget-vector">
            <img class="img-vector" src="/img/icons/stevie/comic-vector.svg" alt="">
        </div>
    </div>
</template>
<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";

export default{

    setup(props) {
        const isHide= ref(false)
        const router = useRouter()
        return{
            isHide,
        }
    }
}

</script>
<style lang="scss" scoped>
.widget-side{
    z-index: 1000;
    position: absolute;
    width: 290px;
    height: 135px;
    display: flex;
    right: 40px;
    transition: all .75s ease;
    bottom: 0;

    .widget-container{
        border-radius: 20px;
        background-image: linear-gradient(140deg, #8dfd36 0%, #369b8d 72%, #308bbd 100% );
        background-position: center center;
        background-size: cover;
        box-shadow: 0px 15px 35px rgba(0,25,117,0.1), 0px 5px 15px rgba(1,36,84,0.3);
        display: flex;
        align-items: center;
        padding:10px ;
        .widget-arrow{
            border-right: 1px solid #0373a8;
            height: 80%;
            margin-right: 5px;
            cursor: pointer;
            width: 20px;
            display: flex;
            align-items: center;
            .img-vector{
                height: 13px;
                width: 14px;
            }

        }
        .widget-text{
            width: 248px ;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1{
                font-size: 15px;
                padding: 5px;
                margin: 0px;
            }
            p{
                font-size: 13px;
                padding: 5px;
                margin: 0px;
                color: #012454;
                font-weight: 500;
            }
            .btn-widget{
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 210px;
                height: 33px;
                .img-vector{
                    margin-right: 5px;
                    height: 14px;
                    width: 14px;
                }
            }
    
        }
    }
    .widget-vector{
        .img-vector{
            margin-top: 80px;
            margin-left: -0.5px;
        }
    }
    &.is-hide{
        right: 0;
        width: 45px;
        

        .widget-container{
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
        }
        .widget-text, .widget-vector{
            display: none;
        }
    }
    
}

</style>