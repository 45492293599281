<template>
  <card
    header-classes="bg-transparent"
    body-classes="p-0 row"
    card-classes="card-need-account"
  >
    <div class="col-12 margin-left-title d-flex justify-content-between">
      <p class="mb-0 card-text font-size-content font-weight-600 ">
        {{ $t("youNeedAccount") }}
      </p>
    </div>
    <div class="col-12">
      <base-button
        block
        borderRadius
        class="button-height-33 share-button-vi text-truncate"
        @click="$emit('register-modal')"
      >
        {{ $t("30DaysFreeTrial") }}
      </base-button>
    </div>
  </card>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "PluginNeedAccount",
  setup() {
    const showInfo = ref(false);
    return {
      showInfo,
    };
  },
};
</script>

<style scoped lang="scss">
.card-need-account {
  background-color: #8dfd36 !important;
  height: 115px;
  border-radius: 20px;
  padding: 0px 15px;
  .font-size-content {
    font-size: 14px;
  }
  .share-button-vi {
    font-size: 13px;
    padding: 0 10px!important;
  }
}
.blur {
  filter: blur(5px);
}
</style>
