<template>
  <modal
    :show="true"
    :is-allowed-close-modal="false"
    body-classes="p-5 border-radius-stevie magic-wand-modal"
    modal-content-classes="border-radius-stevie modal-animation"
  >
    <section class="d-flex">
      <div>
        <h2 class="titleModal">
          {{ $t("magicWandForm.header") }}
          <span class="text-secondary-optional ml-1">({{ $t('optional') }})</span>
        </h2>
        <p class="Paragraph">{{ $t('magicWandForm.paragraph') }}</p>
      </div>

      <div>
        <button
          type="button"
          class="close cursor-pointer close-btn"
          :class="{'disabled-btn': isLoading}"
          @click="setUpLater()"
          aria-label="Close"
        >
          <span>×</span>
        </button>
      </div>
    </section>

    <form>
      <section class="row">
        <div class="col-12 col-lg-3">
          <label>
            <span class="d-block mb-auto">{{ $t("magicWandForm.title.language") }}</span>
          </label>
          <base-input name="language">
            <el-select
              :disabled="isLoading"
              :placeholder="$t('select.language')"
              v-model="magicWandForm.language"
              @change="changeHandlerEmitter('language', $event)"
            >
              <el-option
                v-for="{id, value, label} in optionsLanguagesWand"
                :key="id"
                :value="value"
                :label="$t(`languageValues.${label}`)"
              />
            </el-select>
          </base-input>
          <small
            v-if="isShowValidationsErrors && !magicWandForm.language"
            class="text-danger position-absolute mt--4"
          >
            {{ $t("formValidations.required") }}
          </small>
        </div>
        <div class="col-12 col-lg-3">
          <label>
            <span class="d-block mb-auto">{{ $t("magicWandForm.title.language_style") }}</span>
          </label>
          <base-input name="language_style">
            <el-select
              :disabled="isLoading"
              :placeholder="$t('select.languageStyle')"
              v-model="magicWandForm.language_style"
              @change="changeHandlerEmitter('language_style', $event)"
            >
              <el-option
                v-for="{id, value, label} in optionsLanguagesStylesWand"
                :key="id"
                :value="value"
                :label="$t(`languageStyleValues.${label}`)"
              />
            </el-select>
          </base-input>
          <small
            v-if="isShowValidationsErrors && !magicWandForm.language_style"
            class="text-danger position-absolute mt--4"
          >
            {{ $t("formValidations.required") }}
          </small>
        </div>
        <div class="col-12 col-lg-6">
          <label>
            <span class="d-block mb-auto">
              {{ $t("magicWandForm.title.expected_goal") }}
              <span class="required-label">*</span>
            </span>
          </label>
          <base-input name="language">
            <el-select
              :disabled="isLoading"
              :placeholder="$t('select.expectedGoal')"
              v-model="magicWandForm.expected_goal"
              @change="changeHandlerEmitter('expected_goal', $event)"
            >
              <el-option
                v-for="{id, value, label} in optionsExpectedGoalWand"
                :key="id"
                :value="value"
                :label="$t(`expectedGoalValues.${label}`)"
              />
            </el-select>
          </base-input>
          <small
            v-if="isShowValidationsErrors && !magicWandForm.expected_goal"
            class="text-danger position-absolute mt--4"
          >
            {{ $t("formValidations.required") }}
          </small>
        </div>
      </section>

      <section class="row">
        <div class="col-12 col-lg-6">
          <div class="min-h-label">
            <label class="d-block mb-auto">
              {{ $t("url") }}
              <el-tooltip placement="top" :content="$t('walletInfo')">
                <img
                  src="/img/icons/stevie/info-shape.svg"
                  class="icon-card mx-1"
                  alt="Info icon"
                />
              </el-tooltip>
              <span class="required-label">*</span>
            </label>
          </div>
          <base-input name="url" group>
            <div>
              <Field name="url">
                <input
                  :disabled="isLoading"
                  :placeholder="$t('campaignForm.placeholder.website')"
                  v-model="magicWandForm.url"
                  type="text"
                  class="input-stevie form-control w-100"
                  aria-describedby="button-addon2"
                  :class="{'has-danger': markAsInvalid(magicWandForm.url=== null || magicWandForm.url=== '' || isInvalidUrl )}"
                  @blur="handleBlurUrl($event)"
                  @input="changeHandlerEmitterUrlSpecify($event)"
                >
              </Field>
            </div>
          </base-input>
          <small
            v-if="isShowValidationsErrors && !magicWandForm.url"
            class="text-danger position-absolute mt--4"
          >
            {{ $t("formValidations.required") }}
          </small>
          <small
            v-if="magicWandForm.url && isInvalidUrl"
            class="text-danger position-absolute mt--4"
          >
            {{$t('formValidations.urlValid')}}
          </small>
        </div>
        <div class="col-12 col-lg-6">
          <div class="min-h-label">
            <label class="d-block mb-auto">
              {{ $t("magicWandForm.title.advertised_content") }}
              <span class="required-label">*</span>
            </label>
          </div>
          <base-input
            alternative
            name="advertised_content"
            type="text"
            input-classes="input-stevie"
            :value="magicWandForm.advertised_content"
            :placeholder="$t('enterText')"
            :disabled="isLoading"
            :rows="5"
            @input="changeHandlerEmitter('advertised_content', $event.target.value)"
          />
          <small
            v-if="isShowValidationsErrors && !magicWandForm.advertised_content"
            class="text-danger position-absolute mt--4"
          >
            {{ $t("formValidations.required") }}
          </small>
        </div>
        <div class="col-12 col-lg-6">
          <div class="min-h-label">
            <label class="d-block mb-auto">{{ $t("magicWandForm.title.more_about") }}</label>
          </div>
          <base-input
            alternative
            name="more_about"
            type="textarea"
            input-classes="form-control input-textarea-shadow textarea-block"
            label-classes="input-placeholder-stevie"
            :disabled="isLoading"
            :value="magicWandForm.more_about"
            :placeholder="$t('enterText')"
            :rows="5"
            @input="changeHandlerEmitter('more_about', $event.target.value)"
          />
        </div>
        <div class="col-12 col-lg-6">
          <div class="min-h-label">
            <label class="d-block mb-auto">{{ $t("magicWandForm.title.key_features") }}</label>
          </div>
          <base-input
            alternative
            name="key_features"
            type="textarea"
            input-classes="form-control input-textarea-shadow textarea-block"
            label-classes="input-placeholder-stevie"
            :disabled="isLoading"
            :value="magicWandForm.key_features"
            :placeholder="$t('magicWandForm.placeholder.key_features')"
            :rows="5"
            @input="changeHandlerEmitter('key_features', $event.target.value)"
          />
        </div>
      </section>

      <section>
        <div class="min-h-label">
          <label class="mb-auto mr-1">
            {{ $t("magicWandForm.title.keywords") }}
            <span class="required-label">*</span>
          </label>
          <el-tooltip placement="top" :content="$t('magicKeywordsInfo')">
            <img
              src="/img/icons/stevie/info-shape.svg"
              class="icon-card ml-1"
              alt="Info icon"
            />
          </el-tooltip>
        </div>
        <div
          class="keyword-input input-stevie cursor-pointer"
          :class="{'disabled': isLoading}"
        >
          <div
            v-for="(keyword, index) in magicWandForm.keywords"
            :key="index"
            @click="deleteKeyword(index)"
            class="keyword-input__keyword"
            :class="{'disabled-btn': isLoading}"
          >
            <span :class="{'disabled-btn': isLoading}" class="pr-1">x</span>{{ keyword }}
          </div>
          <input
            v-model.trim="keyInput"
            type="text"
            class="keyword-input__text textarea-header"
            pattern="/^[0-9a-zA-Z]+$/"
            :disabled="isLoading"
            :class="{'disabled-btn': isLoading}"
            :placeholder="magicWandForm.keywords.length >= 8 ?'' : $t('enterKeyword')"
            @keydown.tab.prevent="addKeyword"
            @keypress.enter="addKeyword"
          />
        </div>
        <small
          v-if="isShowValidationsErrors && magicWandForm.keywords && magicWandForm.keywords.length === 0"
          class="text-danger mt--4"
        >
        {{ $t("formValidations.required") }}
      </small>
      </section>

      <section class="row mt-4">
        <div class="col my-2 d-flex justify-content-between align-items-center flex-wrap">
          <div class="col-12 col-lg-5 col-xl-3 mb-3 mb-lg-0 d-flex align-items-center justify-content-center justify-content-lg-start pl-lg-0">
            <span
              @click="setUpLater()"
              class="setUpLaterBtn cursor-pointer py-2 px-4"
              :class="{'disabled-btn text-disabled': isLoading}"
            >
              {{ $t("setUpLater")}}
            </span>
          </div>

          <base-button
            borderRadiusLeft
            borderRadiusRight
            @click="getTextsToAI()"
            class="col-12 col-lg-6 p-0 start-campaign-button"
            :disabled="isLoading"
          >
            <el-tooltip
              v-if="isLoading"
              :visible="true"
              placement="top"
              :content="$t('magicWandLoading')"
            >
              <span class="d-flex align-items-center justify-content-center">
                {{ $t(fieldSelected? "fillField" : "designCampaign" ) }}
                <clip-loader color="#ffffff" size="20px" class="pt-2 pl-2" />
              </span>
            </el-tooltip>
            
            <span v-else class="d-flex align-items-center justify-content-center">
              {{ $t(fieldSelected? "fillField" : "designCampaign" ) }}
            </span>
          </base-button>
      </div>
      </section>
    </form>
  </modal>
</template>

<script>
import { ref, toRaw } from "vue";
import { ElSelect, ElOption } from "element-plus";
import Modal from "../../../../components/Modal.vue";
import BaseButton from "../../../../components/BaseButton.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { optionsLanguagesWand, optionsLanguagesStylesWand, optionsExpectedGoalWand } from "../../../../util/Settings";
import useForms from '@/composables/useForms';
import { formatWebsite } from '../../../../composables/useFormatUrl';
import { Field } from 'vee-validate';

export default {
  name: "MagicWandConfig",
  components: {
    Modal,
    Field
  },
  emits:["close-modal"],
  props: ["magicWandData", "fieldSelected", "isLoading"],
  setup( props, { emit }) {
    const { markAsInvalid, isValidLink } = useForms();
    const magicWandForm = ref(toRaw(props.magicWandData));
    const keyInput = ref(null);
    const isShowValidationsErrors = ref(false);
    const MAX_KEYWORDS = 8;
    const isInvalidUrl = ref(false);

    // Methods
    const setUpLater = () => {
      if(props.isLoading) return;

      cleanFields();
      emit("close-modal");
    }

    const changeHandlerEmitter = (field, value) => {

      emit('data-edited', [ field, value ]);
    }

    const changeHandlerEmitterUrlSpecify = (event) => {
      emit('data-edited', ['url', event.target.value]);
    };

    const addKeyword = () => {
      if(!keyInput.value || keyInput.value === '' || magicWandForm.value.keywords.length >= MAX_KEYWORDS){
        keyInput.value = null;
        return;
      }

      let keyword = keyInput.value.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '');

      magicWandForm.value.keywords.push(keyword);
      keyInput.value = null;
    }

    const cleanFields = () => Object.keys(props.magicWandData).forEach(key => props.magicWandData[key] ||= null);

    const deleteKeyword = (index) => {
      if (props.isLoading) return;
      magicWandForm.value.keywords.splice(index, 1)
    }

    const getTextsToAI = () => {
      const requiredFields = ["language", "language_style", "url", "expected_goal", "advertised_content", "keywords"];
      const magicWandRaw = toRaw(magicWandForm.value);

      for (const key in magicWandForm.value) {
        if (requiredFields.includes(key)) magicWandForm.value[key] ||= "";
      }

      const allRequiredFieldsFilled = requiredFields.every( requiredField => Array.isArray(magicWandRaw[requiredField])? magicWandRaw[requiredField].length > 0: magicWandRaw[requiredField] );


      if (allRequiredFieldsFilled && isValidLink(magicWandForm.value.url)) {
        emit('get-texts-by-ia', props.fieldSelected);
      } else {
        isShowValidationsErrors.value = true;
      }
    }

    const handleBlurUrl = (event) => {
      const url = formatWebsite(event.target.value);
      isInvalidUrl.value = !isValidLink(url);

      emit('data-edited', ['url', url]);
    }

    return {
      addKeyword,
      BaseButton,
      changeHandlerEmitter,
      ClipLoader,
      deleteKeyword,
      ElOption,
      ElSelect,
      getTextsToAI,
      isShowValidationsErrors,
      keyInput,
      magicWandForm,
      optionsLanguagesStylesWand,
      optionsExpectedGoalWand,
      optionsLanguagesWand,
      setUpLater,
      markAsInvalid,
      isValidLink,
      changeHandlerEmitterUrlSpecify,
      handleBlurUrl,
      isInvalidUrl
    }
  },
};
</script>

<style scoped>

.titleModal {
  font-size: 1.5rem;
}
.Paragraph {
  font-size: 0.8rem;
}
.setUpLaterBtn {
  font-size: 0.9rem;
}
.min-h-label {
  min-height: 2rem;
}
.text-disabled {
  filter: contrast(0);
}
.close-btn {
  font-size: 1.5rem;
}
</style>

<style lang="scss">
.required-label {
  font-size: 15px;
  color: red;
}
.magic-wand-modal {
    scrollbar-width: none;  /* Firefox */
}
.magic-wand-modal::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
.magic-wand-modal {
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
}
.input-stevie {
  &.keyword-input {
    height: 100%!important;
  }
}
.keyword-input{
  height: 100%!important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;

  .keyword-input__keyword {
    height: 25px;
    background-color: #eee;
    line-height: 25px;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .keyword-input__keyword > span {
    cursor: pointer;
    opacity: 0.75;
  }

  .keyword-input__text {
    border: none;
    outline: none;
    line-height: 34px;
    background: none;
    display: flex;
    flex-grow: 1;
    max-width: 100%;
  }
  &.disabled{
    background-color: #EDF0F2!important;
    .keyword-input__keyword {
      background-color: #FFFFFF;
    }
  }
}
@media (min-width: 780px) {
  .modal-dialog {
    max-width: 75% !important;
  }
}
</style>
