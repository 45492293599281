export class TemplateFormClass {
    constructor(template){
        template = {...template, ...template.template};

        this.id = template.id ||= null;
        this.name = template.name ? template.name : null;
        this.status = template.status ? template.status : 0;
        this.background = template.background ? template.background : "#ffffff";
        this.categories = template.categories ? template.categories.map((item) => {
          return item.id
        }) : null;

        this.image_header_file = template.image_header && template.image_header.url ? template.image_header.url : null;
        this.image_header_align= template.image_header && template.image_header.align ? template.image_header.align : "center";

        this.title_text = template.title && template.title.text ? template.title.text : null;
        this.title_color = template.title && template.title.color ? template.title.color : "#2b2b2d";
        this.title_align = template.title && template.title.align ? template.title.align : "left";
        this.title_font = template.title && template.title.font ? template.title.font : "Arial";
        this.title_font_size = template.title && template.title.font_size ? template.title.font_size : "32";

        this.subtitle_text = template.subtitle && template.subtitle.text ? template.subtitle.text : null;
        this.subtitle_color = template.subtitle && template.subtitle.color ? template.subtitle.color : "#2b2b2d";
        this.subtitle_align = template.subtitle && template.subtitle.align ? template.subtitle.align : "left";
        this.subtitle_font = template.subtitle && template.subtitle.font ? template.subtitle.font : "Arial";
        this.subtitle_font = template.subtitle && template.subtitle.font ? template.subtitle.font : "Arial";
        this.subtitle_font_size = template.subtitle && template.subtitle.font_size ? template.subtitle.font_size : "26";



        this.footer_text= template.footer && template.footer.text ? template.footer.text : null;
        this.footer_background = template.footer && template.footer.background ? template.footer.background : "#666666";
        this.footer_color = template.footer && template.footer.color ? template.footer.color : "#e2e6ea";
        this.footer_align = template.footer && template.footer.align ? template.footer.align : "center";
        this.footer_font = template.footer && template.footer.font ? template.footer.font : "Arial";
        this.footer_font_size = template.footer && template.footer.font_size ? template.footer.font_size : "14";

        if(!template.blocks || template.blocks.length === 0){
            this.blocks = [new BlockFormClass({})]
        }else{
            this.blocks = template.blocks.map(item=>{
                if(template.blocks.length === 0){
                    return new BlockFormClass({})
                }else{
                    return new BlockFormClass(item)
                }
            });
        }

    }
}
export class BlockFormClass{
    constructor(block){
        this.id = block.id ? block.id : null;
        this.image_url = block.image && block.image.url ? block.image.url : null;
        this.image_align = block.image && block.image.align ? block.image.align : "center";

        this.text_text = block.text && block.text.text ? block.text.text : null;
        this.text_color = block.text && block.text.color ? block.text.color : "#2b2b2d";
        this.text_align = block.text && block.text.align ? block.text.align : "left";
        this.text_font = block.text && block.text.font ? block.text.font : "Arial";
        this.text_font_size = block.text && block.text.font_size ? block.text.font_size : "14"

        this.link_text = block.link && block.link.text ? block.link.text : null;
        this.link_font = block.link && block.link.font ? block.link.font : "Arial";
        this.link_url = block.link && block.link.url ? block.link.url : null;
        this.link_background = block.link && block.link.background ? block.link.background : "#86d3f7";
        this.link_color = block.link && block.link.color ? block.link.color : "#ffffff";
        this.link_align = block.link && block.link.align ? block.link.align : "center";
        this.link_font_size = block.text && block.link.font_size ? block.link.font_size : "14"

    }
}
export class TemplateClass{
    constructor(template){
        this.name = template.name ? template.name : null;
        this.subject = template.subject ? template.subject : null;
        this.status = template.status ? template.status : 0;
        this.image_header = (typeof template.image_header_file === 'string') ? null :  template.image_header_file;
        this.blocks_images = []
        this.template = {
            background: template.background ? template.background : "#ffffff",
            categories: template.categories ? template.categories : null,
            image_header:{
                url: (typeof template.image_header_file === 'string') ? template.image_header_file : null,
                align: template.image_header_align ? template.image_header_align : null,
            },
            title:{
                text: template.title_text ? template.title_text : null,
                color: template.title_color ? template.title_color : "#2b2b2d",
                align: template.title_align ? template.title_align : "left",
                font: template.title_font ? template.title_font : "Arial",
                font_size: template.title_font_size ? template.title_font_size : "18",
            },
            subtitle:{
                text: template.subtitle_text ? template.subtitle_text : null,
                color: template.subtitle_color ? template.subtitle_color : "#2b2b2d",
                align: template.subtitle_align ? template.subtitle_align : "left",
                font: template.subtitle_font ? template.subtitle_font : "Arial",
                font_size: template.subtitle_font_size ? template.subtitle_font_size : "16",
            },
            footer:{
                text:template.footer_text ? template.footer_text : null,
                background: template.footer_background ? template.footer_background : "#666666",
                color: template.footer_color ? template.footer_color : "#e2e6ea",
                align: template.footer_align ? template.footer_align : "center",
                font: template.footer_font ? template.footer_font : "Arial",
                font_size: template.footer_font_size ? template.footer_font_size : "14",
            }
        }
        if(!template.blocks || template.blocks.length === 0){
            this.template.blocks = [new BlockClass({})]
        }else{
            this.template.blocks = template.blocks.map((item, index)=>{
                this.blocks_images[index]= (typeof item.image_url !== 'string') ? item.image_url : null;
                return new BlockClass(item)
            });
        }
        this.template = JSON.stringify(this.template)
    }
}
export class BlockClass{
    constructor(block){
        this.id = block.id ? block.id : null;
        this.image = {
            url: (typeof block.image_url === 'string') ? block.image_url : null,
            align: block.image_align ? block.image_align : "center",
        };
        this.text = {
            text: block.text_text ? block.text_text : null,
            color: block.text_color ? block.text_color : "#2b2b2d",
            align: block.text_align ? block.text_align : "left",
            font: block.text_font ? block.text_font : "Arial",
            font_size: block.text_font_size ? block.text_font_size : "14",
        }
        this.link = {
            text: block.link_text ? block.link_text : null,
            url: block.link_url ? block.link_url : null,
            background: block.link_background ? block.link_background : "#86d3f7",
            color: block.link_color ? block.link_color : "#ffffff",
            align: block.link_align ? block.link_align : "center",
            font: block.link_font ? block.link_font : "Arial",
            font_size: block.link_font_size ? block.link_font_size : "14",
        }
    }
}

export class CampaignTemplateClass{
    constructor(campaignTemplate){
        this.id = campaignTemplate.id ||= null;
        this.url = campaignTemplate.url ||= null;
        this.meta_headline = campaignTemplate.meta_headline ||= null;
        this.meta_url_description = campaignTemplate.meta_url_description ||= null;
        this.meta_text = campaignTemplate.meta_text ||= null;
        this.meta_image = campaignTemplate.meta_image ||= null;
        this.google_headline= campaignTemplate.google_headline? JSON.parse(campaignTemplate.google_headline) : null;
        this.google_description= campaignTemplate.google_description? JSON.parse(campaignTemplate.google_description) : null;
        this.google_square_image= campaignTemplate.google_square_image ||= null}
}