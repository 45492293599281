import { computed, provide, ref } from "vue";
import { useStore } from "vuex";

//TODO: change name of composablo to useLogin

const loggedIn = () => {
  const store = useStore();
  
  const setIsLoggedIn = (isLoggedIn)=>{
    store.commit('visibility/setIsLoggedIn', isLoggedIn)
  }
  const logout = ()=>{
    setIsLoggedIn(false);
    localStorage.removeItem("user");
    localStorage.removeItem("api_token");
    localStorage.removeItem("targets");
    localStorage.removeItem("visibilityData");
    localStorage.removeItem("targetsForm");
    store.commit('visibility/cleanUserData')
    store.commit('user/cleanUserData')
  }

  return {
    isLoading: computed(()=> store.state.visibility.isLoading),
    isLoggedIn: computed(()=> store.state.visibility.isLoggedIn),
    setIsLoggedIn,
    logout
  };
};

export default loggedIn;
