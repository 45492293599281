<template>
  <div
    class="sidenav mb-4 mb-xl-0 navbar navbar-vertical navbar-expand-xs navbar-light bg-white border-radius-stevie stevie-sidebar overflow-hidden sidenav-min-height sidenav-margin-right"
    @mouseenter="$sidebar.onMouseEnter()"
    @mouseleave="$sidebar.onMouseLeave()"
  >
    <div class="scrollbar-inner" ref="sidebarScrollArea">
      <div class="d-flex justify-content-end">
        <badge class="light-badge" v-if="userData.subscription">
          <span v-if="userData.subscription.name">{{$t(`subscriptionsTypes.${userData.subscription.name}`)}}</span>
          <span v-else >Free Trial</span>
        </badge>
      </div>
      <div v-if="isLoggedIn" class="row justify-content-center mx-0 mt-2">
        <div class="col-12 d-flex justify-content-center">
          <img v-if="userData.profile_photo_url" :src="userData.profile_photo_url" class="img-profile" alt="Sidebar logo" />
          <img v-else :src="defaultProfilePhoto" class="img-profile" alt="Sidebar logo" />
        </div>
        <div class="col-12 text-center username">
          <h3 class="mb--1">{{ userData.name }}</h3>
          <span v-if="userData.last_login" class="text-prussian-blue">{{ $t("lastLogin") }}: {{ $d(new Date(userData.last_login), 'short') }}</span>
        </div>
      </div>
      <slot></slot>
      <div class="navbar-inner">
        <ul class="navbar-nav">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </ul>
        <slot name="links-after"></slot>
      </div>
      <div v-if="isLoggedIn" class="text-center position-absolute bottom-0 left-0 right-0 mb-4">
        <base-button outline is="button" class="btn-logout font-weight-500" @click="logoutSession"
          >{{ $t("logout") }}
          <img
            src="/img/icons/stevie/logout-shape.svg"
            class="ml-2"
            alt="Logout icon"
          />
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import useLoggedIn from "../../composables/useLoggedIn"
import useUserData from "../../composables/useUserData"

export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "Creative Tim",
      description: "Sidebar title",
    },
    shortTitle: {
      type: String,
      default: "CT",
      description: "Sidebar short title",
    },
    logo: {
      type: String,
      default: "/img/brand/stevie.png",
      description: "Sidebar app logo",
    },
    defaultProfilePhoto: {
      type: String,
      default: "/img/brand/default-profile-photo.jpg",
      description: "Image profile",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these.",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  setup() {
    const router = useRouter();
    const screenWidth = ref(window.screen.width);
    const { isLoggedIn, logout }= useLoggedIn();
    const { changeUserData, userData }= useUserData();

    const logoutSession = async (e) => {
      const route = "auth/logout";
      await axios.get(route);
      logout()
      await router.push({ name: "Login" });
    };

    const displaySidebar = () => {
      let docClasses = document.body.classList;
      if (window.innerWidth >= 1200) {
        docClasses.add("g-sidenav-pinned");
        docClasses.add("g-sidenav-show");
        docClasses.remove("g-sidenav-hidden");
      } else {
        docClasses.add("g-sidenav-hidden");
        docClasses.remove("g-sidenav-pinned");
        docClasses.remove("g-sidenav-show");
      }
    };

    window.addEventListener("resize", () => {
      screenWidth.value = window.screen.width;
    });

    return {
      isLoggedIn,
      userData,
      changeUserData,
      screenWidth,
      logout,
      displaySidebar,
      logoutSession,
    };
  },
  watch: {
    isLoggedIn: function () {
      if (localStorage.getItem("api_token")) {
        this.changeUserData(JSON.parse(localStorage.getItem("user")));
      }
    },
    $route: {
      immediate: true,
      handler: function () {
        this.displaySidebar();
      },
    },
    screenWidth: function () {
      this.displaySidebar();
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {},
  mounted() {
    this.displaySidebar();
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>

<style scoped>
.sidenav-min-height {
  min-height: 650px;
  margin-bottom: 0;
}
.stevie-sidebar .light-badge{
  width: auto;
}
.sidenav-margin-right {
  margin-right: 22px;
  max-width: none;
  width: auto;
}
</style>
