<template>
 <modal v-model:show="isOpenModal" :is-allowed-close-modal="true" body-classes="p-0 border-radius-stevie bg-athens-gray" modal-content-classes="border-radius-stevie modal-animation">
    <div class="p-4">
      <h3>{{$t('limitSubscriptionModal.title')}}</h3>
      <hr class="my-3" />
      <card header-classes="bg-transparent" body-classes="px-3 row">
        <div class="col-12 d-flex align-items-center">
          <p class="m-0 mr-auto">{{ $t('limitSubscriptionModal.message', [$t(suscriptionName)]) }}</p>
        </div>
      </card>
      <div class="col-12 d-flex justify-content-around px-0">
        <base-button
          borderRadiusLeft
          borderRadiusRight
          class="py-2 px-4"
          @click="changeSuscriptionModal(false)"
        >{{ $t('ok') }}</base-button>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from "../../components/Modal";
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import useSubscription from '@/composables/useSubscription';

export default {
  name: "LimitSubscription",
  props: {
    limit: Object
  },
  emits:['closeModal'],
  components: {
    Modal,
  },
  setup() {
    const { isOpenSubscriptionModal, changeSuscriptionModal, subscriptionRequired }= useSubscription()
    const { t }= useI18n()

    const suscriptionName = computed(()=>{
      return t(`subscriptionsTypes.${subscriptionRequired.value}`)
    })
    const isOpenModal = computed(()=>{
      return isOpenSubscriptionModal.value
    })

    return {
      isOpenModal,
      changeSuscriptionModal,
      suscriptionName
    }
  },
}
</script>

<style scoped>

</style>
