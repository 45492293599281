import axios from "axios";

export const getUserData = async ({ commit }) => {
    commit('setIsLoading', true)
    const response = await axios.get('/user');
    if(response && response.status === 200){
        commit('setUserData', response.data);
    }
    commit('setIsLoading', false)

    return response

}
export const saveUserData = async ({ commit }, user) => {
    const response = await axios.put('/user/update', user);
    if(response && response.status === 200){
        commit('setUserData', response.data);
    }
    return response

}
export const cancelSubscription = async ({ commit }, subscription) => {

    const response = await axios.post('/user/subscriptions/cancel-subscription', subscription);

    return response

}
export const subscribe = async ({ commit }, subscription) => {

    const response = await axios.post('/user/subscriptions/payment-link', subscription);
    if( response.status === 200 && response.data.url){

        window.location.href = response.data.url;
    }
    return response

}
export const confirmSubscription = async ({ commit }, subscription) => {

  const response = await axios.post('/user/subscriptions/confirm-subscription', subscription)

  return response

}
export const getWalletData = async ({ commit }, page) => {
    commit('setIsLoading', true)
    const response = await axios.get(`/user/wallets?page=${page}`);

    if(response && response.status === 200){
        commit('setWallet', response.data);
    }
    commit('setIsLoading', false)

    return response

}
export const getTotalAvailable = async ({ commit }) => {
    commit('setIsLoading', true)
    const response = await axios.get('/user/wallets/total-available');

    if(response && response.status === 200){
        commit('setWalletTotal', response.data);
    }
    commit('setIsLoading', false)

    return response

}
export const rechargeWallet = async ({ commit }, params) => {
    const response = await axios.post('/user/wallets/payment-link', params);
    if( response && response.status === 200 && response.data.url){

        window.location.href = response.data.url;
    }

    return response

}

