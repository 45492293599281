<template>
   <div class="row align-items-center">
        <div class="col-12">
          <h3 class="mb-2">{{ $tc("transaction", 1) }}</h3>
        </div>
        <div class="col-12 text-left pb-2 pb-sm-0 my-2">
          <base-button
              borderRadiusLeft
              borderRadiusRight
              class="py-1 px-3"
              :disabled="!hasSubscription && !inGracePeriod"
              @click="showRechargeModal = true"
          >
              <img class="mr-1" src="/img/icons/stevie/add-shape.svg" alt="Add icon">
              {{ $t("credits") }}
          </base-button>
      </div>
  </div> 

  <el-table
    class="table-responsive table-flush border-top-0 min-table"
    :data="wallet.data"
  >
    <el-table-column
      prop="name"
      label-class-name="border-top-0 text-prussian-blue"
      width="70px"
    >
      <template v-slot="{ row }">
          <div class="d-flex justify-content-center">
            <el-tooltip placement="top" :content="$t(`walletState.${row.name}`)">
             <i :class="iconState[row.name]" class="fa-1x"></i>
            </el-tooltip>
          </div>
      </template>
    </el-table-column>

    <el-table-column
      :label="$t('walletTable.date')"
      prop="date"
      width="150px"
      label-class-name="border-top-0 text-prussian-blue"

    >
      <template v-slot="{ row }">
        <div class="media align-items-center">
          <div class="media-body">
            <span class="font-weight-500 name mb-0 text-sm text-dark">
              {{$d(row.added_at , 'short')  }}
            </span>
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column
      :label="$t('walletTable.detail')"
      prop="detail"
      min-width="200px"
      label-class-name="border-top-0 text-prussian-blue"
    >
      <template v-slot="{ row }">
        <div class="media align-items-center">
          <div v-if="row.detail.stripe" class="media-body text-truncate">
            <strong class="font-weight-500 name mb-0 text-sm text-dark text-truncate">
             {{$t('stripeTransaction')}}:
            </strong>
             {{ row.detail.stripe }}
          </div>
          <div v-if="row.campaign" class="media-body text-truncate">
            <strong class="font-weight-500 name mb-0 text-sm text-dark text-truncate">
             {{$t('campaign')}}:
            </strong>
            <button 
              class="btn-link" 
              @click="goToCampaign(row.campaign.id)" 
            >{{row.campaign.name}}</button>
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column
      :label="$t('walletTable.amount')"
      min-width="100px"
      label-class-name="d-flex justify-content-end border-top-0 text-prussian-blue"
      prop="amount"
      align="right"
    >
      <template v-slot="{ row }">
        <div class="media align-items-center">
          <div class="media-body">
            <span class="font-weight-500 name mb-0 text-sm text-dark">
              {{ $n(row.amount, 'currency') }}
            </span>
          </div>
        </div>
      </template>
    </el-table-column>

  </el-table>

  <div class="card-footer d-flex flex-column justify-content-around align-items-end">
    <div class="pb-4 d-flex justify-content-end">
       <h3 class="pr-3">{{$t("totalAvailable")}}:</h3>
        <span class="wallet-total">{{ $n(walletTotal, 'currency') }}</span>
    </div>
    <base-pagination
      :modelValue="wallet.current_page"
      :total="wallet.total"
      :perPage="wallet.per_page"
      @update:modelValue="changePage"
    ></base-pagination>
  </div>
  <RechargeWallet :showModal="showRechargeModal" originRoute="wallet" :id="null" @closeModal="showRechargeModal= false"></RechargeWallet>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import Modal from "@/components/Modal";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import { ref } from "@vue/reactivity";
import { onMounted } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import useWallet from "@/composables/useWallet.js";
import useSubscription from '@/composables/useSubscription';
import RechargeWallet from "@/views/Components/RechargeWallet";


export default {
  name: "Wallet",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    ClipLoader,
    Modal,
    RechargeWallet
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { wallet, walletTotal , getWalletData, getTotalAvailable } = useWallet();
    const { hasSubscription, inGracePeriod } = useSubscription();
    const showRechargeModal = ref(false);
    const rechargeProducts = ref([
      {
        id: 1,
        price: 99,
      },
      {
        id: 2,
        price: 199,
      },
      {
        id: 3,
        price: 299,
      },
      {
        id: 4,
        price: 399,
      },
    ]);
    const iconState = ref({
      income: "fas fa-plus-square",
      block: "fas fa-lock",
      unblock: "fas fa-lock-open",
      outcome: "fas fa-minus-square"
    })

    const changePage = (event)=>{
      if(event !== wallet.value.current_page){
        getWalletData(event)
      }
    }

    const goToCampaign = (id) => { router.push({ name: 'EditAd', params: { id: id } }) }

    onMounted(async() => {
      await getWalletData(1);
      await getTotalAvailable();
      
      if(route.query.wallet_id) {
        showRechargeModal.value = true;
      }
    })

    return {
      wallet,
      walletTotal,
      getWalletData,
      showRechargeModal,
      rechargeProducts,
      hasSubscription,
      inGracePeriod,
      iconState,
      changePage,
      goToCampaign
    };
  },
};
</script>

<style scoped lang="scss">
    .btn-recharge {
        height: 100px;
        max-height: 150px;
        border: 1px solid lightgray;
        width: 100%;
        &.active-btn {
          box-shadow: none;
          background-color: rgba(90, 111, 231, 0.1);
        }
    }
    .modal-recharge{
        min-height: 300px;
    }
    .wallet-total{
      font-size:1.2rem;
      font-weight: 600;
    }
    .width-column{
      max-width: 70px!important;

    }
    .min-table{
      min-height: 450px;
      max-width: 99%;
    }

    button.btn-link{
      border: none;
      background: transparent;
      padding-left: 10px;
      text-decoration:underline;
      cursor: pointer;
      &:disabled{
        text-decoration: none;
      }
    }

    @media (min-width: 760px){
      /deep/ .modal-dialog{
          max-width: 50%!important;
      }

    }
</style>
