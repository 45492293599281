<template>
  <card header-classes="bg-transparent" body-classes="p-0 row">
    <div class="col-12 d-flex justify-content-between">
      <h4 class="margin-left-title mb-0 title-plugin">{{$t("otherPlatforms")}} <span class="font-light">(LinkedIn, etc)</span></h4>
      <div class="info-card margin-right-title d-flex flex-column justify-content-start align-items-end">
        <div>
          <badge type="max" class="custom-badge"> {{$t("max")}}. 10</badge>
          <el-tooltip placement="top" :content="$t('infoScore.others')">
            <img
              src="/img/icons/stevie/help-shape.svg"
              class="ml-1 icon-card"
              alt="Info icon"
            />
            </el-tooltip>
        </div>
        <div v-if="othersUrls.length > 0">
          <badge v-if="improvePercentage && improvePercentage !== 0" :type="improvePercentage > 0 ? 'success' : 'danger'" class="custom-badge">
             <span v-if="improvePercentage > 0">+</span>
             {{improvePercentage}} %
          </badge>
          <el-tooltip placement="bottom" :content="othersUrls">
            <img
              src="/img/icons/stevie/info-shape.svg"
              class="ml-1 icon-card"
              alt="Info icon"
            />
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="col-12 text-center">
      <span class="card-index" id="percent">{{ (others/10).toPrecision(2) }}</span>
    </div>
  </card>
</template>
<script>
export default {
  name: "PluginOthers",
  props: ["others","othersUrls", "improvePercentage"],
};

</script>

<style scoped lang="scss">
.custom-badge{
  color: black;
  padding: 0.2rem 0.3rem;
  text-transform: capitalize;
  &.badge-success{
    background-color: #BCFD91!important;
  }
  &.badge-danger{
    background-color: #FEC6CF!important;
  }
  &.badge-max{
    font-weight: 800;
    background-color: white!important;
    border: 1px solid #013768;
  }
}
.info-card{
  min-width: max-content;
}
.title-plugin{
  font-size: 16px;
}
.font-light{
  font-weight: 500;
  font-size: 14px;
}
</style>

