export function initFacebook() {
  return new Promise(resolve => {
    window.fbAsyncInit = function() {
      FB.init({
        appId      : process.env.VUE_APP_FB_APP_ID,
        cookie     : true,
        xfbml      : true,
        version    : process.env.VUE_APP_FB_APP_VERSION
      });

      FB.AppEvents.logPageView();

      FB.getLoginStatus(function(response) {
        localStorage.setItem("loginFacebook", JSON.stringify(response));
        resolve();
      });

    };

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  });
}
