import axios from "axios";

export const getMails = async ({ commit }, {page, filters}) => {

    commit('setIsLoading', true)

    let routeList = `/mailing/templates?page=${page}`;

    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        routeList = `${routeList}&${key}=${value}`
      })
    }

    const response = await axios.get(routeList);
    if(response && response.status === 200){
        commit('setMails', response.data);
    }
    commit('setIsLoading', false)

    return response

}

export const getCategories = async ({commit}) => {

  commit('setIsLoading', true)

  let routeList = `/template-categories`;
  const response = await axios.get(routeList);
  if(response && response.status === 200){
    commit('setCategories', response.data);
  }
  commit('setIsLoading', false)

}
export const getAllMails = async ({ commit }) => {

    commit('setIsLoading', true)

    let routeList = `/mailing/templates/actives`;
    const response = await axios.get(routeList);
    if(response && response.status === 200){
        commit('setAllMails', response.data);
    }
    commit('setIsLoading', false)

    return response

}
export const getMail = async ({ commit }, id) => {

    commit('setIsLoading', true)

    let routeList = `/mailing/templates/${id}`;

    const response = await axios.get(routeList);
    if(response && response.status === 200){
        commit('setMail', response.data);
    }
    commit('setIsLoading', false)

    return response

}
export const getPreviewMail = async ({ commit }, mail) =>{
    const route = `/mailing/templates/preview`;
    const response = await axios.post(route, mail);

    return response
}
export const storeMail = async ({ commit }, mail) =>{
    const route = `/mailing/templates`;
    const response = await axios.post(route, mail);

    return response
}
export const updateMail = async ({ commit }, {formData, id}) =>{
    const route = `/mailing/templates/${id}`;
    const response = await axios.post(route, formData);

    return response
}
export const archiveMail = async ({ commit }, mailId) =>{
    const route = `/mailing/templates/${mailId}/archive`;
    const response = await axios.put(route, mailId);

    return response
}
export const deleteMail = async ({ commit }, id) =>{
    const route = `/mailing/templates/${id}`;
    const response = await axios.delete(route);

    return response
}
export const cloneMail = async ({ commit }, id) =>{
    const route = `/mailing/templates/${id}/clone`;
    const response = await axios.get(route);

    return response
}
export const getContacts = async ({ commit }, {page, filters}) => {

    commit('setIsLoading', true)

    let routeList = `mailing/contacts?page=${page}`;

    const response = await axios.get(routeList);
    if(response && response.status === 200){
        commit('setContacts', response.data);
    }
    commit('setIsLoading', false)

    return response

}
export const getAllContacts = async ({ commit }) => {

    commit('setIsLoading', true)

    const routeList = `/mailing/contacts/all`;
    const response = await axios.get(routeList);
    if(response && response.status === 200){
        commit('setAllContacts', response.data);
    }
    commit('setIsLoading', false)

    return response

}

export const storeContact = async ({ commit }, contact) =>{
    const route = `/mailing/contacts`;
    const response = await axios.post(route, contact);

    return response
}
export const updateContact = async ({ commit }, {form, id}) =>{

    const route = `/mailing/contacts/${id}`;
    const response = await axios.put(route, form);

    return response
}
export const deleteContact = async ({ commit }, id) =>{
    const route = `/mailing/contacts/${id}`;
    const response = await axios.delete(route);

    return response
}
export const importContacts = async ({ commit }, contacts) =>{
    const route = `/mailing/import`;
    const response = await axios.post(route, contacts);

    return response
}
export const sendMail = async ({ commit }, data) =>{
    const route = `/mailing/contacts/send`;

    const response = await axios.post(route, data);

    return response
}
export const getContactsSent = async ({ commit }, {id, page}) =>{
    const route = `/mailing/templates/${id}/contacts?page=${page}`;

    const response = await axios.get(route);

    return response
}
export const getMailsTemplates = async ({ commit }, {page, filters}) => {

  commit('setIsLoading', true)

  let routeList = `/mailing/templates/statics?page=${page}`;

  const response = await axios.get(routeList);
  if(response && response.status === 200){
    commit('setMailsTemplates', response.data);
  }
  commit('setIsLoading', false)

  return response

}
export const cloneMailTemplate = async ({ commit }, id) =>{
  const route = `/mailing/templates/statics/${id}/clone`;
  const response = await axios.get(route);

  return response
}
