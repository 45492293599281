<template>
  <modal v-model:show="isViFails" modal-classes="modal-size" body-classes="p-0" modal-content-classes="border-radius-stevie modal-animation" :is-allowed-close-modal="false">
  <div v-if="isViFails" class="viFails row m-3">
    <div class="d-flex justify-content-between w-100">
      <h5 class="title pl-2" >{{$t('contactViFails.title')}}</h5>
      <base-button outline type="secondary" class=" p-2 text-center border-0 btn-close" @click="closeModal()"><i class="fas fa-times"></i></base-button>
    </div>
    <p class="col-12 ">{{$t('contactViFails.contact')}}</p>
    <Form class="col-12 row" @submit="sendConctactForm" @change="changeForm" :initialValues="form" :validation-schema="schema">
      <div class="col-12 d-flex flex-column mb-4">
        <label for="email" class="label d-block">{{$t("email")}}</label>
        <Field
          id="email"
          name="email"
          type="text"
          class="input-stevie"
        ></Field>
        <ErrorMessage name="email" class="text-danger text-required-error" />
      </div>
      <div class="col-12 d-flex flex-column mb-4">
        <label for="url" class="label d-block">{{$t("url")}}</label>
        <Field
          id="url"
          name="url"
          type="text"
          class="input-stevie"
          disabled
        ></Field>
        <ErrorMessage name="url" class="text-danger text-required-error" />
      </div>
    <div class="col-12 d-flex justify-content-center justify-content-md-end">
      <base-button
        class="col-12 col-md-5 mx-0 login-facebook border-0 font-weight-700"
        native-type="submit"
        :disabled="isSendingForm"
      >
        <div v-if="!isSendingForm">
            {{$t('contactViFails.button')}}
        </div>
        <clip-loader v-else color="#ffffff" size="15px" class=""></clip-loader>
      </base-button>
    </div>
    </Form>

  </div>
  </modal>
</template>

<script>
import { ref } from '@vue/reactivity';
import Modal from '@/components/Modal';
import useForms from '@/composables/useForms';
import useVisibility from '@/composables/useVisibility';
import BaseButton from '../../../../components/BaseButton.vue';
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n/index';
import { watch } from '@vue/runtime-core';
import { Form, Field, ErrorMessage } from 'vee-validate';


export default {
  name: "ViFailsModal",
  components: {
    Modal,
    BaseButton,
    Form,
    Field,
    ErrorMessage,
    ClipLoader
},
  setup( props, { emit }) {
    const toast = useToast();
    const { t } = useI18n();

    const { markAsInvalid, isValidForm, uploadForm  } = useForms()
    const { isViFails, viForm , setIsViFails, sendVisibilityFail } = useVisibility()
    const requiredFields = ref(['email', 'url'])
    const isSendingForm = ref(false);
    const form = ref({
      email: null,
      url: null
    })
    const schema = {
      email: "required|email",
      url: "required|invalidUrl"
    }

    const sendConctactForm = async() =>{

      if(!isValidForm(form.value, requiredFields.value))return
      isSendingForm.value = true;
      const response = await sendVisibilityFail(form.value);

      if(response && response.status === 200){
        toast.success(t("notification.success.contact"));
        closeModal()
      }
      isSendingForm.value = false;
    }
    const changeForm = (event)=>{
      form.value = uploadForm(event, form.value)
    }
    const closeModal = ()=> {
      form.value = {
        email: null,
        url: null
      }
      setIsViFails(false)
    }

    watch(() => viForm.value , (newValue)=>{
      form.value.url = newValue.url;
    })

    return {
      form,
      markAsInvalid,
      isViFails,
      isSendingForm,
      sendConctactForm,
      changeForm,
      closeModal,
      schema
    }
  }
}
</script>

<style scoped lang="scss">
.viFails{
  .title{
    font-size: 1.2rem;
  }
  .btn-close{
    max-height: 50px;
  }

}
</style>
