<template>
  <div>
    <!-- Page content -->
    <div class="container" :class="isModal ? 'px-0' : 'pb-5 mt-9'">
      <!-- Table -->
      <div :class="isModal ? '' : 'row justify-content-center'">
        <div :class="isModal ? '' : 'col-lg-6 col-md-8'">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-2">
                <h2>{{ $t("createAccount") }}</h2>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <Form @submit="register" :validation-schema="schema">
                <div class="row">

                  <div class="col-12 mb-4">
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      class="input-stevie w-100"
                      :placeholder="$t('firstName')"
                    ></Field>
                    <ErrorMessage name="name" class="text-danger text-required-error error-message-top" />
                  </div>
                  <div class="col-12 mb-4">
                    <Field
                      id="last_name"
                      name="last_name"
                      type="text"
                      class="input-stevie w-100"
                      :placeholder="$t('lastName')"
                    ></Field>
                    <ErrorMessage name="last_name" class="text-danger text-required-error error-message-top" />
                  </div>
                  <div class="col-12 mb-4">
                    <Field
                      id="company_name"
                      name="company_name"
                      type="text"
                      class="input-stevie w-100"
                      :placeholder="$t('companyName')"
                    ></Field>
                    <ErrorMessage name="company_name" class="text-danger text-required-error error-message-top" />
                  </div>

                  <div class="col-12 mb-4">
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      class="input-stevie w-100"
                      :placeholder="$t('email')"
                    ></Field>
                    <ErrorMessage name="email" class="text-danger text-required-error error-message-top" />
                  </div>
                  <div class="col-12 mb-4">
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      class="input-stevie w-100"
                      :placeholder="$t('password')"
                    ></Field>
                    <ErrorMessage name="password" class="text-danger text-required-error error-message-top" />
                  </div>
                  <div class="col-12 mb-4">
                    <Field
                      id="password_confirmation"
                      name="password_confirmation"
                      type="password"
                      class="input-stevie w-100"
                      :placeholder="$t('passwordConfirmation')"
                    ></Field>
                    <ErrorMessage name="password_confirmation" class="text-danger text-required-error error-message-top" />
                  </div>
                </div>
                <div class="row justify-content-center m-0">
                  <base-button
                    type="primary"
                    native-type="submit"
                    :disabled="isLoading || isLoadingFacebook"
                    class="col-12 mx-0 mb-4">
                    <span v-if="!isLoading">{{ $t("signUp") }}</span>
                    <clip-loader v-else color="#ffffff" size="15px" class=""></clip-loader>
                  </base-button>
                  <base-button
                    class="col-12 mx-0 login-facebook border-0 font-weight-700"
                    @click="registerWithFacebook"
                    :disabled="isLoadingFacebook || isLoading"
                  >
                  <div v-if="!isLoadingFacebook">
                    <img class="mr-2" src="/img/icons/common/facebook.svg" alt="Facebook icon">
                    {{ $t('continueWithFacebook') }}
                  </div>
                  <clip-loader v-else color="#ffffff" size="15px" class=""></clip-loader>
                  </base-button>
                </div>
              </Form>
            </div>
          </div>
          <div class="row mt-3" :class="isModal ? 'ml-2' : ''">
            <div class="col-6">
              <router-link
                v-if="!isModal"
                :to="{ name: 'Login' }"
                class="text-light"
                ><small>{{ $t("alreadyRegister") }}</small></router-link
              >
              <a
                v-else
                @click="$emit('login-modal')"
                class="text-light cursor-pointer"
                ><small>{{ $t("alreadyRegister") }}</small></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n/index";
import { inject, ref } from "vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";


export default {
  name: "register",
  components: {
    Form,
    Field,
    ErrorMessage,
    ClipLoader
  },
  props: ["isModal"],
  data() {
    return {
      model: {
        email: "",
        password: "",
        passwordConfirm: "",
        agree: false,
      },
    };
  },
  emits: ['login-modal', 'modal'],
  setup() {
    const router = useRouter();
    const toast = useToast();
    const { t } = useI18n();
    const loginFacebook = inject("loginFacebook");
    const changeLoginFacebook = inject("changeLoginFacebook");
    const isLoadingFacebook= ref(false);
    const isLoading= ref(false);
    const schema = {
      name: "required",
      last_name: "required",
      company_name: "required",
      email: "required|email",
      password: "required|minLength:6",
      password_confirmation: "required|confirmed:password"
    }

    changeLoginFacebook(JSON.parse(localStorage.getItem("loginFacebook")));

    const register = async (values) => {
      const route = "/auth/signup";
      isLoading.value = true;

      const newTargets = localStorage.getItem("targetsForm")
      if(newTargets){
        values.targets = JSON.parse(newTargets);
      }

      let response = await axios.post(route, values);
      if (response && response.status === 201) {
        await router.push({ name: "Login" });
        toast.success(t("notification.success.register"));
      }
      isLoading.value = false;
    };

    const registerWithFacebook = async () => {
      if (loginFacebook.value.status !== "connected") {
        await new Promise(async (resolve) => {
          FB.login(function (response) {
            changeLoginFacebook(response);
            resolve();
          }, {scope: 'public_profile,email'});
        });
      }

      if (loginFacebook.value.status === "connected") {
        FB.api(`/me?fields=email,first_name,last_name`, async function(response) {
          const data = loginFacebook.value.authResponse;
          data.email = response.email;
          data.name = response.first_name;
          data.last_name = response.last_name;
          const route = "/fb/register";
          isLoadingFacebook.value = true;

          const newTargets = localStorage.getItem("targetsForm")
          if(newTargets){
            data.targets = JSON.parse(newTargets);
          }

          let responseLogin = await axios.post(route, data);
          if (responseLogin && responseLogin.status === 201) {
            await router.push({ name: "Login" });
            toast.success(t("notification.success.general"));
          }
          isLoadingFacebook.value = false;
        });
      }
    }

    onBeforeRouteLeave((to, from, next)=>{
      if(to.name !== 'Login'){
        localStorage.removeItem("goToSubscription")
      }
      next()
    })

    return {
      register,
      registerWithFacebook,
      schema,
      isLoading,
      isLoadingFacebook
    };
  },
};
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.error-message-top {
  top: 2.2rem;
}
:deep()input[id="name"], input[id="last_name"], input[id="company_name"]{
  appearance: none!important;
  padding-left: 33px!important;
  background: url('/img/icons/stevie/user-solid.svg') no-repeat left;
  background-size: 13px;
  background-position: 10px 10px;
}
:deep()input[id="email"]{
  appearance: none!important;
  padding-left: 33px!important;
  background: url('/img/icons/stevie/envelope-solid.svg') no-repeat left;
  background-size: 13px;
  background-position: 10px 10px;
}
:deep()input[type="password"]{
  appearance: none!important;
  padding-left: 33px!important;
  background: url('/img/icons/stevie/unlock-keyhole-solid.svg') no-repeat left;
  background-size: 13px;
  background-position: 10px 10px;
}
</style>
