<template>
    <div class="templates-content d-flex flex-wrap justify-content-center justify-content-lg-around" id="scrollComponent">
      <div class="template-card" v-for="template in allTemplates" :id="template.id" :key="template.id">

        <img v-if="template.meta_image" :src="template.meta_image" class="template-card__image" :alt="$t('image')">
        <img v-else src="/img/brand/default-placeholder.png" class="template-card__image" :alt="$t('image')">

        <div class="template-card__header">
          <p class="text-ellipsis">{{template.name}}</p>
        </div>
        <div class="template-card__body">
          <strong>{{template.meta_headline}}</strong>
          <p>{{template.meta_text}}</p>

          <div class="btn-group template-button">
            <button type="button" class="btn btn-default btn-action" @click="showTemplate(template)">{{ $t("preview") }}</button>
          </div>
        </div>
      <div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center template-height">
      <clip-loader v-if="isLoading" color="#012454" size="50px" class=""></clip-loader>
    </div>
  </div>

  <modal 
    v-model:show="showPreview"
    :showClose="false"
    header-classes="pb-1"
    body-classes="pt-1"
  >
    <template v-slot:header>
    </template>
    <CampaignTemplatePreview
      v-if="templatePreview"
      :template="templatePreview"
      @close="showPreview = false"
      @chooseTemplate="cloneItem($event.id)"
    />
  </modal>
</template>

<script>
import useTemplates from '@/composables/useTemplates';
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { useRoute, useRouter } from 'vue-router';
import ConfirmModal from '@/views/Components/ConfirmModal.vue';
import CampaignTemplatePreview from '@/views/Components/CampaignTemplatePreview.vue';
import modal from '@/components/Modal';
import { ref } from '@vue/reactivity';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { onMounted, onUnmounted } from 'vue';
import { toNumber } from 'lodash';
import { CampaignTemplateClass } from '@/util/Models/TemplateClass.js';

export default {
    name: "templatesList",
    components: {
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        [ElDropdown.name]: ElDropdown,
        [ElDropdownItem.name]: ElDropdownItem,
        [ElDropdownMenu.name]: ElDropdownMenu,
        ConfirmModal,
        ClipLoader,
        CampaignTemplatePreview,
        modal
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const { templates, getTemplates, deleteTemplate, cloneTemplate } = useTemplates()

        const allTemplates = ref([])
        const pagination = ref({
          currentPage: 1,
          lastPage: null
        })
        const templateManageId = ref(null)
        const isLoading = ref(false);
        const scrollComponent = ref(null)
        const lastTemplateId = ref(0);
        const showPreview = ref(false);
        const templatePreview = ref(null);
        const selectedTemplate = ref(null);

        const saveFilters = async()=>{
          localStorage.setItem('table_filters', JSON.stringify({page: templates.value.current_page}))
        }

        const editTemplate = (id)=>{
          saveFilters()
          router.push({ name: 'EditTemplate', params: { id: id } });
        }

        const changePage = (event)=>{
          if(event !== templates.value.current_page){
            getTemplates(event)
          }
        }

        const cloneItem = async (id) => {
          showPreview.value = false;
          const response = await cloneTemplate(id)
          if (response && response.status === 200) {
            router.push({ name: 'EditAd', params: { id: response.data.id } });
          }
        }

        const handleScroll = (e) => {
          scrollComponent.value = document.getElementById('scrollComponent');
          let element = scrollComponent.value;
          if( isLoading.value || pagination.value.currentPage === pagination.value.lastPage)return
          if (element && element.getBoundingClientRect().bottom - 20 < window.innerHeight) {

            lastTemplateId.value = allTemplates.value.at(-1)?.id
            addTemplates( pagination.value.currentPage + 1)
          }
        }
        
        const addTemplates = async (page) =>{
          
          isLoading.value = true;
          const response = await getTemplates(page)
          if(response && response.status === 200){
            let { data, current_page, last_page }= response.data;

            pagination.value = {
              currentPage: toNumber(current_page),
              lastPage: toNumber(last_page) 
            }

            allTemplates.value.push(...data)
          }
          isLoading.value = false;
          let lastElement = document.getElementById(lastTemplateId.value);
          if(lastElement){
            lastElement.scrollIntoView({behavior: "smooth", block: "center"})
          }
        }
        
        const showTemplate = (template) => {
           if(template){
            showPreview.value = true;
            templatePreview.value = new CampaignTemplateClass(template)
          } 
          selectedTemplate.value = template ? template : null;
        }
        
        onMounted(() => {
          const { page } = route.query;
          let currentPage = page ? page : 1;
          if( page ){
            router.replace({...router.currentRoute, query: {}})
          }
          addTemplates(1)
          window.addEventListener("scroll", handleScroll)
        })
        onUnmounted(() => {
          window.removeEventListener("scroll", handleScroll)
        })

        return {
            templates,
            isLoading,
            scrollComponent,
            allTemplates,
            editTemplate,
            changePage,
            cloneItem,
            showTemplate,
            templatePreview,
            selectedTemplate,
            showPreview
        };
    },
}
</script>

<style scoped lang="scss">
.dropdown-item{
  height: 33px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover{
    color: #16181b!important;
    background-color: #f6f9fc!important;
  }
}
.min-table{
  min-height: 450px;
}

.template-card{
  margin: 0 10px 50px 10px;
  width: 258px;
  height: 377px;
  border-radius: 20px;
  box-shadow: 0px 15px 35px rgba(3,115,168,0.1), 0px 20px 10px rgba(3,115,168,0.1);
  p{
    margin: 0;
  }
  &__image{
    width: 100%;
    height: 177px;
    min-height: 177px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    object-fit: cover;
  }
  &__header{
    height: 33px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    text-transform: uppercase;
    color: #ffffff;
    p{
      font-size: 16px;
      font-weight: 500;
    }
  }
  &__body{
    padding: 13px;
    padding-bottom: 29px;
    height: 167px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
      font-size: 13px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      line-height: 1em;  
    }
    .template-button{
      margin-top: auto;
    }


  }
  &:nth-child(1n) .template-card__header{
    background-color: #012454;
  }
  &:nth-child(2n) .template-card__header{
    background-color: #0373a8;
  }
  &:nth-child(3n) .template-card__header{
    background-color: #8dfd36;
  }
  .template-height{
    height: 377px;
  }
  
  .btn-group{
    .btn-action{
      height: 38px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}
</style>
