<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
    <div class="main-content d-lg-flex">
      <side-bar>
        <template v-slot:links>
          <sidebar-item
            :link="{
              name: $t('dashboard'),
              icon: '/img/icons/stevie/dashboard-shape.svg',
              path: '/dashboard'
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="!isLoggedIn"
            :link="{
              name: $t('visibilityIndex'),
              icon: '/img/icons/stevie/vi-shape.svg',
              path: '/Register'
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="isLoggedIn"
            :link="{
              name: $t('campaigns'),
              icon: '/img/icons/stevie/campaigns-shape.svg',
              path: isLoggedIn ? '/campaigns' : '/login',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="isLoggedIn"
            :link="{
              name: $t('mailMarketing'),
              icon: '/img/icons/stevie/email-marketing.svg',
              path: isLoggedIn ? '/mails-marketing' : '/login',
            }"
          >
          </sidebar-item>
           <sidebar-item
            v-if="isLoggedIn"
            :link="{
              name: $t('landingsMarketing'),
              icon: '/img/icons/stevie/landing.svg',
              path: isLoggedIn ? '/landings' : '/login',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="isLoggedIn"
            :link="{
              name: $t('customerData'),
              icon: '/img/icons/stevie/customer-data-shape.svg',
              path: isLoggedIn ? '/profile' : '/login',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="isLoggedIn"
            :link="{
              name: $t('settings'),
              icon: '/img/icons/stevie/settings-shape.svg',
              path: isLoggedIn ? '/settings' : '/login',
            }"
          >
          </sidebar-item>
        </template>
      </side-bar>
      <div class="w-100" @click="$sidebar.displaySidebar(false)">
        <widget-side></widget-side>
        <router-view>
        </router-view>

      </div>
    </div>
    <content-footer v-if="!$route.meta.hideFooter"></content-footer>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import useLoggedIn from "../../composables/useLoggedIn";
import useSubscription from '@/composables/useSubscription';
import WidgetSide from './WidgetSide.vue';

export default {
  data() {
    return {
      subcriptionUrl: `${
        process.env.VUE_APP_BACKOFFICE_URL
      }/pair/billing/${localStorage.getItem("api_token")}`,
    };
  },
  components: {
    DashboardNavbar,
    ContentFooter,
    WidgetSide
},
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  setup() {
    const { isLoggedIn } = useLoggedIn();
    const { hasSubscription, inGracePeriod }= useSubscription();
    return {
      isLoggedIn,
      hasSubscription,
      inGracePeriod
    };
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style scoped lang="scss">
.disabled-sidebar{
  pointer-events: none;
  opacity: 0.5;
}
</style>
