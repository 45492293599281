<template>
  <div class="online-shape"></div>
</template>

<script>
export default {
  name: "OnlineShape",
};
</script>

<style scoped></style>
