import { allSubscriptions } from "@/util/Settings";

export const hasSubscription = ( state ) => {
    if(state.userData.subscription){
        return state.userData.subscription.ends_at === null && state.userData.subscription.stripe_status !== 'incomplete'
    }
 return false;
}
export const isTrialSubscription = ( state ) => {
    if(state.userData.subscription && state.userData.subscription.trial_ends_at){
        return new Date().getTime() < new Date(state.userData.subscription.trial_ends_at).getTime()
    }
 return false;
}
export const inGracePeriod = ( state ) => {
    return !state.hasSubscription
      && state.userData.subscription
      && state.userData.subscription.current_period_end
      && state.userData.subscription.stripe_status !== 'incomplete'
}
export const subscriptionPermissions = ( state ) => {

    let permissions = {
        numberCampaigns: 0,
        allowGoogle: false,
        allowFacebook: false,
        allowLandings: false,
        allowMails: false,
        allowInstagram: false,
    }
    if(state.userData.subscription && state.userData.subscription.name && state.userData.subscription.stripe_status !== 'incomplete'){
        const subscriptionName = state.userData.subscription.name;
        let subscription = allSubscriptions.find(subscripition => subscripition.name === subscriptionName)
        permissions = subscription ? subscription.permissions : permissions
    }
    return permissions
}


