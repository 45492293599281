<template>
  <div>
    <el-tooltip
      v-if="isLoading"
      placement="top"
      :content="$t('magicWandLoading')"
      class="d-flex justify-content-center align-items-center mb-1"
    >
      <clip-loader color="#aaa" size="18px" class="mx-1" />
    </el-tooltip>

    <el-tooltip
      v-else-if="isError"
      :visible="true"
      placement="top"
      :content="$t('tooltipCampaignsErrorAI')"
    >
      <magic-wand-icon class="magic-wand-styles cursor-pointer" :isDisabled="isLoading || isDisabled" @click="!isDisabled && $emit('click', $event)" />
    </el-tooltip>

    <el-tooltip v-else placement="top" :content="$t('tooltipStevieAISuggestion')">
      <magic-wand-icon class="magic-wand-styles cursor-pointer" :isDisabled="isLoading || isDisabled" @click="!isDisabled && $emit('click', $event)" />
    </el-tooltip>
  </div>
</template>

<script>
import MagicWandIcon from "../../components/MagicWandIcon.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "MagicWand",
  props: ["nameField", "isLoading", "isDisabled", "isError"],
  components: {
    MagicWandIcon,
    ClipLoader,
  },
  setup() {
    // Methods

    return {};
  },
  emits: ["click"]
};
</script>

<style lang="scss" scoped>
.magic-wand-styles {
  width: 1.3rem;
  height: 1.3rem;
  margin-bottom: 3px;
}
.magic-wand-styles:hover:not(.disabled, .not-hover-active),
.magic-wand-styles-text:hover:not(.disabled, .not-hover-active) {
  border: 1px solid #71ad47;
  border-radius: 0.3rem;
}
.magic-wand-styles:active:not(.disabled, .not-hover-active),
.magic-wand-styles-text:active:not(.disabled, .not-hover-active) {
  border: 1px solid #71ad47;
  background: #c2dcb0;
  border-radius: 0.3rem;
}

img {
  &.disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }
}
</style>
