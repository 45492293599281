<template>
  <div
    class="dropzone mb-3 dz-clickable"
    :class="[multiple ? 'dropzone-multiple' : 'dropzone-single', disabled ? 'disabled-dropzone' : '']"

  >
    <div class="fallback">
      <div class="custom-file">
        <input
          type="file"
          class="custom-file-input"
          id="projectCoverUploads"
          :multiple="multiple"
        />
        <label class="custom-file-label" for="projectCoverUploads"
          >Choose file</label
        >
      </div>
    </div>
    <div
      class="dz-preview dz-preview-single"
      v-if="!multiple"
      :class="previewClasses"
      ref="previewSingle"
    >
      <div class="dz-preview-cover">
        <img class="dz-preview-img" data-dz-thumbnail />
      </div>
    </div>
    <ul
      v-else
      class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush"
      :class="previewClasses"
      ref="previewMultiple"
    >
      <li class="list-group-item px-0">
        <div class="row align-items-center">
          <div class="col-auto">
            <div class="avatar">
              <img class="avatar-img rounded" data-dz-thumbnail />
            </div>
          </div>
          <div class="col ml--3">
            <h4 class="mb-1" data-dz-name>...</h4>
            <p class="small text-muted mb-0" data-dz-size>...</p>
          </div>
          <div class="col-auto">
            <button data-dz-remove="true" class="btn btn-danger btn-sm">
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
export default {
  name: "dropzone-file-upload",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    value: [String, Object, Array],
    url: {
      type: String,
      default: "http://",
    },
    multiple: Boolean,
    previewClasses: [String, Object, Array],
    disabled: Boolean,
    validationsImage:{
      type: Object,
      default: {}
    },
    isRemovingFile: Boolean,
    placeholder: {
      type: String,
      default: null
    }
  },
  emits: ["removedfile", "totaluploadprogress", "reset", "change"],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      currentFile: null,
      files: [],
      showList: false,
    };
  },
  methods: {
    async initDropzone() {
      let validations = this.validationsImage;
      let Dropzone = await import("dropzone");
      Dropzone = Dropzone.default || Dropzone;
      Dropzone.autoDiscover = false;
      let preview = this.multiple
      ? this.$refs.previewMultiple
      : this.$refs.previewSingle;
      let self = this;
      let finalOptions = {
        ...this.options,
        dictDefaultMessage: this.placeholder ? this.placeholder : this.$t('dropImageHere'),
        url: this.url,
        autoProcessQueue:false,
        thumbnailWidth: null,
        thumbnailHeight: null,
        previewsContainer: preview,
        previewTemplate: preview.innerHTML,
        maxFiles: !this.multiple ? 1 : null,
        // acceptedFiles: !this.multiple ? "image/*, video/*" : null,
        acceptedFiles: this.options.acceptedFiles ? this.options.acceptedFiles : null,
        init: function () {
          this.on("addedfile", function (file) {
            if (!self.multiple && self.currentFile) {
              this.removeFile(self.currentFile);
            }
            self.currentFile = file;
          });
        },
      };
      this.dropzone = new Dropzone(this.$el, finalOptions);
      preview.innerHTML = "";
      let evtList = [
        "drop",
        "dragstart",
        "dragend",
        "dragenter",
        "dragover",
        "addedfile",
        "removedfile",
        "thumbnail",
        "error",
        "processing",
        "uploadprogress",
        "sending",
        "success",
        "complete",
        "canceled",
        "maxfilesreached",
        "maxfilesexceeded",
        "processingmultiple",
        "sendingmultiple",
        "successmultiple",
        "completemultiple",
        "canceledmultiple",
        "totaluploadprogress",
        "reset",
        "queuecomplete",
      ];
      evtList.forEach((evt) => {
        this.dropzone.on(evt, (data) => {
          this.$emit(evt, data);
          if (evt === "addedfile") {
            if (this.multiple) {
              this.files.push(data);
            } else {
              this.files.splice(0,1)
              this.files.push(data)
            }
            // this.files.push(data);

            const isValidImage = (file)=>{

              if(!file || !validations)return true
              const {height, width, size}= file;
              let isValid = true;
              const { minHeight, minWidth, maxSize }= validations;
              if(maxSize){
                isValid = (size/1024) <= maxSize 
              }
              if(minHeight){
                isValid = height >= minHeight && isValid 
              }
              if(minWidth){
                isValid = width >= minWidth && isValid 
              }
              return isValid
            }
            
            this.dropzone.on("thumbnail",()=>{
              if(!isValidImage(this.files[0])){
                this.dropzone.removeAllFiles(true)
                this.showError()
                return
              }
              this.$emit("change", this.files);
            })
            
          } else if (evt === "removedfile") {
            let index = this.files.findIndex(
              (f) => f.upload.uuid === data.upload.uuid
            );
            if (index !== -1) {
              this.files.splice(index, 1);
              this.files.push(data)
            }
            this.$emit("change", []);
          }
        });
      });
    },
    removeAllFiles() {       
      this.dropzone.removeAllFiles(true)
    }
  },
  async mounted() {
    await this.initDropzone();
  },
  watch:{
    isRemovingFile(newValue){
      if(newValue){
        this.dropzone.removeAllFiles(true)
      }
    }
  },
  setup(){
    const { t } = useI18n();
    const toast = useToast();

    const showError = ()=>{
      toast.warning(t("formValidations.invalidImage"));
    }
    return{
      showError
    }
  }
};
</script>
<style lang="scss" scoped>
.disabled-dropzone {
    opacity: 0.5;
    pointer-events: none;
}
</style>
