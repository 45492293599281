<template>
  <div class="row mt-4 mt-sm-7 mb-4 mb-8 width-legal-notice mx-auto">
    <h1 class="col-12 mt-6 mt-sm-8 mb-5">Impressum - Legal Disclosure</h1>
    <p class="col-12 m-0">Information in accordance with Section 5 TMG</p>
    <p class="col-12 mb-4">Represented by: Patricia Kastner und Florian Paul</p>

    <p class="col-12 m-0">Stevie Agency GmbH</p>
    <p class="col-12 m-0">Hauptstrasse 82</p>
    <p class="col-12 m-0">8272 Ermatingen</p>
    <p class="col-12 mb-4">Contact Information:</p>

    <p class="col-12 m-0">Register entry</p>
    <p class="col-12 mb-4">Register Number: CH-440.4.035.671-4VAT number: CHE-352.413.606</p>

    <p class="col-12 mb-4">Accountability for content: Patricia Kastner und Florian Paul</p>
  </div>
</template>

<script>
export default {
  name: "LegalNotice"
}
</script>

<style scoped>
.width-legal-notice {
  max-width: 570px;
}
</style>
