import { computed, ref } from "vue";
import store from "../store";

const userData = () => {

  const changeUserData = (value) => {
    store.commit('visibility/setUserTargets', value.targets)
    store.commit('user/setUserData', value);
  };
  const getUserData = async() => {
    const response= await store.dispatch('user/getUserData')
    await store.commit('visibility/setUserTargets',response.data.targets)
  }
  const saveCompanyName = async(companyName) => {
    const {name, last_name, email}=  store.state.user.userData;
    const user = {
      name: name,
      last_name: last_name,
      email: email,
      company_name: companyName
    }
    const response= await store.dispatch('user/saveUserData', user);
    return response
  }


  return {
    userData: computed(()=> store.state.user.userData),
    isLoading: computed(()=> store.state.user.isLoading),
    changeUserData,
    getUserData,
    saveCompanyName,
  };
};

export default userData;
