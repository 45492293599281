export const setMails = ( state, mailsData ) => {

    state.mailsData = mailsData;
}
export const setCategories = ( state, categories) => {
  state.categories = categories;
}
export const setAllMails = ( state, allMails ) => {

    state.allMails = allMails;
}
export const setMail = ( state, mail ) => {

    state.mail = mail;
}
export const setIsLoading = ( state, isLoading ) => {

    state.isLoading = isLoading;
}
export const setContacts = ( state, contactsData ) => {

    state.contactsData = contactsData;
}
export const setAllContacts = ( state, allContacts ) => {

    state.allContacts = allContacts;
}
export const setMailsTemplates = ( state, mailsTemplatesData ) => {

  state.mailsTemplatesData = mailsTemplatesData;
}

