<template>
  <card header-classes="bg-transparent" body-classes="p-0 row m-0 py-3 py-2">
    <div class="col-12 d-flex justify-content-between pb-1">
      <h4 class="mb-0 card-text">{{ $t("previewMetaAd") }}</h4>
      <div>
        <el-tooltip placement="top" :content="$t('campaingFacebookInfoPreview')">
          <img
            src="/img/icons/stevie/info-shape.svg"
            class="icon-card"
            alt="Info icon"
          />
        </el-tooltip>
      </div>
    </div>
    <div class="col-1 pr-0">
      <img src="/img/icons/stevie/facebook-icon.svg" class="meta-icon" alt="Google icon">
      <img src="/img/icons/stevie/instagram.png" class="meta-icon" alt="Google icon">
    </div>
    <div class="col-11">
      <img v-if="metaImage" :src="metaImage" class="w-100 meta-image" alt="Meta Ad image">
      <img v-else src="/img/theme/default-placeholder.png" class="w-100 meta-image" alt="Meta Ad image">
      <div class="row align-items-center p-1 mx-0 bg-headline">
        <div class="col-12">
          <p class="m-0 url">{{ url?.replace('http://','').replace('https://','').split(/[/?#]/)[0] }}</p>
          <p class="m-0 headline text-uppercase">{{ metaHeadline ? metaHeadline : $t("campaignPreview.title") }}</p>
        </div>
      </div>
      <p class="m-0 description">{{ metaDescription ? metaDescription : $t("campaignPreview.subtitle") }}</p>
      <p class="m-0 meta-text">{{ metaText ? metaText : $t("campaignPreview.description") }}</p>
    </div>
  </card>
</template>

<script>
export default {
  name: "MetaAdPreview",
  props: ["url", "metaHeadline", "metaDescription", "metaImage", "metaText"],
  setup(props) {
    const goUrl = () => {
      window.open(props.url, "_blank")
    }
    return {
      goUrl
    }
  }
}
</script>

<style scoped>
.meta-icon {
  width: 20px;
}
.headline {
  font-size: 12px;
  font-weight: bold;
  text-decoration: none solid rgb(4, 4, 199);
  color: #FFFF;
}
.description {
  font-size: 12px;
  font-weight: bold;
  text-decoration: none solid rgb(102, 102, 102);
  color: #012454;
}
.url {
  font-size: 12px;
  color: #BFBFC3;
  text-decoration: none solid rgb(43, 43, 45);
}
.meta-image {
  height: 100px;
  object-fit: cover;
}
.bg-headline {
  background-color: #012454;
}
.button {
  border: solid 2px #BFBFC3;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  color: #BFBFC3;
}
.meta-text{
  font-size: 9px;
}
</style>
