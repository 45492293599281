<template>
  <div>
    <!-- Page content -->
    <div class="bg-white">
      <div class="img-height">
        <img src="/img/brand/bg-landing-page.jpeg" class="object-cover h-100 w-100" alt="Background">
      </div>
      <visibility-form id="yourVisibility" class="position-absolute center-card-visibility mt--8" @setUrlVi="manageModal" ></visibility-form>

      <div class="landing-page-margin">
        <div class="row justify-content-center justify-content-lg-between mx-0 mt-landing-form">
          <div class="col-12 col-lg-6 row pt-4 pt-sm-0 mb-lg-0">
            <div class="col-12 col-sm-6 d-flex justify-content-center">
              <card class="shadow-none card-size mb-0" body-classes="px-0">
                <template v-slot:image>
                  <img class="card-img-top border-radius-stevie" src="/img/brand/landing_image_6.jpg" alt="Card image cap">
                </template>
                <h5 class="card-title-stevie-vi font-size-content text-center text-sm-left">Vertrieb unterstützen:</h5>
                <p class="card-text-stevie-vi font-size-content text-center text-sm-left">
                  „Mit Stevie kann ich schnell und einfach unsere Produkte bewerben oder auch mal austesten,
                  welches Produkt bei unseren Kunden besser ankommt."
                </p>
              </card>
            </div>
            <div class="col-12 col-sm-6 d-flex justify-content-center">
              <card class="shadow-none card-size mb-0" body-classes="px-0">
                <template v-slot:image>
                  <img class="card-img-top border-radius-stevie" src="/img/brand/landing_image_1.jpeg" alt="Card image cap">
                </template>
                <h5 class="card-title-stevie-vi font-size-content text-center text-sm-left">Marke stärken:</h5>
                <p class="card-text-stevie-vi font-size-content text-center text-sm-left">
                  „Mein Unternehmen noch bekannter bei unseren Kunden zu machen und mir Marktanteile zu sichern -
                  dabei hilft mir Stevie - schnell, einfach und unkompliziert."
                </p>
              </card>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <router-link :to="{ name: 'Register' }">
                <base-button
                  block
                  borderRadius
                  class="button-height-33 trial-button-vi"
                >
                  {{ $t("30DaysFreeTrial") }}
                </base-button>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-6 mt-4 mt-lg-0 pr-0">
            <div class="card bg-dark text-white border-0 border-radius-left-stevie height-landing bg-before-img">
              <img class="img-fluid img-cover h-100 border-radius-left-stevie" src="/img/brand/landing_image_3.jpeg" alt="Card image">
              <div class="card-img-overlay d-flex align-items-end">
                <div>
                  <badge class="bg-green-yellow text-prussian-blue">AKTUELLER SICHTBARKEITSINDEX - „HIGHLIGHT“</badge>
                  <h5 class="display-3 card-title text-prussian-blue bg-green-yellow my-2 px-2 rounded">www.kiesel.net – 9.0</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="moreVisibility" class="row mt-9 mx-0 bg-malibu-stevie">
        <div class="col-12">
          <img class="position-absolute center-position-absolute" src="/img/icons/stevie/oval-shape-1.svg" alt="Oval">
          <img class="position-absolute center-position-absolute" src="/img/icons/stevie/oval-shape-2.svg" alt="Oval">
          <img class="position-absolute center-position-absolute" src="/img/icons/stevie/oval-shape-3.svg" alt="Oval">
          <img class="position-absolute center-position-absolute" src="/img/icons/stevie/oval-shape-4.svg" alt="Oval">
        </div>
        <div class="col-12 row justify-content-center mt-9 mx-0">
          <h1 class="col-12 text-center display-1 text-dark">Marketing-Kampagnen wirkungsvoll schalten mit Stevie</h1>
          <p class="col-10 col-lg-6 col-xl-5 text-center text-landing-stevie font-weight-600">
            Mit Stevie die Online-Sichtbarkeit Ihres Unternehmens verbessern, Ihre Produkte schneller online bewerben und neue Kunden gewinnen.
          </p>
        </div>
        <div class="col-12 row mt-7 mt-sm-9 landing-page-padding-left">
          <div class="col-12 col-sm-4 px-0 pr-sm-4">
            <div>
              <img class="position-absolute center-position-absolute-left" src="/img/icons/stevie/circle-shape-1.svg" alt="Oval">
              <img class="position-absolute center-position-absolute-left" src="/img/icons/stevie/circle-shape-2.svg" alt="Oval">
              <img class="position-absolute center-position-absolute-left" src="/img/icons/stevie/circle-shape-3.svg" alt="Oval">
              <h1 class="position-absolute center-position-absolute-left display-3 text-white font-weight-900">1</h1>
            </div>
            <div class="mt-6 stevie-title-height-100">
              <h1 class="text-dark">Meine Marke online sichtbarer machen</h1>
            </div>
            <div>
              <p class="text-landing-stevie font-weight-600 text-dark stevie-line-height-13">
                Für das Marketing Ihres Unternehmens spielt die OnlineSichtbarkeit eine extrem wichtige Rolle. Allerdings sind die 
                Prozesse hierfür meist noch aus den 90-iger Jahren und damit fast völlig analog.
              </p>
            </div>
            <collapse></collapse>
            <collapse-item name="1" id="1" header-classes="p-0 bg-transparent border-0" container-classes="bg-transparent shadow-none">
              <template v-slot:title>
                <a href="" class="text-allports"><img src="/img/icons/stevie/right-arrow-shape.svg" class="mr-2" alt="Arrow"> <b>Mehr erfahren</b></a>
              </template>
              <div>
                <p>In der Regel wird wie folgt für Online-Marketing vorgegangen:</p>
                <ul>
                  <li>Digital-Agentur aussuchen, Konditionen und Projekte briefen und verhandeln.</li>
                  <li>Konzept in zahlreichen Meetings erarbeiten.</li>
                  <li>Einzelne Onlinekanäle je nach Agenturempfehlung aussuchen.</li>
                  <li>Marketing-, Produkt- oder HR-Werbekampagne entwickeln und in den einzelnen Kanälen anlegen.</li>
                  <li>Die einzelnen Kanäle überwachen und warten, wie sich die Kampagne entwickelt und in
                    analogen Abstimmungsrunden lernen, welche Kampagne in welchem Kanal mit welchem Inhalt besser funktioniert hat.</li>
                  <li>Die nächste Kampagne wieder auf dieselbe Art und Weise händisch anlegen und verbessern.</li>
                </ul>
                Dieses Vorgehen ist unflexibel, analog, langsam und teuer. Nutzen Du Stevie, um auf allen
                wichtigen Plattformen schnell und günstig online präsent zu sein. Mit wenigen Klicks können
                Du die Sichtbarkeit Ihres Unternehmens online verbessern.
              </div>
            </collapse-item>
          </div>

          <div class="col-12 col-sm-4 mt-6 mt-sm-0 px-0 pr-sm-4 text-right text-sm-left">
            <div>
              <img class="position-absolute center-position-absolute-right-left" src="/img/icons/stevie/circle-shape-1.svg" alt="Oval">
              <img class="position-absolute center-position-absolute-right-left" src="/img/icons/stevie/circle-shape-2.svg" alt="Oval">
              <img class="position-absolute center-position-absolute-right-left" src="/img/icons/stevie/circle-shape-4.svg" alt="Oval">
              <h1 class="position-absolute center-position-absolute-right-left display-3 text-white font-weight-900">2</h1>
            </div>
            <div class="mt-6 stevie-title-height-100">
              <h1 class="text-dark">Kunden  gewinnen</h1>
            </div>
            <div>
              <p class="text-landing-stevie font-weight-600 text-dark stevie-line-height-13">
                Dank Stevie finden Du schnell und einfach online potentielle Kunden und Ihre Bestandskunden 
                erhalten zudem online fortlaufend interessante Neuigkeiten über Ihr Unternehmen und werden
                nachhaltig an Ihr Unternehmen gebunden.
              </p>
            </div>

            <collapse-item name="2" id="2" header-classes="p-0 bg-transparent border-0" container-classes="bg-transparent shadow-none">
              <template v-slot:title>
                <a href="" class="text-allports"><img src="/img/icons/stevie/right-arrow-shape.svg" class="mr-2" alt="Arrow"> <b>Mehr erfahren</b></a>
              </template>
              <div>
                Du selbst haben in Ihrem Unternehmen das Wissen und die Kompetenz, um Ihre Kunden
                von sich zu begeistern – aber wie werden Kunden auf Du aufmerksam?<br/>
                Wie generieren Du online mehr Aufmerksamkeit und Interesse bei Ihren Kunden?<br/>
                Es ist heute einfacher, einen Film online auszuleihen und ihn sich anzusehen,
                wann immer man will, als für ein Unternehmen seine Produkte und Dienstleistungen schnell,
                einfach und kostengünstig in den für ihn wichtigsten Online-Plattformen zu bewerben. Das
                ändern wir mit Stevie - Nutzen Du Stevie, um mehr Kunden für Du zu interessieren!
              </div>
            </collapse-item>
          </div>

          <div class="col-12 col-sm-4 mt-6 mt-sm-0 px-0 pr-sm-4">
            <div>
              <img class="position-absolute center-position-absolute-left" src="/img/icons/stevie/circle-shape-1.svg" alt="Oval">
              <img class="position-absolute center-position-absolute-left" src="/img/icons/stevie/circle-shape-2.svg" alt="Oval">
              <img class="position-absolute center-position-absolute-left" src="/img/icons/stevie/circle-shape-3.svg" alt="Oval">
              <h1 class="position-absolute center-position-absolute-left display-3 text-white font-weight-900">3</h1>
            </div>
            <div class="mt-6 stevie-title-height-100">
              <h1 class="text-dark">Produkte online bewerben</h1>
            </div>
            <div>
              <p class="text-landing-stevie font-weight-600 text-dark stevie-line-height-13">
                Du produzieren die besten Produkte oder bieten herausragende
                Dienstleistungen an? Warum bewerben Du diese Produkte und
                Dienstleistungen nicht einfach und schnell online? Und zwar ganz
                ohne langes und kompliziertes Einarbeiten in Onlinemarketing,
                Zielgruppenanalysen und technische Kompetenz.
              </p>
            </div>

            <collapse-item name="3" id="3" header-classes="p-0 bg-transparent border-0" container-classes="bg-transparent shadow-none">
              <template v-slot:title>
                <a href="" class="text-allports"><img src="/img/icons/stevie/right-arrow-shape.svg" class="mr-2" alt="Arrow"> <b>Mehr erfahren</b></a>
              </template>
              <div>
                Wir versetzen Du in die Lage, beim Erstellen, Buchen und
                Optimieren Ihrer Online-Kampagne. Ihre Mitbewerber lassen wir
                alt oder besser „analog“ aussehen.<br/>
                Wir helfen Ihnen mit unserer Lösung, nicht nur Ihre internen
                Marketingprozesse zu digitalisieren, sondern effizient Google und
                soziale Netzwerke wie Facebook oder Instagram für Ihren Erfolg
                arbeiten zu lassen und Digitalisieren so auch Ihren Vertrieb. Mit
                Stevie ist es einfach, Ihre Produkte und Services schnell und
                effizient online zu bewerben.
              </div>
            </collapse-item>

          </div>
        </div>
        <div class="col-12 d-flex justify-content-center mt-5 mt-sm-8 mb-9">
          <router-link :to="{ name: 'Register' }">
            <base-button
              block
              borderRadius
              class="button-height-33 trial-button-vi"
            >
              {{ $t("30DaysFreeTrial") }}
            </base-button>
          </router-link>
        </div>
      </div>

      <div id="channels" class="row mt--7 mt-sm--6 mx-0">
        <div class="col-12 px-3 px-md-8 bg-before-landing-stevie">
          <img src="/img/brand/landing_image_4.jpeg" class="mr-2 w-100 border-radius-top-stevie" alt="Photo">
        </div>
        <div class="col-12 row px-4 px-md-9 mt-landing-stevie mx-0">
          <div class="col-12 mt-6 mt-sm-0">
            <h1 class="title-size-landing-stevie font-weight-900 text-white text-center mb-0">5 Kanäle mit nur wenigen Klicks</h1>
          </div>
          <div class="col-12 px-4">
            <p class="font-size-landing-stevie font-weight-600 text-white text-center">
              Die Stevie KI unterstützt Du einfach, schnell und kostengünstig mit erfolgserprobten Kampagnen beim Bespielen aller
              wichtigen Online-Kanäle – so stärken Du nachhaltig Ihre Online-Sichtbarkeit!
            </p>
          </div>
          <div class="col-12 col-md-6 col-lg-4 px-0 px-md-3">
            <card
              header-classes="bg-transparent"
              body-classes="p-0 row"
              class="p-3 card-height-landing"
            >
              <div class="col-12 mb-3">
                <img src="/img/icons/stevie/google.png" class="icon-size-social-landing img-cover" alt="Oval">
              </div>
              <div class="col-12 mb-3">
                <h1 class="display-3 text-prussian-blue font-weight-800">Google</h1>
              </div>
              <div class="col-12 text-card-height">
                <p class="text-landing-stevie font-weight-600">
                  99% aller Suchanfragen laufen über Google! Nutzen Du diese
                  herausragende Marktführerschaft von Google für Ihren Erfolg…
                </p>
              </div>

              <collapse-item name="4" id="4" header-classes="p-0 bg-transparent border-0" container-classes="col-12 bg-transparent shadow-none mb-0">
                <template v-slot:title>
                  <a href="#" class="text-allports"><img src="/img/icons/stevie/right-arrow-shape.svg" class="mr-2" alt="Arrow"> <b>Mehr erfahren</b></a>
                </template>
                <div>
                  Pro Tag werden weltweit ca. 5.5 Mrd. Suchanfragen an Google
                  gestellt. Wir sorgen dafür, dass Du als Unternehmen schnell und
                  effizient mit Ihrem Angebot und Ihren Dienstleistungen gefunden
                  werden. Platzieren Du sich in der Google-Suche vor Ihre
                  Mitbewerber und landen Du mit Google Anzeigen auf der ersten
                  Seite. Mit nur wenigen Klicks und der Stevie-Intelligenz setzen Du
                  daher Ihr Budget effizient, nachhaltig und erfolgreich ein.
                </div>
              </collapse-item>

            </card>
          </div>
          <div class="col-12 col-md-6 col-lg-4 px-0 px-md-3">
            <card
              header-classes="bg-transparent"
              body-classes="p-0 row"
              class="p-3 card-height-landing"
            >
              <div class="col-12 mb-3">
                <img src="/img/icons/stevie/facebook.png" class="icon-size-social-landing img-cover" alt="Oval">
              </div>
              <div class="col-12 mb-3">
                <h1 class="display-3 text-prussian-blue font-weight-800">Facebook</h1>
              </div>
              <div class="col-12 text-card-height">
                <p class="text-landing-stevie font-weight-600">Zielgruppengerechtes Werben im größten sozialen Netzwerk der
                  Welt – Facebook ist ein großartiger Kanal…</p>
              </div>

              <collapse-item name="5" id="5" header-classes="p-0 bg-transparent border-0" container-classes="col-12 bg-transparent shadow-none mb-0">
                <template v-slot:title>
                  <a href="#" class="text-allports"><img src="/img/icons/stevie/right-arrow-shape.svg" class="mr-2" alt="Arrow"> <b>Mehr erfahren</b></a>
                </template>
                <div>
                  Fast 65% aller Erwachsenen im DACH Raum nutzten in den letzten
                  Monaten Facebook täglich. Ihre Zielgruppen dort wirkungsvoll
                  anzusprechen ist für Ihr Unternehmen mit Hilfe der Stevie
                  Kampagnenbuchung ab sofort super einfach! <br/>
                  Egal, ob Ihre Marke oder Ihr Produkt im Vordergrund stehen soll –
                  Stevie bringt Ihr Unternehmen bei Facebook auf die Bühne.
                  Schalten Du mit Stevie mit wenigen Klicks im größten sozialen
                  Netzwerk der Welt Werbung – einfach schnell und kostengünstig
                </div>
              </collapse-item>

            </card>
          </div>
          <div class="col-12 col-md-6 col-lg-4 px-0 px-md-3">
            <card
              header-classes="bg-transparent"
              body-classes="p-0 row"
              class="p-3 card-height-landing"
            >
              <div class="col-12 mb-3">
                <img src="/img/icons/stevie/instagram.png" class="icon-size-instagram-landing img-cover" alt="Oval">
              </div>
              <div class="col-12 mb-3">
                <h1 class="display-3 text-prussian-blue font-weight-800">Instagram</h1>
              </div>
              <div class="col-12 text-card-height">
                <p class="text-landing-stevie font-weight-600">Gewinnen Du auch jüngere Kunden über Instagram für sich.
                  Instagram ist bei Jugendlichen täglich im Einsatz…</p>
              </div>

              <collapse-item name="6" id="6" header-classes="p-0 bg-transparent border-0" container-classes="col-12 bg-transparent shadow-none mb-0">
                <template v-slot:title>
                  <a href="#" class="text-allports"><img src="/img/icons/stevie/right-arrow-shape.svg" class="mr-2" alt="Arrow"> <b>Mehr erfahren</b></a>
                </template>
                <div>
                  Wollen Du mit einem Klick Ihre Onlinesichtbarkeit gleich auch für
                  jüngere Kunden im schnell wachsenden Netzwerk verbessern?
                  Buchen Du einfach das Exklusiv-Paket und nutzen Du damit die
                  Vorteile dieser interessanten Social-Media-Plattform für Ihre
                  Sichtbarkeit.
                </div>
              </collapse-item>

            </card>
          </div>

          <div class="col-12 col-md-6 col-lg-4 px-0 px-md-3">
            <card
              header-classes="bg-transparent"
              body-classes="p-0 row"
              class="p-3 card-height-landing"
            >
              <div class="col-12">
                <h1 class="display-3 text-prussian-blue font-weight-800">E-Mail</h1>
              </div>
              <div class="col-12 text-card-height">
                <p class="text-landing-stevie font-weight-600">
                  Die Kommunikation per E-Mail ist schon über 30 Jahre alt und
                  immer noch extrem erfolgreich: Neu- und Bestandskunden mit EMailkampagnen
                  schnell und wirkungsvoll erreichen…
                </p>
              </div>

              <collapse-item name="7" id="7" header-classes="p-0 bg-transparent border-0" container-classes="col-12 bg-transparent shadow-none mb-0">
                <template v-slot:title>
                  <a href="#" class="text-allports"><img src="/img/icons/stevie/right-arrow-shape.svg" class="mr-2" alt="Arrow"> <b>Mehr erfahren</b></a>
                </template>
                <div>
                  DSGVO konforme E-Mail-Kampagnen für Bestands- oder
                  Neukundenaktionen flankieren das erfolgreiche Werben in den
                  Online-Kanälen. Mit Hilfe von Stevie erstellen Du ab sofort an Ihre
                  Kunden und Adressbestände Emails zu Ihren Produkten und
                  Services und generieren neue Potentiale. Vorgefertigte E-Mail
                  Kampagnen können Du bei Stevie im Exklusiv-Paket nutzen!
                </div>
              </collapse-item>

            </card>
          </div>
          <div class="col-12 col-md-6 col-lg-4 px-0 px-md-3">
            <card
              header-classes="bg-transparent"
              body-classes="p-0 row"
              class="p-3 card-height-landing"
            >
              <div class="col-12">
                <h1 class="display-3 text-prussian-blue font-weight-800">Landingpage</h1>
              </div>
              <div class="col-12 text-card-height">
                <p class="text-landing-stevie font-weight-600">
                  Bewerben Du unkompliziert Produkte mit einer eigenen
                  Landingpage und erfahren Du mit A/B-Testing, welches Vorgehen
                  die besten Erfolgsaussichten hat…
                </p>
              </div>

              <collapse-item name="8" id="8" header-classes="p-0 bg-transparent border-0" container-classes="col-12 bg-transparent shadow-none mb-0">
                <template v-slot:title>
                  <a href="#" class="text-allports"><img src="/img/icons/stevie/right-arrow-shape.svg" class="mr-2" alt="Arrow"> <b>Mehr erfahren</b></a>
                </template>
                <div>
                  Ein neues Produkt bewerben und dazu ohne Aufwand eine dazu
                  passende Landingpage gestalten, ist bei Stevie mit wenigen Klicks
                  buchbar, super einfach und schon im Professionell Paket inklusive!
                </div>
              </collapse-item>

            </card>
          </div>
          <div class="col-12 col-md-6 col-lg-4 px-0 px-md-3">
            <card
              header-classes="bg-transparent"
              body-classes="p-0 row"
              class="p-3 card-height-landing bg-green-yellow"
            >
              <div class="col-12">
                <h1 class="display-3 text-prussian-blue font-weight-800">Dashboard</h1>
              </div>
              <div class="col-12 text-card-height">
                <p class="text-landing-stevie font-weight-600">100% Transparenz bei all Ihren Maßnahmen und das 24 Stunden an
                  7 Tagen die Woche…</p><br/>
              </div>

              <collapse-item name="9" id="9" header-classes="p-0 bg-transparent border-0" container-classes="col-12 bg-transparent shadow-none mb-0">
                <template v-slot:title>
                  <a href="#" class="text-allports"><img src="/img/icons/stevie/right-arrow-shape.svg" class="mr-2" alt="Arrow"> <b>Mehr erfahren</b></a>
                </template>
                <div>
                  Einmalig cool und völlig umsonst: Der „Stevie
                  Sichtbarkeitsindex“ auf deinem personalisierten Stevie Dashboard.
                  Wie steht mein Unternehmen online da? Auf welchen Kanälen bin
                  ich wie präsent und wie ist mein Mitbewerber online sichtbar? Mit
                  nur einem Klick können Du alle Infos über alle Ihre Kanäle und die
                  Entwicklung Ihrer geschalteten Kampagnen live auf dem Stevie
                  Dashboard verfolgen.
                </div>
              </collapse-item>

            </card>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-end">
          <router-link :to="{ name: 'Register' }">
            <base-button
              block
              borderRadius
              class="button-height-33 trial-button-vi"
            >
              {{ $t("30DaysFreeTrial") }}
            </base-button>
          </router-link>
        </div>
      </div>

      <div id="prices" class="row mt-4 mt-md-6 mx-0">
        <div class="col-12">
          <h1 class="display-1 text-dark text-center">Das Online-Marketingtool, das Du
            definitiv brauchen</h1>
        </div>
        <div class="col-12 d-flex justify-content-center mt-4">
          <span class="text-dark font-weight-500 mr-2"> <b>jährlich</b></span>
          <base-switch @input="input" :model-value="true" on-text="" off-text=""></base-switch>
          <span class="text-dark font-weight-500 ml-2"> <b>monatlich</b></span>
        </div>
        <div class="col-12 row px-4 px-md-6 mt-4 mx-0">
          <div class="col-12 col-xl-4 px-0 px-xl-4">
            <card
              header-classes="bg-transparent"
              body-classes="p-0 row"
              class="p-3 mb-1 card-shandow-landing h-100"
            >
              <div class="col-12 mb-4 mt-4">
                <h1 class="display-3 text-center text-prussian-blue font-weight-800">STARTER-PAKET</h1>
              </div>
              <div class="col-12 mb-6">
                <p class="mb-2">beinhaltet:</p>
                <ul class="text-landing-stevie stevie-text-height-250">
                  <li>1 Kampagne</li>
                  <li>Stevie Dashboard</li>
                  <li>Online-Marketing-Index</li>
                  <li>KI-basierte Kampagnenoptimierung</li>
                  <li>Google SEA und Display</li>
                  <li>Facebook Werbung</li>
                </ul>
              </div>
              <div class="col-12 d-flex justify-content-center align-items-end mb-3">
                <base-button
                  @click="goToSubscriptions('starter')"
                  block
                  borderRadius
                  class="button-height-33 trial-button-vi"
                >
                  € {{ isMonthlyOrYearly === "monthly" ? monthlyStarter.price : yearlyStarter.price }} / {{ isMonthlyOrYearly === "monthly" ? "mtl" : "jhrl" }}.
                </base-button>
              </div>
              <div class="col-12 text-center mb-3">
                <p class="text-dark m-0">{{ isMonthlyOrYearly === "monthly" ? monthlyStarter.text : yearlyStarter.text }}</p>
              </div>
            </card>

          </div>
          <div class="col-12 col-xl-4 mt-5 mt-xl-0 px-0 px-xl-4">
            <card
              header-classes="bg-transparent"
              body-classes="p-0 row"
              class="p-3 mb-1 bg-green-yellow card-shandow-landing h-100"
            >
              <div class="col-12 d-flex justify-content-center mb-4 mt-4">
                <badge class="bg-malibu text-prussian-blue position-absolute right-50 mt--4">EMPFOHLEN</badge>
                <h1 class="display-3 text-center  text-prussian-blue font-weight-800">PROFESSIONAL-PAKET</h1>
              </div>
              <div class="col-12 mb-6">
                <p class="mb-2">beinhaltet:</p>
                <ul class="text-landing-stevie stevie-text-height-250">
                  <li>3 Kampagne</li>
                  <li>Stevie Dashboard</li>
                  <li>Online-Marketing-Index</li>
                  <li>KI-basierte Kampagnenoptimierung</li>
                  <li>Google SEA und Display</li>
                  <li>Facebook Werbung</li>
                  <li>Landing-Page</li>
                </ul>
              </div>
              <div class="col-12 d-flex justify-content-center align-items-end mb-3">
                <base-button
                  @click="goToSubscriptions('professional')"
                  block
                  borderRadius
                  class="button-height-33 trial-button-vi"
                >
                  € {{ isMonthlyOrYearly === "monthly" ? monthlyProfessional.price : yearlyProfessional.price }} / {{ isMonthlyOrYearly === "monthly" ? "mtl" : "jhrl" }}.
                </base-button>
              </div>
              <div class="col-12 text-center mb-3">
                <p class="text-dark m-0">{{ isMonthlyOrYearly === "monthly" ? monthlyProfessional.text : yearlyProfessional.text }}</p>
              </div>
            </card>

          </div>
          <div class="col-12 col-xl-4 mt-5 mt-xl-0 px-0 px-xl-4">
            <card
              header-classes="bg-transparent"
              body-classes="p-0 row"
              class="p-3 mb-1 card-shandow-landing h-100"
            >
              <div class="col-12 mb-4 mt-4">
                <h1 class="display-3 text-center text-prussian-blue font-weight-800">EXKLUSIV-PAKET</h1>
              </div>
              <div class="col-12 mb-6">
                <p class="mb-2">beinhaltet:</p>
                <ul class="text-landing-stevie stevie-text-height-250">
                  <li>5 Kampagne</li>
                  <li>Stevie Dashboard</li>
                  <li>Online-Marketing-Index</li>
                  <li>KI-basierte Kampagnenoptimierung</li>
                  <li>Google SEA und Display</li>
                  <li>Facebook Werbung</li>
                  <li>Landing-Page</li>
                  <li>E-Mail-Kampagnen</li>
                  <li>Instagram</li>
                </ul>
              </div>
              <div class="col-12 d-flex justify-content-center align-items-end mb-3">
                <base-button
                  @click="goToSubscriptions('exclusive')"
                  block
                  borderRadius
                  class="button-height-33 trial-button-vi"
                >
                  € {{ isMonthlyOrYearly === "monthly" ? monthlyExclusive.price : yearlyExclusive.price }} / {{ isMonthlyOrYearly === "monthly" ? "mtl" : "jhrl" }}.
                </base-button>
              </div>
              <div class="col-12 text-center mb-3">
                <p class="text-dark m-0">{{ isMonthlyOrYearly === "monthly" ? monthlyExclusive.text : yearlyExclusive.text }}</p>
              </div>
            </card>

          </div>
        </div>
      </div>

      <div class="row mt-6 mt-md-9 mx-0">
        <div class="col-12 col-lg-6 pl-0">
          <img src="/img/brand/landing_image_5.jpeg" class="mr-2 border-radius-right-stevie img-cover w-100" alt="Arrow">
        </div>
        <div class="col-12 col-lg-6 row align-items-center">
          <h1 class="col-12 display-2 text-dark text-center text-lg-left">Die wichtigsten Vorteile, wenn Du mit Stevie Ihre Online-Sichtbarkeit verbessern!</h1>
          <p class="col-12 text-dark font-weight-500 text-center text-lg-left">Werben Du mit Stevie erfolgreich, transparent, einfach, schnell, vorrausschauend und innovativ.</p>
          <div class="col-12 text-center text-lg-left mb-4 mb-lg-0">
            <a href="docs/Stevie_Vorteile.pdf" download="Stevie_Vorteile" class="text-allports"><img src="/img/icons/stevie/right-arrow-shape.svg" class="mr-2" alt="Arrow"> Mehr erfahren - hier Vorteile als PDF downloaden!</a>
          </div>
          <div class="col-12 d-flex d-lg-inline justify-content-center">
            <router-link :to="{ name: 'Register' }">
              <base-button
                block
                borderRadius
                class="button-height-33 trial-button-vi"
              >
                {{ $t("30DaysFreeTrial") }}
              </base-button>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mt-6 mt-lg-9 bg-malibu-stevie mx-0">
        <div class="col-12 mb-6">
          <h1 class="display-2 text-dark text-center mt-4 mt-lg-6">Kontakt</h1>
        </div>
        <div class="col-12 row justify-content-center mt--5 mt-lg-0">
          <div class="col-12 col-lg-6 row justify-content-end pr-lg-5 mb-5 mb-lg-0">
            <div class="col-12 d-flex justify-content-center justify-content-lg-end">
              <div class="profile-photo-contact-size d-flex justify-content-center align-items-center">
                <div class="bg-gradient-landing-contact position-absolute"></div>
                <img class="img-size-contact position-relative" src="/img/brand/landing_image_7.png" alt="Profile photo">
              </div>
            </div>
            <div class="col-12 text-center text-lg-right">
              <h1>Leslie Fernandez</h1>
              <span class="text-prussian-blue font-weight-500">
                Deutsch oder Englisch? Wir melden uns schnellstens bei Ihnen/
                we will get back to you asap
              </span>
            </div>
          </div>
          <contact-form></contact-form>
        </div>
      </div>
    </div>
    <visibility-modal :isOpenModal="isOpenModal" :website="consultedUrl" @closeModal="manageModal('', false)" ></visibility-modal>
    <vi-fails-modal :url="consultedUrl"></vi-fails-modal>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import useLoggedIn from '@/composables/useLoggedIn'
import useSubscription from '@/composables/useSubscription';

import Collapse from '@/components/Collapse/Collapse'
import CollapseItem from '@/components/Collapse/CollapseItem'
import ContactForm from "./Components/ContactForm";
import VisibilityForm from "./Components/VisibilityForm";
import VisibilityModal from "../../Components/VisibilityModal";
import ViFailsModal from "./Components/ViFailsModal.vue";

export default {
  name: "Landing",
  props: ["isDashboard"],
  components: {
    VisibilityForm,
    Collapse,
    CollapseItem,
    ContactForm,
    VisibilityModal,
    ViFailsModal,
},
  data() {
    return {
      monthlyStarter: {
        price: "9",
        text: "Spare über 10 %, pro Monat mit Jahresplan!"
      },
      monthlyProfessional: {
        price: "45",
        text: "Spare über 7 €, pro Monat mit Jahresplan!"
      },
      monthlyExclusive: {
        price: "90",
        text: "Spare 15 €, pro Monat mit Jahresplan!"
      },

      yearlyStarter: {
        price: "90",
        text: "Du sparst über 10 %, pro Monat!"
      },
      yearlyProfessional: {
        price: "450",
        text: "Du sparst über 7 €, pro Monat!"
      },
      yearlyExclusive: {
        price: "900",
        text: "Du sparst 15 €, pro Monat!"
      },
    }
  },
  methods: {
    
  },
  setup(){
    const router = useRouter();
    const { isLoggedIn } = useLoggedIn();
    const { hasSubscription }= useSubscription();
    const isOpenModal = ref(false)
    const isMonthlyOrYearly= ref("monthly")
    const consultedUrl = ref('')


    const manageModal= ( url , isOpen = true) => {
      consultedUrl.value = url.url;
      isOpenModal.value = isOpen;
    }
    const input= (value)=> {
      if (value) {
        isMonthlyOrYearly.value = "monthly"
      } else {
        isMonthlyOrYearly.value = "yearly"
      }
    }
    const goToSubscriptions= (subscription)=> {

      let route;
      //
      if(isLoggedIn.value){
        route = hasSubscription.value ? {name: 'subscription'} : { name: 'subscription', query: {plan: isMonthlyOrYearly.value, subscription: subscription}};
      }else{
        route = {name: 'Register'}
        localStorage.setItem("goToSubscription", true)
      }
      router.push(route)
    }

    return{
      consultedUrl,
      isMonthlyOrYearly,
      isOpenModal,
      manageModal,
      input,
      goToSubscriptions
    }
  }
};
</script>

<style scoped>
.height-landing {
  height: 399px;
}
.img-cover {
  object-fit: cover;
  object-position: top right;
}
.card-size {
  width: 280px;
}
.img-size-contact {
  width: 168px;
  border-radius: 100px;
}
.mt-landing-form {
  margin-top: 200px;
}
.profile-photo-contact-size {
  width: 191px;
  height: 191px;
}
.object-cover {
  object-fit: cover;
  object-position: top;
}
</style>
