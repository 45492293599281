<template>
        <div class="row justify-content-between mx-0">
            <div class="col-12 col-xl-7" v-if="!hasSubscription && selectedSubscription === null">
                <h1 class="subcription-text">{{ $t("noSubscription.title") }}</h1>
                <h4 class="subcription-text">{{ $t("noSubscription.subtitle") }}</h4>
            </div>
            <div class="col-12 col-xl-7" v-if="hasSubscription">
                <h1 class="subcription-text">{{ $t("subscriptionTitle") }}</h1>
            </div>
            <div class="col-12 col-md-5 d-flex justify-content-start justify-content-xl-end align-items-center" v-if="daysOfSubscription">
              <badge v-if="userData.subscription.stripe_status === 'incomplete'" type="warning" class="text-wrap">
                <el-tooltip placement="bottom" :content="$t('stripePaymentIncomplete.message', [userData.subscription.name])">
                  <em class="fas fa-exclamation-triangle icon-info-error"></em>
                </el-tooltip>
                {{ $t('stripePaymentIncomplete.paymentIncomplete') }}. <a class="text-primary" :href="userData.subscription.payment_confirmation_page">{{ $t('stripePaymentIncomplete.completePaymentMethod') }}</a>.
              </badge>
              <badge v-else-if="userData.subscription.stripe_status === 'past_due'" type="danger" class="text-wrap">
                <el-tooltip placement="bottom" :content="$t('stripePaymentError.message')">
                  <em class="fas fa-exclamation-triangle icon-info-error"></em>
                </el-tooltip>
                {{ $t('stripePaymentError.paymentError') }}. <a class="text-primary" :href="userData.subscription.payment_confirmation_page">{{ $t('stripePaymentError.updatePaymentMethod') }}</a>.
              </badge>
              <badge v-else :type="daysOfSubscription > 30 ? 'primary': 'warning'" class="text-wrap">
                {{$t(`daysLeft${isTrialSubscription ? 'Trial':''}`, [daysOfSubscription])}}
              </badge>
            </div>
        </div>
        <div class="row mt-4 mx-0">
            <div v-if="subscriptionTypesFiltered.length > 1" class="col-12 d-flex justify-content-center">
                <span class="text-dark font-weight-500 mr-2">{{$t("year")}}</span>
                <base-switch
                    @input="input"
                    :model-value="isMonthPlan"
                    on-text=""
                    off-text=""
                ></base-switch>
                <span class="text-dark font-weight-500 ml-2">{{$t("month")}}</span>
            </div>
            <div v-if="selectedSubscription !== null" class="col-12 d-flex justify-content-end">
                <base-button
                    block
                    borderRadius
                    type="outline"
                    class="d-flex button-height-33 trial-button-vi"
                    @click="selectSubscription(null)"
                    >
                        {{$t("changeSubscription")}}
                </base-button>
            </div>
            <div class="col-12 row justify-content-center mt-4 mx-0">
                <div
                    v-for="subscription in subscriptionTypesFiltered"
                    :key="subscription.id"
                    class="col-12 col-xl-4 px-0 mb-4 px-xl-2"
                >
                    <card
                    header-classes="bg-transparent"
                    body-classes="p-0"
                    class="p-3 card-shadow-landing h-100 disabled"
                    >
                        <div>
                            <div class="text-center mb-4 mt-4">
                                <h1
                                class="display-4 text-prussian-blue font-weight-800 text-uppercase"
                                >
                                {{ $t(`subscriptionsTypes.${subscription.name}`)}}
                                </h1>
                            </div>
                            <div class="mb-6">
                                <p class="mb-2">{{ $t(`subscriptionsTypes.includes`) }}</p>
                                <ul class="text-landing-stevie stevie-text-height-250">
                                <li v-for="item in subscription.includes" :key="item">
                                    <span v-if="item === 'campaigns'">{{
                                    $t(`subscriptionsTypes.${item}`, [subscription.campaigns])
                                    }}</span>
                                    <span v-else>{{ $t(`subscriptionsTypes.${item}`) }}</span>
                                </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="subscriptionTypesFiltered.length > 1">
                            <div
                                class="d-flex justify-content-center align-items-end mb-3"
                            >
                                <base-button
                                block
                                borderRadius
                                class="button-height-33 trial-button-vi"
                                @click="selectSubscription(subscription.name)"
                                >
                                    €
                                    {{
                                        isMonthPlan
                                        ? subscription.priceMonth
                                        : subscription.priceYear
                                    }}
                                    / {{ isMonthPlan ? "mtl" : "jhrl" }}.
                                </base-button>

                            </div>
                            <div class="col-12 text-center mb-3 min-h-text">
                                <p class="text-dark m-0">
                                {{
                                    isMonthPlan
                                    ? $t(`subscriptionsSave.monthly.${subscription.name}`, [
                                        subscription.savedMonth,
                                        ])
                                    : $t(`subscriptionsSave.annual.${subscription.name}`, [
                                        subscription.savedYear,
                                        ])
                                }}
                                </p>
                            </div>
                        </div>
                    </card>
                </div>
                <div v-if="subscriptionTypesFiltered.length === 1" class="col-8 col-md-6 d-flex flex-xl-column justify-content-center px-0 mb-4 px-xl-2 subscription-info">
                    <template v-for="subscription in subscriptionTypesFiltered"
                    :key="subscription.id" class="pl-2 d-flex flex-column justify-content-center">
                    <div class="d-flex flex-column flex-lg-row">

                        <div class="d-flex" v-if="showPaymentResponse && !isLoading">
                            <base-alert :type="isPaymentSuccesfull ? 'success': 'danger'">
                                <strong>{{$t(`notification.${isPaymentSuccesfull ? `success` : `error`}.paid.title`) }}!</strong>
                                {{$t(`notification.${isPaymentSuccesfull ? `success` : `error`}.paid.text`) }}
                            </base-alert>
                        </div>
                        <div v-if="!hasSubscription" class="p-4 d-flex flex-column">
                            <div class="d-flex justify-content-start">
                                <span class="text-dark font-weight-500 mr-2">{{$t("year")}}</span>
                                <base-switch
                                    @input="input"
                                    :model-value="isMonthPlan"
                                    on-text=""
                                    off-text=""
                                ></base-switch>
                                <span class="text-dark font-weight-500 ml-2">{{$t("month")}}</span>
                            </div>
                            <div class="pt-4 color-muted">
                                <b class="pr-2">{{$t("subscriptionPrice")}}:</b>
                                <span class="text-lowercase">{{isMonthPlan ? subscription.priceMonth : subscription.priceYear}}€/{{$t(isMonthPlan ? 'month': 'year')}}</span>
                            </div>
                            <div class="pt-2 color-muted" >
                                <b class="pr-2">{{$t("saving")}}:</b>
                                <span>{{isMonthPlan ? subscription.savedMonth : subscription.savedYear}}{{ subscription.name === STARTER_STR ? '%' : '€' }}</span>
                            </div>
                            <div class="pt-4" >
                                <base-button
                                block
                                borderRadius
                                class="d-flex button-height-33 trial-button-vi"
                                @click="subscribe(subscription.name)"
                                :disabled="(isRequestLoading || userData.subscription && userData.subscription.current_period_end)"
                                >
                                    <clip-loader v-if="isRequestLoading" color="#ffffff" size="15px"></clip-loader>
                                    <span v-else>{{$t("subscribe")}}</span>
                                </base-button>
                            </div>
                        </div>
                        <div v-if="hasSubscription" class="p-4 d-flex flex-column">
                            <div class="color-muted">
                                <b class="pr-2">{{$t("subscriptionType")}}:</b>
                                <span >{{$t(isMonthPlan ? 'month': 'year')}}</span>
                            </div>
                            <div v-if="userData.subscription.current_period_end" class="pt-2 color-muted" >
                                <b class="pr-2">{{$t("nextRenewal")}}:</b>
                                <span>{{$d(userData.subscription.current_period_end, 'short')}}</span>
                            </div>
                            <div class="pt-4" >
                                <base-button
                                block
                                borderRadius
                                type="danger"
                                class="d-flex button-height-33 "
                                @click="cancel(subscription.name)"
                                :disabled="isRequestLoading"
                                >
                                <clip-loader v-if="isRequestLoading" color="#ffffff" size="15px"></clip-loader>
                                <span v-else>{{$t("cancel")}}</span>
                                </base-button>
                            </div>
                        </div>
                    </div>

                    </template>
                </div>
            </div>
        </div>
</template>
<script>
import Swal from 'sweetalert2'

import { ref } from "@vue/reactivity";
import { computed, onMounted, watch } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import useUserData from '@/composables/useUserData';
import useSubscription from '@/composables/useSubscription';

import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import BaseAlert from "@/components/BaseAlert.vue"
import Badge from "@/components/Badge.vue"
import { useI18n } from 'vue-i18n/index';
import { allSubscriptions, STARTER_STR } from "@/util/Settings.js";

export default {
    name: "Subscription",
    components:{
        ClipLoader,
        BaseAlert,
        Badge
    },
    setup() {
        const { t , d } = useI18n();
        const  route = useRoute()
        const { userData, getUserData, isLoading }= useUserData()
        const { hasSubscription, isTrialSubscription ,  cancelSubscription, newSubscription, confirmSubscription }= useSubscription()
        const typePlan = ref("month");
        const isRequestLoading = ref(false);
        const isMonthPlan = ref(true);
        const showPaymentResponse = ref(false);
        const isPaymentSuccesfull = ref(true);
        const subscriptionTypes = ref(allSubscriptions);
    const selectedSubscription= ref(null)
    const subscriptionTypesFiltered = computed({
        get:() =>{
            if(selectedSubscription.value || hasSubscription.value){
                const subscriptionName = selectedSubscription.value? selectedSubscription.value : userData.value.subscription.name;
                return subscriptionTypes.value.filter(subscription => subscriptionName === subscription.name)
            }else{
                return subscriptionTypes.value
            }
        },

    })
    const daysOfSubscription = computed({
        get:() =>{
            if(userData.value.subscription && userData.value.subscription.current_period_end){
                const dayInMs = 1000 * 3600 * 24;
                let daysOf = new Date(userData.value.subscription.current_period_end).getTime() - new Date().getTime();
                daysOf = Math.ceil(daysOf/(dayInMs))
                return daysOf;
            }
            return null
        },

    })
    const input = value => {
        isMonthPlan.value = value;
    };
    const selectSubscription = subscriptionName =>{

        showPaymentResponse.value = false;
        selectedSubscription.value =  subscriptionName;
    }
    const cancel= async (subscriptionName) => {
        let modalText;
        if(isTrialSubscription.value){

            modalText =t("modalCancelSubscription.trialText")
        }else{
            modalText= t("modalCancelSubscription.normalText", [d(new Date(userData.value.subscription.current_period_end), "short" )])
        }
            Swal.fire({
            title: t('modalCancelSubscription.title'),
            text: modalText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#172B4D',
            cancelButtonColor: '#CDE3EE',
            cancelButtonText: t('goBack'),
            confirmButtonText: t('modalCancelSubscription.btnCancel')
            }).then(async(result) => {
            if (result.isConfirmed) {

                const subscription = {
                    product: subscriptionName
                }
                isRequestLoading.value = true;
                await cancelSubscription(subscription)
                isRequestLoading.value = false
            }
            })
    }
    const subscribe = async(subscriptionName)=>{

        isRequestLoading.value = true;
        const subscription = {
            product: subscriptionName,
            plan: isMonthPlan.value ? 'month': 'year'
        };
        localStorage.setItem("subscription", JSON.stringify(subscription))
        await newSubscription(subscription)
        isRequestLoading.value = false;
    }
    onMounted(async() => {
        if(route.query.session_id){
          const subscription = {
            session_id: route.query.session_id,
          };
          const responseSubscription = await confirmSubscription(subscription)
          showPaymentResponse.value = true;
          isPaymentSuccesfull.value = responseSubscription.data.payment_status === "paid"
          const subscriptionSaved= JSON.parse(localStorage.getItem("subscription"));
          if(!isPaymentSuccesfull.value && subscriptionSaved){
              selectedSubscription.value = subscriptionSaved.product;
              isMonthPlan.value = subscriptionSaved.plan === 'month';
          }
          localStorage.removeItem("subscription");
        }
        if(hasSubscription.value) {
          isMonthPlan.value = userData.value.subscription.subscription_type === "month";
        }
        if(route.query.plan && route.query.subscription){

            selectedSubscription.value= route.query.subscription;
            isMonthPlan.value= route.query.plan === "monthly";
        }
        localStorage.removeItem("goToSubscription")
    })
    watch(hasSubscription.value, (value) => {
      if (isPaymentSuccesfull.value && !value) {
        getUserData()
      }
      if (value) {
        isMonthPlan.value = userData.value.subscription.subscription_type === "month";
      }
    })
    return {
        hasSubscription,
        userData,
        typePlan,
        isRequestLoading,
        showPaymentResponse,
        subscriptionTypes,
        selectedSubscription,
        isLoading,
        isMonthPlan,
        isPaymentSuccesfull,
        daysOfSubscription,
        isTrialSubscription,
        input,
        subscriptionTypesFiltered,
        selectSubscription,
        subscribe,
        cancel,

        STARTER_STR
    };
    },
};
</script>
<style lang="scss" scoped>
.subcription-text {
    font-weight: 300;
    color: rgba(0, 0, 0, 0.65);
}
.text-landing-stevie {
    font-size: 16px;
}
.card-shadow-landing{
    margin-bottom: 25px;
    /deep/ .card-body{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
.subscription-info{
    .color-muted{
        color: #8898aa;
    }
}
.min-h-text{
    min-height: 80px;
}
.button-height-33{
    max-width: 225px;
}
.alert-danger{
    color: #f75676;
    border-color: #f75676;
    background-color: transparent;
}
.alert-success{
    color: #4fd69c;
    border-color: #4fd69c;
    background-color: transparent;
}
</style>
