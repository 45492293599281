import { computed } from "vue";
import { useStore } from "vuex";


const useSocialMedia = () =>{

    const store = useStore();


    const getSocialMediaUrls = async(url) =>{
        const response = await store.dispatch('visibility/getSocialMediaUrls', url)
        return response
    };
    const setRrssForm = (rrssForm)=>{
        store.commit('visibility/setRrssForm', rrssForm)
    }

    return{
        socialMediaUrls: computed(()=> store.state.visibility.socialMediaUrls),
        isLoadingSocialMedia: computed(()=> store.state.visibility.isLoadingSocialMedia),

        getSocialMediaUrls,
        setRrssForm,
    }
}
export default useSocialMedia;