<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success pt-7 pb-4 pt-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">{{ $t("pageNotFound") }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10">
          <div class="card card-profile bg-secondary mt-5">
            <div class="card-body px-5">
              <div class="text-center mb-4">
                <img
                  src="/img/brand/stevie.png"
                  class="border-0 w-50"
                  alt="logo"
                />
              </div>
              <div class="text-center">
                <h3 class="m-0">
                  {{ $t("goBackToThe") }}
                  <badge rounded tag="a" @click="goToDashboard" type="info">{{
                    $t("dashboard")
                  }}</badge>
                  {{ $t("andTryAgain") }}.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";

export default {
  name: "NotFound",
  setup() {
    const router = useRouter();

    const goToDashboard = () => {
      router.push({ name: "Login" });
    };

    return {
      goToDashboard,
    };
  },
};
</script>
<style></style>
