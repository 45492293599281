<template>
    <modal
    v-model:show="showModal"
    @close="closeModal"
    modal-classes=""
    body-classes="p-0 border-radius-stevie"
    modal-content-classes="border-radius-stevie modal-animation"
  >
    <div v-if="showRechargeModal && !showResponse" class="modal-recharge d-flex flex-column justify-content-between m-0 p-4">
        <div class="text-center">
            <h2>{{$t("rechargeTitle")}}</h2>
        </div>
        <div class="row my-4">
            <div  v-for="(recharge, index) in rechargeProducts" :key="recharge.id" :class="{'offset-lg-3': index%2 ===0}" class="col-6 col-lg-3  d-flex justify-content-center">
                <base-button
                  @click="selectedRecharge = recharge.id"
                  type="checkbox"
                  :class="{ 'active-btn': selectedRecharge === recharge.id }"
                  class="btn-recharge p-0 mb-2 d-flex flex-column justify-content-center"
                >
                <h1 class="px-2 mb-0">
                  {{ $n(recharge.price, 'currency') }}
                </h1>
                </base-button>
            </div>
        </div>
        <div class="mt-2 d-flex justify-content-center">
            <base-button
            block
            borderRadius
            class="d-flex button-height-33 trial-button-vi"
            @click="newTransaction()"
            :disabled="isLoadingRecharge || selectedRecharge === null"
            >
                <clip-loader v-if="isLoadingRecharge" color="#ffffff" size="15px"></clip-loader>
                <span v-else>{{$t("recharge")}}</span>
            </base-button>
        </div>
    </div>
    <div v-else class="m-0 p-4">
        <div class="d-flex flex-column justify-content-between align-items-center text-center">
          <div v-if="isPaymentSuccesfull" >
            <h2 class="text-success">
              <i class="fas fa-check-circle"></i>
              {{$t(`notification.success.paid.title`) }}!
            </h2>
            <span class="text-success">
              {{$t(`notification.success.paid.text`) }}
            </span>
            <div class="mt-4">
                <span class="">{{$t("yourBalance")}}</span>
                <strong class="pl-2">{{ $n(walletTotal, 'currency') }}</strong> 
              </div>
          </div>
          <div v-else >
            <h2 class="text-danger">
              <i class="fas fa-times-circle"></i>
              {{$t(`notification.error.paid.title`) }}!
            </h2>
            <span class="text-danger">
              {{$t(`notification.error.paid.text`) }}
            </span>
            <div class="mt-4 d-flex justify-content-center">
               <base-button
                block
                borderRadius
                class="button-height-33 trial-button-vi"
                @click="showResponse = false; showRechargeModal = true"
                >
                    <span >{{$t("tryAgain")}}</span>
                </base-button>
             </div>
          </div>
        </div>        
    </div>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import { ref } from "@vue/reactivity";
import { watch } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import useWallet from "@/composables/useWallet.js";

export default {
  name: "RechargeWalletModal",
  components: {
    Modal,
    ClipLoader,
  },
  props:{
    showModal: Boolean,
    originRoute: String,
    id:Number
    },
  setup( props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const { walletTotal , getTotalAvailable, rechargeWallet } = useWallet();
    const showRechargeModal = ref(false);
    const showResponse = ref(false)
    const rechargeProducts = ref([
      {
        id: 1,
        price: 99,
      },
      {
        id: 2,
        price: 199,
      },
      {
        id: 3,
        price: 299,
      },
      {
        id: 4,
        price: 399,
      },
    ]);
    const selectedRecharge = ref(null);
    const isLoadingRecharge = ref(false);
    const isPaymentSuccesfull = ref(false);

    const newTransaction = async() => {
      
        isLoadingRecharge.value = true;
        const params = {
          amount: Number(selectedRecharge.value), 
          route: props.originRoute,
          id: props.id
        }
        if(!props.id){
          delete params.id
        }
        await rechargeWallet(params)
        
        isLoadingRecharge.value = false;
        
    };
    const closeModal= () => {
        emit('closeModal')
        selectedRecharge.value = null
        showRechargeModal.value = true;
        showResponse.value = false;
        router.replace({...router.currentRoute, query: {}})
    }
    watch(()=> props.showModal, (value)=>{
      if(value){
        if(route.query.wallet_id){
          showRechargeModal.value = false;
          const walletId = route.query.wallet_id
          isPaymentSuccesfull.value = walletId !== "null";
          showResponse.value = true;
          }else{
            showRechargeModal.value= true;
          }
      }
    })

    return {
      walletTotal,
      rechargeWallet,
      showRechargeModal,
      showResponse,
      rechargeProducts,
      selectedRecharge,
      isLoadingRecharge,
      isPaymentSuccesfull,
      newTransaction,
      closeModal
    };
  },
};
</script>

<style scoped lang="scss">
    .btn-recharge {
        height: 100px;
        max-height: 150px;
        border: 1px solid lightgray;
        width: 100%;
        &.active-btn {
          box-shadow: none;
          background-color: rgba(90, 111, 231, 0.1);
        }
    }
    .modal-recharge{
        min-height: 300px;
    }
    .wallet-total{
      font-size:1.2rem;
      font-weight: 600;
    }
    .width-column{
      max-width: 70px!important;

    }
    .min-table{
      min-height: 450px;
      max-width: 99%;
    }

    @media (min-width: 760px){
      /deep/ .modal-dialog{
          max-width: 50%!important;
      }

    }
</style>
