import axios from "axios";
import { formatWebsite } from "../../../composables/useFormatUrl";
import store from "../../index.js";


export const postVisibility = async({ commit, state }, viForm) => {
    commit('setUrlInvalid', '')
    commit('setIsLoading', {isLoading: true , name:'Visibility'})
    commit('setViForm', viForm)
    
    if(!store.state.visibility.isLoggedIn){
        let emptyVI = {}
        Object.keys(state.visibilityData).forEach(field=> emptyVI[field]= null)
        commit('setVisibilityData', emptyVI);
    }
    

    if(state.isLoggedIn && state.userTargets.website && state.userTargets.website.includes(viForm.website)){
        viForm = {...state.userTargets, ...viForm}
    }
    const response = await axios.post('/visibility', viForm )
    if(response && response.status === 200){

        let viData = response.data;
        viData.company_size = viForm.company_size

        const socialMedia = ['google', 'instagram', 'facebook'];
        socialMedia.forEach(item => {
            if (!viForm[item]) {
                viData[item] = null
                viData[item + "_score"] = null
            }
        })
        
        commit('setIsLoading', {isLoading: false , name:'Visibility'})
        commit('setVisibilityData', viData);
       
    } else if(response.status !== 200 && !store.state.visibility.isLoggedIn) {
        setTimeout(()=>{
            //To show modal when vi fails
            commit('setIsViFails', true)
        }, 350)
        
        if( response.url && response.url[0]){
            commit('setUrlInvalid', response.url[0])
        }
    }
    commit('setIsLoading', {isLoading: false , name:'Visibility'})
    return response

}
export const getVisibility = async ({ commit, state }) => {

    commit('setIsLoading', {isLoading: true , name:'Visibility'})

    let response = await axios.get('/visibility');
        
    if(response && response.status === 200){
        let viData = response.data;
        if(state.userTargets){  
            const { userTargets } = state;
            const socialMedia = ['google', 'instagram', 'facebook'];
            socialMedia.forEach(target => {
                if (userTargets[target] === null) {
                    viData[target] = null
                    viData[target + "_score"] = null
                }
            })
        }
        commit('setVisibilityData', viData);
    }
    commit('setIsLoading', {isLoading: false , name:'Visibility'})
    return response

}

export const getSocialMediaUrls = async({ commit }, url) => {
    commit('setUrlInvalid', '')

    commit('setIsLoading', {isLoading: true , name:'SocialMedia'})

    const response = await axios.post('/search-social-media', {url:formatWebsite(url)})
    if(response && response.status === 200){
        commit('setSocialMediaUrls', response.data )
    } else if(response) {
        if( response.url && response.url[0]){
            commit('setUrlInvalid', response.url[0])
        }
    }
    commit('setIsLoading', {isLoading: false , name:'SocialMedia'})
    return response

}

export const editTargets = async ({ commit }, targets) => {

    const response = await axios.put('/user/targets', targets);
    if(response && response.status === 200){

        commit('setUserTargets', response.data)
        commit('setNewTargets', {})
    }

    return response

}
export const getTargets = async ({ commit }) => {

    const response = await axios.get('/user/targets');
    if(response && response.status === 200){

        commit('setUserTargets', response.data);
    }

    return response

}
export const sendVisibilityFail = async ({ commit }, form) => {

    const response = await axios.post('/visibility/fail', form);
    
    return response

}
export const removeTarget = async ({ commit }, target) => {
    const data = {
        platform: target,
        _method: "DELETE"
    }
    const response = await axios.post('/user/targets', data);

    if(response && response.status === 200){

        commit('setUserTargets', response.data)
    }
    
    return response

}

export const editWebsite = async({ commit }, websiteUrl) => {
    const response = await axios.put('/user/targets/website', { website: websiteUrl});

    return response;
}

export const getOptionsToGooglePlaces = async({ commit }, inputUrl) => {
    const response = await axios.post('/user/targets/google-pages', { url: inputUrl});

    return response;
}
