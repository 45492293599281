export const regionsGermany = [
  { id: 0, name: 'germany' },
  { id: 1, name: 'austria' },
  { id: 2, name: 'switzerland' },
  { id: 3, name: 'D_A_CH' },
];

export const campaignStatus = [
  { id: 0, name: 'pending', color: "primary", value: 0, label: "pending" },
  { id: 1, name: 'inReview', color: "info", value: 1, label: "ready" },
  { id: 2, name: 'started', color: "success", value: 2, label: "started" },
  { id: 3, name: 'paused', color: "warning", value: 3, label: "paused" },
  { id: 4, name: 'canceled', color: "danger", value: 4, label: "canceled" },
  { id: 5, name: 'finalised', color: "danger", value: 5, label: "finalised" },
  { id: 6, name: 'inReview', color: "primary", value: 6, label: "inReview" },
]

export const basicToolbarHtmlEditor = [
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
]

export const mailTemplateStatus = {
  0: { id: 0, name: 'pending', color: "primary" },
  1: { id: 1, name: 'ready', color: "success" },
  2: { id: 2, name: 'archived', color: "danger" },
}

export const landingTemplateStatus = {
  0: { id: 0, name: 'pending', color: "primary" },
  1: { id: 1, name: 'ready', color: "success" },
}

export const optionsAlignButtons = [
  { value: 'left', icon: 'align-left' },
  { value: 'center', icon: 'align-center' },
  { value: 'right', icon: 'align-right' },
]

export const optionsAlignButtonsPicture = [
  { value: 'right', icon: 'objects-align-right' },
  { value: 'center', icon: 'objects-align-center' },
  { value: 'left', icon: 'objects-align-left' },
]
export const optionsFontSize = [
  { value: 8, id: 8 },
  { value: 9, id: 9 },
  { value: 10, id: 10 },
  { value: 11, id: 11 },
  { value: 12, id: 12 },
  { value: 14, id: 14 },
  { value: 16, id: 16 },
  { value: 18, id: 18 },
  { value: 20, id: 20 },
  { value: 22, id: 22 },
  { value: 24, id: 24 },
  { value: 26, id: 26 },
  { value: 28, id: 28 },
  { value: 36, id: 36 },
  { value: 48, id: 48 },
  { value: 72, id: 72 },
]

export const optionsStatusCampaignes = [
  { id: 0, value: 0, label: 'pending' },
  { id: 1, value: 1, label: 'ready' },
  { id: 2, value: 2, label: 'started' },
  { id: 3, value: 3, label: 'paused' },
  { id: 4, value: 4, label: 'canceled' },
  { id: 5, value: 5, label: 'finalised' },
  { id: 6, value: 6, label: 'inReview' }
]

export const includesSubscription = [
  "campaigns",
  "dashboard",
  "visibilityIndex",
  "optimisation",
  "SEO",
  "facebook",
  "landing",
  "email",
  "instagram",
]

export const allSubscriptions = [
  {
    id: 1,
    name: "starter",
    priceMonth: "9",
    priceYear: "90",
    savedMonth: "10",
    savedYear: "10",
    includes: [...includesSubscription].splice(0, 6),
    campaigns: "1",
    permissions: {
      numberCampaigns: 1,
      allowDashboard: true,
      allowGoogle: true,
      allowFacebook: true,
      allowLandings: false,
      allowMails: false,
      allowInstagram: false,
    }
  },
  {
    id: 2,
    name: "professional",
    priceMonth: "45",
    priceYear: "450",
    savedMonth: "7",
    savedYear: "7",
    includes: [...includesSubscription].splice(0, 7),
    campaigns: "3",
    permissions: {
      numberCampaigns: 3,
      allowGoogle: true,
      allowFacebook: true,
      allowLandings: true,
      allowMails: false,
      allowInstagram: false,
    }
  },
  {
    id: 3,
    name: "exclusive",
    priceMonth: "90",
    priceYear: "900",
    savedMonth: "15",
    savedYear: "15",
    includes: includesSubscription,
    campaigns: "5",
    permissions: {
      numberCampaigns: 5,
      allowGoogle: true,
      allowFacebook: true,
      allowLandings: true,
      allowMails: true,
      allowInstagram: true,
    }
  },
]

export const allowedVideoFormatsCampaigns = [
  'video/3g2',
  'video/3gp',
  'video/3gp2',
  'video/asf',
  'video/avi',
  'video/dat',
  'video/divx',
  'video/dv',
  'video/f4v',
  'video/flv',
  'video/gif',
  'video/m2ts',
  'video/m4v',
  'video/mkv',
  'video/mod',
  'video/mov',
  'video/mp4',
  'video/mpe',
  'video/mpeg',
  'video/mpeg4',
  'video/mpg',
  'video/mts',
  'video/nsv',
  'video/ogm',
  'video/ogv',
  'video/qt',
  'video/tod',
  'video/ts',
  'video/vob',
  'video/wmv',
]

export const optionsLanguagesStylesWand = [
  { id: 0, value: 'standard language', label: 'standard' },
  { id: 1, value: 'colloquial language', label: 'colloquial' },
  { id: 2, value: 'technical jargon', label: 'technical' },
  { id: 3, value: 'formal language', label: 'formal' },
  { id: 4, value: 'youth slang', label: 'youth' },
]

export const optionsExpectedGoalWand = [
  { id: 0, value: 'increase brand awareness', label: 'increaseBrandAwareness' },
  { id: 1, value: 'increase website traffic', label: 'increaseWebsiteTraffic' },
  { id: 2, value: 'generate leads', label: 'generateLeads' },
  { id: 3, value: 'increase sales', label: 'increaseSales' },
  { id: 4, value: 'customer retention', label: 'customerRetention' },
  { id: 5, value: 'other', label: 'other' }
]

export const optionsLanguagesWand = [
  { id: 0, value: 'german', label: 'german' },
  { id: 1, value: 'english', label: 'english' },
  { id: 2, value: "afrikaans", label: "afrikaans" },
  { id: 3, value: "albanian", label: "albanian" },
  { id: 4, value: "amharic", label: "amharic" },
  { id: 5, value: "arabic", label: "arabic" },
  { id: 6, value: "armenian", label: "armenian" },
  { id: 7, value: "assamese", label: "assamese" },
  { id: 8, value: "aymara", label: "aymara" },
  { id: 9, value: "azerbaijani", label: "azerbaijani" },
  { id: 10, value: "bambara", label: "bambara" },
  { id: 11, value: "basque", label: "basque" },
  { id: 12, value: "belarusian", label: "belarusian" },
  { id: 13, value: "bengali", label: "bengali" },
  { id: 14, value: "bhojpuri", label: "bhojpuri" },
  { id: 15, value: "bosnian", label: "bosnian" },
  { id: 16, value: "bulgarian", label: "bulgarian" },
  { id: 17, value: "catalan", label: "catalan" },
  { id: 18, value: "cebuano", label: "cebuano" },
  { id: 19, value: "chinese simplified", label: "chinese simplified" },
  { id: 20, value: "chinese traditional", label: "chinese traditional" },
  { id: 21, value: "corsican", label: "corsican" },
  { id: 22, value: "croatian", label: "croatian" },
  { id: 23, value: "czech", label: "czech" },
  { id: 24, value: "danish", label: "danish" },
  { id: 25, value: "dhivehi", label: "dhivehi" },
  { id: 26, value: "dogri", label: "dogri" },
  { id: 27, value: "dutch", label: "dutch" },
  { id: 28, value: "esperanto", label: "esperanto" },
  { id: 29, value: "estonian", label: "estonian" },
  { id: 30, value: "ewe", label: "ewe" },
  { id: 31, value: "filipino", label: "filipino" },
  { id: 32, value: "finnish", label: "finnish" },
  { id: 33, value: "french", label: "french" },
  { id: 34, value: "frisian", label: "frisian" },
  { id: 35, value: "galician", label: "galician" },
  { id: 36, value: "georgian", label: "georgian" },
  { id: 37, value: "greek", label: "greek" },
  { id: 38, value: "guarani", label: "guarani" },
  { id: 39, value: "gujarati", label: "gujarati" },
  { id: 40, value: "haitian creole", label: "haitian creole" },
  { id: 41, value: "hausa", label: "hausa" },
  { id: 42, value: "hawaiian", label: "hawaiian" },
  { id: 43, value: "hebrew", label: "hebrew" },
  { id: 44, value: "hindi", label: "hindi" },
  { id: 45, value: "hmong", label: "hmong" },
  { id: 46, value: "hungarian", label: "hungarian" },
  { id: 47, value: "icelandic", label: "icelandic" },
  { id: 48, value: "igbo", label: "igbo" },
  { id: 49, value: "ilocano", label: "ilocano" },
  { id: 50, value: "indonesian", label: "indonesian" },
  { id: 51, value: "irish", label: "irish" },
  { id: 52, value: "italian", label: "italian" },
  { id: 53, value: "japanese", label: "japanese" },
  { id: 54, value: "javanese", label: "javanese" },
  { id: 55, value: "kannada", label: "kannada" },
  { id: 56, value: "kazakh", label: "kazakh" },
  { id: 57, value: "khmer", label: "khmer" },
  { id: 58, value: "kinyarwanda", label: "kinyarwanda" },
  { id: 59, value: "konkani", label: "konkani" },
  { id: 60, value: "korean", label: "korean" },
  { id: 61, value: "krio", label: "krio" },
  { id: 62, value: "kurdish", label: "kurdish" },
  { id: 63, value: "kyrgyz", label: "kyrgyz" },
  { id: 64, value: "lao", label: "lao" },
  { id: 65, value: "latin", label: "latin" },
  { id: 66, value: "latvian", label: "latvian" },
  { id: 67, value: "lingala", label: "lingala" },
  { id: 68, value: "lithuanian", label: "lithuanian" },
  { id: 69, value: "luganda", label: "luganda" },
  { id: 70, value: "luxembourgish", label: "luxembourgish" },
  { id: 71, value: "macedonian", label: "macedonian" },
  { id: 72, value: "maithili", label: "maithili" },
  { id: 73, value: "malagasy", label: "malagasy" },
  { id: 74, value: "malay", label: "malay" },
  { id: 75, value: "malayalam", label: "malayalam" },
  { id: 76, value: "maltese", label: "maltese" },
  { id: 77, value: "maori", label: "maori" },
  { id: 78, value: "marathi", label: "marathi" },
  { id: 79, value: "meiteilon", label: "meiteilon" },
  { id: 80, value: "mizo", label: "mizo" },
  { id: 81, value: "mongolian", label: "mongolian" },
  { id: 82, value: "myanmar", label: "myanmar" },
  { id: 83, value: "nepali", label: "nepali" },
  { id: 84, value: "norwegian", label: "norwegian" },
  { id: 85, value: "nyanja", label: "nyanja" },
  { id: 86, value: "odia", label: "odia" },
  { id: 87, value: "oromo", label: "oromo" },
  { id: 88, value: "pashto", label: "pashto" },
  { id: 90, value: "persian", label: "persian" },
  { id: 91, value: "polish", label: "polish" },
  { id: 92, value: "portuguese", label: "portuguese" },
  { id: 93, value: "punjabi", label: "punjabi" },
  { id: 94, value: "quechua", label: "quechua" },
  { id: 95, value: "romanian", label: "romanian" },
  { id: 96, value: "russian", label: "russian" },
  { id: 97, value: "samoan", label: "samoan" },
  { id: 98, value: "sanskrit", label: "sanskrit" },
  { id: 99, value: "scots gaelic", label: "scots gaelic" },
  { id: 100, value: "sepedi", label: "sepedi" },
  { id: 101, value: "serbian", label: "serbian" },
  { id: 102, value: "sesotho", label: "sesotho" },
  { id: 103, value: "shona", label: "shona" },
  { id: 104, value: "sindhi", label: "sindhi" },
  { id: 105, value: "sinhala", label: "sinhala" },
  { id: 106, value: "slovak", label: "slovak" },
  { id: 107, value: "slovenian", label: "slovenian" },
  { id: 108, value: "somali", label: "somali" },
  { id: 109, value: "spanish", label: "spanish" },
  { id: 110, value: "sundanese", label: "sundanese" },
  { id: 111, value: "swahili", label: "swahili" },
  { id: 112, value: "swedish", label: "swedish" },
  { id: 113, value: "tagalog", label: "tagalog" },
  { id: 114, value: "tajik", label: "tajik" },
  { id: 115, value: "tamil", label: "tamil" },
  { id: 116, value: "tatar", label: "tatar" },
  { id: 117, value: "telugu", label: "telugu" },
  { id: 118, value: "thai", label: "thai" },
  { id: 119, value: "tigrinya", label: "tigrinya" },
  { id: 120, value: "tsonga", label: "tsonga" },
  { id: 121, value: "turkish", label: "turkish" },
  { id: 122, value: "turkmen", label: "turkmen" },
  { id: 123, value: "twi", label: "twi" },
  { id: 124, value: "ukrainian", label: "ukrainian" },
  { id: 125, value: "urdu", label: "urdu" },
  { id: 126, value: "uyghur", label: "uyghur" },
  { id: 127, value: "uzbek", label: "uzbek" },
  { id: 128, value: "vietnamese", label: "vietnamese" },
  { id: 129, value: "welsh", label: "welsh" },
  { id: 130, value: "xhosa", label: "xhosa" },
  { id: 131, value: "yiddish", label: "yiddish" },
  { id: 132, value: "yoruba", label: "yoruba" },
  { id: 133, value: "zulu", label: "zulu" }
]


export const STARTER_STR = 'starter';
