<template>
  <div class="row mt-4 mt-sm-7 mb-4 mb-8 width-legal-notice mx-auto">
    <h1 class="col-12 mt-6 mt-sm-8 mb-5">Haftungs-, Urheberrechts- und Datenschutzhinweis:</h1>
    <p class="col-12">Alle Angaben und Informationen auf unseren Internetseiten sind sorgfältig recherchiert und
      geprüft. Für die Richtigkeit, Vollständigkeit und Aktualität können wir allerdings keine
      Haftung übernehmen. Ebenso übernehmen wir keine Haftung für eventuell auftretende
      Schäden oder Konsequenzen, die durch die Nutzung unserer Seiten entstehen. Für
      Internetseiten Dritter, auf die durch Link verwiesen wird oder von denen unsere Seiten
      aufgerufen werden können, tragen wir keine Verantwortung. Ebenso sind wir nicht
      verantwortlich für den Inhalt solcher Seiten Dritter. Alle Inhalte unserer Internetseiten sind
      urheberrechtlich geschützt. Die Vervielfältigung von Inhalten oder Daten, insbesondere die
      Verwendung von Texten, Textteilen oder Bildmaterial bedarf unserer ausdrücklichen
      vorherigen Genehmigung. Alle personenbezogenen Daten, die wir über unsere InternetSeiten sammeln, werden von Ihnen freiwillig gegeben. Diese werden von uns nur verwendet,
      um Ihre Anfragen zu beantworten oder um Du über unsere Produkte zu informieren. Wir
      werden Ihre personenbezogenen Daten weder an Dritte weitergeben noch anderweitig
      vermarkten.</p>

    <h3 class="col-12 m-0">Datenschutzrichtlinie</h3>
    <p class="col-12">Wir nehmen den Schutz der Daten der Nutzer („Nutzer“ oder „Du“) unserer Website und/
      oder unseres Mobile-App (die „Website“ bzw. der „Mobile-App“) sehr ernst und verpflichten
      uns, die Informationen, die Nutzer uns in Verbindung mit der Nutzung unserer Website und/
      oder unseres Mobile-App (zusammen: „digitale Assets“) zur Verfügung stellen, zu schützen.
      Des Weiteren verpflichten wir uns, Ihre Daten gemäss anwendbarem Recht zu schützen und
      zu verwenden.
      Diese Datenschutzrichtlinie erläutert unsere Praktiken in Bezug auf die Erfassung,
      Verwendung und Offenlegung Ihrer Daten durch die Nutzung unserer digitalen Assets (die
      „Dienste“), wenn Du über Ihre Geräte auf die Dienste zugreifen.
      Lesen Du die Datenschutzrichtlinie bitte sorgfältig durch und stellen Du sicher, dass Du
      unsere Praktiken in Bezug auf Ihre Daten vollumfänglich verstehen, bevor Du unsere Dienste
      verwenden. Wenn Du diese Richtlinie gelesen, vollumfänglich verstanden haben und nicht
      mit unserer Vorgehensweise einverstanden sind, müssen Du die Nutzung unserer digitalen
      Assets und Dienste einstellen. Mit der Nutzung unserer Dienste erkennen Du die
      Bedingungen dieser Datenschutzrichtlinie an. Die weitere Nutzung der Dienste stellt Ihre
      Zustimmung zu dieser Datenschutzrichtlinie und allen Änderungen daran dar. <br/>
      In dieser Datenschutzrichtlinie erfahren Du:</p>
    <ul>
      <li>Welche Daten wir erfassen</li>
      <li>Wie wir Daten sammeln</li>
      <li>Warum wir diese Daten erfassen</li>
      <li>An wen wir die Daten weitergeben</li>
      <li>Wo die Daten gespeichert werden</li>
      <li>Wie lange die Daten vorgehalten werden</li>
      <li>Wie wir die Daten schützen</li>
      <li>Wie wir mit Minderjährigen umgehen</li>
      <li>Aktualisierungen oder Änderungen der Datenschutzrichtlinie</li>
    </ul>

    <h3 class="col-12">Welche Daten erfassen wir?</h3>
    <p class="col-12">Nachstehend erhalten Du einen Überblick über die Daten, die wir erfassen können:</p>
    <ul>
      <li class="mb-3">Nicht identifizierte und nicht identifizierbare Informationen, die Du während des
        Registrierungsprozesses bereitstellen oder die über die Nutzung unserer Dienste
        gesammelt werden („nicht personenbezogene Daten“). Nicht personenbezogene
        Daten lassen keine Rückschlüsse darauf zu, von wem sie erfasst wurden. Nicht
        personenbezogene Daten, die wir erfassen, bestehen hauptsächlich aus technischen
        und zusammengefassten Nutzungsinformationen.
      </li>
      <li>Individuell identifizierbare Informationen, d. h. all jene, über die man Du
        identifizieren kann oder mit vertretbarem Aufwand identifizieren könnte
        („personenbezogene Daten“). Zu den personenbezogenen Daten, die wir über unsere
        Dienste erfassen, können Informationen gehören, die von Zeit zu Zeit angefordert
        werden, wie Namen, E-Mail-Adressen, Adressen, Telefonnummern, IP-Adressen und
        mehr. Wenn wir personenbezogene mit nicht personenbezogenen Daten
        kombinieren, werden diese, solange sie in Kombination vorliegen, von uns als
        personenbezogene Daten behandelt.
      </li>
    </ul>

    <h3 class="col-12">Wie sammeln wir Daten?</h3>
    <p class="col-12">Nachstehend sind die wichtigsten Methoden aufgeführt, die wir zur Sammlung von Daten verwenden:</p>
    <ul>
      <li>Wir erfassen Daten bei der Nutzung unserer Dienste. Wenn Du also unsere digitalen
        Assets besuchen und Dienste nutzen, können wir die Nutzung, Sitzungen und die
        dazugehörigen Informationen sammeln, erfassen und speichern.
      </li>
      <li>Wir erfassen Daten, die Du uns selbst zur Verfügung stellen, beispielsweise, wenn Du
        über einen Kommunikationskanal direkt mit uns Kontakt aufnehmen (z. B. eine E-Mail
        mit einem Kommentar oder Feedback) oder sich registrieren und dort Daten
        eingeben.
      </li>
      <li>Wir können, wie unten beschrieben, Daten aus Drittquellen erfassen.
      </li>
      <li>Wir erfassen Daten, die Du uns zur Verfügung stellen, wenn Du sich über einen
        Drittanbieter wie Facebook oder Google bei unseren Diensten anmelden.
      </li>
    </ul>

    <h3 class="col-12">Warum erfassen wir diese Daten?</h3>
    <p class="col-12">Wir können Ihre Daten für folgende Zwecke verwenden:</p>
    <ul>
      <li>um unsere Dienste zur Verfügung zu stellen und zu betreiben;</li>
      <li>um unsere Dienste zu entwickeln, anzupassen und zu verbessern;</li>
      <li>um auf Ihr Feedback, Ihre Anfragen und Wünsche zu reagieren und Hilfe anzubieten;</li>
      <li>um Anforderungs- und Nutzungsmuster zu analysieren;</li>
      <li>für sonstige interne, statistische und Recherchezwecke;</li>
      <li>um unsere Möglichkeiten zur Datensicherheit und Betrugsprävention verbessern zu können;</li>
      <li>um Verstösse zu untersuchen und unsere Bedingungen und Richtlinien durchzusetzen
        sowie um dem anwendbaren Recht, den Vorschriften bzw. behördlichen Anordnungen zu entsprechen;
      </li>
      <li>um Ihnen Aktualisierungen, Nachrichten, Werbematerial und sonstige Informationen
        im Zusammenhang mit unseren Diensten zu übermitteln. Bei Werbe-E-Mails können
        Du selbst entscheiden, ob Du diese weiterhin erhalten möchten. Wenn nicht, klicken
        Du einfach auf den Abmeldelink in diesen E-Mails.
      </li>
    </ul>

    <h3 class="col-12">An wen geben wir diese Daten weiter?</h3>
    <p class="col-12">Wir können Ihre Daten an unsere Dienstleister weitergeben, um unsere Dienste zu betreiben
      (z. B. Speicherung von Daten über Hosting-Dienste Dritter, Bereitstellung technischer
      Unterstützung usw.).
    </p>
    <p class="col-12">Wir können Ihre Daten auch unter folgenden Umständen offenlegen: (i) um rechtswidrige
      Aktivitäten oder sonstiges Fehlverhalten zu untersuchen, aufzudecken, zu verhindern oder
      dagegen vorzugehen; (ii) um unsere Rechte auf Verteidigung zu begründen oder auszuüben;
      (iii) um unsere Rechte, unser Eigentum oder unsere persönliche Sicherheit sowie die
      Sicherheit unserer Nutzer oder der Öffentlichkeit zu schützen; (iv) im Falle eines
      Kontrollwechsels bei uns oder bei einem unserer verbundenen Unternehmen (im Wege einer
      Verschmelzung, des Erwerbs oder Kaufs (im Wesentlichen) aller Vermögenswerte u. a.); (v)
      um Ihre Daten mittels befugter Drittanbieter zu erfassen, vorzuhalten und/oder zu verwalten
      (z. B. Cloud-Service-Anbieter), soweit dies für geschäftliche Zwecke angemessen ist; (vi) um
      mit Drittanbietern gemeinsam an der Verbesserung Ihres Nutzererlebnisses zu arbeiten. Zur
      Vermeidung von Missverständnissen möchten wir darauf hinweisen, dass wir nicht
      personenbezogene Daten nach eigenem Ermessen an Dritte übermitteln bzw. weitergeben
      oder anderweitig verwenden können.
    </p>

    <h3 class="col-12">Blog und Forum</h3>
    <p class="col-12">Bitte beachten Du, dass unsere Dienste soziale Interaktionen (z. B. Inhalte, Informationen
      und Kommentare öffentlich posten und mit anderen Nutzern chatten) ermöglichen. Wir
      weisen Du darauf hin, dass alle Inhalte oder Daten, die Du in diesen Bereichen zur Verfügung
      stellen, von anderen Personen gelesen, erfasst und verwendet werden können. Wir raten
      davon ab, Informationen zu posten oder mit anderen zu teilen, die Du nicht öffentlich
      machen wollen. Wenn Du Inhalte auf unseren digitalen Assets hochladen oder anderweitig
      im Rahmen der Nutzung eines Dienstes zur Verfügung stellen, erfolgt dies auf eigenes Risiko.
      Wir können nicht die Aktionen anderer Nutzer oder Mitglieder der Öffentlichkeit mit Zugriff
      auf Ihre Daten oder Inhalte kontrollieren. Du nehmen zur Kenntnis und bestätigen hiermit,
      dass Kopien Ihrer Daten selbst nach deren Löschung auf zwischengespeicherten und
      archivierten Seiten oder nach der Erstellung einer Kopie/Speicherung Ihrer Inhalte durch
      Dritte abrufbar bleiben können.
    </p>

    <h3 class="col-12">Cookies und ähnliche Technologien</h3>
    <p class="col-12">Wenn Du unsere Dienste besuchen oder darauf zugreifen, autorisieren wir Dritte dazu,
      Webbeacons, Cookies, Pixel Tags, Skripte sowie andere Technologien und Analysedienste
      („Tracking-Technologien“) einzusetzen. Diese Tracking-Technologien können es Dritten
      ermöglichen, Ihre Daten automatisch zu erfassen, um das Navigationserlebnis auf unseren
      digitalen Assets zu verbessern, deren Performance zu optimieren und ein
      massgeschneidertes Nutzererlebnis zu gewährleisten, sowie zu Zwecken der Sicherheit und
      der Betrugsprävention.
    </p>
    <p class="col-12">Wir können über unsere Dienste und unsere digitalen Assets (einschliesslich Websites und
      Anwendungen, die unsere Dienste einsetzen) Werbung bereitstellen, die auch auf Du
      zugeschnitten sein kann, z. B. Anzeigen, die auf Ihrem jüngsten Surfverhalten auf Websites,
      Geräten oder Browsern basieren.
    </p>
    <p class="col-12">Um diese Werbeanzeigen für Du bereitzustellen, können wir Cookies und/oder JavaScript
      und/oder Webbeacons (einschliesslich durchsichtiger GIFs) und/oder HTML5 Local Storage
      und/oder andere Technologien einsetzen. Wir können auch Dritte einsetzen, wie z. B.
      Netzwerkinserenten (d. h. Dritte, die Werbeanzeigen auf der Grundlage Ihrer WebsiteBesuche einblenden), um gezielte Anzeigen zu schalten. Externe Anbieter von
      Werbenetzwerken, Werbetreibende, Sponsoren und/oder Dienste zur Messung des WebsiteTraffics können ebenfalls Cookies und/oder JavaScript und/oder Webbeacons (einschliesslich
      durchsichtiger GIFs) und/oder Flash-Cookies und/oder andere Technologien verwenden, um
      die Wirksamkeit ihrer Anzeigen zu messen und Werbeinhalte für Du anzupassen. Diese
      Drittanbieter-Cookies und andere Technologien unterliegen der spezifischen
      Datenschutzrichtlinie des jeweiligen Drittanbieters und nicht dieser hier.
    </p>

    <h3 class="col-12">Nicht personenbezogene Daten</h3>
    <p class="col-12">Bitte beachten Du, dass unsere Unternehmen sowie unsere vertrauenswürdigen Partner und
      Dienstanbieter auf der ganzen Welt ansässig sind. Zu den in dieser Datenschutzrichtlinie
      erläuterten Zwecke speichern und verarbeiten wir alle nicht personenbezogenen Daten, die
      wir erfassen, in unterschiedlichen Rechtsordnungen.
    </p>

    <h3 class="col-12">Personenbezogene Daten</h3>
    <p class="col-12">Personenbezogene Daten können in den Vereinigten Staaten, in Irland, Südkorea, Taiwan,
      Israel und so weit für die ordnungsgemässe Bereitstellung unserer Dienste und/oder
      gesetzlich vorgeschrieben (wie nachstehend weiter erläutert) in anderen Rechtsordnungen
      gepflegt, verarbeitet und gespeichert werden.
    </p>

    <h3 class="col-12">Wie lange werden die Daten vorgehalten?</h3>
    <p class="col-12">Bitte beachten Du, dass wir die erfassten Daten so lange aufbewahren, wie es für die
      Bereitstellung unserer Dienste, zur Einhaltung unserer gesetzlichen und vertraglichen
      Verpflichtungen gegenüber Ihnen, zur Beilegung von Streitigkeiten und zur Durchsetzung
      unserer Vereinbarungen erforderlich ist.
      Wir können unrichtige oder unvollständige Daten jederzeit nach eigenem Ermessen
      berichtigen, ergänzen oder löschen.
    </p>

    <h3 class="col-12">Wie schützen wir die Daten?</h3>
    <p class="col-12">Der Hosting-Dienst für unserer digitalen Assets stellt uns die Online-Plattform zu Verfügung,
      über die wir Ihnen unsere Dienste anbieten können. Ihre Daten können über die
      Datenspeicherung, Datenbanken und allgemeine Anwendungen unseres Hosting-Anbieters
      gespeichert werden. Er speichert Ihre Daten auf sicheren Servern hinter einer Firewall und er
      bietet sicheren HTTPS-Zugriff auf die meisten Bereiche seiner Dienste.
    </p>
    <p class="col-12">Alle von uns und unserem Hosting-Anbieter für unsere digitalen Assets angebotenen
      Zahlungsmöglichkeiten halten die Vorschriften des PCI-DSS (Datensicherheitsstandard der
      Kreditkartenindustrie) des PCI Security Standards Council (Rat für Sicherheitsstandards der
      Kreditkartenindustrie) ein. Dabei handelt es sich um die Zusammenarbeit von Marken wie
      Visa, MasterCard, American Express und Discover. PCI-DSS-Anforderungen helfen, den
      sicheren Umgang mit Kreditkartendaten (u. a. physische, elektronische und
      verfahrenstechnische Massnahmen) durch unseren Shop und die Dienstanbieter zu
      gewährleisten.
    </p>
    <p class="col-12">
      Ungeachtet der von uns und unserem Hosting-Anbieter ergriffenen Massnahmen und
      Bemühungen können und werden wir keinen absoluten Schutz und keine absolute Sicherheit
      der Daten garantieren, die Du hochladen, veröffentlichen oder anderweitig an uns oder
      andere weitergeben.<br/>
      Aus diesem Grund möchten wir Du bitten, sichere Passwörter festzulegen und uns oder
      anderen nach Möglichkeit keine vertraulichen Informationen zu übermitteln, deren
      Offenlegung Ihnen Ihrer Meinung nach erheblich bzw. nachhaltig schaden könnte. Da E-Mail
      und Instant Messaging nicht als sichere Kommunikationsformen gelten, bitten wir Du
      ausserdem, keine vertraulichen Informationen über einen dieser Kommunikationskanäle
      weiterzugeben.
    </p>

    <h3 class="col-12">Wie gehen wir mit Minderjährigen um?</h3>
    <p class="col-12">Die Dienste sind nicht für Nutzer bestimmt, die noch nicht die gesetzliche Volljährigkeit
      erreicht haben. Wir werden wissentlich keine Daten von Kindern erfassen. Wenn Du noch
      nicht volljährig sind, sollten Du die Dienste nicht herunterladen oder nutzen und uns keine
      Informationen zur Verfügung stellen.
    </p>
    <p class="col-12">Wir behalten uns das Recht vor, jederzeit einen Altersnachweis zu verlangen, damit wir
      überprüfen können, ob Minderjährige unsere Dienste nutzen. Für den Fall, dass wir Kenntnis
      davon erlangen, dass ein Minderjähriger unsere Dienste nutzt, können wir diesen Nutzern
      den Zugang zu unseren Diensten untersagen und ihn sperren, und wir können alle bei uns
      gespeicherten Daten über diesen Nutzer löschen. Sollten Du Grund zu der Annahme haben,
      dass ein Minderjähriger Daten an uns weitergegeben hat, nehmen Du bitte, wie unten
      erläutert, Kontakt zu uns auf.
    </p>
    <p class="col-12">Wir verwenden Ihre personenbezogenen Daten nur für die in der Datenschutzrichtlinie
      festgelegten Zwecke und nur, wenn wir davon überzeugt sind, dass:
    </p>
    <ul>
      <li>die Verwendung Ihrer personenbezogenen Daten erforderlich ist, um einen Vertrag zu
        erfüllen oder zu schliessen (z. B. um Ihnen die Dienste selbst oder Kundenbetreuung
        bzw. technischen Support bereitzustellen);
      </li>
      <li>die Verwendung Ihrer personenbezogenen Daten notwendig ist, um entsprechenden
        rechtlichen oder behördlichen Verpflichtungen nachzukommen, oder
      </li>
      <li>die Verwendung Ihrer personenbezogenen Daten notwendig ist, um unsere
        berechtigten geschäftlichen Interessen zu unterstützen (unter der Massgabe, dass
        dies jederzeit in einer Weise erfolgt, die verhältnismässig ist und Ihre
        Datenschutzrechte respektiert).
      </li>
    </ul>

    <p class="col-12 m-0">Als EU-Ansässiger können Du:</p>
    <ul>
      <li>eine Bestätigung darüber verlangen, ob personenbezogene Daten verarbeitet
        werden, die Du betreffen, oder nicht, und Zugriff auf Ihre gespeicherten
        personenbezogenen Daten sowie auf bestimmte Zusatzinformationen anfordern;
      </li>
      <li>den Erhalt von personenbezogenen Daten, die Du uns bereitgestellt haben, in einem
        strukturierten, gängigen und maschinenlesbaren Format verlangen;
      </li>
      <li>die Berichtigung lhrer personenbezogenen Daten verlangen, die bei uns gespeichert
        sind;
      </li>
      <li>die Löschung Ihrer personenbezogenen Daten verlangen;</li>
      <li>der Verarbeitung Ihrer personenbezogenen Daten durch uns widersprechen;</li>
      <li>die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten verlangen, oder</li>
      <li>eine Beschwerde bei einer Aufsichtsbehörde einreichen.</li>
    </ul>

    <p class="col-12">Bitte beachten Du jedoch, dass diese Rechte nicht uneingeschränkt gültig sind und unseren
      eigenen berechtigten Interessen und regulatorischen Anforderungen unterliegen können.
      Wenn Du allgemeine Fragen zu den von uns erfassten personenbezogenen Daten und deren
      Verwendung haben, wenden Du sich bitte an uns, wie unten angegeben.
      Im Zuge der Bereitstellung der Dienste können wir Daten grenzüberschreitend an
      verbundene Unternehmen oder andere Dritte und aus Ihrem Land/Ihrer Rechtsordnung in
      andere Länder/Rechtsordnungen weltweit übertragen. Durch die Nutzung der Dienste
      stimmen Du der Übertragung Ihrer Daten ausserhalb des EWR zu.
    </p>
    <p class="col-12">Wenn Du im EWR ansässig sind, werden Ihre personenbezogenen Daten nur dann an
      Standorte ausserhalb des EWR übertragen, wenn wir davon überzeugt sind, dass ein
      angemessenes oder vergleichbares Niveau zum Schutz personenbezogener Daten besteht.
      Wir werden geeignete Schritte unternehmen, um sicherzustellen, dass wir über
      angemessene vertragliche Vereinbarungen mit unseren Drittparteien verfügen, um zu
      gewährleisten, dass entsprechende Sicherheitsvorkehrungen getroffen werden, so dass das
      Risiko einer unrechtmässigen Nutzung, Änderung, Löschung, eines Verlusts oder Diebstahls
      Ihrer personenbezogenen Daten minimiert wird und dass diese Drittparteien jederzeit in
      Übereinstimmung mit den geltenden Gesetzen handeln.
      Rechte gemäss kalifornischem Verbraucherschutzgesetz
    </p>
    <p class="col-12">Wenn Du die Dienste als Einwohner Kaliforniens nutzen, dann sind Du möglicherweise nach
      dem kalifornischen Verbraucherschutzgesetz (California Consumer Privacy Act; „CCPA“)
      berechtigt, Zugriff auf und Löschung Ihrer Daten zu verlangen.
    </p>
    <p class="col-12">Um Ihr Recht auf den Zugriff und die Löschung Ihrer Daten geltend zu machen, lesen Du bitte
      nachstehend, wie Du Kontakt zu uns aufnehmen können.
    </p>
    <p class="col-12">Wir verkaufen keine personenbezogenen Daten der Nutzer für die Absichten und Zwecke des
      CCPA
    </p>
    <p class="col-12">Nutzer der Dienste, die in Kalifornien ansässig und unter 18 Jahre alt sind, können per E-Mail
      unter der nachstehend im Abschnitt „Kontakt“ angegebenen Adresse die Löschung ihrer
      veröffentlichten Inhalte verlangen und erwirken. Diese Anträge müssen alle mit „California
      Removal Request“ gekennzeichnet sein. Alle Anforderungen müssen eine Beschreibung der
      Inhalte enthalten, deren Löschung Du wünschen sowie ausreichende Informationen, mit
      deren Hilfe wir das Material ausfindig machen können. Wir akzeptieren keine Mitteilungen,
      die nicht gekennzeichnet sind bzw. nicht ordnungsgemäss übermittelt werden, und wir sind
      möglicherweise nicht in der Lage zu antworten, wenn Du nicht ausreichend Informationen
      zur Verfügung stellen. Bitte beachten Du, dass Ihre Anforderung nicht sicherstellt, dass das
      Material vollständig bzw. umfassend gelöscht wird. Von Ihnen veröffentlichtes Material kann
      beispielsweise von anderen Nutzern oder Dritten wiederveröffentlicht oder erneut gepostet
      werden.
    </p>

    <h3 class="col-12">Aktualisierungen oder Änderungen der Datenschutzrichtlinie</h3>
    <p class="col-12">Wir können diese Datenschutzrichtlinie nach eigenem Ermessen von Zeit zu Zeit
      überarbeiten, die auf der Website veröffentlichte Version ist immer aktuell (siehe Angabe
      zum „Stand“). Wir bitten Du, diese Datenschutzrichtlinie regelmässig auf Änderungen zu
      überprüfen. Bei wesentlichen Änderungen werden wir einen Hinweis dazu auf unserer
      Website veröffentlichen. Wenn Du die Dienste nach erfolgter Benachrichtigung über
      Änderungen auf unsere Website weiter nutzen, gilt dies als Ihre Bestätigung und Zustimmung
      zu den Änderungen der Datenschutzrichtlinie und Ihr Einverständnis an die Bedingungen
      dieser Änderungen gebunden zu sein.
    </p>

    <h3 class="col-12">Kontakt</h3>
    <p class="col-12">Wenn Du allgemeine Fragen zu den Diensten oder den von uns über Du erfassten Daten und
      deren Verwendung haben, kontaktieren Du uns bitte unter:
    </p>
    <p class="col-12 m-0">Name: Patricia Kastner – Stevie Agency GmbH</p>
    <p class="col-12 m-0">Anschrift: Hauptstrasse 82, 8272 Ermatingen, Schweiz</p>
    <p class="col-12">E-Mail-Adresse: info(@)stevie.cloud</p>

    <h3 class="col-12">Ausschlussklausel</h3>
    <p class="col-12">Die hier enthaltenen Informationen ersetzen keine Rechtsberatung und Du sollten sich nicht
      allein darauf stützen. Spezifische Anforderungen in Bezug auf Rechtsbegriffe und Richtlinien
      können sich von Staat zu Staat und/oder von Rechtssystem zu Rechtssystem unterscheiden.
      Wie in unseren Nutzungsbedingungen dargelegt, sind Du selbst dafür verantwortlich
      sicherzustellen, dass Ihre Dienste nach dem für Du maßgeblichen Recht zulässig sind und Du
      sich daran halten.<br/>
      Um sicherzustellen, dass Du Ihren gesetzlichen Verpflichtungen vollumfänglich entsprechen,
      empfehlen wir Ihnen ausdrücklich, sich professionell beraten zu lassen, um besser
      nachvollziehen zu können, welche Anforderungen für Du speziell gelten.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped>
.width-legal-notice {
  max-width: 1080px;
}
</style>
