import { computed } from "vue";
import store from "../store";

const useWallet = () => {

  const rechargeWallet = async(params ) => {
    const response= await store.dispatch('user/rechargeWallet', params)
    return response
  }
  const getWalletData = async(page = 1) => {
    const response= await store.dispatch('user/getWalletData', page)
    return response
  }
  const getTotalAvailable = async() => {
    const response= await store.dispatch('user/getTotalAvailable')
    return response
  }
  return {
    wallet: computed(()=> store.state.user.wallet),
    walletTotal: computed(()=> store.state.user.walletTotal),
    rechargeWallet,
    getWalletData,
    getTotalAvailable
  };
};

export default useWallet;
