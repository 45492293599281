export const setCampaigns = ( state, campaignsData ) => {

    state.campaignsData = campaignsData;
}
export const setIsLoading = ( state, isLoading ) => {

    state.isLoading = isLoading;
}
export const setTemplates = ( state, templates ) => {
    state.templates = templates;
}
export const setTotalActivesCampaigns = ( state, totalActivesCampaigns ) => {
    state.totalActivesCampaigns = totalActivesCampaigns;
}
