import axios from "axios";

export const getCampaignTexts = async ({ commit }, { isFirstGoogleDescription = null, currentFieldValue, data :payload }) => {
  const fieldWithNewEndpoint = ['google_headline', 'google_description', 'google_long_headline', 'meta_headline', 'meta_text', 'meta_url_description'];
  const isUniqueField = payload.fields.length === 1;

  let route = '/campaigns/magic-wand';

  payload.keywords = JSON.stringify(payload.keywords);

  if(isUniqueField && fieldWithNewEndpoint.includes(payload.fields[0])) {
    const field = payload.fields[0];
    route = `${route}/${field}`;

    payload[field] = JSON.stringify(currentFieldValue.filter(fieldValue => fieldValue !== ''));
    if (isFirstGoogleDescription !== null) payload.is_first_description = isFirstGoogleDescription;
  }

  try {
    const response = await axios.post(route, payload);
    return { response, isUniqueField };
  } catch (error) {
    console.log(error);
  }
}
