import { createStore } from 'vuex'
import visibility from './modules/visibility'
import user from './modules/user'
import campaigns from './modules/campaigns'
import mails from './modules/mails'
import landings from './modules/landings'
import stevieAI from './modules/stevieAI'



const store = createStore({
    modules: {
        visibility,
        user,
        campaigns,
        mails,
        landings,
        stevieAI,
    }
})




export default store