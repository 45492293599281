<template>
  <base-nav
    container-classes="row vw-100 h-100 justify-content-between align-items-center padding-navbar bg-transparent"
    menu-classes="justify-content-start col-9 px-0"
    class="navbar-horizontal navbar-height"
    :class="{ 'navbar-dark': type === 'default' }"
    :transparent="true"
    expand="xl"
  >
    <template v-slot:brand>
      <div class="navbar-wrapper col-3 px-0">
        <router-link class="navbar-brand logo-size p-0" to="/">
          <img
            class="w-100 h-100"
            src="/img/brand/stevie-white.png"
            alt="logo"
          />
        </router-link>
      </div>
    </template>
    <!-- Navbar links -->
    <div class="row w-100">
      <div class="col-8 px-0">
        <h1 v-if="isLoggedIn" class="m-0 text-white font-weight-900 font-size">
          Hi {{ userData.name }}
        </h1>
        <div v-if="isLoggedIn && visibilityData.timestamp">
          <img
            class="styles-loading-shape"
            src="/img/icons/stevie/loading-shape.svg"
            alt="Loading shape"
          />
          <span class="ml-2 text-malibu"
            >{{ $t("lastUpdated") }}: {{ $d(new Date(visibilityData.timestamp),'long') }} Uhr</span
          >
        </div>
      </div>
      <div class="col-4 px-0">
        <ul class="navbar-nav align-items-center">
          <li class="nav-item d-xl-none">
            <!-- Sidenav toggler -->
            <div
              class="pr-3 sidenav-toggler"
              :class="{
                active: $sidebar.showSidebar,
                'sidenav-toggler-dark': type === 'default',
                'sidenav-toggler-light': type === 'light'
              }"
              @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </div>
          </li>
        </ul>
        <ul
          class="navbar-nav align-items-center d-flex justify-content-end mt-3"
          :class="{'justify-content-between': isLoggedIn}"
        >
          <li class="padding-navbar-icons">
            <base-button
              v-if="!isLoggedIn"
              block
              class="bg-transparent border-0 shadow-none"
              @click="searchNewUrl"
            >
              <img
                class="styles-search-shape"
                src="/img/icons/stevie/search-shape.svg"
                alt="Search shape"
              />
            </base-button>
          </li>
          <li v-if="isLoggedIn && !hasSubscription" class="mr-1">
            <div>
              <base-button
                block
                borderRadius
                class="button-height-33 share-button-db subscription-btn"
                @click="router.push({name: 'subscription'})"
              >
                <i class="fas fa-crown mr-1"></i>
                {{ $t("subscribe") }}
              </base-button>
            </div>
          </li>
          <li v-if="false"> 
            <!-- temporary commented code until facebook integration is working again -->
            <div>
              <!-- <base-button
                block
                borderRadius
                class="button-height-33 share-button-db"
                @click="shareWithFacebook"
              >
                <img
                  class="styles-message-shape mr-1 mt--1"
                  src="/img/icons/stevie/share-shape.svg"
                  alt="Share shape"
                />
                {{ $t("share") }} 
              </base-button> -->
            </div>
          </li> 
        </ul>
      </div>
    </div>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import OnlineShape from "../../components/Stevie/OnlineShape";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n/index";
import useIsloggedIn from "../../composables/useLoggedIn"
import useVisibility from '../../composables/useVisibility';
import useUserData from '../../composables/useUserData';
import { useRouter } from 'vue-router';
import useSubscription from "../../composables/useSubscription";

export default {
  components: {
    BaseNav,
    OnlineShape,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      name: null,
      profileUrl: `${
        process.env.VUE_APP_BACKOFFICE_URL
      }/pair/profile/${localStorage.getItem("api_token")}`,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    setBackgroundColor() {
      document.body.classList.add("background-dashboard-gradient");
      document.body.classList.add("min-vh-100");
    },
    removeBackgroundColor() {
      document.body.classList.remove("background-dashboard-gradient");
      document.body.classList.remove("min-vh-100");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },
  setup() {
    const router= useRouter()
    const { t } = useI18n();
    const toast = useToast();
    const { isLoggedIn } = useIsloggedIn()
    const { userData }= useUserData();
    const { setVisibilityData, visibilityData }= useVisibility();
    const { hasSubscription }= useSubscription();

    const shareWithFacebook = () => {
      FB.ui({
        method: 'share',
        href: process.env.VUE_APP_URL
      }, function(response){
        if (response && !response.error_message) {
          toast.success(t("notification.success.general"));
        } else {
          toast.error(t("notification.error.general"));
        }
      });
    }

    const searchNewUrl = () => {
      const visibilityData= {
        id: null,
        company_type:null,
        company_size:null,
        facebook_score: null,
        google_score: null,
        instagram_score: null,
        other01_score: null,
        other02_score: null,
        technik_score: null,
        website_score: null,
        facebook: null,
        google: null,
        instagram: null,
        kompass: null,
        linkedin: null,
        twitter: null,
        url: null,
        website:null,
        werzuwem: null,
        wlw: null,
        xing: null,
        youtube: null,
    }
      setVisibilityData(visibilityData)
    }

    return {
      isLoggedIn,
      userData,
      visibilityData,
      hasSubscription,
      router,
      shareWithFacebook,
      searchNewUrl
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.font-size {
  font-size: 30px;
}
.subscription-btn{
  background-color: #8dfd36!important;
  color: #001975!important;
  width: 100%!important;
  display: flex;
}
</style>
