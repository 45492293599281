<template>
  <card
    header-classes="bg-transparent"
    body-classes="p-0 card-vi row"
    card-classes="card-vi"
  >
    <div class="col-12 d-flex justify-content-between mb--5">
      <h4 class="margin-left-title mb-0 card-text">
        {{ $t("yourOnlineVisibility") }}
      </h4>
      <div class="margin-right-title">
        <base-button v-if="isLoggedIn" class="btn-default bg-transparent border-0 shadow-none" @click="reloadVI" :disabled="isLoadingVisibility">
          <img
            v-if="!isLoadingVisibility"
            :src="loadingShape"
            class="stevie-rotate-180 mt--2 mr-0 mr-sm-2 icon-card"
            alt="Polygon icon"
          />
          <clip-loader v-else class="mr-0 mr-sm-2" :color="colorLoader" :size="sizeLoader"></clip-loader>
        </base-button>
        <img
          :src="polygonShape"
          class="stevie-rotate-180 mt--2 mr-0 mr-sm-2 icon-card"
          alt="Polygon icon"
        />
        <span class="mb-0 card-text">{{ indexVisibility.website }}</span>
        <el-tooltip placement="top" :content="$t('infoScore.visibility')">
          <img :src="helpShape" class="mt--1 ml-0 m-sm-2 icon-card" alt="Info icon" />
        </el-tooltip>
      </div>
    </div>
    <div class="col-12">
      <bar-meter :index="indexVisibility.website_score"></bar-meter>
    </div>
  </card>
</template>

<script>
import { ref } from "vue";
import useVisibility from '../../../composables/useVisibility';
import useLoggedIn from '../../../composables/useLoggedIn';

import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import BarMeter from "./BarMeter";

export default {
  name: "pluginVI",
  props: ["indexVisibility", "loggedIn"],
  components: {
    BarMeter,
    ClipLoader,
  },
  data() {
    return {
      polygonShape: "/img/icons/stevie/polygon-shape.svg",
      helpShape: "/img/icons/stevie/help-shape.svg",
      speedometer1: "/img/icons/stevie/speedometer-1.svg",
      speedometer2: "/img/icons/stevie/speedometer-2.svg",
      speedometer3: "/img/icons/stevie/speedometer-3.svg",
      ovalShape: "/img/icons/stevie/oval-shape.svg",
      loadingShape: "/img/icons/stevie/loading-shape.svg",
    };
  },
  emits: ['reloadVI'],
  setup(props, { emit }) {
    const visibilityMessage = ref(null);
    const progressType = ref("");
    const colorLabelText = ref("");

    const colorLoader = ref("#86D3F7");
    const sizeLoader = ref("15px");
    const { isLoggedIn } = useLoggedIn()
    const { visibilityData, isLoadingVisibility  } = useVisibility()

    const goToLogin = () => {
      emit("login");
    };

    const goToRegister = () => {
      emit("register");
    };
    const reloadVI = async() => {
      emit('reloadVI')
    }

    return {
      visibilityMessage,
      progressType,
      colorLabelText,
      isLoggedIn,
      visibilityData,
      colorLoader,
      sizeLoader,
      isLoadingVisibility,
      goToLogin,
      goToRegister,
      reloadVI,
    };
  },
};
</script>

<style scoped>
.therm-height {
  max-height: 434px;
}
.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 575px) {
  .therm-height {
    max-height: 250px;
  }
}
</style>
