
export default () => ({
    isLoggedIn: false,
    isLoading: false,
    userData:{
      id: 2,
      name: "",
      last_name: "",
      company_name: null,
      email: "",
      language: "",
      access_token: "",
      token_type: "",
      is_linked_facebook: false,
      last_login: "",
      subscription: {
        id: 0,
        user_id: 0,
        name: "",
        stripe_id: "",
        stripe_status: "",
        stripe_price: "",
        quantity: 0,
        trial_ends_at: "",
        ends_at: "",
        created_at: "",
        updated_at: "",
        items: [
          {
            id: 0,
            subscription_id: 0,
            stripe_id: "",
            stripe_product: "",
            stripe_price: "",
            quantity: 0,
            created_at: "",
            updated_at: ""
          }
        ]
      },
      profile_photo_path: ""
    },
    wallet:{},
    walletTotal:0,
    isOpenSubscriptionModal: false,
    subscriptionRequired: "professional"

})