<template>
  <div class="row justify-content-between">
    <div class="col-5 col-sm-3 bg-athens-gray margin-profile height-profile">
      <div class="d-flex justify-content-center mt-5">
        <img v-if="userData.profile_photo_url" :src="userData.profile_photo_url" class="img-profile-data" alt="Profile photo" />
        <img v-else :src="defaultProfilePhoto" class="img-profile-data" alt="Profile photo" />
      </div>
      <div class="text-center position-absolute bottom-0 left-0 right-0 mb-4">
        <base-button
          class="mx-0 login-facebook border-0 font-weight-700"
          size="sm"
          @click="unlinkFacebookModal"
        >
          <img class="mr-2" src="/img/icons/common/facebook.svg" alt="Facebook icon">
          {{ userData.is_linked_facebook ? $t('unlinkFromFacebook') : $t('linkWithFacebook') }}
        </base-button>
      </div>
    </div>
    <div class="col-7 col-sm-9">
      <Form
        id="updatePersonalInfo"
        class="mt-6"
        @submit="updatePersonalInfo"
        :validation-schema="schema"
        :initialValues="userData"
      >
        <div>
          <div class="row">
            <div class="col-lg-6 my-2">
              <label for="name" class="label d-block uploader-campaigns">{{ $t('firstName') }}</label>
              <Field
                id="name"
                name="name"
                type="text"
                :placeholder="$t('firstName')"
                class="input-stevie w-100"
              />
              <ErrorMessage name="name" class="text-danger text-required-error"/>
            </div>
            <div class="col-lg-6 my-2">
              <label for="last_name" class="label d-block">{{ $t('lastName') }}</label>
              <Field
                id="last_name"
                name="last_name"
                type="text"
                :placeholder="$t('lastName')"
                class="input-stevie w-100"
              />
              <ErrorMessage name="last_name" class="text-danger text-required-error"/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 my-2">
              <label for="email" class="label d-block">{{ $t('email') }}</label>
              <Field
                id="email"
                name="email"
                type="email"
                :placeholder="$t('email')"
                class="input-stevie w-100"
              />
              <ErrorMessage name="email" class="text-danger text-required-error"/>
            </div>
            <div class="col-lg-6 my-2">
              <label for="company_name" class="label d-block">{{ $t('companyName') }}</label>
              <Field
                id="company_name"
                name="company_name"
                type="text"
                :placeholder="$t('companyName')"
                class="input-stevie w-100"
              />
              <ErrorMessage name="company_name" class="text-danger text-required-error"/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 user-profile">
              <label for="image" class="label">{{ $t("image") }}:</label>
              <Field name="image" v-slot="{ field }">
                <dropzone-file-upload
                  id="image"
                  ref="image"
                  :multiple="false"
                  :url="urlDropzone"
                  :options="{ acceptedFiles: 'image/jpeg, image/jpg, image/png' }"
                  @change="onFileSelected"
                  class="image-height"
                />
              </Field>
            </div>
          </div>
          <div class="row justify-content-end m-0">
            <base-button
              borderRadiusLeft
              borderRadiusRight
              native-type="submit"
              class="p-0 save-button mt-4"
              form="updatePersonalInfo"
              :disabled="isLoading"
            >
            <span v-if="!isLoading"> {{ $t("save") }}</span>
            <clip-loader v-else color="#ffffff" size="20px" class="pt-2"></clip-loader>
            </base-button>
          </div>
        </div>
      </Form>
    </div>
  </div>
  <UnlinkFacebookModal v-model:show="isModalUnlinkFacebook" @unlink-facebook-modal="linkUnlinkFacebook" @show-facebook-modal="unlinkFacebookModal"></UnlinkFacebookModal>
  <modal
    v-model:show="isModalVueCropper"
    modal-classes="border-radius-stevie"
    body-classes="p-0 border-radius-stevie bg-athens-gray"
    modal-content-classes="border-radius-stevie modal-animation"
    :is-allowed-close-modal="isAllowedCloseModal"
  >
    <div class="crop-image">
      <Cropper
        ref="cropper"
        class="w-100 bg-white"
        :src="selectedFile"
        :stencil-props="{
				  aspectRatio: 1,
			  }"
        :stencil-component="$options.components.CircleStencil"
      />
      <div
        class="crop-button border-radius-stevie font-weight-500 text-dark cursor-pointer bg-white-50 d-flex justify-content-center position-absolute"
        @click="crop"
      >{{ $t("cropImage") }}</div>
    </div>
  </modal>
</template>

<script>
import { Form, Field, ErrorMessage  } from "vee-validate";
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
import axios from "axios";
import { useI18n } from "vue-i18n/index";
import { useToast } from "vue-toastification";
import { ref, inject } from "vue";
import UnlinkFacebookModal from "../../../Components/UnlinkFacebookModal";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import modal from "../../../../components/Modal";
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import useUserData from "@/composables/useUserData"

export default {
  name: "UserData",
  components: {
    Form,
    DropzoneFileUpload,
    ClipLoader,
    UnlinkFacebookModal,
    modal,
    Cropper,
    CircleStencil,
    Field,
    ErrorMessage
  },
  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const defaultProfilePhoto = ref("/img/brand/default-profile-photo.jpg");
    const loginFacebook = inject("loginFacebook");
    const changeLoginFacebook = inject("changeLoginFacebook");
    changeLoginFacebook(JSON.parse(localStorage.getItem("loginFacebook")));
    const isModalUnlinkFacebook = ref(false)
    const isModalVueCropper = ref(false)
    const urlDropzone = ref(process.env.VUE_APP_URL);
    const image = ref(null);
    const cropper = ref(null);
    const mimeType = ref();
    const selectedFile = ref();
    const resultingImage = ref(null);
    const isAllowedCloseModal = ref(false);
    const isLoading = ref(false);
    const { changeUserData, userData }= useUserData();

    const schema = {
      name: 'required',
      last_name: 'required',
      email: 'required|email',
      company_name: 'required',
    };

    const updatePersonalInfo = async (value) => {
      isLoading.value = true;
      const route = "/user/update";
      const formData = new FormData();
      const fields = ["name", "last_name", "email", "company_name"]
      fields.forEach(field => formData.append(field, value[field]))
      if(resultingImage.value) formData.append("photo", resultingImage.value);
      formData.append("_method", "PUT");
      let response = await axios.post(route, formData);

      if (response && response.status === 200) {
        toast.success(t("notification.success.general"));
        changeUserData(response.data);

      }
      isLoading.value = false;
    }

    const unlinkFacebookModal = () => {
      if (userData.value.is_linked_facebook) {
        isModalUnlinkFacebook.value = !isModalUnlinkFacebook.value;
      } else {
        linkUnlinkFacebook();
      }
    }

    const linkUnlinkFacebook = async () => {
      isModalUnlinkFacebook.value = false;
      if (!userData.value.is_linked_facebook) {
        if (loginFacebook.value.status !== "connected") {
          await new Promise(async (resolve) => {
            FB.login(function (response) {
              changeLoginFacebook(response);
              resolve();
            }, {scope: 'public_profile,email'});
          });
        }

        if (loginFacebook.value.status === "connected") {
          FB.api(`/me?fields=email`, async function(response) {
            const data = loginFacebook.value.authResponse;
            data.email = response.email;
            const route = "/fb/link";
            let responseLink = await axios.post(route, data);
            if (responseLink && responseLink.status === 200) {
              changeLoginFacebook(responseLink.data);
              userData.value.is_linked_facebook = true;
              changeUserData(userData.value)
              toast.success(t("notification.success.general"));
            }
          });
        }
      }

      if (userData.value.is_linked_facebook) {
        const route = "/fb/unlink";
        let response = await axios.post(route);
        if (response && response.status === 200) {
          userData.value.is_linked_facebook = false;
          changeUserData(userData.value)
          toast.success(t("notification.success.general"));
        }
      }
    }

    const onFileSelected = (e) => {
      if(e.length === 0)return
      isModalVueCropper.value = true
      const file = e[0];
      mimeType.value = file.type
      if (typeof FileReader === 'function') {
        isModalVueCropper.value = true
        const reader = new FileReader()
        reader.onload = (event) => {
          selectedFile.value = event.target.result
        }
        reader.readAsDataURL(file)
      } else {
        toast.success(t("notification.error.fileReaderNotSupported"));
        isModalVueCropper.value = false;
      }
    }

    const crop = () => {
      const { canvas } = cropper.value.getResult();
      resultingImage.value = dataURLtoFile(canvas.toDataURL(mimeType.value, 1.0), "profilePhoto");
      isModalVueCropper.value = false;
    }

    const dataURLtoFile = (dataUrl, fileName) => {
      let arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {type:mime});
    }

    return {
      userData,
      defaultProfilePhoto,
      isModalUnlinkFacebook,
      isModalVueCropper,
      urlDropzone,
      image,
      cropper,
      selectedFile,
      isAllowedCloseModal,
      schema,
      updatePersonalInfo,
      linkUnlinkFacebook,
      unlinkFacebookModal,
      onFileSelected,
      crop,
      isLoading
    }
  }
}
</script>

<style scoped>
.margin-profile {
  margin-bottom: -78px;
  margin-left: -9px;
  border-bottom-left-radius: 20px;
}
.height-profile {
  min-height: 565px;
}
.image-height{
  height: 188px;
}
</style>
