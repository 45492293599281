import { createI18n } from "vue-i18n/index";

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const datetimeFormats = {
  'de': {
    short: {
      year: 'numeric', month: '2-digit', day: '2-digit'
    },
    long: {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second:'2-digit', hour12:false
    },
    longWithoutSeconds:{
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12:false
    },
    fullHour: {
      hour: '2-digit', minute: '2-digit', second:'2-digit', hour12:false
    }
  },
  'en': {
    short: {
      year: 'numeric', month: '2-digit', day: '2-digit'
    },
    long: {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second:'2-digit', hour12:false
    },
    longWithoutSeconds:{
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12:false
    },
    fullHour: {
      hour: '2-digit', minute: '2-digit', second:'2-digit', hour12:false
    }
  }
}
const numberFormats = {
  'de': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  "en": {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'code',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  }
}

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  datetimeFormats,
  numberFormats
});

export default i18n;
