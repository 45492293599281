<template>
    <img
        :class="{'disabled': isDisabled}"
        src="/img/icons/common/magic-wand.png"
        alt="Magic wand icon"
    >
</template>
  
<script>

export default {
    name: "MagicWandIcon",
    props: ['isDisabled'],
    setup() {
        // Methods

        return {
        };
    },
};
</script>

<style lang="scss" scoped>
img{
    &.disabled{
        cursor: not-allowed;
        filter: grayscale(1);
    }
}
</style>
  