<template>
  <div>
    <!-- Page content -->
    <div class="container" :class="isModal ? 'px-0' : 'pb-5 mt-9'">
      <div :class="isModal ? '' : 'row justify-content-center'">
        <div :class="isModal ? '' : 'col-lg-6 col-md-8'">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent pb-5">
              <div class="text-muted text-center mt-2 mb-2">
                <h2>{{ $t("signIn") }}</h2>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <Form @submit="login" :validation-schema="schema" :initialValues="userForm">
                <div class="row">
                  <div class="col-12 mb-4">
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      class="input-stevie w-100"
                      :placeholder="$t('email')"
                    ></Field>
                    <ErrorMessage name="email" class="text-danger text-required-error error-message-top" />
                  </div>
                  <div class="col-12 mb-4">
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      class="input-stevie w-100"
                      :placeholder="$t('password')"
                    ></Field>
                    <ErrorMessage name="password" class="text-danger text-required-error error-message-top" />
                  </div>
                  <div class="col-12 mb-4">
                    <Field
                      id="rememberMe"
                      name="rememberMe"
                      type="checkbox"
                      class="input-stevie"
                      v-slot="{ field }"
                      :placeholder="$t('rememberMe')"
                    >
                      <label>
                        <input type="checkbox" v-model="userForm.rememberMe" />
                        {{ $t("rememberMe") }}
                      </label>
                    </Field>
                  </div>
                </div>
                <div class="row justify-content-center m-0">
                  <base-button
                    type="primary"
                    native-type="submit"
                    class="col-12 mb-4 mx-0"
                    :disabled="isLoading || isLoadingFacebook"
                    >
                    <span v-if="!isLoading"> {{ $t("signIn") }}</span>
                     <clip-loader v-else color="#ffffff" size="15px" class=""></clip-loader>
                    </base-button
                  >
                  <base-button
                    class="col-12 mx-0 login-facebook border-0 font-weight-700"
                    @click="loginWithFacebook"
                    :disabled="isLoading || isLoadingFacebook"
                  >
                    <div v-if="!isLoadingFacebook">
                      <img class="mr-2" src="/img/icons/common/facebook.svg" alt="Facebook icon">
                      {{ $t('loginWithFacebook') }}
                    </div>
                    <clip-loader v-else color="#ffffff" size="15px" class=""></clip-loader>
                  </base-button>
                </div>
              </Form>
            </div>
          </div>
          <div class="row mt-3" :class="isModal ? 'mr-2' : ''">
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <router-link
                v-if="!isModal"
                :to="{ name: 'Register' }"
                class="text-light"
                ><small>{{ $t("createNewAccount") }}</small></router-link
              >
              <a
                v-else
                @click="$emit('register-modal')"
                class="text-light cursor-pointer"
                ><small>{{ $t("createNewAccount") }}</small></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VerifiedEmailModal v-model:show="isVerifiedEmailModal" @change-is-verified-email-modal="changeIsVerifiedEmailModal"></VerifiedEmailModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n/index";
import { inject, onMounted, ref } from "vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import VerifiedEmailModal from "../Components/VerifiedEmailModal";

import useLoggedIn from '../../composables/useLoggedIn';
import useVisibility from '../../composables/useVisibility';
import useUserData from '../../composables/useUserData';

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    VerifiedEmailModal,
    ClipLoader
  },
  props: {
    isModal: {
      type: Boolean,
    },
  },
  emits: ['register-modal', 'modal'],
  setup(props, { emit }) {

    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { t } = useI18n();
    const { getUserData, changeUserData }= useUserData();
    const loginFacebook = inject("loginFacebook");
    const changeLoginFacebook = inject("changeLoginFacebook");
    const isVerifiedEmailModal = ref(route.query.verified);
    const isLoadingFacebook= ref(false);
    const isLoading= ref(false);
    const userForm = ref({
      email: '',
      password: '',
      rememberMe: false
    })


    const { setIsLoggedIn, logout, isLoggedIn }= useLoggedIn();
    const { getVisibility } = useVisibility();
    const routeName = ref(localStorage.getItem("goToSubscription") ? "subscription" : "Dashboard" );

    changeLoginFacebook(JSON.parse(localStorage.getItem("loginFacebook")));

    const login = async (values) => {
      const route = "/auth/login";
      isLoading.value = true;
      let response = await axios.post(route, values);
      if (response && response.status === 200) {
        if(userForm.value.rememberMe){
          localStorage.setItem("recordedUser", values.email)
        }else{
          localStorage.removeItem("recordedUser")
        }
        const user = response.data;
        const accessToken = response.data["access_token"];
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("api_token", accessToken);
        axios.defaults.headers.common = {
          Authorization: `Bearer ${localStorage.getItem("api_token")}`,
        };
        setIsLoggedIn(true)
        changeUserData(JSON.parse(localStorage.getItem("user")));
        emit("modal");
        await getUserData()
        getVisibility();
        await router.push({ name: routeName.value });
        toast.success(t("welcome"));
      }
      isLoading.value = false;
    };

    const loginWithFacebook = async () => {
      if (loginFacebook.value.status !== "connected") {
        await new Promise(async (resolve) => {
          FB.login(function (response) {
            changeLoginFacebook(response);
            resolve();
          }, {scope: 'public_profile,email'});
        });
      }

      if (loginFacebook.value.status === "connected") {
        FB.api(`/me?fields=email`, async function(response) {
          const data = loginFacebook.value.authResponse;
          data.email = response.email;
          const route = "/fb/login";
          isLoadingFacebook.value = true;

          let responseLogin = await axios.post(route, data);
          if (responseLogin && responseLogin.status === 200) {
            const user = responseLogin.data;
            const accessToken = responseLogin.data["access_token"];
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("api_token", accessToken);
            axios.defaults.headers.common = {
              Authorization: `Bearer ${localStorage.getItem("api_token")}`,
            };
            setIsLoggedIn(true);
            changeUserData(JSON.parse(localStorage.getItem("user")));
            emit("modal");
            await getUserData()
            getVisibility();
            await router.push({name: routeName.value});
            toast.success(t("welcome"));
          }
          isLoadingFacebook.value = false;

        });
      }
    }

    const changeIsVerifiedEmailModal = () => {
      isVerifiedEmailModal.value = false;
      router.push({ name: "Login" });
    }

    const schema ={
      email: "required",
      password: "required",
    };
    onMounted(() => {
        if(isLoggedIn.value && !localStorage.getItem("api_token")){
          logout()
        }
        const recordedUser = localStorage.getItem("recordedUser")
        if(recordedUser){
          userForm.value.email = recordedUser;
          userForm.value.rememberMe = true;
        }
    })

    return {
      login,
      loginFacebook,
      isVerifiedEmailModal,
      loginWithFacebook,
      changeIsVerifiedEmailModal,
      schema,
      isLoading,
      isLoadingFacebook,
      userForm
    };
  },
};
</script>
<style lang="scss" scoped>
  :deep()label{
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    color: #525f7f;
  }
  :deep()input[id="email"]{
    appearance: none!important;
    padding-left: 33px!important;
    background: url('/img/icons/stevie/envelope-solid.svg') no-repeat left;
    background-size: 13px;
    background-position: 10px 10px;
  }
  :deep()input[type="password"]{
    appearance: none!important;
    padding-left: 33px!important;
    background: url('/img/icons/stevie/unlock-keyhole-solid.svg') no-repeat left;
    background-size: 13px;
    background-position: 10px 10px;
  }
  .error-message-top {
    top: 2.2rem;
  }
</style>
