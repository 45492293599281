import axios from "axios";
import router from "../router";
import { useToast } from "vue-toastification";

const toast = useToast();
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.params = {};
axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("api_token")}`,
};
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if(error && !error.response){
      toast.error("Something was wrong", { timeout: 2000 });
      return
    }
    //Avoid show error in post visibility
    const response = error.response;
    if(response.config.url === "/visibility" && response.config.method === "post" && router.currentRoute.value.name === "Landing"){
      error.response.data.errors = [];
    }
    //Avoid show multilple errors when logout
    if(response.request && response.request.responseURL && response.request.responseURL.includes('logout')) return

    //ERROR FIELDS
    let fieldsErrors = error.response.data;
    let message = fieldsErrors.message;
    let errors = fieldsErrors.errors;

    if (error.response.status === 422 && errors["url"]) {
      return errors;
    }
    if(error.response.status === 422 && Boolean(errors["error_openAI"])) {
      return errors;
    }

    // Error google api
    if (error.response.status === 403 && fieldsErrors.error && fieldsErrors.error.status === "PERMISSION_DENIED") {
      return fieldsErrors.error;
    }

    if (errors && typeof errors !== "string") {
      for (const field in errors) {
        const allErrors = errors[field];
        allErrors.map((error) => {
          toast.error(error, { timeout: 3000 });
          if (error === "Der aktuelle Status ist nicht gültig.") {
            router.push({ name: "CampaignsList"});
          }
        });
      }
    } else {
      //GENERAL ERROR
      switch (error.response.status) {
        case 401:
          localStorage.removeItem("user");
          localStorage.removeItem("api_token");
          router.push({ name: "Login" });
          break;
        case 504:
          message = "The request took too long, please try again later."
      }
      toast.error(message, { timeout: 3000 });
    }
    return error.response
  }
);

export default axios;
