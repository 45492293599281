export const formatWebsite = (value) => {
  if(!value)return value
  if(value.startsWith("http://")){
    value = value.split('http://')[1]
  }
  if (
    !(value.startsWith("http://") || value.startsWith("https://"))
  ) {
    value = `https://${value}`;
  }

  return value;
};

export const shortWebsite = (value) => {
  if(!value)return value
  if(value.startsWith("http://")|| value.startsWith("https://")){
    const start = value.startsWith("http://") ? "http://" : "https://";
    value.split(start)[1]
  }

  return value;
};

export const addHttpOrHttps = (url) => {
  if(!url) return url;
  
  const httpStr = "http://";
  const httpsStr = "https://";
  
  if(!url.startsWith(httpStr) && !url.startsWith(httpsStr)) {
    url = `${httpsStr}${url}`;
  }
  return url;
}

export const isInvalidUrl = (url) => {
  const regaex = /\b(?:(?:https?|ftp):\/\/)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|](\.)[a-z]{2}/i;
  return !regaex.test(url);
}
